import axios from "axios";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { errorToast } from "../../../react-toastfiy/toast";
import auctionService from "../../../services/auction/auction.service";

const CreateSegmentsModalPopup = (props) => {
  const { modal, data, setdata, setModal, setModalUpdate } = props;
  const [errors, setErrors] = useState({});
  const source = axios.CancelToken.source();

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const errors = {};
      if (!data?.name) errors.name = "Segment Name is required.";

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }

      const form_data = new FormData();
      form_data.append("name", data?.name);

      auctionService.CreateSegment(form_data, source).then((res) => {
        if (res.status === 200) {
          setModal(false);
          setModalUpdate(false);
        } else {
          setModal(false);
        }
      });
    } catch (error) {
      errorToast(error.msg);
    }
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    try {
      const errors = {};
      if (!data?.name) errors.name = "Segment Name is required.";

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }

      const form_data = new FormData();
      form_data.append("name", data?.name);
      form_data.append("segment_id", data?.segment_id);
      auctionService.UpdateSegment(form_data, source).then((res) => {
        if (res.status === 200) {
          setModal(false);
          setModalUpdate(false);
        } else {
          setModal(false);
        }
      });
    } catch (error) {
      errorToast(error.msg);
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        {data?.segment_id ? <h5>Update Segment</h5> : <h5>Create Segment</h5>}
      </Modal.Header>
      <Modal.Body>
        <div className="container table-responsive mt-2">
          <div className="col-12">
            <label>Name</label>
            <input
              className="form-control"
              type="text"
              name="name"
              value={data?.name}
              onChange={(e) => {
                setdata((prev) => ({ ...prev, ["name"]: e.target.value }));
              }}
              required
            />
            {errors.name && (
              <small className="text-danger">{errors.name}</small>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>
          Close
        </Button>
        {data?.segment_id !== "" ? (
          <Button variant="primary" onClick={handleUpdate}>
            Update
          </Button>
        ) : (
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CreateSegmentsModalPopup;
