import jwt from "jwt-decode";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import Select from "react-select";
import { Link, useLocation } from "react-router-dom";
import DatePicker from "../../common/DatePicker";
import SearchBox from "../../common/SearchBox";
import { formatDate } from "../../helpers/functions/dateFormatter";
import { errorToast } from "../../react-toastfiy/toast";
import auctionService from "../../services/auction/auction.service";
import { indianCurrencyFormater } from "../../helpers/functions/currencyFormater";

const AuctionSummaryDashboardData = () => {
  const location = useLocation();
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwt(currentUser);
  const phone = user.phone;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [segType, setSegType] = useState([]);
  const [auctionFilteredData, setAuctionFilteredData] = useState([]);
  const [AuctionData, setAuctionData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectSegType, setSelectSegType] = useState({
    segmentType: "",
  });

  useEffect(() => {
    try {
      if (startDate === "" && endDate === "") {
        setLoading(true);
        const monthly_summary_start_date =
          location?.state?.monthly_summary_start_date;
        const monthly_summary_end_date =
          location?.state?.monthly_summary_end_date;
        if (monthly_summary_start_date && monthly_summary_end_date) {
          setStartDate(monthly_summary_start_date);
          setEndDate(monthly_summary_start_date);

          let form_data = new FormData();
          form_data.append("phone", phone);
          form_data.append("start_date", monthly_summary_start_date);
          form_data.append("end_date", monthly_summary_end_date);
          auctionService.GetAuctionInDateRangeList(form_data).then((res) => {
            setLoading(false);
            if (res?.status === 200) {
              setAuctionData(res?.data);
              setAuctionFilteredData(res?.data);
            }
          });
        } else {
          const currentDate = new Date();
          const year = currentDate.getFullYear();
          const month = currentDate.getMonth();
          const date = currentDate.getDate();

          let firstDayOfMonth = formatDate(new Date(year, month - 1, date));
          let lastDayOfMonth = formatDate(new Date(year, month, date - 1));
          setStartDate(firstDayOfMonth);
          setEndDate(lastDayOfMonth);

          let form_data = new FormData();
          form_data.append("phone", phone);
          form_data.append("start_date", firstDayOfMonth);
          form_data.append("end_date", lastDayOfMonth);
          auctionService.GetAuctionInDateRangeList(form_data).then((res) => {
            setLoading(false);
            if (res?.status === 200) {
              setAuctionData(res?.data);
              setAuctionFilteredData(res?.data);
            }
          });
        }
      }
    } catch (error) {
      errorToast(error.msg);
    }
  }, []);

  useEffect(() => {
    auctionService.GetSegmentType().then((res) => {
      if (res.status === 200) {
        const segTypeOption = res.data.map((segmentType) => {
          return { label: segmentType.name, value: segmentType };
        });
        segTypeOption.unshift({
          label: "All Segment",
          value: { name: "all", id: 0 },
        });
        setSegType(segTypeOption);
        console.log({ segTypeOption });
        setSelectSegType({
          segmentType: segTypeOption[0]?.value?.id,
        });
      }
    });
    // }
  }, []);

  const handleGetData = () => {
    try {
      setLoading(true);
      let form_data = new FormData();
      form_data.append("phone", phone);
      form_data.append("start_date", startDate);
      form_data.append("end_date", endDate);
      auctionService.GetAuctionInDateRangeList(form_data).then((res) => {
        if (res?.status === 200) {
          setLoading(false);

          setAuctionData(res?.data);
          setAuctionFilteredData(res?.data);
        }
      });
    } catch (error) {
      errorToast(error.msg);
      setLoading(false);
    }
  };

  const generateExcel = (e, data) => {
    e.preventDefault();

    let csvContent = "data:text/csv;charset=utf-8,";

    const columns = [
      "Sr. No.",
      "Reference no.",
      "Segment",
      "Financier",
      "End Date",
      "Approvals",
      "Payment Done",
      "Backout",
      "Total bids",
      "Participating bidders count",
      "Non participating bidders count",
      "Total bidders",
      "Bided assets count",
      "Non bidded assets count",
      "Total listed assets",
      "Avg bid value",
      "Max Bid",
    ];

    csvContent += columns.join(",") + "\n";

    data.forEach((row, index) => {
      const values = [
        index + 1,
        row.auction_id,
        row.segment_name,
        row.auction_id?.split("-")[0],
        row.end_date,
        row.approvals,
        row.payments_done,
        row.backouts,
        row.total_bids,
        row.participating_bidders_count,
        row.non_participating_bidders_count,
        row.total_biders,
        row.bidded_assets_count,
        row.non_bidded_assets_count,
        row.total_assets_listed,
        row.avg_bid_value,
        row.max_bid,
      ];

      const adjustedValues = values.map((value) => {
        return `"${value.toString().replace(/"/g, '""')}"`;
      });
      csvContent += adjustedValues.join(",") + "\n";
    });

    const encodedUri = encodeURI(csvContent);

    const link = document.createElement("a");

    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `Dashboard_data_${endDate}`);
    document.body.appendChild(link);
    link.click();
  };

  const getYesterday = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (endDate > yesterday) {
      setEndDate(yesterday);
    } else {
      return endDate;
    }
  };

  const handleSelectSegmentType = (e) => {
    setSelectSegType({ segmentType: e.value.id });
    let currentAuctionData = [...AuctionData];
    if (e?.value?.name !== "all") {
      let newAuctionData = currentAuctionData.filter(
        (value) => value?.segment_name === e?.value?.name
      );
      setAuctionFilteredData(newAuctionData);
    } else {
      return setAuctionFilteredData(AuctionData);
    }
  };

  return (
    <>
      <div className="container mt-4">
        <div className="col-md-12 col-12 col-lg-12 mb-2 ">
          <div className="d-flex flex-wrap justify-content-between align-items-end">
            <div
              className="col-lg-3 col-12 col-md-3 col-sm-4 mb-2"
              style={{ paddingRight: "13px" }}
            >
              <span
                className="heading heading5 hind-font medium-font-weight"
                style={{
                  fontSize: "25px",
                  color: "black !important",
                  borderBottom: "1px solid grey",
                }}
              >
                Dashboard History
              </span>
            </div>
          </div>
        </div>
        <div className=" border-bottom-2 border-primary mb-2">
          {Object.keys(AuctionData).length !== 0 && (
            <>
              <span className="text-primary mx-2 mt-3">Download: </span>
              <Link
                onClick={(e) => {
                  generateExcel(e, auctionFilteredData);
                }}
              >
                Excel
              </Link>
            </>
          )}
        </div>
        <div className="col-md-12 col-12 col-lg-12 mb-2 ">
          <div className="d-flex flex-wrap justify-content-between align-items-end">
            <div
              className="col-lg-3 col-12 col-md-3 col-sm-2 mb-2"
              style={{ paddingRight: "10px" }}
            >
              <SearchBox
                allData={AuctionData}
                setFilteredData={setAuctionFilteredData}
              />
            </div>
            <div
              className="col-lg-3 col-12 col-md-3 col-sm-2 mb-2"
              style={{ paddingRight: "10px" }}
            >
              <Select
                options={segType}
                // isDisabled={Loading}
                value={
                  segType &&
                  segType.length !== 0 &&
                  segType.filter(
                    (segmentType) =>
                      segmentType?.value?.id ===
                      parseInt(selectSegType?.segmentType)
                  )[0]
                }
                onChange={handleSelectSegmentType}
              />
            </div>
            <div className="col-md-6 col-lg-5 col-12 mb-2">
              <div className="d-flex justify-content-between align-items-end gap-1">
                <div className="col-5">
                  <DatePicker
                    date={startDate}
                    id="start_date"
                    name="start_date"
                    placeholder="Start Date"
                    setDate={(e) => {
                      setStartDate((prev) => e);
                    }}
                    label="Start Date"
                    isRequired={false}
                  />
                </div>

                <div className="col-5">
                  <DatePicker
                    date={endDate}
                    id="end_date"
                    name="end_date"
                    placeholder="End Date"
                    setDate={(e) => setEndDate(e)}
                    label="End Date"
                    isRequired={false}
                    maxDate={getYesterday()}
                  />
                </div>
                <button
                  className=" btn btn-primary"
                  onClick={handleGetData}
                  disabled={loading}
                >
                  Go
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="d-flex justify-content-center col-12">
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <>
          {Object.keys(auctionFilteredData).length === 0 ? (
            <div className="container table-responsive mt-2">
              <table className="table table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Sr No.</th>
                    <th className="text-align-start ms-2"> Reference no.</th>
                    <th className="text-align-start">Segment</th>
                    <th className="text-align-start">Financier</th>
                    <th scope="col">End Date</th>
                    <th scope="col">ASP</th>
                    <th scope="col">Total bids</th>
                    <th scope="col">Participating bidders</th>
                    <th scope="col">Non participating bidders</th>
                    <th scope="col">Total bidders</th>
                    <th scope="col">Bided assets</th>
                    <th scope="col">Non bidded assets</th>
                    <th scope="col">Total assets</th>

                    <th scope="col">Approvals</th>
                    <th scope="col">Payment Done</th>
                    <th scope="col">Backout</th>
                    <th scope="col">Max Bid</th>
                  </tr>
                </thead>
              </table>
              <div className="container">
                <p className="text-center text-danger">No data</p>
              </div>
            </div>
          ) : (
            <>
              <div
                className=" container table-responsive mt-2"
                style={{ maxHeight: "65vh" }}
              >
                <table className="table table-bordered table-hover">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Sr No.</th>
                      <th className="text-align-start ms-2"> Reference no.</th>
                      <th className="text-align-start">Segment</th>
                      <th className="text-align-start">Financier</th>
                      <th scope="col">End Date</th>
                      <th scope="col">ASP</th>
                      <th scope="col">Total bids</th>
                      <th scope="col">Participating bidders</th>
                      <th scope="col">Non participating bidders</th>
                      <th scope="col">Total bidders</th>
                      <th scope="col">Bided assets</th>
                      <th scope="col">Non bidded assets</th>
                      <th scope="col">Total assets</th>

                      <th scope="col">Approvals</th>
                      <th scope="col">Payment Done</th>
                      <th scope="col">Backout</th>
                      <th scope="col">Max Bid</th>
                    </tr>
                  </thead>

                  <tbody>
                    {!loading &&
                    Object.entries(auctionFilteredData) &&
                    Object.entries(auctionFilteredData).length === 0 ? (
                      <>
                        <div className="text-danger mt-2 "></div>
                      </>
                    ) : (
                      Object.entries(auctionFilteredData).map(
                        ([key, rowData], rowIndex) => (
                          <tr key={rowIndex}>
                            <td>{rowIndex + 1}</td>
                            <td className="text-align-start ms-2">
                              {rowData?.auction_id}
                            </td>
                            <td className="text-align-start ms-2">
                              {rowData?.segment_name}
                            </td>
                            <td className="text-align-start ms-2">
                              {rowData?.auction_id?.split("-")[0]}
                            </td>
                            <td className="text-align-start">
                              {rowData?.end_date}
                            </td>
                            <td className="text-align-start">
                              {indianCurrencyFormater(rowData?.avg_bid_value)}
                            </td>
                            <td>{rowData?.total_bids}</td>
                            <td>{rowData?.participating_bidders_count}</td>
                            <td>{rowData?.non_participating_bidders_count}</td>
                            <td>{rowData?.total_biders}</td>
                            <td>{rowData?.bidded_assets_count}</td>
                            <td>{rowData?.non_bidded_assets_count}</td>
                            <td>{rowData?.total_assets_listed}</td>

                            <td className="text-align-start">
                              {rowData?.approvals}
                            </td>
                            <td className="text-align-start">
                              {rowData?.payments_done}
                            </td>
                            <td className="text-align-start">
                              {rowData?.backouts}
                            </td>
                            <td className="text-align-start">
                              {indianCurrencyFormater(rowData?.max_bid)}
                            </td>
                          </tr>
                        )
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AuctionSummaryDashboardData;
