import React, { useState } from "react";
import { AuctionSummaryHeader } from "./RapidbidHeader";
import ReactLoading from "react-loading";
import jsPDF from "jspdf";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";

const RapibidAuctionSummary = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const asset_id = location.pathname.split("/")[4]?.split("&")[0];

  const header = AuctionSummaryHeader;
  const data = [
    {
      no: 1,
      bidder_name: "Santosh Dangre",
      bidder_phone: "9504969695",
      pan: "COJPD1819M",
      bid: "264500",
      date: "25-02-2024 11:07:27",
    },
    {
      no: 2,
      bidder_name: "Santosh Dangre",
      bidder_phone: "9504969695",
      pan: "COJPD1819M",
      bid: "263500",
      date: "24-02-2024 18:57:44",
    },
    {
      no: 3,
      bidder_name: "Santosh Dangre",
      bidder_phone: "9504969695",
      pan: "COJPD1819M",
      bid: "262500",
      date: "24-02-2024 17:17:25",
    },
    {
      no: 4,
      bidder_name: "Shailendra Adsure",
      bidder_phone: "9011053024",
      pan: "-",
      bid: "261500",
      date: "24-02-2024 17:02:59",
    },
    {
      no: 5,
      bidder_name: "Santosh Dangre",
      bidder_phone: "9504969695",
      pan: "COJPD1819M",
      bid: "260500",
      date: "24-02-2024 12:45:06",
    },
  ];

  const handleDownloadPDF = () => {
    if (asset_id !== "") {
      const doc = new jsPDF();
      doc.setFontSize(8);
      doc.text(`Auction summary of asset_id ${asset_id}`, 8, 8);

      let headerY = 15;

      header.forEach((column, index) => {
        doc.text(column.header, 15 + index * 25, headerY);
      });

      // return;+
      let y = 23;
      const fieldGap = 8;
      data.forEach((item) => {
        Object.values(item).forEach((value, index) => {
          doc.text(value.toString(), 15 + index * 25, y);
        });
        y += fieldGap;
      });
      doc.save(`auction_summary of asset_id ${asset_id}.pdf`);
    }
  };
 
  const handleDownloadExcel = () => {
    if (asset_id !== "") {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);
      const headerValues = AuctionSummaryHeader.map(header => header.header);
      XLSX.utils.sheet_add_json(ws, [{}], {
        header: headerValues,
        skipHeader: false,
        origin: "A1",
      });
  
      XLSX.utils.book_append_sheet(wb, ws, "Auction Summary");
  
      const wbout = XLSX.write(wb, { type: "array", bookType: "xlsx" });
      const blob = new Blob([wbout], { type: "application/octet-stream" });
  
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = `auction_summary of asset_id ${asset_id}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <>
      <div className=" container mt-5 my-3 ">
        <div className="d-flex justify-content-between">
          <h4>Auction Summary</h4>
        </div>
        <div className="col-lg-3 col-12 col-md-4 col-sm-12 ms-auto mb-2">
          {/* <SearchBox allData={MakeData} setFilteredData={setFilterAllMake} /> */}
        </div>

        <div
          className="table-responsive"
          style={{ minHeight: "5vh", maxHeight: "70vh" }}
        >
          {loading ? (
            <div className="d-flex justify-content-center">
              <ReactLoading
                type={"bubbles"}
                color={"black"}
                height={100}
                width={100}
              />
            </div>
          ) : (
            <>
              <table className="table table-hover">
                <>
                  <thead className="thead-dark">
                    <tr
                      style={{
                        fontSize: "small",
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      {!loading &&
                        header &&
                        header.length !== 0 &&
                        header.map((data) => (
                          <th key={data.dataKey}>{data.header}</th>
                        ))}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading &&
                      Object.entries(data)?.length !== 0 &&
                      Object.entries(data)?.map(([key, item], index) => (
                        <tr key={index}>
                          <td>{item.no}</td>
                          <td>{item.bidder_name}</td>
                          <td>{item.bidder_phone}</td>
                          <td>{item.pan}</td>
                          <td>{item.bid}</td>
                          <td>{item.date}</td>
                          <td className="col-2">
                            <i
                              className="bi bi-trash mx-3"
                              id={item.id}
                              // onClick={(e) => {
                              //   handleDelete(e, item);
                              // }}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </>
              </table>
              {!loading &&
                Object.entries(data) !== undefined &&
                Object.entries(data).length === 0 && (
                  <div className="text-danger text-center mt-2 ">No Data</div>
                )}

              <div className="col-md-12 mb-3 form-group text-center">
                <button
                  className="btn btn-primary mt-2 mx-2 my-2 px-4 py-2"
                  onClick={handleDownloadPDF}
                >
                  Download PDF
                </button>
                <button
                  className="btn btn-primary mt-2 mx-2 my-2 px-4 py-2"
                  onClick={handleDownloadExcel}
                >
                  Download Excel
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RapibidAuctionSummary;
