import { useState } from "react";
import { Modal } from "react-bootstrap";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import MTLogo from "../img/logo2.png";
import error_in_image_loading from "../img/error_in_image_loading.svg";

const ImageViewer = (props) => {
  const { images, activeImage = 0 } = props;
  const [currentImage, setCurrentImage] = useState(activeImage);
  const [rotation, setRotation] = useState(0);
  const handleNext = () => {
    setRotation(0);
    setCurrentImage((prev) => (images.length - 1 === prev ? 0 : prev + 1));
  };
  const handlePrev = () => {
    setRotation(0);
    setCurrentImage((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };
  const rotateLeft = () => setRotation(rotation - 90);
  const rotateRight = () => setRotation(rotation + 90);
  return (
    <Modal
      {...props}
      fullscreen
      style={{
        background: "transparent",
        backgroundColor: "transparent",
        height: "100dvh",
        zIndex: 9999,
      }}
      contentClassName=" bg-transparent"
    >
      <button
        className=" btn btn-light bg-light btn-close position-absolute end-0 p-2 m-1"
        onClick={props?.onHide}
        style={{ zIndex: 45 }}
      ></button>
      <button
        className=" btn btn-light position-absolute top-50 start-0 btn-sm ms-1"
        onClick={handlePrev}
        style={{ zIndex: 45 }}
      >
        <i className="bi bi-chevron-left"></i>
      </button>
      <button
        className=" btn btn-light position-absolute top-50 end-0 btn-sm me-1"
        onClick={handleNext}
        style={{ zIndex: 45 }}
      >
        <i className="bi bi-chevron-right"></i>
      </button>
      <img
        src={MTLogo}
        height={14}
        className=" bg-white position-absolute top-0 start-0 m-1"
        style={{ zIndex: 45 }}
      />
      <div className=" d-flex position-absolute bottom-0 col-12 justify-content-center gap-2 mb-2">
        <button
          className=" btn btn-light btn-sm"
          onClick={rotateLeft}
          style={{ zIndex: 45 }}
        >
          <i className="bi bi-arrow-counterclockwise fs-6"></i>
        </button>
        <button
          className=" btn btn-light btn-sm"
          onClick={rotateRight}
          style={{ zIndex: 45 }}
        >
          <i className="bi bi-arrow-clockwise"></i>
        </button>
      </div>
      <TransformWrapper>
        <TransformComponent>
          <div
            className=" bg-transparent d-flex justify-content-center align-items-center"
            style={{
              background: "transparent",
              backgroundColor: "transparent",
              width: "100vw",
              height: "100dvh",
            }}
          >
            <img
              src={`${images[currentImage]}`}
              className="d-block w-100 bg-transparent"
              alt="No image loaded"
              loading="lazy"
              style={{
                objectFit: "contain",
                transform: `rotate(${rotation}deg)`,
                maxHeight: "100dvh",
              }}
              width={"100%"}
              onError={(e) => {
                e.target.src = error_in_image_loading;
              }}
            />
          </div>
        </TransformComponent>
      </TransformWrapper>
    </Modal>
  );
};
export default ImageViewer;