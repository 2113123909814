import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import SubscriptionActiveSubscriptionList from "../../subscription/ActiveSubscriptionList";
import ExpiredUsersSubscriptionlist from "./ExpiredUsersSubscriptionlist";
import SubscriptionExpiredUsersSubscriptionList from "../../subscription/ExpiredUsersSubscriptionList";

function SubscriptionUserListHomePage() {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  let history = useHistory();
  const [active, setactive] = useState("active-user");

  const handleAttendaceClicksh = (e) => {
    const sh_phone = e.target.getAttribute("phone");
    const sh_state = e.target.getAttribute("state");

    history.push(`/stateDashboard:${sh_phone}&state=${sh_state}`);
  };

  const handlechangetab = (e) => {
    const id = e.target.getAttribute("id");
    setactive(id);
    localStorage.setItem("active_sub_tab", id);
  };

  useEffect(() => {
    document.title = "rapibid Home";
    const lastActiveTab = localStorage.getItem("active_sub_tab");
    if (lastActiveTab) {
      setactive(lastActiveTab);
    }
  }, []);

  return (
    <>
      <div className="container mt-3">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link
              className={
                active === "active-user" ? `nav-link active` : `nav-link`
              }
              aria-current="page"
              to="#"
              id="active-user"
              onClick={handlechangetab}
            >
              Active
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={
                active === "expired-user" ? `nav-link active` : `nav-link`
              }
              to="#"
              id="expired-user"
              onClick={handlechangetab}
            >
              Expired
            </Link>
          </li>
        </ul>
      </div>
      {active === "active-user" && (
        <>
          <SubscriptionActiveSubscriptionList />
        </>
      )}
      {active === "expired-user" && (
        <>
          <SubscriptionExpiredUsersSubscriptionList />
        </>
      )}
    </>
  );
}

export default SubscriptionUserListHomePage;
