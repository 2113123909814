import axios from "axios";

class StateService {
  getStateById = (id) => {
    return axios({
      method: "post",
      url: `/users/get-state`,
      data: id,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getAllState = () => {
    return axios({
      method: "post",
      url: `/users/get-all-state`,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  CreateState = (formdata) => {
    return axios({
      method: "post",
      url: `/users/create-state`,
      data: formdata,
      headers: {},
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  EditState = (formdata) => {
    return axios({
      method: "post",
      url: `/users/edit-state`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  DeleteState = (formdata) => {
    return axios({
      method: "post",
      url: `/users/delete-state`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
}

export default new StateService();
