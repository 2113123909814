import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import dashboardService from "../../services/dashboard.service";
import { useQuery } from "react-query";
import ErrorHandler from "../../common/ErrorHandler";
import { errorToast } from "../../react-toastfiy/toast";
import "../../css/dashboard.css";

function AddTaskModal(props) {
  const [dashboardData, setDashboardData] = useState([]);

  const getTaskData = async () => {
    return await dashboardService.getTaskData();
  };

  let { id } = useParams();
  const phone = id.split(":")[1];

  useEffect(() => {
    try {
      getTaskData().then((res) => {
        setDashboardData(Object.values(res)[1]);
      });
    } catch (error) {
      console.error(error);
      errorToast(error?.message);
    }
    // setDashboardData(data);
  }, []);

  // Queries
  const {
    isLoading,
    isError,
    data,
    error: q_error,
    isSuccess,
  } = useQuery("getTaskData", getTaskData, { cacheTime: 0 });

  if (isLoading) return <span>Loading...</span>;

  try {
    return (
      <>
        <Modal
          {...props}
          size="md"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Task
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Object.keys(data.data).map((key, i) => {
              let operationId = key;
              let activityData = data.data[key].activities;
              return (
                <div className="container" key={i}>
                  <div className="row">
                    <div className="col-xs-12">
                      <ul className="list-unstyled">
                        <li key={key}>
                          <h4>{data.data[key].operation_name}</h4>
                          <ul className="live" style={{ paddingLeft: "1rem" }}>
                            {Object.keys(activityData).map((key, i) => {
                              let activityId = key;
                              if (activityData[key].form_url_bool == true) {
                                if (
                                  activityData[key].form_name === "sale_by_loan"
                                ) {
                                  return (
                                    <li key={key}>
                                      <Link
                                        to={
                                          activityData[key].form_url +
                                          `&operationId=` +
                                          operationId +
                                          `&activityId=` +
                                          activityId +
                                          `&phone=` +
                                          phone +
                                          `&edit=false&saleBy=loan` +
                                          `&activityName=` +
                                          activityData[key].activity_name
                                        }
                                        style={{
                                          textDecoration: "none",
                                          color: "black",
                                        }}
                                        // target="_blank"
                                      >
                                        {activityData[key].activity_name}
                                      </Link>
                                    </li>
                                  );
                                } else if (
                                  activityData[key].form_name === "sale_by_cash"
                                ) {
                                  return (
                                    <li key={key}>
                                      <Link
                                        to={
                                          activityData[key].form_url +
                                          `&operationId=` +
                                          operationId +
                                          `&activityId=` +
                                          activityId +
                                          `&phone=` +
                                          phone +
                                          `&edit=false&saleBy=cash` +
                                          `&activityName=` +
                                          activityData[key].activity_name
                                        }
                                        style={{
                                          textDecoration: "none",
                                          color: "black",
                                        }}
                                        // target="_blank"
                                      >
                                        {activityData[key].activity_name}
                                      </Link>
                                    </li>
                                  );
                                } else {
                                  return (
                                    <li key={key}>
                                      <Link
                                        to={
                                          activityData[key].form_url +
                                          `&operationId=` +
                                          operationId +
                                          `&activityId=` +
                                          activityId +
                                          `&phone=` +
                                          phone +
                                          `&edit=false` +
                                          `&activityName=` +
                                          activityData[key].activity_name
                                        }
                                        style={{
                                          textDecoration: "none",
                                          color: "black",
                                        }}
                                        // target="_blank"
                                      >
                                        {activityData[key].activity_name}
                                      </Link>
                                    </li>
                                  );
                                }
                              } else {
                                <li key={key}>
                                  <Link
                                    to={`/dashboard/addtask`}
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                    }}
                                  >
                                    {activityData[key].activity_name}
                                  </Link>
                                </li>;
                              }
                            })}
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </Modal.Body>
        </Modal>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
}

export default AddTaskModal;
