export function formatDate(date) {
  try {
    const day = String(date?.getDate())?.padStart(2, "0");
    const month = String(date?.getMonth() + 1)?.padStart(2, "0");
    const year = date?.getFullYear();
    return `${year}-${month}-${day}`;
  } catch (error) {
    return date;
  }
}

export function formatDateDDMMYYYY(date) {
  try {
    const day = String(date?.getDate())?.padStart(2, "0");
    const month = String(date?.getMonth() + 1)?.padStart(2, "0");
    const year = date?.getFullYear();
    return `${day}-${month}-${year}`;
  } catch (error) {
    return date;
  }
}
