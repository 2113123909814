import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
import RapibidAddMake from "./RapibidAddMake";
import { MakeHeader } from "./RapidbidHeader";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
import Swal from "sweetalert2";
import SearchBox from "../../../common/SearchBox";
import { errorToast } from "../../../react-toastfiy/toast";

const Make = () => {
  let header = MakeHeader;
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const initialValue = {
    name: "",
    logo: "",
  };
  const [createMakeData, setCreateMakeData] = useState(initialValue);
  const [MakeData, setMakeData] = useState([]);
  const [filterAllMake, setFilterAllMake] = useState([]);

  useEffect(() => {
    try {
      setLoading(true);
      SubscriptionServices.GetAllMake().then((res) => {
        if (Object.entries(res).length !== 0) {
          let all_make = Object.entries(res).map(([key, entry], index) => {
            entry["no"] = index + 1;
            return entry;
          });
          if (all_make.length !== 0) {
            setLoading(false);
            setMakeData(all_make);
            setFilterAllMake(all_make);
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
    }
  }, []);

  const handleOpenModal = (e, item) => {
    setModal(true);
    setCreateMakeData(item);
  };

  const handleDelete = (e, item) => {
    e.preventDefault();
    const name = item.name;
    const id = item.id;
    let form_data = new FormData();
    form_data.append("id", id);
    const notice = `You want to delete  ${name}  `;

    Swal.fire({
      title: "Are you sure?",
      html: notice,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await SubscriptionServices.DeleteMake(form_data);

          if (response.status === 200) {
            Swal.fire(
              "Deleted!",
              "Your data has been deleted.",
              "success"
            ).then((result) => {
              result && window.location.replace("/rapibid/auction/make");
            });
          }
          response.status !== 200 && errorToast(response.msg);
        } catch (error) {
          console.log(error);
          errorToast(error.message);
        }
      },
    });
  };

  return (
    <>
      {modal && (
        <RapibidAddMake
          show={modal}
          data={createMakeData}
          setmakedata={setCreateMakeData}
          onHide={() => {
            setModal(false);
          }}
        />
      )}
      <div className=" container mt-5 my-3 ">
        <div className="d-flex justify-content-between">
          <h4>Manage Makes</h4>

          <button
            className="btn btn-primary float-end my-1"
            // data-bs-toggle="modal"
            // data-bs-target="#staticBackdrop"
            onClick={handleOpenModal}
          >
            <i className="bi bi-plus-lg me-1"></i>Add Make
          </button>
        </div>
        <div className="col-lg-3 col-12 col-md-4 col-sm-12 ms-auto mb-2">
          <SearchBox allData={MakeData} setFilteredData={setFilterAllMake} />
        </div>

        <div
          className="table-responsive"
          style={{ minHeight: "5vh", maxHeight: "70vh" }}
        >
          {loading ? (
            <div className="d-flex justify-content-center">
              <ReactLoading
                type={"bubbles"}
                color={"black"}
                height={100}
                width={100}
              />
            </div>
          ) : (
            <>
              <table className="table table-hover table-bordered">
                <>
                  <thead className="thead-dark">
                    <tr
                      // className="text-center"
                      style={{
                        fontSize: "small",
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      <th>No</th>
                      {!loading &&
                        header &&
                        header.length !== 0 &&
                        header.map((column) => (
                          <th key={column.dataKey}>{column.header}</th>
                        ))}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading &&
                      Object.entries(filterAllMake)?.length !== 0 &&
                      Object.entries(filterAllMake)?.map(
                        ([key, item], index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td className="col-7">{item.logo}</td>
                            <td className="col-2">
                              <Link to="#" className="viewEditLink">
                                <i
                                  className="bi bi-pencil-square mx-3"
                                  id={item.id}
                                  onClick={(e) => handleOpenModal(e, item)}
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </Link>
                              <i
                                className="bi bi-trash mx-3"
                                id={item.id}
                                onClick={(e) => {
                                  handleDelete(e, item);
                                }}
                                style={{ cursor: "pointer" }}
                              ></i>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </>
              </table>
              {!loading &&
                Object.entries(filterAllMake) !== undefined &&
                Object.entries(filterAllMake).length === 0 && (
                  <div className="text-danger text-center mt-2 ">No Data</div>
                )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Make;
