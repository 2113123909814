import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
import SearchBox from "../../common/SearchBox";
import { errorToast } from "../../react-toastfiy/toast";
import auctionService from "../../services/auction/auction.service";

const PaymentPending = (props) => {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  let phone = user.phone;
  let state = user.state;

  const [paymentPending, setPaymentPending] = useState([]);
  const [FilteredDataPaymentPending, setFilteredDataPaymentPending] = useState(
    []
  );
  const [paymentPendingLoading, setPaymentPendingLoading] = useState(false);

  useEffect(() => {
    try {
      setPaymentPendingLoading(true);
      let form_data = new FormData();
      form_data.append("phone", phone);
      form_data.append("admin", true);
      auctionService.GetAllPaymentPending(form_data).then((res) => {
        setPaymentPendingLoading(false);
        if (res.status === 200) {
          setPaymentPending(res.data);
          setFilteredDataPaymentPending(res.data);
        }
      });
    } catch (error) {
      errorToast(error);
      console.log(error);
    }
  }, []);

  return (
    <>
      <div className="container mt-4">
        <span
          className="heading heading5 hind-font medium-font-weight"
          style={{
            fontSize: "25px",
            color: "black !important",
            borderBottom: "1px solid grey",
          }}
        >
          Auction List For Payment Pending
        </span>
        <div className="col-md-3 col-12 float-end mb-2 ms-2">
          {/* <label className="form-label mb-0">Search Here</label> */}
          <SearchBox
            placeholder="Search by Auction Id..."
            style={{ margin: "1px !important", fontSize: "16px" }}
            allData={paymentPending}
            setFilteredData={setFilteredDataPaymentPending}
          />
        </div>
      </div>
      {paymentPendingLoading &&
      Object.keys(FilteredDataPaymentPending).length === 0 ? (
        <ReactLoading
          type={"bubbles"}
          color={"black"}
          height={100}
          width={100}
          className="reactLoadingDash"
        />
      ) : (
        <>
          {FilteredDataPaymentPending.length === 0 ? (
            <div className="container table-responsive mt-2">
              <table className="table table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th>No.</th>
                    <th>Auction ID</th>
                    <th>Auction Name(Financier)</th>
                    <th>State</th>
                    <th>segment type</th>
                    <th>Count</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Close Request</th>
                  </tr>
                </thead>
              </table>
              <div className="container">
                <p className="text-center text-danger">No data</p>
              </div>
            </div>
          ) : (
            <>
              <div className="container mt-3">
                <div
                  className="container table-responsive mt-2"
                  style={{ minHeight: "5rem", maxHeight: "40rem" }}
                >
                  <table className="table table-bordered table-hover">
                    <thead className="thead-dark">
                      <tr>
                        <th>No.</th>
                        <th>Auction ID</th>
                        <th>Auction Name(Financier)</th>
                        <th>State</th>
                        <th>segment type</th>
                        <th>Count</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Close Request</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(FilteredDataPaymentPending).map(
                        ([key, index]) => {
                          let data = FilteredDataPaymentPending[key];

                          return (
                            <tr>
                              <td>{parseInt(key) + 1}</td>
                              <td>
                                <Link
                                  to={
                                    "/arDashboard/paymentPending/AssetListForPaymentPending:" +
                                    data["c_auction_id"] +
                                    "&endDate=" +
                                    data["end_date"] +
                                    "&startDate=" +
                                    data["start_date"]
                                  }
                                  style={{
                                    color: "black ",
                                    textDecoration: "none",
                                  }}
                                >
                                  {data["c_auction_id"]}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  style={{
                                    color: "black ",
                                    textDecoration: "none",
                                  }}
                                  to={
                                    "/arDashboard/paymentPending/AssetListForPaymentPending:" +
                                    data["c_auction_id"] +
                                    "&endDate=" +
                                    data["end_date"] +
                                    "&startDate=" +
                                    data["start_date"]
                                  }
                                >
                                  {data["auction_name"]}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  style={{
                                    color: "black ",
                                    textDecoration: "none",
                                  }}
                                  to={
                                    "/arDashboard/paymentPending/AssetListForPaymentPending:" +
                                    data["c_auction_id"] +
                                    "&endDate=" +
                                    data["end_date"] +
                                    "&startDate=" +
                                    data["start_date"]
                                  }
                                >
                                  {data["state"]}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  style={{
                                    color: "black ",
                                    textDecoration: "none",
                                  }}
                                  to={
                                    "/arDashboard/paymentPending/AssetListForPaymentPending:" +
                                    data["c_auction_id"] +
                                    "&endDate=" +
                                    data["end_date"] +
                                    "&startDate=" +
                                    data["start_date"]
                                  }
                                >
                                  {data["segment_type"]}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  style={{
                                    color: "black ",
                                    textDecoration: "none",
                                  }}
                                  to={
                                    "/arDashboard/paymentPending/AssetListForPaymentPending:" +
                                    data["c_auction_id"] +
                                    "&endDate=" +
                                    data["end_date"] +
                                    "&startDate=" +
                                    data["start_date"]
                                  }
                                >
                                  {data["count"]}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  style={{
                                    color: "black ",
                                    textDecoration: "none",
                                  }}
                                  to={
                                    "/arDashboard/paymentPending/AssetListForPaymentPending:" +
                                    data["c_auction_id"] +
                                    "&endDate=" +
                                    data["end_date"] +
                                    "&startDate=" +
                                    data["start_date"]
                                  }
                                >
                                  {data["start_date"]}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  style={{
                                    color: "black ",
                                    textDecoration: "none",
                                  }}
                                  to={
                                    "/arDashboard/paymentPending/AssetListForPaymentPending:" +
                                    data["c_auction_id"] +
                                    "&endDate=" +
                                    data["end_date"] +
                                    "&startDate=" +
                                    data["start_date"]
                                  }
                                >
                                  {data["end_date"]}
                                </Link>
                              </td>
                              <td>
                                <button
                                  className="btn btn-success btn-sm"
                                  style={{ margin: "2px !important" }}
                                >
                                  <Link
                                    style={{
                                      color: "white ",
                                      textDecoration: "none",
                                    }}
                                    to={
                                      "/arDashboard/paymentPending/AssetListForPaymentPending:" +
                                      data["c_auction_id"] +
                                      "&endDate=" +
                                      data["end_date"] +
                                      "&startDate=" +
                                      data["start_date"]
                                    }
                                  >
                                    View Details
                                  </Link>
                                </button>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PaymentPending;
