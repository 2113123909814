import React, { useState, useEffect } from "react";
import AddAuctionModalData from "./AddAuctionModalData";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
// import { useParams } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import EditAuctionModalData from "./EditAuctionModalData";
import { successToast } from "../../../react-toastfiy/toast";

const EditAuctionForm = () => {
  const location = useLocation();

  const [asset_id, setAsset_id] = useState("");

  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  let phone = user.phone;
  const [ContinueButton, setContinueButton] = useState(false);
  const [modal, setModal] = useState(false);
  const [stateList, setStateList] = useState([]);
  //  const id = useLocation.pathname

  let initialValue = {
    state: "",
    make: "",
    model: "",
    year: "",
    reg_no: "",
    location: "",
    note: "",
    date: "",
    start_time: "",
    minutes: "",
    recommendedBid: "",
    loan_ref_no: "",
    img: "",
  };
  const [addAuctionData, setaddAuctionData] = useState([]);
  const [addAuctionList, setaddAuctionList] = useState(initialValue);
  const [StateName, setStateName] = useState([]);
  const [stageOne, setStageOne] = useState(true);
  const [stageTwo, setStageTwo] = useState(true);
  const [stageThree, setStageThree] = useState(true);
  const [TractorBrand, setTractorBrand] = useState([]);
  const [yearLoading, setYearLoading] = useState(true);
  const [brandLoading, setBrandLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [TractorModal, setTractorModal] = useState([]);
  const [years, setYears] = useState([]);
  const [regLoading, setRegLoading] = useState(true);
  const [locationLoading, setLocationLoading] = useState(true);
  const [noteLoading, setNoteLoading] = useState(true);
  const [dateTimeLoading, setDateTimeLoading] = useState(true);
  const [startTimeLoading, setStartTimeLoading] = useState(true);
  const [minuteLoading, setMinuteLoading] = useState(true);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [recommendedLoading, setRecommendedLoading] = useState(true);
  const [loanRefNoLoading, setLoanRefNoLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    try {
      const asset = location.pathname.split("/")[4]?.split("&")[0];

      if (asset !== undefined && addAuctionData.length === 0) {
        setAsset_id(asset);

        let form_data = new FormData();
        form_data.append("asset_id", asset);

        SubscriptionServices.GetassetByAssetId(form_data).then((res) => {
          if (res.status === 400) {
            setaddAuctionData("");

            setaddAuctionList("");
          } else if (Object.entries(res).length !== 0) {
            let start_date = res?.start_time
              ? res.start_time.split(" ")[0]
              : "";
            let start_time = res?.start_time
              ? res.start_time.split(" ")[1].split(":")[0] +
                ":" +
                res.start_time.split(" ")[1].split(":")[1]
              : "";

            setStateName({
              ["state"]: res.state,
              ["make"]: res.make,
              ["model"]: res.model,
              ["year"]: res.year,
              ["reg_no"]: res.reg_no,
              ["location"]: res.location,
              ["note"]: res.note,
              ["start_date"]: start_date,
              ["start_time"]: start_time,
              ["minutes"]: res.minutes,
              ["recommendedBid"]: res.recommendedBid,
              ["loanRefNo"]: res.loanRefNo,
            });

            setaddAuctionList(res);
          }
        });
      }
    } catch (error) {}
  }, [location.pathname]);

  const handleUpdate = (e) => {
    e.preventDefault();
    setSubmitButtonLoading(true);

    let form_data = new FormData();
    form_data.append("asset_id", asset_id);
    form_data.append("state", addAuctionData.state_id);
    form_data.append("phone", phone);
    form_data.append("make", addAuctionData.make_id);
    form_data.append("model", addAuctionData.model_id);
    form_data.append("location", addAuctionData.location);
    form_data.append("reg_no", addAuctionData.reg_no);
    form_data.append("year", addAuctionData.year);
    form_data.append("note", addAuctionData.note);
    form_data.append("start_date", addAuctionList.date);
    form_data.append("start_time", addAuctionList.start_time);
    form_data.append("minutes", addAuctionData.minutes);
    form_data.append("recommendedBid", addAuctionData.recommendedBid);
    form_data.append("loanRefNo", addAuctionData.loan_ref_no);

    const front_filesBlob = new Blob(addAuctionData.front_img, {
      type: "application/octet-stream",
    });

    form_data.append("front-file-input", front_filesBlob, "front_files");

    const back_filesBlob = new Blob(addAuctionData.back_img, {
      type: "application/octet-stream",
    });
    form_data.append("back-file-input", back_filesBlob, "back_files");

    const tyre_filesBlob = new Blob(addAuctionData.tyre_img, {
      type: "application/octet-stream",
    });
    form_data.append("tyre-file-input", tyre_filesBlob, "tyre_files");

    const side_filesBlob = new Blob(addAuctionData.side_img, {
      type: "application/octet-stream",
    });
    form_data.append("side-file-input", side_filesBlob, "side_files");

    const engine_filesBlob = new Blob(addAuctionData.engine_img, {
      type: "application/octet-stream",
    });
    form_data.append("engine-file-input", engine_filesBlob, "engine_files");

    const misc_filesBlob = new Blob(addAuctionData.misc_img, {
      type: "application/octet-stream",
    });
    form_data.append("misc-file-input", misc_filesBlob, "misc_files");

    SubscriptionServices.UpdateAsset(form_data).then((res) => {
      console.log({ res }, res?.status);
      setSubmitButtonLoading(false);
      if (res.status === 200) {
        console.log({ res }, res?.status);
        successToast(res.message);

        setTimeout(() => {
          window.location.replace(
            `/rapidbid/manage-auction/manage-edit-auction/${asset_id}`
          );
        }, 500);
      } else {
        console.log({ res }, res?.status);
        successToast(res.message);
      }
    });
  };

  const handleselectTractor = (e) => {
    setModal(true);
    setaddAuctionData("");
    if (
      addAuctionData?.state_id == "" ||
      addAuctionData?.state_id == undefined
    ) {
      setStageOne(false);
      SubscriptionServices.GetAllState().then((res) => {
        if (Object.entries(res).length !== 0) {
          setStateList(res);
        }
      });
      setBrandLoading(true);
      SubscriptionServices.GetAllMake().then((res) => {
        if (Object.entries(res).length !== 0) {
          setBrandLoading(false);
          setTractorBrand(res);
        }
      });
    } else if (
      addAuctionData?.make_id == "" ||
      addAuctionData?.make_id == undefined
    ) {
      setStageTwo(false);
    } else if (
      addAuctionData?.model_id == "" ||
      addAuctionData?.model_id == undefined
    ) {
      setStageThree(false);
      setModalLoading(true);
      SubscriptionServices.GetAllModel().then((res) => {
        if (Object.entries(res).length !== 0) {
          setModalLoading(false);
          setTractorModal(res);
        }
      });
    } else if (
      addAuctionData?.year == undefined ||
      addAuctionData?.year == ""
    ) {
      setYearLoading(false);
      if (
        addAuctionData.model_id !== undefined &&
        addAuctionData.model_id !== ""
      ) {
        const currentYear = new Date().getFullYear();
        const last20Years = Array.from(
          { length: 20 },
          (_, index) => currentYear - index
        );
        setYears(last20Years);
        setYearLoading(true);
      }
    } else if (
      addAuctionData.reg_no == undefined ||
      addAuctionData.reg_no == ""
    ) {
      setRegLoading(false);
    } else if (
      addAuctionData.location == undefined ||
      addAuctionData.location == ""
    ) {
      setLocationLoading(false);
    } else if (addAuctionData.note == undefined || addAuctionData.note == "") {
      setNoteLoading(false);
    } else if (addAuctionData.date == undefined && addAuctionData.date == "") {
      setDateTimeLoading(false);
    } else if (
      addAuctionData.start_time == undefined ||
      addAuctionData.start_time == ""
    ) {
      setStartTimeLoading(false);
    } else if (
      addAuctionData.minutes == undefined ||
      addAuctionData.minutes == ""
    ) {
      setMinuteLoading(false);
    } else if (
      addAuctionData.recommendedBid == undefined ||
      addAuctionData.recommendedBid == ""
    ) {
      setRecommendedLoading(false);
    } else if (
      addAuctionData.loanRefNo == undefined ||
      addAuctionData.loanRefNo == ""
    ) {
      setLoanRefNoLoading(false);
    } else {
      setImageLoading(false);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    window.location.replace(
      `/rapidbid/manage-auction/edit-auction-home/:${asset_id}`
    );
  };

  return (
    <>
      {modal && (
        <EditAuctionModalData
          show={modal}
          setaddAuctionData={setaddAuctionData}
          setLocationLoading={setLocationLoading}
          locationLoading={locationLoading}
          setaddAuctionList={setaddAuctionList}
          setDateTimeLoading={setDateTimeLoading}
          dateTimeLoading={dateTimeLoading}
          setStageOne={setStageOne}
          noteLoading={noteLoading}
          setNoteLoading={setNoteLoading}
          setStageTwo={setStageTwo}
          setStageThree={setStageThree}
          setYearLoading={setYearLoading}
          setTractorBrand={setTractorBrand}
          brandLoading={brandLoading}
          setBrandLoading={setBrandLoading}
          setModalLoading={setModalLoading}
          setregLoading={setRegLoading}
          regLoading={regLoading}
          TractorModal={TractorModal}
          setTractorModal={setTractorModal}
          TractorBrand={TractorBrand}
          modalLoading={modalLoading}
          setYears={setYears}
          years={years}
          yearLoading={yearLoading}
          stageOne={stageOne}
          stageTwo={stageTwo}
          stageThree={stageThree}
          addAuctionList={addAuctionList}
          addAuctionData={addAuctionData}
          StateName={StateName}
          setStateName={setStateName}
          startTimeLoading={startTimeLoading}
          setMinuteLoading={setMinuteLoading}
          setStartTimeLoading={setStartTimeLoading}
          setRecommendedLoading={setRecommendedLoading}
          setLoanRefNoLoading={setLoanRefNoLoading}
          loanRefNoLoading={loanRefNoLoading}
          recommendedLoading={recommendedLoading}
          minuteLoading={minuteLoading}
          setImageLoading={setImageLoading}
          imageLoading={imageLoading}
          //   data={modalData}
          //   setstatedata={setModalData}
          setModal={setModal}
          stateList={stateList}
          onHide={() => {
            setModal(false);
          }}
        />
      )}
      <div className="container d-flex d-flex-wrap justify-content-center ">
        <div
          className=" col-md-6 col-12 shadow-lg bg-body rounded"
          style={{
            marginTop: "5rem",
          }}
        >
          <div class="text-bg-dark rounded mx-1 mt-1">
            <h5
              class="card-header text-center py-2"
              //   style={{ width: "40rem", height: "3rem" }}
            >
              Edit Auction
            </h5>
            <p className="text-center mb-1">
              Edit information to update the auction for all dealerships
              available out there.
            </p>
          </div>

          <form>
            <div className="row mt-3">
              <div className=" col-md-10 col-11 mx-3 my-3 form-group ">
                <label htmlFor="name">Select Tractor</label>
                <input
                  className="form-control"
                  placeholder="Year , Make , Model"
                  type="text"
                  name="Make, Model, Year"
                  value={
                    addAuctionList !== ""
                      ? `${addAuctionList?.year} ${addAuctionList?.make}  ${addAuctionList?.model}`
                      : "Year , Make , Model"
                  }
                  onClick={handleselectTractor}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className=" col-md-10 col-11 mx-3 my-3 form-group">
                <label htmlFor="name">State and Location</label>
                <input
                  className="form-control"
                  placeholder="State, Location"
                  type="text"
                  name="plan_name"
                  value={
                    addAuctionList !== ""
                      ? `${addAuctionList?.state} ${addAuctionList?.location}`
                      : "State, Location"
                  }
                  onClick={handleselectTractor}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className=" col-md-10 col-11 mx-3 my-3 form-group">
                <label htmlFor="name">Recommended Bid</label>
                <input
                  className="form-control"
                  placeholder="Recommended Bid"
                  type="text"
                  value={
                    addAuctionList !== ""
                      ? `${addAuctionList?.recommendedBid}`
                      : "Recommended Bid"
                  }
                  name="plan_name"
                  onClick={handleselectTractor}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className=" col-md-10 col-11 mx-3 my-3 form-group">
                <label htmlFor="name">Registration Number</label>
                <input
                  className="form-control"
                  placeholder="Registration No"
                  type="text"
                  name="plan_name"
                  value={
                    addAuctionList !== ""
                      ? `${addAuctionList?.reg_no}`
                      : "Registration No"
                  }
                  onClick={handleselectTractor}
                />
              </div>
            </div>
            <div className="d-flex d-flex-wrap justify-content-center mb-3">
              {submitButtonLoading ? (
                <button className="btn btn-success" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className="btn btn-success mx-1 my-2 px-3 py-2 rounded"
                  type="submit"
                  onClick={handleUpdate}
                >
                  Update
                </button>
              )}
              <button
                className="btn btn-danger mx-1 my-2 px-3 py-2 rounded"
                type="submit"
                onClick={(e) => {
                  handleCancel(e);
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditAuctionForm;
