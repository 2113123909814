import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import SearchBox from "../../common/SearchBox";
import "../../css/common.css";
import { errorToast } from "../../react-toastfiy/toast";
import SubscriptionServices from "../../services/subscription/subscription.service";
import CustomTable from "./CustomTable";
import { TenorHeader } from "./SubscriptionHeader";
import AddTenureModalPopup from "./AddTenureModalPopup";

const PlatformTenureList = () => {
  const header = TenorHeader;
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  let entry;
  const [createDurationData, setCreateDurationData] = useState({
    platform_type: "",
    duration: "",
  });
  const [FilterTenorData, setFilterTenorData] = useState([]);
  useEffect(() => {
    try {
      setLoading(true);
      SubscriptionServices.GetSubscriptionTenorList().then((res) => {
      
        if (res?.status === 200 && res?.data?.length >= 0) {
          setLoading(false);
         
          let Tenor_data = Object.entries(res.data).map(
            ([key, entry], index) => {
              entry["no"] = index + 1;
              return entry;
            }
          );
          if (Tenor_data.length !== 0) {
            setData(Tenor_data);
            setFilterTenorData(Tenor_data);
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
          setData(res.data);
          setFilterTenorData(res.data);
        }
      });
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
    }
  }, []);

  const handleDelete = (e, entry) => {
    e.preventDefault();
    const id = e.target;
    let form_data = new FormData();
    form_data.append("duration_id", entry.duration_id);
    const notice = `You want to delete this subscription duration  ${id}  `;
    Swal.fire({
      title: "Are you sure?",
      text: notice,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const ṛes = await SubscriptionServices.DeleteTenorList(form_data);
          if (ṛes.status === 200) {
            Swal.fire(
              "Deleted!",
              "Your data has been deleted.",
              "success"
            ).then((result) => {
              result &&
                window.location.replace("/subscription/manage-platform-tenor");
            });
          }

          ṛes.status !== 200 && errorToast(ṛes.msg);
        } catch (error) {
          console.log(error);
          errorToast(error.message);
        }
      }
    });
  };

  const handleModal = (e, entry) => {
    setModal(true);
    setCreateDurationData((prevState) => {
      return {
        ...prevState,
        ["platform_type"]: entry.platform_type,
        ["duration"]: entry.duration.replace(" Months", ""),
        ["duration_id"]: entry.duration_id,
      };
    });
  };

  return (
    <>
      {modal && (
        <AddTenureModalPopup
          show={modal}
          setCreateDurationData={setCreateDurationData}
          createDurationData={createDurationData}
          onHide={() => {
            setModal(false);
          }}
        />
      )}
      <div className="container mt-3">
        <div className="col-md-12 col-12 col-lg-12 mb-2 ">
          <div className="d-flex flex-wrap justify-content-between align-items-end">
            <div className="col-lg-7 col-12 col-md-6 col-sm-12 ms-2">
              <h4>Tenure List</h4>
            </div>

            <div className="col-lg-3 col-12 col-md-3 col-sm-12 ms-auto">
              <SearchBox allData={data} setFilteredData={setFilterTenorData} />
            </div>
            <div className="col-lg-1 col-12 col-md-2 col-sm-12 ms-auto">
              <button
                type="button"
                // className="btn btn-primary yx-3"
                className="btn btn-primary flex-grow-1 mt-2"
                onClick={(e) => {
                  setModal(true);
                  setCreateDurationData([]);
                }}
              >
                Add Tenure
              </button>
            </div>
          </div>
        </div>
        <CustomTable
          loading={Loading}
          columns={header}
          handleModal={handleModal}
          setModal={setModal}
          setCreateDurationData={setCreateDurationData}
          data={FilterTenorData}
          handleDelete={handleDelete}
          entry={entry}
          action={"Tenure"}
        />
      </div>
    </>
  );
};

export default PlatformTenureList;
