import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useHistory } from "react-router-dom";
import { errorToast } from "../../react-toastfiy/toast";
import auctionService from "../../services/auction/auction.service";

const LogoutUser = () => {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwtDecode(currentUser);
  const history = useHistory();
  const source = axios.CancelToken.source();
  const [loading, setLoading] = useState(false);
  const [logoutButtonLoading, setLogoutButtonLoading] = useState(false);
  const [logoutUserData, setLogoutUserData] = useState([]);
  const [filterLogoutUserData, setFilterLogoutUserData] = useState([]);
  const headers = [
    { header: "Name", dataKey: "user_name" },
    { header: "Phone", dataKey: "phone" },
    { header: "Login Date", dataKey: "login_time" },
  ];

  useEffect(() => {
    try {
      setLoading(true);
      auctionService.GetUserLoginDetails(source).then((res) => {
        setLoading(false);
        if (res?.length >= 0) {
          console.log({ res });
          setLogoutUserData(res);
          setFilterLogoutUserData(res);
        }
      });
    } catch (error) {
      errorToast(error.msg);
    }
  }, []);

  const handleLogout = (e, value, index) => {
    e.preventDefault();
    try {
      setLogoutButtonLoading(true);
      const form_data = new FormData();
      form_data.append("phone", value?.phone);
      auctionService.UserLogout(form_data, source).then((res) => {
        if (res.length >= 0) {
          setLoading(true);
          auctionService.GetUserLoginDetails(source).then((res) => {
            setLoading(false);
            setLogoutButtonLoading(false);

            if (res?.length >= 0) {
              setLogoutUserData(res);
              setFilterLogoutUserData(res);
            }
          });
        } else {
          errorToast(res?.msg);
        }
      });
    } catch (error) {
      errorToast(error.msg);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    if (value !== "") {
      const user_logout = logoutUserData?.filter(
        (val) =>
          val?.phone.toString().includes(value) ||
          val?.user_name.toString().includes(value)
      );
      setFilterLogoutUserData(user_logout);
    }
  };

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container responsive mt-2 ms-auto ">
        <div className="row mt-3 mb-2">
          <span className="d-flex justify-content-between col-1">
            <i
              className="bi bi-arrow-left-circle"
              onClick={() => history.push("/meratractor")}
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </span>
          <div className="col-10 col-sm-4 mt-2 float-start ms-2">
            <h4>Logout User</h4>
          </div>
          <div className="col-md-6 col-lg-4 col-12 ms-auto mt-2">
            <input
              className="form-control"
              type="text"
              name="name"
              placeholder="Search by Segment"
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>

      {loading ? (
        <div
          className="d-flex justify-content-center container"
          style={{ minHeight: "80vh", alignItems: "center" }}
        >
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <>
          <div className="container table-responsive mt-3">
            {/* <div
              className="d-flex justify-content-between"
              style={{ marginBottom: "-21px" }}
            >
              <i
                className="bi bi-arrow-left-circle"
                onClick={() => history.goBack()}
                style={{
                  fontSize: "xx-large",
                  color: "black",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              ></i>
            </div>
            <div className="row mb-2 mt-3">
              <div className="col-md-6 col-lg-4 col-12 mt-3">
                <h4>Manage Financiers</h4>
              </div>

              <div className="col-md-6 col-lg-4 col-12 ms-auto">
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  placeholder="Search by Segment"
                  onChange={handleSearch}
                />
              </div>
            </div> */}
            {/* <div className="container d-flex flex-wrap justify-content-between mt-3"> */}

            <div
              className="table-responsive text-center"
              style={{ minHeight: "5vh", maxHeight: "80vh" }}
            >
              <table className=" table table-hover table-bordered">
                <thead className="thead-dark">
                  <tr
                    className="text-center"
                    style={{ fontSize: "small", position: "sticky", top: 0 }}
                  >
                    <th> Sr. No</th>
                    {headers.map((column) => (
                      <th className="col" key={column.header}>
                        {column.header}
                      </th>
                    ))}
                    <th> Logout</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(filterLogoutUserData) &&
                  Object.entries(filterLogoutUserData).length === 0 ? (
                    <div className="text-danger mt-2 "></div>
                  ) : (
                    Object.entries(filterLogoutUserData).map(
                      ([key, rowData], rowIndex) => (
                        <tr
                          style={{
                            fontSize: "small",
                          }}
                        >
                          <td>{rowIndex + 1}</td>
                          <td className="text-align-start">
                            {rowData.user_name}
                          </td>
                          <td>{rowData.phone}</td>
                          <td>{rowData.login_time}</td>
                          <td>
                            {logoutButtonLoading ? (
                              <button
                                className="btn btn-danger"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-border spinner-border-sm me-1"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Loading...
                              </button>
                            ) : (
                              <button
                                className="btn btn-danger"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  handleLogout(e, rowData, rowIndex);
                                }}
                              >
                                Logout
                              </button>
                            )}
                          </td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </table>
              {logoutUserData && logoutUserData.length === 0 && (
                <div className="text-danger text-center mt-2 ">No Data</div>
              )}
            </div>
          </div>
          {/* </div> */}
        </>
      )}
    </>
  );
};

export default LogoutUser;
