import { Column } from "jspdf-autotable";
import { startCase } from "lodash";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const AuctionvehicalImgModal = (props) => {
  const {
    shown,
    onHide,
    selectedFile,
    selectedFrontFile,
    setImgModalData,
    selectedBackFile,
    selectedTyreFile,
    setImgModal,
    setselectedFrontFile,
    setselectedBackFile,
    setselectedTyreFile,
    setselectedSideFile,
    setselectedMiscFile,
    setselectedEngineFile,
    flag,
  } = props;

  console.log({ flag, selectedFrontFile });

  const handleRemoveImage = (e, value, index) => {
    if (flag == "front") {
      const RemovedData = selectedFile[index];
      const updatedData = selectedFile.filter((item) => item !== RemovedData);

      setselectedFrontFile(updatedData);
      setImgModalData(updatedData);
      if (selectedFile.length === 1) {
        setImgModal(false);
      }
    } else if (flag == "back") {
      const RemovedData = selectedFile[index];
      const updatedData = selectedFile.filter((item) => item !== RemovedData);

      setselectedBackFile(updatedData);
      setImgModalData(updatedData);
      if (selectedFile.length === 1) {
        setImgModal(false);
      }
    } else if (flag == "tyre") {
      const RemovedData = selectedFile[index];
      const updatedData = selectedFile.filter((item) => item !== RemovedData);
      setselectedTyreFile(updatedData);
      setImgModalData(updatedData);
      if (selectedFile.length === 1) {
        setImgModal(false);
      }
    } else if (flag == "side") {
      const RemovedData = selectedFile[index];
      const updatedData = selectedFile.filter((item) => item !== RemovedData);
      setselectedSideFile(updatedData);
      setImgModalData(updatedData);
      if (selectedFile.length === 1) {
        setImgModal(false);
      }
    } else if (flag == "engine") {
      const RemovedData = selectedFile[index];
      const updatedData = selectedFile.filter((item) => item !== RemovedData);
      setselectedEngineFile(updatedData);
      setImgModalData(updatedData);
      if (selectedFile.length === 1) {
        setImgModal(false);
      }
    } else if (flag == "misc") {
      const RemovedData = selectedFile[index];
      const updatedData = selectedFile.filter((item) => item !== RemovedData);
      setselectedMiscFile(updatedData);
      setImgModalData(updatedData);
      console.log(selectedFile.length)
      if (selectedFile.length === 1) {
        console.log(selectedFile.length)
        setImgModal(false);
      }
    }
  };
  try {
    return (
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        aaria-labelledby="contained-modal-title-vcenter"
        // style={{height:"1200px"}}
        dialogClassName="modal-90w"
        centered
        // fullscreen
      >
        <Modal.Header closeButton>
          <div
            id="example-custom-modal-styling-title"
            className="modal-title-custom container"
          >
            {/* <h4>Update Make</h4> */}
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="row mt-3 ">
            {selectedFile.map((value, index) => (
              <>
                <img
                  src={value ? URL.createObjectURL(value, index) : ""}
                  id={`${flag}${index}`}
                  alt="img"
                  style={{
                    margin: "startCase",
                    flexDirection: "column-reverse",
                    height: "200px",
                    width: "350px",
                  }}
                />
                <i
                  className="bi bi-x"
                  onClick={(e) => handleRemoveImage(e, value, index)}
                  style={{
                    margin: "startCase",
                    flexDirection: "column-reverse",
                    height: "200px",
                    width: "350px",
                    cursor: "pointer",
                  }}
                ></i>
              </>
            ))}
          </div>
        </Modal.Body>

        {/* <Modal.Footer>
              <Button onClick={onHide}>Close</Button>
              {data && data?.id !== "" ? (
                <>
                  {UpdateButtonLoading ? (
                    <button
                      className="btn btn-primary mt-2"
                      type="button"
                      disabled
                      id="loadingBtnTp"
                    >
                      <span
                        className="spinner-border spinner-border-sm mx-1 px-2"
                        type="submit"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <Button
                      variant="primary"
                      // onClick={handleUpdate}
                    >
                      Update
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {SubmitButtonLoading ? (
                    <button
                      className="btn btn-primary mt-2"
                      type="button"
                      disabled
                      id="loadingBtnTp"
                    >
                      <span
                        className="spinner-border spinner-border-sm mx-1 px-2"
                        type="submit"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <Button
                      variant="primary"
                      // onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  )}
                </>
              )}
            </Modal.Footer> */}
      </Modal>
    );
  } catch (error) {
    console.log(error);
  }
};

export default AuctionvehicalImgModal;
