import React from "react";
import Modal from "react-bootstrap/Modal";
import "../../css/dashboard.css";

import ErrorHandler from "../../common/ErrorHandler";
import { formatDateDDMMYYYY } from "../../helpers/functions/dateFormatter";
import { indianCurrencyFormater } from "../../helpers/functions/currencyFormater";

function AuctionUserDetailsModal(props) {
  const { data } = props;

  const rowClass =
    "d-flex justify-content-start flex-column col-md-6 col-12 p-2 col-xl-4";

  try {
    return (
      <>
        <Modal
          {...props}
          size="lg"
          backdrop="static"
          aaria-labelledby="contained-modal-title-vcenter"
          //   className="top-right-modal"
          scrollable
          //   centered={isCenter}
          centered
        >
          <Modal.Header closeButton>
            <h5>{data?.name}</h5>
          </Modal.Header>
          <Modal.Body>
            <div className=" row">
              <div className={rowClass}>
                <span className=" fw-semibold">Name</span>
                <span>{data?.name}</span>
              </div>
              <div className={rowClass}>
                <span className=" fw-semibold">Phone number</span>
                <span>{data?.phone}</span>
              </div>
              <div className={rowClass}>
                <span className=" fw-semibold">Email</span>
                <span>{data?.email ? data?.email : "-"}</span>
              </div>
            </div>
            <div className="row">
              <div className={rowClass}>
                <span className=" fw-semibold">State</span>
                <span>
                  {typeof data?.state === "object"
                    ? data?.state?.label
                    : data?.state}
                </span>
              </div>
              <div className={rowClass}>
                <span className=" fw-semibold">Address</span>
                <span>{data?.address ? data?.address : "-"}</span>
              </div>
              <div className={rowClass}>
                <span className=" fw-semibold">Pincode</span>
                <span>{data?.pincode ? data?.pincode : "-"}</span>
              </div>
            </div>
            <div className="row">
              <div className={rowClass}>
                <span className=" fw-semibold">AADHAAR</span>
                <span>{data?.aadhar}</span>
              </div>
              <div className={rowClass}>
                <span className=" fw-semibold">AADHAAR front</span>
                {data?.front_aadhar_file ? (
                  <span>
                    <a
                      href={data?.front_aadhar_file}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      {" "}
                      <i className=" bi bi-cloud-arrow-down-fill"></i> Download
                    </a>
                  </span>
                ) : (
                  <span className=" text-danger fw-semibold">No document</span>
                )}
              </div>
              <div className={rowClass}>
                <span className=" fw-semibold">AADHAAR back</span>
                {data?.back_aadhar_file ? (
                  <span>
                    <a
                      href={data?.back_aadhar_file}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      {" "}
                      <i className=" bi bi-cloud-arrow-down-fill"></i> Download
                    </a>
                  </span>
                ) : (
                  <span className=" text-danger fw-semibold">No document</span>
                )}
              </div>
            </div>
            <div className=" row">
              <div className={rowClass}>
                <span className=" fw-semibold">PAN</span>
                <span>{data?.pan}</span>
              </div>
              <div className={`${rowClass}`}>
                <span className=" fw-semibold">PAN file</span>
                {data?.pan_file ? (
                  <span className="">
                    <a
                      className="text-decoration-none"
                      href={data?.pan_file}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      <i className=" bi bi-cloud-arrow-down-fill"></i> Download
                    </a>
                  </span>
                ) : (
                  <span className=" text-danger fw-semibold">No document</span>
                )}
              </div>
              <div className={rowClass}>
                <span className=" fw-semibold">Payment amount</span>
                <span className=" text-success fw-semibold">
                  {indianCurrencyFormater(data?.payment_amount, 0)}
                </span>
              </div>
            </div>
            <div className=" row">
              <div className={rowClass}>
                <span className=" fw-semibold">Payment date</span>
                <span>
                  {data?.payment_date
                    ? formatDateDDMMYYYY(new Date(data?.payment_date))
                    : "-"}
                </span>
              </div>
              <div className={rowClass}>
                <span className=" fw-semibold">UTR number</span>
                <span>{data?.utr}</span>
              </div>
              <div className={rowClass}>
                <span className=" fw-semibold">Payment proof</span>
                {data?.payment_proof ? (
                  <span>
                    <a
                      href={data?.payment_proof}
                      target="_blank"
                      className="text-decoration-none"
                      rel="noreferrer"
                    >
                      {" "}
                      <i className=" bi bi-cloud-arrow-down-fill"></i> Download
                    </a>
                  </span>
                ) : (
                  <span className=" text-danger fw-semibold">No document</span>
                )}
              </div>{" "}
            </div>
            <div className="row">
              <div className={rowClass}>
                <span className=" fw-semibold">Plan Name</span>
                <span>{data?.plan_name ? data?.plan_name : "-"}</span>
              </div>
              <div className={rowClass}>
                <span className=" fw-semibold">Plan Tenure</span>
                <span>
                  {data?.plan_tenure ? data?.plan_tenure : "-"} Month
                  {parseInt(data?.plan_tenure) > 1 ? "s" : ""}
                </span>
              </div>
              <div className={rowClass}>
                <span className=" fw-semibold">Remarks</span>
                <span>{data?.remarks ? data?.remarks : "-"}</span>
              </div>
            </div>
            <div className="row">
              <div className={rowClass}>
                <span className=" fw-semibold">
                  Auction Super Admin remarks
                </span>
                <span>
                  {data?.auction_admin_user_approval_remarks
                    ? data?.auction_admin_user_approval_remarks
                    : "-"}
                </span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  } catch (error) {
    console.log(error);
    return <ErrorHandler error={error} />;
  }
}

export default AuctionUserDetailsModal;
