import React, { useEffect, useState } from "react";
import UserWallet from "../../img/add_user_wallet.png";
import Auction from "../../img/auction.svg";
import brand from "../../img/brands-logo.svg";
import finance from "../../img/finance.svg";
import LogoutUser from "../../img/logout_users.png";
import Permissions from "../../img/permissions.png";
import Wallet from "../../img/wallet.png";

import CardBlock from "../../common/CardBlock";
import ErrorHandler from "../../common/ErrorHandler";
import { useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";

function MeratractorHome(props) {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwtDecode(currentUser);
  const history = useHistory();
  const [url, setUrl] = useState(null);
  useEffect(() => {
    document.title = "Meratractor Home";
  }, []);
  useEffect(() => {
    if (user.role === "Auction Back Office") {
      setUrl(
        `/auction-back-office-dashboard:${user.phone}&state=${user.state}`
      );
    } else if (user.role === "Auction Super Admin") {
      setUrl(`/asaDashboard:${user.phone}&state=${user.state}`);
    } else {
      setUrl(`/superadminhome:${user.phone}&state=${user.state}`);
    }
  }, []);

  try {
    return (
      <>
        <div className="container-fluid shadow-sm">
          <div className="container">
            <div className="row">
              <div className="d-sm-block d-md-none d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                  </div>
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-block d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12 col-md-6 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-6 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-none d-lg-block d-xl-block">
                <div className="row">
                  <div className="col-12 col-md-8 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-4 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container responsive mt-2 "
          // style={{ marginLeft: "1rem" }}
        >
          <div className="row mt-3 mb-2">
            <div className="d-flex justify-content-between col-1">
              <i
                className="bi bi-arrow-left-circle"
                onClick={() => history.push(url)}
                style={{
                  fontSize: "xx-large",
                  color: "black",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              ></i>
            </div>
            <div className="col-10 mt-2 float-start ms-2">
              <h4 className=""> Manage Auction</h4>
            </div>
          </div>
        </div>

        <div className="container container-body">
          <CardBlock
            name="Auction"
            logo={Auction}
            to={"/meratractor/auction-home"}
          />
          <CardBlock
            name="Add Wallet User"
            logo={UserWallet}
            to="/meratractor/user/add-user-to-wallet"
          />
          <CardBlock
            name="Logout User"
            logo={LogoutUser}
            to="/meratractor/user/logout/users"
          />

          <CardBlock
            name="Wallet"
            logo={Wallet}
            to={"/meratractor/wallet-home"}
          />
          <CardBlock
            name="Permission"
            logo={Permissions}
            to={"/meratractor/state/permission"}
          />

          <CardBlock
            name="Financier"
            logo={finance}
            to={"/meratractor/manage-financiers"}
          />
          <CardBlock
            name="Segment"
            logo={UserWallet}
            to="/meratractor/manage-segments"
          />
          <CardBlock
            name="State"
            logo={UserWallet}
            to="/meratractor/manage-states"
          />

          <CardBlock
            name="Brand"
            logo={brand}
            to={"/meratractor/manage-brands"}
          />
        </div>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
}

export default MeratractorHome;
