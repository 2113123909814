import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Link, useHistory } from "react-router-dom";
import SearchBox from "../../../common/SearchBox";
import { errorToast, successToast } from "../../../react-toastfiy/toast";
import auctionService from "../../../services/auction/auction.service";
import AuctionAdminFilters from "../../auction/AuctionAdminFilters";
import TableLoading from "../../../common/TableLoading";

const ARCompletedAuction = () => {
  const token = localStorage.getItem("refreshToken");
  const history = useHistory();
  const user = jwtDecode(token);
  const source = axios.CancelToken.source();
  const [auctionData, setAuctionData] = useState([]);
  const [filterAuctionData, setFilterAuctionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchData, setFetchData] = useState(true);

  const [viewableLoading, setViewableLoading] = useState({});

  const headers = [
    { header: "Auction Id", dataKey: "c_auction_id" },
    { header: "Asset Count", dataKey: "asset_count" },
    { header: "Description", dataKey: "description" },
    { header: "Start Date", dataKey: "start_date" },
    { header: "End Date", dataKey: "end_date" },
    {
      header: "Financier",
      dataKey: "financier",
    },
    { header: "Segment", dataKey: "segment" },
    { header: "State", dataKey: "state" },
    { header: "ACR Locked", dataKey: "is_locked" },
    { header: "Viewable", dataKey: "viewable" },
  ];

  useEffect(() => {
    try {
      if (fetchData) {
        setFetchData(false);
        setLoading(true);
        const form_data = new FormData();
        form_data.append("key", "completed");
        auctionService.GetARAuction(form_data, source).then((res) => {
          setLoading(false);
          const completed = res?.completed;
          if (res?.completed !== undefined) {
            setAuctionData(completed);
            setFilterAuctionData(completed);
          } else {
            errorToast(completed?.msg);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [fetchData]);

  const handeleRedirect = (c_auction_id) => {
    const newUrl = `/meratractor/auctions/modify?uid=${c_auction_id}`;
    history.push(newUrl);
  };

  const handleSwitch = (e, rowData) => {
    e.preventDefault();
    try {
      if (e.target.checked === true) {
        setViewableLoading((prev) => {
          return { ...prev, [rowData.c_auction_id]: true };
        });
        const form_data = new FormData();
        form_data.append("uid", rowData.c_auction_id);
        form_data.append("phone", user.phone);
        form_data.append("viewable", "true");
        auctionService.SwitchAuctionData(form_data, source).then((res) => {
          setViewableLoading((prev) => {
            return { ...prev, [rowData.c_auction_id]: false };
          });
          if (res.status === 200) {
            successToast("Success!");
            setFilterAuctionData((prev) =>
              prev.map((val) => {
                if (val.c_auction_id === rowData.c_auction_id) {
                  return { ...val, viewable: "true" };
                }
                return val;
              })
            );
            setAuctionData((prev) =>
              prev.map((val) => {
                if (val.c_auction_id === rowData.c_auction_id) {
                  return { ...val, viewable: "true" };
                }
                return val;
              })
            );
          } else {
            errorToast("Error!", res?.msg);
          }
        });
      } else if (e.target.checked === false) {
        setViewableLoading((prev) => {
          return { ...prev, [rowData.c_auction_id]: true };
        });
        const form_data = new FormData();
        form_data.append("uid", rowData.c_auction_id);
        form_data.append("phone", user.phone);
        form_data.append("viewable", "false");
        auctionService.SwitchAuctionData(form_data, source).then((res) => {
          setViewableLoading((prev) => {
            return { ...prev, [rowData.c_auction_id]: false };
          });
          if (res.status === 200) {
            successToast("Success!");
            setFilterAuctionData((prev) =>
              prev.map((val) => {
                if (val.c_auction_id === rowData.c_auction_id) {
                  return { ...val, viewable: "false" };
                }
                return val;
              })
            );
            setAuctionData((prev) =>
              prev.map((val) => {
                if (val.c_auction_id === rowData.c_auction_id) {
                  return { ...val, viewable: "false" };
                }
                return val;
              })
            );
          } else {
            errorToast("Error!", res?.msg);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container  mt-2">
      <AuctionAdminFilters
        auctions={auctionData}
        setAuctions={setAuctionData}
        filteredAuctionData={filterAuctionData}
        setFilteredAuctionData={setFilterAuctionData}
        loading={loading}
      >
        <div
          className="table-responsive mt-2 text-center"
          style={{ minHeight: "5vh", maxHeight: "80vh" }}
        >
          <table className="table table-hover table-bordered">
            <thead className="thead-dark">
              <tr
                className="text-center align-middle"
                style={{
                  fontSize: "small",
                  position: "sticky",
                  top: 0,
                  zIndex: 3,
                }}
              >
                <th> Sr. No</th>
                {headers.map((column) => (
                  <>
                    <th className="col text-align-center" key={column.header}>
                      {column.header}
                    </th>
                    {column.header === "viewable" && (
                      <th
                        style={{
                          position: "sticky",
                          top: 0,
                          zIndex: 5,
                          display: "flex",
                          content: "center",
                        }}
                      >
                        Viewable
                      </th>
                    )}
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <TableLoading rows={10} cols={11} />
              ) : (
                filterAuctionData &&
                Object.entries(filterAuctionData).map(
                  ([key, rowData], rowIndex) => (
                    <tr
                      className="text-center align-middle"
                      style={{
                        fontSize: "small",
                      }}
                    >
                      <td key={rowIndex}>{rowIndex + 1}</td>
                      <td
                        onClick={() => handeleRedirect(rowData.c_auction_id)}
                        style={{ cursor: "pointer", fontWeight: "bold" }}
                        className="text-primary"
                      >
                        <Link
                          to={`/meratractor/auctions/modify?uid=${rowData.c_auction_id}`}
                        >
                          {rowData.c_auction_id}
                        </Link>
                      </td>
                      <td>{rowData.asset_count}</td>
                      <td>{rowData.description}</td>
                      <td>{rowData.start_date}</td>
                      <td>{rowData.end_date}</td>
                      <td>{rowData.financier}</td>
                      <td>{rowData.segment}</td>
                      <td>{rowData.state}</td>
                      <td>
                        {rowData?.is_locked === "True" ? (
                          <i class="bi bi-check-circle-fill text-success fs-6"></i>
                        ) : rowData?.is_locked === "False" ? (
                          <i class="bi bi-x-circle-fill text-danger fs-6"></i>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {viewableLoading[rowData?.c_auction_id] ? (
                          <>
                            <div
                              class="spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </>
                        ) : (
                          <div className="form-check form-switch text-center m-0 p-0 d-flex justify-content-center">
                            <input
                              className="form-check-input m-0 p-0"
                              type="checkbox"
                              role="switch"
                              id={`flexSwitchCheck${rowIndex}`}
                              checked={
                                rowData.viewable &&
                                rowData.viewable.toLowerCase() === "true"
                              }
                              onClick={(e) => handleSwitch(e, rowData)}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  )
                )
              )}
            </tbody>
          </table>
          {!loading && filterAuctionData && filterAuctionData.length === 0 && (
            <div className="text-danger text-center mt-2 ">No Data</div>
          )}
        </div>
      </AuctionAdminFilters>
    </div>
  );
};

export default ARCompletedAuction;
