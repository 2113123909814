import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
import { successToast } from "../../../react-toastfiy/toast";
const AuctionAddDocuments = () => {
  const location = useLocation();
  const asset = location.pathname.split("/")[4]?.split("&")[0];
  const [asset_id, setAsset_id] = useState(asset);
  const [files, setFiles] = useState([]);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  let phone = user.phone;

  const handleSelect = (e) => {
    e.preventDefault();
    const selectedFiles = Array.from(e.target.files);
    // setFiles(selectedFiles);

    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ asset_id });
    if (asset_id !== "") {
      setSubmitButtonLoading(true);
      let form_data = new FormData();
      const asset_doc = new Blob(files, {
        type: "application/octet-stream",
      });
    

   files.forEach((file, index) => {
    form_data.append("documents", file);
  });
      form_data.append("asset_id", asset_id);
      SubscriptionServices.AddAssetDocs(form_data).then((res) => {
        console.log({ res });
        if (res.status === 200) {
          successToast(res.msg);
          setSubmitButtonLoading(false);
          setTimeout(() => {
            window.location.replace(
              `/rapidbid/manage-auction/manage-download-docs/${asset_id}`
            );
          }, 500);
        } else {
          setSubmitButtonLoading(false);
        }
      });
    }
  };
  console.log({ files }, files);
  return (
    <>
      <div className="container d-flex d-flex-wrap justify-content-center ">
        <div
          className=" col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7 shadow-lg bg-body"
          style={{
            marginTop: "2rem",
          }}
        >
          <h3
            className="card-header py-2 ms-4 mt-2"
            //   style={{ width: "40rem", height: "3rem" }}
          >
            Asset Documents
          </h3>
          <div className="  mx-3 mb-1 mt-3">
            <div className="ms-2 mt-4 mb-2">Upload Documents</div>
            <div className=" d-flex justify-content-center col-md-12 col-12 gap-2">
              <div className="input-group  mb-3 ms-2 me-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Choose File"
                  aria-label="Choose File"
                  name="aad_doc"
                  value={
                    files.length === 0 ? "Choose File" : `${files.length} files`
                  }
                  readOnly
                />
                <label
                  className="input-group-text rounded-end"
                  //   id="aad_doc"
                  htmlFor="file-input"
                  style={{ cursor: "pointer" }}
                >
                  Browse
                </label>

                <input
                  type="file"
                  className="form-control"
                  name="aad_doc"
                  id="file-input"
                  isDisabled={submitButtonLoading}
                  onChange={handleSelect}
                  aria-label="Choose File"
                  style={{ display: "none " }}
                  multiple
                />
              </div>
            </div>
          </div>

          <div className="mb-1 mx-4 gap-2">
            Note:
            <span className="text-danger text-center">
              Previously uploaded documents will be removed.
            </span>
          </div>

          <div class="d-grid gap-2 mx-2 my-4 px-3 py-2 rounded">
            {submitButtonLoading ? (
              <button className="btn btn-success" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            ) : (
              <button
                class="btn btn-success"
                type="submit"
                onClick={handleSubmit}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AuctionAddDocuments;
