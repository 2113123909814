import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import "react-toastify/dist/ReactToastify.css";
import "../../css/common.css";
import { errorToast } from "../../react-toastfiy/toast";

//CSS
import "react-toastify/dist/ReactToastify.css";

import jwtDecode from "jwt-decode";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import ErrorHandler from "../../common/ErrorHandler";
import SearchBox from "../../common/SearchBox";
import {
  default as User,
  default as userService,
} from "../../services/user.service";
import AddEditAuctionUsers from "./AddEditAuctionUsers";
import AuctionUserDetailsModal from "./AuctionUserDetailsModal";

function UnifiedUsersList() {
  const refreshToken = localStorage.getItem("refreshToken");
  const user = jwtDecode(refreshToken);

  const [filteredData, setFilteredData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [singleUserData, setSingleUserData] = useState({});
  const [editForm, setEditForm] = useState(false);
  const [fetchData, setFetchData] = useState(true);
  const [userFilter, setUserFilter] = useState("all");
  const [showLoadingAction, setShowLoadingAction] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);

  useEffect(() => {
    document.title = "List Users";

    try {
      if (fetchData) {
        setIsLoading(true);
        setFetchData(false);
        const form_data = new FormData();
        form_data.append("phone", user.phone);
        userService.GetUserByUserAdded(form_data).then((res) => {
          setIsLoading(false);
          if (res.status === 200) {
            setAllData(res.data);
            setFilteredData(res.data);
          } else {
            errorToast(res.msg);
          }
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }, [fetchData]);

  useEffect(() => {
    if (!isLoading) {
      let filtered_user_data = structuredClone(allData);
      if (userFilter === "approved") {
        filtered_user_data = filtered_user_data?.filter(
          (user) => user?.is_user_approved
        );
      }
      if (userFilter === "rejected") {
        filtered_user_data = filtered_user_data?.filter(
          (user) => user?.is_user_rejected
        );
      }
      if (userFilter === "pending") {
        filtered_user_data = filtered_user_data?.filter(
          (user) => !user?.is_user_rejected && !user?.is_user_approved
        );
      }

      setFilteredData(filtered_user_data);
    }
  }, [userFilter]);

  const handleDelete = async (auc_user) => {
    let formData = new FormData();
    formData.append("id", auc_user.id);

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete ${auc_user?.name}(${auc_user?.phone}) account, You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: !showLoadingAction,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          setShowLoadingAction(true);
          const response = await User.DeleteUser(formData);

          if (response.status === 200) {
            setShowLoadingAction(false);
            Swal.fire(
              "Deleted!",
              "Your data has been deleted.",
              "success"
            ).then(() => {
              setFetchData(true);
            });
          } else {
            setShowLoadingAction(false);
            errorToast(response.msg);
          }
        } catch (error) {
          setShowLoadingAction(false);
          console.log(error);
          errorToast(error?.message);
        }
      },
    });
  };

  // const handleReapply = async (auc_user) => {
  //   let formData = new FormData();
  //   formData.append("phone", auc_user.phone);

  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: !showLoadingAction,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Re-Apply",
  //     allowOutsideClick: false,
  //     allowEscapeKey: false,
  //     showLoaderOnConfirm: true,
  //     preConfirm: async () => {
  //       try {
  //         setShowLoadingAction(true);

  //         const response = await User.ReApplyRejectedUser(formData);

  //         if (response.status === 200) {
  //           Swal.fire(
  //             "Success!",
  //             "User Details has been sent for approval successfully",
  //             "success"
  //           ).then((result) => {
  //             setFetchData(true);
  //           });
  //         } else {
  //           setShowLoadingAction(true);
  //           errorToast(response.msg);
  //         }
  //       } catch (error) {
  //         console.log(error);
  //         setShowLoadingAction(true);
  //         errorToast(error?.message);
  //       }
  //     },
  //   });
  // };

  try {
    return (
      <>
        {showAddEditModal && (
          <AddEditAuctionUsers
            show={showAddEditModal}
            onHide={() => {
              setShowAddEditModal(false);
              setEditForm(false);
            }}
            data={singleUserData}
            edit_form={editForm}
            set_fetch_data={setFetchData}
          />
        )}
        {showUserDetails && (
          <AuctionUserDetailsModal
            data={singleUserData}
            show={showUserDetails}
            onHide={() => {
              setShowUserDetails(false);
            }}
          />
        )}
        <div className="container">
          <div className=" my-2 d-flex flex-wrap justify-content-between align-items-end gap-2 gap-md-0">
            <div className="col-12 d-flex justify-content-between align-items-md-end justify-content-md-start col-md-6 gap-md-2">
              <div>
                <span className=" fs-4  border-bottom border-dark">
                  Auction Users
                </span>
              </div>
              <div className="">
                <label htmlFor="">Status:</label>
                <select
                  className=" form-select"
                  value={userFilter}
                  onChange={(e) => setUserFilter(e.target.value)}
                >
                  <option value="all">All</option>
                  <option value="approved">Approved</option>
                  <option value="pending">Pending</option>
                  <option value="rejected">Rejected</option>
                </select>
              </div>
            </div>

            <div className="col-12 d-flex flex-column align-items-end gap-2 col-md-6">
              <button
                className=" btn btn-primary"
                onClick={() => {
                  setShowAddEditModal(true);
                  setEditForm(false);
                }}
              >
                <i
                  className="bi bi-person-add"
                  style={{ cursor: "pointer" }}
                ></i>
                Add User / Update User
              </button>
              <SearchBox allData={allData} setFilteredData={setFilteredData} />
            </div>
          </div>

          <div className="table-responsive" style={{ maxHeight: "85vh" }}>
            <table className="table table-hover table-bordered">
              <thead>
                <tr className=" text-center">
                  <th scope="row">No</th>
                  <th scope="row">Name</th>
                  <th scope="row">Phone</th>
                  <th scope="row">Email</th>
                  <th scope="row">State</th>
                  <th scope="row">PAN</th>
                  <th scope="row">AADHAAR</th>
                  <th scope="row">User added by</th>
                  <th scope="row">Approval Status</th>
                  <th scope="row">View details</th>
                  <th scope="row">Rejection Reason</th>
                  <th
                    scope="row"
                    style={{
                      position: "sticky",
                      right: 0,
                      backgroundColor: "whitesmoke",
                    }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {!isLoading &&
                  Object.values(filteredData).map((user, index) => (
                    <tr key={user.id} className=" text-center">
                      <th className="col-1">{index + 1}</th>
                      <td className="col" style={{ cursor: "pointer" }}>
                        {user.name}
                      </td>
                      <td className="col">{user.phone}</td>
                      <td className="col">{user.email}</td>
                      <td className="col">{user?.state?.label}</td>
                      <td className="col">{user.pan}</td>
                      <td className="col">{user.aadhar}</td>
                      <td className="col">{user?.user_added_by}</td>
                      <td className="col">
                        <span
                          className={`fw-bold text-${
                            user?.is_user_approved
                              ? "success"
                              : user?.is_user_rejected
                              ? "danger"
                              : "warning"
                          }`}
                        >
                          {user?.is_user_approved
                            ? "Approved"
                            : user?.is_user_rejected
                            ? "Rejected"
                            : "Pending"}
                        </span>
                      </td>
                      <td>
                        {" "}
                        <button
                          onClick={() => {
                            setSingleUserData(user);
                            setShowUserDetails(true);
                          }}
                          className=" btn btn-primary text-nowrap"
                        >
                          <i className=" bi bi-eye"></i> View
                        </button>
                      </td>{" "}
                      <td>
                        {user?.is_user_rejected ? user?.rejected_reason : "-"}
                      </td>
                      <td>
                        {user?.is_user_rejected ? (
                          <button
                            className=" btn btn-primary"
                            onClick={() => {
                              const userData = structuredClone(user);
                              userData.state = user.state?.value;
                              setShowAddEditModal(true);
                              setEditForm(true);
                              setSingleUserData(userData);
                            }}
                          >
                            Re-apply
                          </button>
                        ) : (
                          "-"
                        )}
                      </td>
                      {/* <td
                        className="col-1"
                        style={{
                          position: "sticky",
                          right: 0,
                          backgroundColor: "whitesmoke",
                        }}
                      >
                        <div className="d-flex justify-content-around flex-wrap">
                          <i
                            className="bi bi-pencil-square click-animation"
                            id={user.id}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                            }}
                          ></i>

                          <i
                            className="bi bi-trash click-animation"
                            // id={user.id}
                            onClick={(e) => {
                              handleDelete(user);
                            }}
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
            {isLoading && (
              <div className=" d-flex justify-content-center">
                <ReactLoading
                  type={"bubbles"}
                  color={"black"}
                  height={100}
                  width={100}
                />
              </div>
            )}
            {!isLoading && Object.entries(filteredData)?.length === 0 && (
              <div className=" text-danger text-center">No Data</div>
            )}
          </div>
        </div>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
}

export default UnifiedUsersList;
