import React, { useState, useEffect } from "react";
import AddAuctionModalData from "./AddAuctionModalData";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
// import { useParams } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { successToast } from "../../../react-toastfiy/toast";

const AddAuction = () => {
  const location = useLocation();
  const history = useHistory();
    useEffect(() => {
      document.title = "Add Auction";
    }, []);

  const [asset_id, setAsset_id] = useState("");

  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  let phone = user.phone;
  const [ContinueButton, setContinueButton] = useState(false);
  const [modal, setModal] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  //  const id = useLocation.pathname

  let initialValue = {
    state: "",
    make: "",
    model: "",
    year: "",
    reg_no: "",
    location: "",
    note: "",
    date: "",
    start_time: "",
    minutes: "",
    recommendedBid: "",
    loan_ref_no: "",
    img: "",
  };
  const [addAuctionData, setaddAuctionData] = useState([]);
  const [addAuctionList, setaddAuctionList] = useState(initialValue);
  const [StateName, setStateName] = useState([]);
  const [stageOne, setStageOne] = useState(true);
  const [stageTwo, setStageTwo] = useState(true);
  const [stageThree, setStageThree] = useState(true);
  const [TractorBrand, setTractorBrand] = useState([]);
  const [yearLoading, setYearLoading] = useState(true);
  const [brandLoading, setBrandLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [TractorModal, setTractorModal] = useState([]);
  const [years, setYears] = useState([]);
  const [regLoading, setRegLoading] = useState(true);
  const [locationLoading, setLocationLoading] = useState(true);
  const [noteLoading, setNoteLoading] = useState(true);
  const [dateTimeLoading, setDateTimeLoading] = useState(true);
  const [startTimeLoading, setStartTimeLoading] = useState(true);
  const [minuteLoading, setMinuteLoading] = useState(true);
  const [recommendedLoading, setRecommendedLoading] = useState(true);
  const [loanRefNoLoading, setLoanRefNoLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitButtonLoading(true);
    let form_data = new FormData();

    form_data.append("state", addAuctionData.state_id);
    form_data.append("phone", phone);
    form_data.append("make", addAuctionData.make_id);
    form_data.append("model", addAuctionData.model_id);
    form_data.append("location", addAuctionData.location);
    form_data.append("reg_no", addAuctionData.reg_no);
    form_data.append("year", addAuctionData.year);
    form_data.append("note", addAuctionData.note);
    form_data.append("start_date", addAuctionData.date);
    form_data.append("start_time", addAuctionData.start_time);
    form_data.append("minutes", addAuctionData.minutes);
    form_data.append("recommendedBid", addAuctionData.recommendedBid);
    form_data.append("loanRefNo", addAuctionData.loan_ref_no);
    const front_filesBlob = new Blob(addAuctionData.front_img, {
      type: "application/octet-stream",
    });
    form_data.append("front-file-input", front_filesBlob, "front_files");

    const back_filesBlob = new Blob(addAuctionData.back_img, {
      type: "application/octet-stream",
    });
    form_data.append("back-file-input", back_filesBlob, "back_files");

    const tyre_filesBlob = new Blob(addAuctionData.tyre_img, {
      type: "application/octet-stream",
    });
    form_data.append("tyre-file-input", tyre_filesBlob, "tyre_files");

    const side_filesBlob = new Blob(addAuctionData.side_img, {
      type: "application/octet-stream",
    });
    form_data.append("side-file-input", side_filesBlob, "side_files");

    const engine_filesBlob = new Blob(addAuctionData.engine_img, {
      type: "application/octet-stream",
    });
    form_data.append("engine-file-input", engine_filesBlob, "engine_files");

    const misc_filesBlob = new Blob(addAuctionData.misc_img, {
      type: "application/octet-stream",
    });
    form_data.append("misc-file-input", misc_filesBlob, "misc_files");

    SubscriptionServices.CreateAuction(form_data).then((res) => {
      setSubmitButtonLoading(false);

      if (res.status === 200) {
        console.log({ res }, res?.status);
        successToast(res.message);

        setTimeout(() => {
          window.location.replace(
            "/rapidbid/manage-auction/manage-add-auction"
          );
        }, 500);
      } else {
        console.log({ res }, res?.status);
        successToast(res.message);
      }
    });
  };

  const handleselectTractor = (e) => {
    setModal(true);

    if (addAuctionList?.state == "") {
      setStageOne(false);
      SubscriptionServices.GetAllState().then((res) => {
        if (Object.entries(res).length !== 0) {
          setStateList(res);
        }
      });
      setBrandLoading(true);
      SubscriptionServices.GetAllMake().then((res) => {
        if (Object.entries(res).length !== 0) {
          setBrandLoading(false);
          setTractorBrand(res);
        }
      });
    } else if (addAuctionList?.make == "") {
      setStageTwo(false);
    } else if (addAuctionList?.model == "") {
      setStageThree(false);
      setModalLoading(true);
      SubscriptionServices.GetAllModel().then((res) => {
        if (Object.entries(res).length !== 0) {
          setModalLoading(false);
          setTractorModal(res);
        }
      });
    } else if (addAuctionList?.year == "") {
      if (
        addAuctionData.model_id !== undefined ||
        addAuctionData.model_id !== ""
      ) {
        setYearLoading(false);
        const currentYear = new Date().getFullYear();
        const last20Years = Array.from(
          { length: 20 },
          (_, index) => currentYear - index
        );
        setYears(last20Years);
        // setYearLoading(true);
      }
    } else if (addAuctionData.reg_no == undefined) {
      setRegLoading(false);
    } else if (addAuctionData.location == undefined) {
      setLocationLoading(false);
    } else if (addAuctionData.note == undefined) {
      setNoteLoading(false);
    } else if (
      addAuctionData.date == undefined &&
      addAuctionData.start_time !== ""
    ) {
      setDateTimeLoading(false);
    } else if (addAuctionData.start_time == undefined) {
      setStartTimeLoading(false);
    } else if (addAuctionData.minutes == undefined) {
      setMinuteLoading(false);
    } else if (
      addAuctionData.recommendedBid == undefined ||
      addAuctionData.recommendedBid == ""
    ) {
      setRecommendedLoading(false);
    } else if (
      addAuctionData.loan_ref_no == undefined ||
      addAuctionData.loan_ref_no == ""
    ) {
      setLoanRefNoLoading(false);
    } else {
      setImageLoading(false);
    }
  };

  return (
    <>
      {modal && (
        <AddAuctionModalData
          show={modal}
          setaddAuctionData={setaddAuctionData}
          setLocationLoading={setLocationLoading}
          locationLoading={locationLoading}
          setaddAuctionList={setaddAuctionList}
          setDateTimeLoading={setDateTimeLoading}
          setStateList={setStateList}
          dateTimeLoading={dateTimeLoading}
          setStageOne={setStageOne}
          noteLoading={noteLoading}
          setNoteLoading={setNoteLoading}
          setStageTwo={setStageTwo}
          setStageThree={setStageThree}
          setYearLoading={setYearLoading}
          setTractorBrand={setTractorBrand}
          brandLoading={brandLoading}
          setBrandLoading={setBrandLoading}
          setModalLoading={setModalLoading}
          setregLoading={setRegLoading}
          regLoading={regLoading}
          TractorModal={TractorModal}
          setTractorModal={setTractorModal}
          TractorBrand={TractorBrand}
          modalLoading={modalLoading}
          setYears={setYears}
          years={years}
          yearLoading={yearLoading}
          stageOne={stageOne}
          stageTwo={stageTwo}
          stageThree={stageThree}
          addAuctionList={addAuctionList}
          addAuctionData={addAuctionData}
          StateName={StateName}
          setStateName={setStateName}
          startTimeLoading={startTimeLoading}
          setMinuteLoading={setMinuteLoading}
          setStartTimeLoading={setStartTimeLoading}
          setRecommendedLoading={setRecommendedLoading}
          setLoanRefNoLoading={setLoanRefNoLoading}
          loanRefNoLoading={loanRefNoLoading}
          recommendedLoading={recommendedLoading}
          minuteLoading={minuteLoading}
          setImageLoading={setImageLoading}
          imageLoading={imageLoading}
          //   data={modalData}
          //   setstatedata={setModalData}
          setModal={setModal}
          stateList={stateList}
          onHide={() => {
            setModal(false);
          }}
        />
      )}

      <ul
        class="breadcrumb alert alert-secondary"
        style={{ borderRadius: "0" }}
      >
        <li class="breadcrumb-item" style={{ marginTop: "-12px" }}>
          <div
            onClick={() => history.goBack()}
            className="text-primary"
            style={{
              textDecoration: "none",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            <i className="bi bi-arrow-left-short custom-icon"></i>
          </div>
        </li>
      </ul>

      <div className="container d-flex d-flex-wrap justify-content-center ">
        <div
          className=" col-md-6 col-12 shadow-lg bg-body rounded"
          style={{
            marginTop: "5rem",
          }}
        >
          <div class="text-bg-dark rounded mx-1 mt-1">
            <h5
              class="card-header text-center py-2"
              //   style={{ width: "40rem", height: "3rem" }}
            >
              Add Auction
            </h5>
            <p className="text-center mb-1">
              Fill information to add the auction for all dealerships available
              out there.
            </p>
          </div>

          <form>
            <div className="row mt-3">
              <div className=" col-md-10 col-11 mx-3 my-3 form-group ">
                <label htmlFor="name">Select Tractor</label>
                <input
                  className="form-control"
                  placeholder="Select Tractor"
                  type="text"
                  value={`${addAuctionList?.year} ${addAuctionList?.make}  ${addAuctionList?.model}`}
                  onClick={handleselectTractor}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className=" col-md-10 col-11 mx-3 my-3 form-group">
                <label htmlFor="name">State and Location</label>
                <input
                  className="form-control"
                  // placeholder="State and Location"
                  type="text"
                  value={`${addAuctionList?.state} ${addAuctionList?.location}`}
                  onClick={handleselectTractor}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className=" col-md-10 col-11 mx-3 my-3 form-group">
                <label htmlFor="name">Recommended Bid</label>
                <input
                  className="form-control"
                  // placeholder="Recommended Bid"
                  type="text"
                  value={`${addAuctionList?.recommendedBid}`}
                  name="recommendedBid"
                  onClick={handleselectTractor}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className=" col-md-10 col-11 mx-3 my-3 form-group">
                <label htmlFor="name">Registration Number</label>
                <input
                  className="form-control"
                  // placeholder="Registration Number"
                  type="text"
                  value={`${addAuctionList?.reg_no}`}
                  onClick={handleselectTractor}
                />
              </div>
            </div>
            <div className="d-flex d-flex-wrap justify-content-center mb-3">
              {submitButtonLoading ? (
                <button className="btn btn-success" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className="btn btn-success mx-1 my-2 px-3 py-2 rounded"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Continue
                </button>
              )}
              <button
                className="btn btn-secondary mx-1 my-2 px-3 py-2 rounded"
                type="submit"
              >
                Remove Data
              </button>
              <button
                className="btn btn-danger mx-1 my-2 px-3 py-2 rounded"
                type="submit"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddAuction;
