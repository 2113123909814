import React, { useState, useEffect } from "react";
import auctionService from "../../../../services/auction/auction.service";
import { errorToast, successToast } from "../../../../react-toastfiy/toast";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { useLocation, useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";
import axios from "axios";

const UploadMultipleTransactionForSingleUser = () => {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwtDecode(currentUser);
  const history = useHistory();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 430);
  const [isSmall, setIsSmall] = useState(window.innerWidth < 380);
  const [isSmallScreenMargin, setIsSmallScreenMargin] = useState(
    window.innerWidth < 770
  );

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 430);
    setIsSmallScreenMargin(window.innerWidth < 770);
    setIsSmall(window.innerWidth < 380);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const singleuploadbreadcrumb = {
    height: isSmall
      ? "105px"
      : isSmallScreen
      ? "85px"
      : isSmallScreenMargin
      ? "60px"
      : "30px",
    borderRadius: "0",
    marginLeft: isSmallScreen ? "0px" : "auto",
    marginBottom: isSmallScreen ? "12px" : "",
    // fontSize:"small"
  };

  const singleuploadbreadcrumbMargin = {
    marginTop:
      isSmall || isSmallScreen || isSmallScreenMargin ? "-14px" : "-12px",
    marginBottom: isSmall || isSmallScreen || isSmallScreenMargin ? "14px" : "",
  };

  const location = useLocation();
  let initialValue = {
    phone: "",
    name: "",
    transaction_file: "",
  };

  const [data, setData] = useState(initialValue);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [namefield, setNameField] = useState(false);
  const [phoneField, setPhoneField] = useState(false);

 const source = axios.CancelToken.source();

  useEffect(() => {
    try {
      if (data?.phone?.length >= 4 && loading === true) {
        const form_data = new FormData();
        form_data.append("phone", data?.phone);
        auctionService.GetUserList(form_data, source).then((res) => {
          setLoading(false);
          if (Object.entries(res)?.length > 0) {
            const suggestions_Data = Object.values(res);
            setSuggestions(suggestions_Data);
          }
        });
      } else if (data?.name?.length >= 3 && loading === true) {
        const form_data = new FormData();
        form_data.append("name", data?.name);
        auctionService.GetUserList(form_data, source).then((res) => {
          setLoading(false);
          if (Object.entries(res)?.length > 0) {
            const suggestions_Data = Object.values(res);
            setSuggestions(suggestions_Data);
          }
        });
      } else {
        if (data?.phone?.length< 3) {
          setData((prev) => ({
            ...prev,
            //  ["phone"]: "",
            ["name"]: "",
          }));
        }
      }
    } catch (error) {
      errorToast(error.msg);
    }
  }, [data?.name, data?.phone, loading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log({ data });
    if (value) {
      console.log({ value });
      setData((prev) => ({
        ...prev,
        [name]: value,
      }));
      setLoading(true);
      setSuggestions([]);
      name === "phone" ? setPhoneField(true) : setNameField(true);
    } else {
      setData((prev) => ({
        ...prev,
        [name]: value,
      }));
      setLoading(true);
      setSuggestions([]);
      setPhoneField(false);
      setNameField(false);
    }
  };

  const handleSuggestionSelect = (suggestion) => {
    console.log({suggestion})
    setData((prev) => ({
      ...prev,
      ["name"]: suggestion.name,
      ["phone"]: suggestion.phone,
    }));

    setSuggestions([]);
  };

  const handleSetFile = (e) => {
    const file = e.target.files[0];
    setData((prev) => ({
      ...prev,
      ["transaction_file"]: file,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // setFetchData(true);
    setButtonLoading(true);
    const form_data = new FormData();
    form_data.append("number", data?.phone);
    form_data.append("name", data?.name);
    form_data.append("transaction_file", data?.transaction_file);
    auctionService
      .GetMultiTransactionForOneUser(form_data, source)
      .then((res) => {
        setButtonLoading(false);
        console.log({ res });
        if (res?.status === 200) {
          successToast(res?.msg);
        } else {
          errorToast(res?.msg);
        }
      });
  };

  const downloadExcel = (e) => {
    e.preventDefault();
    const data = [
      ["phone", ""],
      ["transaction type", ""],
      ["description", ""],
      ["amount", ""],
    ];

    const transposedData = data[0].map((_, colIndex) =>
      data.map((row) => row[colIndex])
    );

    const ws = XLSX.utils.aoa_to_sheet(transposedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, "Excel format multi ledger one user.xlsx");
  };

  const handleExcelofUserDetails = (e) => {
    e.preventDefault();
    auctionService
      .UploadMultipleTransactionForSingleUserHandleExcelofUserDetails(source)
      .then((res) => {
        if (res && res.length > 0) {
          const data = [
            ["phone", "name"],
            ...res.map((user) => [user.phone, user.name]),
          ];
          const ws = XLSX.utils.aoa_to_sheet(data);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          XLSX.writeFile(wb, "user_details.xlsx");
        } else {
          console.log("No data to export");
        }
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });
  };

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container responsive mt-2 ms-auto ">
        <div className="row mt-3 mb-2">
          <span className="d-flex justify-content-between col-1">
            <i
              className="bi bi-arrow-left-circle "
              onClick={() =>
                history.push("/meratractor/wallet/single-user-txn")
              }
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </span>

          <div className="col-10 mt-2 float-start ms-2">
            <h4 className="">Single Transaction</h4>
          </div>
        </div>
      </div>

      <div className="container d-flex d-flex-wrap justify-content-between ">
        <div
          className={
            isSmallScreen
              ? "col-md-8 col-lg-6 col-12 bg-body mb-2"
              : "col-md-7 col-lg-6 col-12  bg-body p-3 mb-2  rounded"
          }
          style={{
            marginTop: "2rem",
          }}
        >
          <hr className="soften mt-2" />
          <form className="ms-auto" onSubmit={handleSubmit}>
            <div className="row mt-3">
              <div className="col-md-12 mb-3 form-group">
                <label for="phone">Phone</label>
                <input
                  className="form-control"
                  placeholder="Phone"
                  id="phone"
                  type="number"
                  name="phone"
                  value={data?.phone}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  required
                  isDisabled={loading}
                />
                {phoneField && (
                  <div className="suggestion-box">
                    {suggestions && suggestions.length > 0 && (
                      <>
                        {suggestions.map((suggestion, index) => (
                          <div
                            key={"suggestion_" + index}
                            className="suggestion-item"
                            onClick={() => handleSuggestionSelect(suggestion)}
                            style={{ cursor: "pointer" }}
                          >
                            {suggestion.name} - {suggestion.phone}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12 mb-3 form-group">
                <label for="name">Name</label>
                <input
                  className="form-control"
                  placeholder="Name"
                  id="name"
                  type="text"
                  name="name"
                  value={data?.name}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  required
                  isDisabled={loading}
                />

                {namefield && (
                  <div className="suggestion-box">
                    {suggestions && suggestions.length > 0 && (
                      <>
                        {suggestions.map((suggestion, index) => (
                          <div
                            key={"suggestion_" + index}
                            className="suggestion-item"
                            onClick={() => handleSuggestionSelect(suggestion)}
                            style={{ cursor: "pointer" }}
                          >
                            {suggestion.name} - {suggestion.phone}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="row mt-3 align-items-center">
              <div className="col-md-12 form-group">
                <input
                  className="form-control"
                  type="file"
                  name="transaction_file"
                  id="inputGroupFile02"
                  // value={data?.transaction_file}
                  onChange={handleSetFile}
                  required
                />
              </div>
            </div>

            <div className="d-flex  justify-content-center  mt-4">
              {buttonLoading ? (
                <button
                  className="btn btn-primary mt-2"
                  type="button"
                  disabled
                  id="loadingBtnTp"
                >
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className="btn btn-primary  col-4"
                  type="submit"
                  // onClick={handleSubmit}
                  disabled={loading}
                >
                  Submit
                </button>
              )}
            </div>

            <div class="row mt-2">
              <div class="col-12 ">
                Download :
                <Link className="ms-1 me-1" onClick={downloadExcel}>
                  Sample Excel{" "}
                </Link>
                |
                <Link className="ms-1 me-1" onClick={handleExcelofUserDetails}>
                  Excel of User Details
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UploadMultipleTransactionForSingleUser;
