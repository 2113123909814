import React, { useEffect, useState } from "react";
import { stateHeader } from "./RapidbidHeader";
import CustomTable from "./CustomTable";
import RapibidAddState from "./RapibidAddState";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
import Swal from "sweetalert2";
import SearchBox from "../../../common/SearchBox";
import { errorToast } from "../../../react-toastfiy/toast";

const RapibidStateList = () => {
  let header = stateHeader;
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  let initialValue = {
    id: "",
    state_name: "",
    shorthand: "",
  };
  let entry;
  const [modalData, setModalData] = useState(initialValue);
  const [createStateData, setCreateStateData] = useState([]);
  const data = [
    {
      no: "1",
      state: "Andhra Pradesh",
      shorthand: "AP",
    },
    { no: "2", state: "	Bihar", shorthand: "BR" },
    {
      no: "3",
      state: "	Gujarat",
      shorthand: "GJ",
    },
    {
      no: "4",
      state: "Jharkhand",
      shorthand: "JH",
    },
    {
      no: "5",
      state: "Karnataka",
      shorthand: "KA",
    },
    {
      no: "6",
      state: "Madhya Pradesh",
      shorthand: "MP",
    },
  ];

  const [stateData, setStateData] = useState([]);
  const [filterAllState, setFilterAllState] = useState([]);

  useEffect(() => {
    try {
      setLoading(true);
      SubscriptionServices.GetAllState().then((res) => {
        if (Object.entries(res).length !== 0) {
          let all_state = Object.entries(res).map(([key, entry], index) => {
            entry["no"] = index + 1;
            return entry;
          });
          if (all_state.length !== 0) {
            setStateData(all_state);
            setFilterAllState(all_state);
            setLoading(false);
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      errorToast(error.message);
    }
  }, []);

  const handleOpenModal = (e, item) => {
    console.log({item})
    setModal(true);
    setStateData(item);
  };
  console.log({setStateData})

  // const handleDelete = (e, entry) => {
  //   e.preventDefault();
  //   const name = entry.state_name;
  //   const id = entry.id;
  //   let form_data = new FormData();
  //   form_data.append("id", id);
  //   const notice = `You want to delete  ${name}  `;
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: notice,
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       try {
  //         const ṛes = await SubscriptionServices.DeleteState(form_data);

  //         if (ṛes.status === 200) {
  //           Swal.fire(
  //             "Deleted!",
  //             "Your data has been deleted.",
  //             "success"
  //           ).then((result) => {
  //             result && window.location.replace("/rapibid/auction/state");
  //           });
  //         }

  //         ṛes.status !== 200 && errorToast(ṛes.msg);
  //       } catch (error) {
  //         console.log(error);
  //         errorToast(error.message);
  //       }
  //     }
  //   });
  // };



  const handleDelete = (e, entry) => {
    e.preventDefault();
    const name = entry.state_name;
    const id = entry.id;
    let form_data = new FormData();
    form_data.append("id", id);
    const notice = `You want to delete  ${name}  `;

    Swal.fire({
      title: "Are you sure?",
      html: notice,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await SubscriptionServices.DeleteState(form_data);

          if (response.status === 200) {
            Swal.fire(
              "Deleted!",
              "Your data has been deleted.",
              "success"
            ).then((result) => {
              result && window.location.replace("/rapibid/auction/state");
            });
          }
          response.status !== 200 && errorToast(response.msg);
        } catch (error) {
          console.log(error);
          errorToast(error.message);
        }
      },
    });
  };

  return (
    <>
      {modal && (
        <RapibidAddState
          show={modal}
          data={stateData}
          setstatedata={setStateData}
          // setModal={setModal}
          onHide={() => {
            setModal(false);
          }}
        />
      )}

      <div className=" container mt-5 my-3 ">
        <div className="d-flex justify-content-between">
          <h4>Manage State</h4>

          <button
            className="btn btn-primary float-end my-1"
            // data-bs-toggle="modal"
            // data-bs-target="#staticBackdrop"
            onClick={handleOpenModal}
          >
            <i className="bi bi-plus-lg me-1"></i>Add State
          </button>
        </div>

        <div className="col-lg-3 col-12 col-md-4 col-sm-12 ms-auto mb-2">
          <SearchBox allData={stateData} setFilteredData={setFilterAllState} />
        </div>

        <CustomTable
          loading={loading}
          columns={header}
          data={filterAllState}
          entry={entry}
          handleOpenModal={handleOpenModal}
          //   data={FilterPlanData}
          handleDelete={handleDelete}
          action={"Model"}
        />
      </div>
    </>
  );
};

export default RapibidStateList;
