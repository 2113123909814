import React, { useEffect, useState } from "react";
import CardBlock from "../../../../common/CardBlock";
import ViewTransactionsofUser from "../../../../img/view_user_txn.svg";
import UploadmultipleTransactionsforsingleUser from "../../../../img/upload_single_txn.svg";
import UpdateSingleTransactions from "../../../../img/single_tns_form.svg";
import ErrorHandler from "../../../../common/ErrorHandler";
import { Link, useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";

const SingleUserTransectionHome = () => {
    const currentUser = localStorage.getItem("refreshToken");
    const user = jwtDecode(currentUser);
  const history = useHistory();

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 430);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 430);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const singleuploadbreadcrumb = {
    height: isSmallScreen ? "60px" : "30px",
    borderRadius: "0",
  };

  const singleuploadbreadcrumbMargin = {
    marginTop: isSmallScreen ? "-14px" : "-12px",
    marginBottom: isSmallScreen ? "14px" : "",
  };

  useEffect(() => {
    document.title = "Home";
  }, []);

  try {
    return (
      <>
        <div className="container-fluid shadow-sm">
          <div className="container">
            <div className="row">
              <div className="d-sm-block d-md-none d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                  </div>
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-block d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12 col-md-6 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-6 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-none d-lg-block d-xl-block">
                <div className="row">
                  <div className="col-12 col-md-8 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-4 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container responsive mt-2 ms-auto ">
          <div className="row mt-3 mb-2">
            <span className="d-flex justify-content-between col-1">
              <i
                className="bi bi-arrow-left-circle"
                onClick={() => history.push("/meratractor/wallet-home")}
                style={{
                  fontSize: "xx-large",
                  color: "black",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              ></i>
            </span>
            <div className="col-10 mt-2 float-start ms-2">
              <h5 className="mt-2">Single User Transactions</h5>
            </div>
          </div>
        </div>

        <div className="container container-body">
          <CardBlock
            name="Update Single Transactions"
            logo={UpdateSingleTransactions}
            to="/meratractor/wallet/single-transaction"
          />
          <CardBlock
            name="View Transactions of User"
            logo={ViewTransactionsofUser}
            to="/meratractor/wallet/view-transactions-by-user"
          />
          <CardBlock
            name="Upload Single User Transactions"
            logo={UploadmultipleTransactionsforsingleUser}
            to="/meratractor/wallet/upload-transactions-for-one-user"
          />
        </div>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
};

export default SingleUserTransectionHome;
