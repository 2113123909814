import "../../css/BottomDrawer.css";

function BottomDrawer(props) {
  const { show, styles = {} } = props;
  return (
    <>
      {" "}
      <div
        className={`bottom-drawer ${show ? "drawer-show" : ""}`}
        style={styles}
      >
        {props.children}
      </div>
    </>
  );
}

export default BottomDrawer;
