import React, { useState, useEffect } from "react";

import Select from "react-select";
import jwtDecode from "jwt-decode";
import ReactLoading from "react-loading";
import SearchBox from "../../common/SearchBox";
import DatePicker from "../../common/DatePicker";
import { errorToast } from "../../react-toastfiy/toast";
import { useLocation, useParams } from "react-router-dom";
import { formatDate } from "../../helpers/functions/dateFormatter";
import auctionService from "../../services/auction/auction.service";

const FinancierWiseAuctionList = () => {
  const { id } = useParams();
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [Loading, setLoading] = useState(false);
  const [fetchData, setFetchData] = useState(true);
  const token = localStorage.getItem("refreshToken");
  const [FilteredData, setFilteredData] = useState([]);
  const [states, setStates] = useState([]);
  const [segType, setSegType] = useState([]);
  const [selectStates, setSelectStates] = useState({
    state: "",
  });
  const [selectSegType, setSelectSegType] = useState({
    segmentType: "",
  });

  const [FinancierWiseAuctionList, setFinancierWiseAuctionList] = useState([]);
  const user = jwtDecode(token);
  const location = useLocation();
  const phone = user.phone;
  const state = user.state;

  useEffect(() => {
    const month_start_date = location?.state?.month_start_date;
    const month_end_date = location?.state?.month_end_date;
    if (month_start_date && month_end_date) {
      setStartDate(month_start_date);
      setEndDate(month_end_date);
    } else {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();
      const date = currentDate.getDate();
      // Calculate the first day of the month
      let firstDayOfMonth = formatDate(new Date(year, month, 6));
      // Calculate the last day of the next month and then subtract one day
      let lastDayOfMonth = formatDate(new Date(year, month + 1, 5));

      if (date <= 5) {
        firstDayOfMonth = formatDate(new Date(year, month - 1, 6));
        lastDayOfMonth = formatDate(new Date(year, month, 5));
      }

      setStartDate(firstDayOfMonth);
      setEndDate(lastDayOfMonth);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    auctionService.GetSegmentType().then((res) => {
      if (res.status === 200) {
        const segTypeOption = res.data.map((segmentType) => {
          return { label: segmentType.name, value: segmentType };
        });
        setSegType(segTypeOption);
        setSelectSegType({
          segmentType: segTypeOption[0]?.value?.id,
        });
      }
    });
  }, []);

  useEffect(() => {
    try {
      if (startDate !== " " && endDate !== "" && fetchData) {
        setLoading(true);
        setFilteredData([]);
        setFinancierWiseAuctionList([]);
        let form_data = new FormData();
        form_data.append("phone", phone);
        auctionService.GetState(form_data).then((res) => {
          if (res.status === 200) {
            const StateOption = res.data.map((state) => {
              return { label: state.name, value: state };
            });
            setStates(StateOption);

            let local_storage_state_name = "";

            if (!localStorage.getItem("state")) {
              local_storage_state_name = StateOption[0]?.value?.id;

              localStorage.setItem("state", StateOption[0]?.value?.id);
            } else {
              local_storage_state_name = localStorage.getItem("state");
            }
            setSelectStates({
              state: local_storage_state_name,
            });

            if (
              local_storage_state_name !== "" &&
              selectSegType.segmentType !== ""
            ) {
              let form_data = new FormData();
              // console.log(parseInt(selectStates.state));
              form_data.append("state", parseInt(local_storage_state_name));
              form_data.append("start_date", startDate);
              form_data.append("segment_type", selectSegType?.segmentType);
              form_data.append("end_date", endDate);
              auctionService
                .GetFinancierWiseAuctionList(form_data)
                .then((res) => {
                  setFetchData(false);
                  setLoading(false);
                  if (res.status === 200) {
                    setFinancierWiseAuctionList(res.data);
                    setFilteredData(res.data);
                  }
                });
            }
          }
        });
      }
      //  else {
      //   setLoading(false);
      // }
    } catch (error) {
      setLoading(false);
      setFetchData(false);
      errorToast(error);
      console.log(error);
    }
  }, [startDate && endDate && fetchData && selectSegType.segmentType]);
  let total_assets_count = 0;
  let total_auctions_count = 0;
  let total_approved_count = 0;
  let total_backout_count = 0;
  let total_held_by_seller_count = 0;
  let total_payment_done_count = 0;

  const handleSelectState = (e) => {
    localStorage.setItem("state", e.value.id);
    setSelectStates({ state: e.value.id });
    setLoading(true);
    setFilteredData([]);
    setFinancierWiseAuctionList([]);
    let form_data = new FormData();
    form_data.append("state", parseInt(e.value.id));
    form_data.append("segment_type", selectSegType?.segmentType);
    form_data.append("start_date", startDate);
    form_data.append("end_date", endDate);
    auctionService.GetFinancierWiseAuctionList(form_data).then((res) => {
      setFetchData(false);
      setLoading(false);
      if (res.status === 200) {
        setFinancierWiseAuctionList(res.data);
        setFilteredData(res.data);
      }
    });
  };
  const handleSelectSegmentType = (e) => {
    // localStorage.setItem("segmentType", e.value);
    setSelectSegType({ segmentType: e.value.id });
    setLoading(true);
    setFilteredData([]);
    setFinancierWiseAuctionList([]);
    let form_data = new FormData();
    form_data.append("state", parseInt(selectStates.state));
    form_data.append("start_date", startDate);
    form_data.append("segment_type", parseInt(e.value.id));
    form_data.append("end_date", endDate);
    auctionService.GetFinancierWiseAuctionList(form_data).then((res) => {
      setFetchData(false);
      setLoading(false);
      if (res.status === 200) {
        setFinancierWiseAuctionList(res.data);
        setFilteredData(res.data);
      }
    });
  };
  return (
    <>
      <div className="container mt-4">
        <div className="col-md-12 col-12 col-lg-12 mb-2 ">
          <div className="d-flex flex-wrap justify-content-between align-items-end">
            <div className="col-lg-4 col-12 col-md-6 col-sm-12">
              <span
                className="heading heading5 hind-font medium-font-weight"
                style={{
                  fontSize: "25px",
                  color: "black !important",
                  borderBottom: "1px solid grey",
                }}
              >
                Auction List Financier Wise
              </span>
            </div>
            <div
              className="col-lg-3 col-12 col-md-4 col-sm-12"
              style={{ paddingRight: "10px" }}
            >
              <SearchBox
                placeholder="Search by financier..."
                style={{ margin: "1px !important", fontSize: "16px" }}
                allData={FinancierWiseAuctionList}
                setFilteredData={setFilteredData}
              />
            </div>
          </div>
        </div>

        <div className="col-md-12 col-12 col-lg-12 mb-2 ">
          <div className="d-flex flex-wrap justify-content-between align-items-end">
            <div
              className="col-lg-3 col-12 col-md-4 col-sm-2 mb-2"
              style={{ paddingRight: "10px" }}
            >
              <Select
                options={segType}
                isDisabled={Loading}
                value={
                  segType &&
                  segType.length !== 0 &&
                  segType.filter(
                    (segmentType) =>
                      // console.log(
                      //   segmentType?.value?.id,
                      //   parseInt(selectSegType?.segmentType)
                      // )
                      segmentType?.value?.id ===
                      parseInt(selectSegType?.segmentType)
                  )[0]
                }
                onChange={handleSelectSegmentType}
              />
            </div>
            <div
              className="col-lg-3 col-12 col-md-4 col-sm-2 mb-2"
              style={{ paddingRight: "10px" }}
            >
              <Select
                options={states}
                isDisabled={Loading}
                value={
                  states &&
                  states.length !== 0 &&
                  states.filter(
                    (state) =>
                      state?.value?.id === parseInt(selectStates?.state)
                  )[0]
                }
                onChange={handleSelectState}
              />
            </div>
            <div className="col-md-12 col-lg-5 col-12 mb-2">
              <div className="d-flex justify-content-between align-items-end gap-1">
                <div className="col-5">
                  <DatePicker
                    date={startDate}
                    id="start_date"
                    name="start_date"
                    placeholder="Start Date"
                    setDate={(e) => {
                      setStartDate((prev) => e);
                    }}
                    label="Start Date"
                    isRequired={false}
                  />
                </div>

                <div className="col-5">
                  <DatePicker
                    date={endDate}
                    id="end_date"
                    name="end_date"
                    placeholder="End Date"
                    setDate={(e) => {
                      setEndDate((prev) => e);
                    }}
                    label="End Date"
                    isRequired={false}
                  />
                </div>
                <button
                  className=" btn btn-primary"
                  onClick={() => setFetchData(true)}
                  disabled={Loading}
                >
                  Go
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {Loading && Object.keys(FilteredData).length === 0 ? (
        <ReactLoading
          type={"bubbles"}
          color={"black"}
          height={100}
          width={100}
          className="reactLoadingDash"
        />
      ) : (
        <>
          {!Loading && Object.entries(FilteredData).length === 0 ? (
            <div className="container table-responsive mt-2">
              <table className="table table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th>No.</th>
                    <th>Financier</th>
                    <th>Auction Count</th>
                    <th>Asset Count</th>
                    <th>Approved Count</th>
                    <th>Backout Count</th>
                    <th>Payment Count</th>
                    <th>Held by seller Count</th>
                  </tr>
                </thead>
              </table>
              <div className="container">
                <p className="text-center text-danger">No data</p>
              </div>
            </div>
          ) : (
            <>
              <div
                className="container table-responsive mt-2"
                style={{ minHeight: "5rem", maxHeight: "23rem" }}
              >
                <table className="table table-bordered table-hover">
                  <thead className="thead-dark">
                    <tr>
                      <th>No.</th>
                      <th>Financier</th>
                      <th>Auction Count</th>
                      <th>Asset Count</th>
                      <th>Approved Count</th>
                      <th>Backout Count</th>
                      <th>Payment Count</th>
                      <th>Held by seller Count</th>
                    </tr>
                  </thead>

                  <tbody>
                    {!Loading &&
                      Object.entries(FilteredData).map(
                        ([key, value], index) => {
                          total_assets_count += value["assets_count"];
                          total_auctions_count += value["auctions_count"];
                          total_approved_count += value["approved_count"];
                          total_backout_count += value["backout_count"];
                          total_held_by_seller_count +=
                            value["held_by_seller_count"];
                          total_payment_done_count +=
                            value["payment_done_count"];
                          return (
                            <tr>
                              <td>{parseInt(index) + 1}</td>
                              <td>{key}</td>
                              <td>{value["auctions_count"]}</td>
                              <td>{value["assets_count"]}</td>
                              <td>{value["approved_count"]}</td>
                              <td>{value["backout_count"]}</td>
                              <td>{value["payment_done_count"]}</td>
                              <td>{value["held_by_seller_count"]}</td>
                            </tr>
                          );
                        }
                      )}
                    {!Loading &&
                      FilteredData &&
                      Object.entries(FilteredData).length !== 0 && (
                        <tr>
                          <th colSpan={2} className="ms-auto">
                            Total
                          </th>
                          <th>{total_auctions_count}</th>
                          <th>{total_assets_count}</th>
                          <th>{total_approved_count}</th>
                          <th>{total_backout_count}</th>
                          <th>{total_payment_done_count}</th>
                          <th>{total_held_by_seller_count}</th>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default FinancierWiseAuctionList;
