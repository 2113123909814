import React, { useEffect, useState } from "react";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
import { errorToast } from "../../../react-toastfiy/toast";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";
import SearchBox from "../../../common/SearchBox";
import CustomTable from "./CustomTable";
import ReactLoading from "react-loading";

const RefundTransectionHistory = () => {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  const history = useHistory();
  let { id } = useParams();

  const [model, setModel] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [LoadingModal, setLoadingModal] = useState(false);

  // console.log({ user, id });

  const header = [
    {
      header: "Sr. No",
      dataKey: "no",
    },
    {
      header: "Name",
      dataKey: "name",
    },
    {
      header: "User Phone",
      dataKey: "user_phone",
    },
  ];

  const [data, setData] = useState([]);
  const [FilterPlanData, setFilterPlanData] = useState([]);
  const [modalDataList, setModalDataList] = useState([]);
  console.log({ modalDataList });
  let entry;
  useEffect(() => {
    try {
      setLoading(true);
      SubscriptionServices.GetAllUsers().then((res) => {
        setLoading(false);
        console.log({ res });
        const renderedData = Object.entries(res).map(([key, item], index) => ({
          no: index + 1,
          name: `${item.first_name} ${item.middle_name} ${item.last_name}`,
          user_phone: item.phone,
        }));
        console.log({ renderedData });
        if (renderedData.length !== 0) {
          setData(renderedData);
          setFilterPlanData(renderedData);
        }
      });
    } catch (error) {
      console.log(error);
      errorToast(error.msg);
      setLoading(false);
    }
  }, []);

  const redirectToTransactionHistory = (e, entry) => {
    const url = `/rapidbid/user-transaction-history/:${entry.user_phone}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="container mt-3">
        <div className="col-md-12 col-12 col-lg-12 mb-2 ">
          <div className="d-flex flex-wrap justify-content-between align-items-end">
            <div className="col-lg-3 col-12 col-md-4 col-sm-12">
              <h4>User Transaction History</h4>
            </div>
            <div className="col-lg-3 col-12 col-md-4 col-sm-12 ms-auto">
              <SearchBox allData={data} setFilteredData={setFilterPlanData} />
            </div>
          </div>
        </div>

        {/* <CustomTable
            header={header}
            Loading={Loading}
            entry={entry}
            data={FilterPlanData}
            model={setModel}
            setModel={setModel}
            action={"Refund Transection History"}
          /> */}

        {Loading && Object.keys(FilterPlanData).length === 0 ? (
          <div
            className="d-flex justify-content-between container"
            style={{ minHeight: "80vh", alignItems: "center" }}
          >
            <ReactLoading
              type={"bubbles"}
              color={"black"}
              height={100}
              width={100}
              className="reactLoadingDash "
            />
          </div>
        ) : (
          <>
            {FilterPlanData.length === 0 ? (
              <div className="container table-responsive mt-2">
                <table className="table table-bordered table-hover">
                  <thead className="thead-dark">
                    <tr>
                      <th>Sr. No.</th>
                      <th>User Name</th>
                      <th>User Phone</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                </table>
                <div className="container">
                  <p className="text-center text-danger">No data</p>
                </div>
              </div>
            ) : (
              <>
                <div className="table-responsive d-flex justify-content-between mt-3" >
                  <table
                    className="table table-hover table-bordered table-responsive"
                    // className="d-flex justify-content-between"
                    
                  >
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>User Name</th>
                        <th>User Phone</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {Object?.entries(FilterPlanData)?.map(
                        ([key, entry], index) => (
                          <tr key={index} className="align-middle" >
                            <td>{index + 1}</td>
                            <td>{entry.name}</td>
                            <td>{entry.user_phone}</td>

                            <td>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) =>
                                  redirectToTransactionHistory(e, entry)
                                }
                              >
                                View Details
                              </button>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default RefundTransectionHistory;
