import axios from "axios";
import { errorToast } from "../../react-toastfiy/toast";

class SubscriptionServices {
  SubscriptionFormCreation = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/create-subscription-plan`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  GetSubscriptionPlanList = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/subscription-get-all-plan`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  GetSubscriptionPlanListById = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/get-subscription-plan-by-id`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  UpdateSubscriptionPlan = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/update-subscription-plan`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  GetAllNewPublishedPlan = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/get-all-new-plans`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  GetAllSubscriptionApprovalRequest = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/user-subscriptions-request-list`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  DeleteSubscriptionPlan = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/delete-subscription-plan`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  GetAllRapibidUsers = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/get-active-or-expired-user-subscriptions`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  GetAllNewSubscriptionPlan = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/get-all-new-subscription-plans`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  GetAllAddonSubscriptionPlan = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/get-all-add-on-subscription-plans`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  GetAllStatePermissionsSubscriptionPlan = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/get-all-state-permission-subscription-plans`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  UpdateUserList = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/update-user-subscription-admin`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  SubscriptionApprovalRejectRequest = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/approve-reject-subscription-request`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  // GetAllUsers = async (formData) => {
  //   return axios({
  //     method: "post",
  //     url: `/user/user-get-all`,
  //     data: formData,
  //     headers: {
  //       "content-type": "application/json",
  //     },
  //   })
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .catch(function (error) {
  //       console.log(error.toJSON());
  //       errorToast(error.toJSON().message);
  //     });
  // };
  GetUserTransactionHistory = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/user-transaction-history`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  GetAllRefundApprovalRequestList = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/refund-request-list-admin`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  ApprovalRefundApprovalRequest = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/approve-reject-refund-request-admin`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  GetAllMake = async (formData) => {
    return axios({
      method: "post",
      url: `https://rapidbid.meratractor.com/auction/make-get-all`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  CreateMake = async (formData) => {
    return axios({
      method: "post",
      url: `https://rapidbid.meratractor.com/auction/make-create`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  UpdateMake = async (formData) => {
    return axios({
      method: "post",
      url: `https://rapidbid.meratractor.com/auction/make-edit`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  DeleteMake = async (formData) => {
    return axios({
      method: "post",
      url: `https://rapidbid.meratractor.com/auction/make-delete`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  GetAllModel = async (formData) => {
    return axios({
      method: "post",
      url: `https://rapidbid.meratractor.com/auction/model-get-all`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  GetAllModelNameById = async (formData) => {
    return axios({
      method: "post",
      url: `https://rapidbid.meratractor.com/auction/model-name-get-all`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  CreateModel = async (formData) => {
    return axios({
      method: "post",
      url: `https://rapidbid.meratractor.com/auction/model-create`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  UpdateModel = async (formData) => {
    return axios({
      method: "post",
      url: `https://rapidbid.meratractor.com/auction/model-edit`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  DeleteModel = async (formData) => {
    return axios({
      method: "post",
      url: `https://rapidbid.meratractor.com/auction/model-delete`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  GetAllState = async (formData) => {
    return axios({
      method: "post",
      url: `https://rapidbid.meratractor.com/user/state-get-all`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  CreateState = async (formData) => {
    return axios({
      method: "post",
      url: `https://rapidbid.meratractor.com/user/state-create `,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  UpdateState = async (formData) => {
    return axios({
      method: "post",
      url: `https://rapidbid.meratractor.com/user/state-edit`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  DeleteState = async (formData) => {
    return axios({
      method: "post",
      url: `https://rapidbid.meratractor.com/user/state-delete`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  GetAllUsers = async (formData) => {
    return axios({
      method: "post",
      url: `/users/get-all-users`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  GetUsersById = async (formData) => {
    return axios({
      method: "post",
      url: `/user/user-get `,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  CreateUser = async (formData) => {
    return axios({
      method: "post",
      url: `/user/user-create-react`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  UpdateUser = async (formData) => {
    return axios({
      method: "post",
      url: `/user/user-edit-react`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  DeleteUser = async (formData) => {
    return axios({
      method: "post",
      url: `/user/user-delete`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  GetAuction = async (formData) => {
    return axios({
      method: "post",
      url: `/auction/get-auction`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  CreateAuction = async (formData) => {
    return axios({
      method: "post",
      url: `/auction/add-auction`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  GetassetByAssetId = async (formData) => {
    return axios({
      method: "post",
      url: `https://rapidbid.meratractor.com/auction/asset-get`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  UpdateAsset = async (formData) => {
    return axios({
      method: "post",
      url: `/auction/asset-edit`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  AddAssetDocs = async (formData) => {
    return axios({
      method: "post",
      url: `/auction/add-asset-docs`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  AddAssetImage = async (formData) => {
    return axios({
      method: "post",
      url: `/auction/asset-img-add`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  GetAssetImage = async (formData) => {
    return axios({
      method: "post",
      url: `/auction/asset-img-get`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  EditAssetImageDelete = async (formData) => {
    return axios({
      method: "post",
      url: `/auction/asset-img-delete`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  EditAssetImagePrimary = async (formData) => {
    return axios({
      method: "post",
      url: `/auction/asset-img-edit`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  GetDownloadDocs = async (formData) => {
    return axios({
      method: "post",
      url: `/auction/get-asset-docs`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  GetAllStateList = async (formData) => {
    return axios({
      method: "post",
      url: `/user/state-get-all`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };

  GetSubscriptionTenorList = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/get-all-tenures`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  CreateTenorList = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/add-new-tenure`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  UpdateTenorList = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/edit-tenure-by-id`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  DeleteTenorList = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/delete-tenure-by-id`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
  GetSubscriptionTenorListByPlatform = async (formData) => {
    return axios({
      method: "post",
      url: `/subscriptions/get-tenures-by-platform`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error.toJSON());
        errorToast(error.toJSON().message);
      });
  };
}

export default new SubscriptionServices();
