import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
import { successToast } from "../../../react-toastfiy/toast";
const RapibidAddMake = (props) => {
  const { data, show, onHide, setmakedata, handlesubmit } = props;
  const [UpdateButtonLoading, setUpdateButtonLoading] = useState(false);
  const [SubmitButtonLoading, setSubmitButtonLoading] = useState(false);


  const handleChangeMake = (e) => {
    const { name, value } = e.target;
    setmakedata((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleFileChange = (e) => {
    setmakedata((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    setSubmitButtonLoading(true);
    let form_data = new FormData();

    form_data.append("name", data.name);
    form_data.append("logo_url", "");
    SubscriptionServices.CreateMake(form_data).then((res) => {
      if (res.status === 200) {
        successToast(res.msg);
        setSubmitButtonLoading(false);
        setTimeout(() => {
          window.location.replace("/rapibid/auction/make");
        }, 500);
      } else {
        console.log(res.msg);
        setSubmitButtonLoading(false);
      }
    });
  };

  const handleUpdate = () => {
    setUpdateButtonLoading(true);
    let form_data = new FormData();
    form_data.append("id", data.id);
    form_data.append("name", data.name);
    form_data.append("logo_url", "");
    SubscriptionServices.UpdateMake(form_data).then((res) => {
      if (res.status === 200) {
        successToast(res.msg);
        setUpdateButtonLoading(false);
        setTimeout(() => {
          window.location.replace("/rapibid/auction/make");
        }, 500);
      } else {
        console.log(res.msg);
        setUpdateButtonLoading(false);
      }
    });
  };

  try {
    return (
      <Modal
        {...props}
        size="xs"
        backdrop="static"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        {data && data?.id !=="" ? (
          <Modal.Header closeButton>
            <div
              id="example-custom-modal-styling-title"
              className="modal-title-custom"
            >
              <h4>Update Make</h4>
            </div>
          </Modal.Header>
        ) : (
          <Modal.Header closeButton>
            <div
              id="example-custom-modal-styling-title"
              className="modal-title-custom"
            >
              <h4>Add Make</h4>
            </div>
          </Modal.Header>
        )}

        <Modal.Body>
          <div className="row mt-2">
            <div className="col-3">
              <label>Make :</label>
            </div>
            <div className="col-8">
              <input
                className="form-control"
                placeholder="Make"
                id="name"
                type="text"
                name="name"
                value={data?.name}
                onChange={(e) => handleChangeMake(e)}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-3">
              <label>Logo :</label>
            </div>
            <div className="col-8">
              <input
                className="form-control"
                placeholder="Logo"
                id="logo"
                // type="file"
                type="text"
                name="logo"
                accept="image/*"
                value={data?.logo}
                onChange={(e) => handleFileChange(e)}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
          {data && data?.id !=="" ? (
            <>
              {UpdateButtonLoading ? (
                <button
                  className="btn btn-primary mt-2"
                  type="button"
                  disabled
                  id="loadingBtnTp"
                >
                  <span
                    className="spinner-border spinner-border-sm mx-1 px-2"
                    type="submit"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <Button variant="primary" onClick={handleUpdate}>
                  Update
                </Button>
              )}
            </>
          ) : (
            <>
              {SubmitButtonLoading ? (
                <button
                  className="btn btn-primary mt-2"
                  type="button"
                  disabled
                  id="loadingBtnTp"
                >
                  <span
                    className="spinner-border spinner-border-sm mx-1 px-2"
                    type="submit"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <Button variant="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              )}
            </>
          )}
        </Modal.Footer>
      </Modal>
    );
  } catch (error) {
    console.log(error);
  }
};

export default RapibidAddMake;
