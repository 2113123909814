import React, { useEffect, useState } from "react";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
import ReactLoading from "react-loading";
import { errorToast } from "../../../react-toastfiy/toast";
// import SearchBox from "../../../common/SearchBox";
import SearchBox from "../../../common/SearchBox";

const UserLoginDetails = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [userList, setuserList] = useState([]);
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    try {
      setLoading(true);
      // key
      const form_data = new FormData();
      form_data.append("key", "login");
      SubscriptionServices.GetUserLoginDetailsList(form_data).then((res) => {
        if (Object.entries(res)?.length > 0) {
          setuserList(res);
        }
      });

      SubscriptionServices.GetUserLoginDetailsList().then((res) => {
        setLoading(false);
        console.log({ res });
        if (Object.entries(res)?.length > 0) {
          setData(res);
          setFilterData(res);
        }
      });
    } catch (error) {
      errorToast(error.msg);
    }
  }, []);
  console.log(Object.entries(userList)?.length);

  return (
    <>
      <div className="container mt-4">
        <div className="d-flex flex-wrap justify-content-between align-items-end">
          <div className="col-lg-6 col-12 col-md-6 col-sm-12">
            <span
              className="heading heading5 hind-font medium-font-weight"
              style={{
                fontSize: "25px",
                color: "black !important",
                borderBottom: "1px solid grey",
              }}
            >
              User Login Details
            </span>
          </div>
          <div className="col-lg-3 col-12 col-md-6 col-sm-12 ms-auto me-2">
            <SearchBox allData={data} setFilteredData={setFilterData} />
          </div>
        </div>
        {!loading && userList && (
          <div
            className="col-sm-12 mb-3 mt-2"
          >
            <h3>
              {userList?.login_user} Users logged in out of {" "}
              {userList?.all_users}
            </h3>
          </div>
        )}
      </div>
      {loading ? (
        <div className="d-flex justify-content-center col-12">
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <>
          {Object.keys(filterData).length === 0 ? (
            <div className="container table-responsive mt-2">
              <table className="table table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Sr No.</th>
                    <th className="text-align-start ms-2">User</th>
                    <th className="text-align-start">Devices</th>
                    <th className="text-align-start">Phone</th>
                  </tr>
                </thead>
              </table>
              <div className="container">
                <p className="text-center text-danger">No data</p>
              </div>
            </div>
          ) : (
            <>
              <div
                className=" container table-responsive mt-2"
                style={{
                  maxHeight: "85vh",
                }}
              >
                {/* <div
                  className="col-sm-12 mb-3"
                  style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 3,
                  }}
                >
                  <h3>
                    {userList?.login_user} Users logged in out of {""}
                    {userList?.all_users}
                  </h3>
                </div> */}
                <table className="table table-bordered table-hover">
                  <thead
                    className="thead-dark"
                    style={{
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    <tr>
                      <th scope="col">Sr No.</th>
                      <th className="text-align-start ms-2">User</th>
                      <th className="text-align-start">Devices</th>
                      <th className="text-align-start">Phone</th>
                    </tr>
                  </thead>

                  <tbody>
                    {!loading &&
                    Object.entries(filterData) &&
                    Object.entries(filterData)?.length === 0 ? (
                      <>
                        <div className="text-danger mt-2 "></div>
                      </>
                    ) : (
                      Object.entries(filterData).map(
                        ([key, rowData], rowIndex) => (
                          <tr key={rowIndex}>
                            <td>{rowIndex + 1}</td>
                            <td className="text-align-start ms-2">
                              {rowData?.user}
                            </td>
                            <td className="text-align-start ms-2">
                              {rowData?.token}
                            </td>
                            <td className="text-align-start ms-2">
                              {rowData?.phone}
                            </td>
                          </tr>
                        )
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default UserLoginDetails;
