import React from "react";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";

const CustomTable = ({
  columns,
  data,
  action,
  handleDelete,
  handleApproval,
  handleModal,
  handleOpenUserForm,
  handleOpenModal,
  setCreateDurationData,
  setModal,
  e,
  entry,
  loading,
}) => {
  const redirectToTransactionHistory = (e, entry) => {
    const url = `/rapidbid/user-transaction-history/:${entry.user_phone}`;
    window.open(url, "_blank");
  };
 

  console.log({data, columns})

  return (
    <>
      {loading && Object.keys(data).length === 0 ? (
        <div
          className="d-flex  container"
          style={{ minHeight: "80vh", alignItems: "center" }}
        >
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
            className="reactLoadingDash "
          />
        </div>
      ) : (
        <>
          {data.length === 0 ? (
            <div className="container table-responsive mt-2">
              <table className="table table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    {columns?.map((column) =>
                      <th key={column.dataKey}>{column.header}</th>
                    )}
                    {action === "DraftPlan" && <th>Action</th>}
                    {action === "Published" && <th>Action</th>}
                    {action === "Tenure" && <th>Action</th>}
                    {action === "ActiveSubscriptionUserList" && <th>Action</th>}
                    {action === "Subscription Approval" && (
                      <>
                        <th>View</th>
                        <th>Action</th>
                      </>
                    )}
                    {action === "Refund Approval pending" && (
                      <>
                        <th>Action</th>
                      </>
                    )}
                  </tr>
                </thead>
              </table>
              <div className="container">
                <p className="text-center text-danger">No data</p>
              </div>
            </div>
          ) : (
            <>
              <div className="table-responsive mt-3">
                <table className="table table-hover table-bordered table-responsive">
                  <thead>
                    {action === "Transaction History For All Users" && (
                      <tr>
                        {columns?.map((column) => (
                          <th key={column.dataKey}>{column.header}</th>
                        ))}
                      </tr>
                    )}
                    {action === "Users_List" && (
                      <>
                        <tr>
                          {columns?.map((column) => (
                            <th key={column.dataKey}>{column.header}</th>
                          ))}
                          <th>Action</th>
                        </tr>
                      </>
                    )}
                    {action === "Refund Approval pending" && (
                      <>
                        <tr>
                          {columns?.map((column) => (
                            <th key={column.dataKey}>{column.header}</th>
                          ))}
                          <th>Action</th>
                        </tr>
                      </>
                    )}
                    {action === "Refund Transection History" && (
                      <>
                        <tr>
                          {columns?.map((column) => (
                            <th key={column.dataKey}>{column.header}</th>
                          ))}
                          <th>View</th>
                        </tr>
                      </>
                    )}
                    {action === "Subscription Approval" && (
                      <>
                        <tr>
                          {columns?.map((column) => (
                            <th key={column.dataKey}>{column.header}</th>
                          ))}
                          <th>View</th>
                          <th>Action</th>
                        </tr>
                      </>
                    )}
                    {action === "ActiveSubscriptionUserList" && (
                      <>
                        <tr>
                          {columns?.map((column) => (
                            <th key={column.dataKey}>{column.header}</th>
                          ))}
                          <th>Action</th>
                        </tr>
                      </>
                    )}
                    {action === "Published" && (
                      <>
                        <tr>
                          {columns?.map((column) => (
                            <th key={column.dataKey}>{column.header}</th>
                          ))}
                          <th>Action</th>
                        </tr>
                      </>
                    )}
                    {action === "Tenure" && (
                      <>
                        <tr>
                          {columns?.map((column) => (
                            <th key={column.dataKey}>{column.header}</th>
                          ))}
                          <th>Action</th>
                        </tr>
                      </>
                    )}
                    {action === "DraftPlan" && (
                      <>
                        <tr>
                          {columns?.map((column) => (
                            <th key={column.dataKey}>{column.header}</th>
                          ))}
                          <th>Action</th>
                        </tr>
                      </>
                    )}
                    {action === "Model" && (
                      <>
                        <tr>
                          {columns?.map((column) => (
                            <th key={column.dataKey}>{column.header}</th>
                          ))}
                          <th>Action</th>
                        </tr>
                      </>
                    )}
                    {action === "auction_List" && (
                      <>
                        <tr>
                          {columns?.map((column) => (
                            <th key={column.dataKey}>{column.header}</th>
                          ))}
                        </tr>
                      </>
                    )} 
                    {action === "active" && (
                      <>
                        <tr>
                          {columns?.map((column) => (
                            <th key={column.dataKey}>{column.header}</th>
                          ))}
                        </tr>
                      </>
                    )}
                  </thead>

                  <tbody>
                    {Object?.entries(data)?.map(([key, entry], index) => (
                      <tr key={index}>
                        {columns.map((column) => (
                          <>
                            <td>
                              {column.dataKey === "plan_type"
                                ? entry[column.dataKey] === "new"
                                  ? "New"
                                  : entry[column.dataKey] === "add-on"
                                  ? "Add-on"
                                  : entry[column.dataKey] === "upgrade"
                                  ? "Upgrade"
                                  : "State Permission"
                                : column.dataKey === "plan_category"
                                ? entry[column.dataKey] === "normal"
                                  ? "Normal"
                                  : "Corporate"
                                : entry[column.dataKey]}
                            </td>
                          </>
                        ))}

                        {action === "DraftPlan" && (
                          <>
                            <td>
                              <Link
                                to={`/rapidbid/manage-plan/create-plan/:${entry.plan_id}`}
                              >
                                <i
                                  className="bi bi-pencil-square mx-3"
                                  // id={user.id}
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </Link>
                              <i
                                className="bi bi-trash mx-3"
                                id={entry.plan_id}
                                onClick={(e) => {
                                  handleDelete(e);
                                }}
                                style={{ cursor: "pointer" }}
                              ></i>
                            </td>
                          </>
                        )}
                        {action === "Users_List" && (
                          <>
                            <td>
                              <Link
                                to={`/rapidbid/manage-user/add-user/:${entry.id}`}
                              >
                                <i
                                  className="bi bi-pencil-square mx-3"
                                  // id={user.id}
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </Link>
                              <i
                                className="bi bi-trash mx-3"
                                id={entry.id}
                                onClick={(e) => {
                                  handleDelete(e, entry);
                                }}
                                style={{ cursor: "pointer" }}
                              ></i>
                            </td>
                          </>
                        )}
                        {action === "Model" && (
                          <>
                            <td>
                              <Link to={"#"}>
                                <i
                                  className="bi bi-pencil-square mx-3"
                                  onClick={(e) => handleOpenModal(e, entry)}
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </Link>
                              <i
                                className="bi bi-trash mx-3"
                                id={entry.plan_id}
                                onClick={(e) => {
                                  handleDelete(e, entry);
                                }}
                                style={{ cursor: "pointer" }}
                              ></i>
                            </td>
                          </>
                        )}

                        {action === "Published" && (
                          <>
                            <td>
                              <Link
                                to={`/subscription/manage-plan/create-plan/:${entry.plan_id}`}
                              >
                                <i
                                  className="bi bi-pencil-square mx-3"
                                  // id={user.id}
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </Link>
                              <i
                                className="bi bi-trash mx-3"
                                id={entry.plan_id}
                                onClick={(e) => {
                                  handleDelete(e);
                                }}
                                style={{ cursor: "pointer" }}
                              ></i>
                            </td>
                          </>
                        )}
                        {action === "Tenure" && (
                          <>
                            <td>
                              {/* <Link> */}
                              <i
                                className="bi bi-pencil-square mx-3"
                                // id={user.id}
                                onClick={(e) => {
                                  handleModal(e, entry);
                                }}
                                style={{ cursor: "pointer" }}
                              ></i>
                              {/* </Link> */}
                              <i
                                className="bi bi-trash mx-3"
                                id={entry.plan_id}
                                onClick={(e) => {
                                  handleDelete(e, entry);
                                }}
                                style={{ cursor: "pointer" }}
                              ></i>
                            </td>
                          </>
                        )}
                        {action === "ActiveSubscriptionUserList" && (
                          <>
                            <td>
                              <button
                                type="button"
                                className="btn btn-success m-2 y-2"
                              >
                                Upgrade
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger  y-5 m-2 yx-5"
                              >
                                {" "}
                                Cancel
                              </button>
                            </td>
                          </>
                        )}

                        {action === "Subscription Approval" && (
                          <>
                            <td>
                              <button
                                type="button"
                                // className="btn btn-primary yx-3"
                                className="btn btn-primary flex-grow-1 m-2"
                                onClick={(e) => {
                                  handleModal(e, entry);
                                }}
                              >
                                View Details
                              </button>
                            </td>
                            <td className="d-flex">
                              <button
                                name="Approved"
                                type="button"
                                value={entry.user_phone}
                                className="btn btn-success flex-grow-1 m-2"
                                onClick={(e) => {
                                  handleApproval(e, entry);
                                }}
                              >
                                Approval
                              </button>
                              <button
                                name="Rejected"
                                value={entry.user_phone}
                                type="button"
                                // className="btn btn-danger flex-grow-1 ms-2 m-2"
                                className="btn btn-danger flex-grow-1 m-2"
                                onClick={(e) => {
                                  handleApproval(e, entry);
                                }}
                              >
                                Reject
                              </button>
                            </td>
                          </>
                        )}
                        {action === "Refund Transection History" && (
                          <td className="text-center">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) =>
                                redirectToTransactionHistory(e, entry)
                              }
                            >
                              View Details
                            </button>
                          </td>
                        )}
                        {action === "Refund Approval pending" && (
                          <>
                            <td className="d-flex">
                              <button
                                name="Approved"
                                type="button"
                                value={entry.user_phone}
                                className="btn btn-success flex-grow-1 m-2"
                                onClick={(e) => {
                                  handleApproval(e, entry);
                                }}
                              >
                                Approval
                              </button>
                              <button
                                name="Rejected"
                                value={entry.user_phone}
                                type="button"
                                // className="btn btn-danger flex-grow-1 ms-2 m-2"
                                className="btn btn-danger flex-grow-1 m-2"
                                onClick={(e) => {
                                  handleApproval(e, entry);
                                }}
                              >
                                Reject
                              </button>
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CustomTable;
