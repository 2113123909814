import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Link, useHistory, useLocation } from "react-router-dom";
import SearchBox from "../../common/SearchBox";
import { indianCurrencyFormater } from "../../helpers/functions/currencyFormater";
import auctionService from "../../services/auction/auction.service";

const AuctionAssetStatus = () => {
  const location = useLocation();
  const history = useHistory();
  const asset_id = location?.pathname?.split(":")[1];
  const source = axios.CancelToken.source();
  const headers = [
    { header: "Reference No", dataKey: "reference_no" },
    { header: "Make Model", dataKey: "make_model" },
    { header: "Bidder Name", dataKey: "bidder_name" },
    { header: "Bid", dataKey: "bid" },
    { header: "Date", dataKey: "date" },
    { header: "Year", dataKey: "mfg_yr" },
    { header: "Yard Name", dataKey: "yard_name" },
    { header: "status", dataKey: "status" },
    { header: "Franchise", dataKey: "is_franchised" },
  ];
  const [auctionData, setAuctionData] = useState([]);
  const [filterAuctionData, setFilterAuctionData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      setLoading(true);
      const form_data = new FormData();
      form_data.append("uid", asset_id);
      auctionService
        .GetAuctionStatus(asset_id, form_data, source)
        .then((res) => {
          setLoading(false);
          if (Object.entries(res)?.length >= 0) {
            setAuctionData(res);
            setFilterAuctionData(res);
          }
        });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleRedirect = (e, index) => {
    let data = filterAuctionData[index];
    let ref_no = data?.reference_no;
    e.preventDefault();
    history.push(
      `/meratractor/auctions/modify/view/asset_summary:ref_no=${ref_no}&uid=${asset_id}`
    );
  };

  return (
    <>
      <ul class="breadcrumb alert alert-secondary">
        <li class="breadcrumb-item" style={{ marginTop: "-12px" }}>
          <Link
            to={`/meratractor/auctions/modify?uid=${asset_id}`}
            className="text-primary"
            style={{ textDecoration: "none", fontWeight: "bold" }}
          >
            View Auction
          </Link>
        </li>
        <li
          class="breadcrumb-item active text-danger"
          aria-current="page"
          style={{ marginTop: "-12px" }}
        >
          <b className="text-danger">Asset Status</b>
        </li>
      </ul>

      {loading ? (
        <div
          className="d-flex justify-content-center container"
          style={{ minHeight: "80vh", alignItems: "center" }}
        >
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <>
          <div
            className="container  table-responsive mt-2 text-center"
            style={{ minHeight: "5vh", maxHeight: "80vh" }}
          >
            <div className="col-md-6 col-lg-4 col-12 ms-auto mt-2">
              <SearchBox
                placeholder="Search by Reference No..."
                allData={auctionData}
                setFilteredData={setFilterAuctionData}
              />
            </div>
            <table className="table table-hover table-bordered mt-2">
              <thead className="thead-dark">
                <tr
                  className="text-center"
                  style={{ fontSize: "small", position: "sticky", top: 0 }}
                >
                  <th> Sr. No</th>
                  {headers.map((column) => (
                    <th className="col" key={column.header}>
                      {column.header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.entries(filterAuctionData) &&
                Object.entries(filterAuctionData)?.length === 0 ? (
                  <div className=" text-danger text-center mt-2 "></div>
                ) : (
                  Object.entries(filterAuctionData)?.map(
                    (rowData, rowIndex) => (
                      <tr
                        key={rowIndex}
                        style={{
                          fontSize: "small",
                        }}
                      >
                        <td>{rowIndex + 1}</td>
                        {headers?.map((header, index) => (
                          <>
                            {header.dataKey === "reference_no" ? (
                              <td
                                key={index}
                                className="text-primary"
                                onClick={(e) => {
                                  handleRedirect(e, rowIndex);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {rowData[1][header.dataKey]}
                              </td>
                            ) : header.dataKey === "bid" ? (
                              <td>
                                {indianCurrencyFormater(
                                  rowData[1][header?.dataKey]
                                )}
                              </td>
                            ) : (
                              <td key={index}>{rowData[1][header.dataKey]}</td>
                            )}
                          </>
                        ))}
                      </tr>
                    )
                  )
                )}
              </tbody>
            </table>
            {auctionData && auctionData?.length === 0 && (
              <div className="text-danger text-center mt-2 ">
                Auction isn't over yet.
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AuctionAssetStatus;
