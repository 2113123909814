import jwtDecode from "jwt-decode";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CardBlock from "../../common/CardBlock";
import AddAuction from "./../../img/add_assets.svg";
import EditAuctionDetails from "./../../img/view.svg";

const AddAsset = () => {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwtDecode(currentUser);
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const auctionId = searchParams.get("uid");

  useEffect(() => {
    document.title = "Add Asset";
  }, []);

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container responsive mt-2 ">
        <div className="row mt-3 mb-2">
          <div className="d-flex justify-content-between col-1">
            <i
              className="bi bi-arrow-left-circle"
              onClick={() =>
                history.push(`/meratractor/auctions/modify?uid=${auctionId}`)
              }
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </div>
          <div className="col-10 mt-2 float-start ms-2">
            <h5 className="">Auction ID : {auctionId}</h5>
          </div>
        </div>
      </div>

      <div className="container container-body">
        <CardBlock
          name="Single Upload"
          logo={AddAuction}
          to={`/meratractor/auctions/upload_single_asset?uid=${auctionId}`}
        />
        <CardBlock
          name="Upload excel file"
          logo={EditAuctionDetails}
          to={`/meratractor/auctions/upload_multiple_assets?uid=${auctionId}`}
        />
      </div>
    </>
  );
};

export default AddAsset;
