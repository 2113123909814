import React, { useEffect, useState } from "react";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
import { errorToast, successToast } from "../../../react-toastfiy/toast";

const Permissions = () => {
  const [userData, setUserData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [permissionLoading, setPermissionLoading] = useState(false);
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const [arrayLength, setArrayLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handleAccordionToggle = (index) => {
    setOpenAccordionIndex(openAccordionIndex === index ? null : index);
  };

  useEffect(() => {
    setUserLoading(true);
    const form_data = new FormData();
    form_data.append("pg_no", currentPage);
    SubscriptionServices.GetAllUserList(form_data).then((res) => {
      setUserLoading(false);
      let users = res.users;
      if (users?.length > 0) {
        setUserData(users);
        let user_list = users.map((val) => val[1]);
        const lenTrans = users[0];
        const userLength = lenTrans.map((val) => val);
        const lenValue = userLength[2].len;
        setArrayLength(lenValue);
        setPermissionLoading(true);
        SubscriptionServices.GetStateListData().then((res) => {
          let state_list = res.states;
          if (res?.states?.length > 0) {
            setStateData(state_list);
          }
        });
        const form_data = new FormData();
        form_data.append("users", JSON.stringify(user_list));
        SubscriptionServices.Permissions(form_data).then((res) => {
          setPermissionLoading(false);
          if (res?.length > 0) {
            setUpdatedData(res);
          }
        });
      }
    });
  }, [currentPage]);

  const pageCount = Math.ceil(arrayLength / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, arrayLength);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleChange = (e, state, val) => {
    console.log({ e, state, val });
    e.preventDefault();

    const index = updatedData.findIndex(
      (item) => item.user_id === val[1] && item.state_id === state
    );

    if (index !== -1 && updatedData[index].permission === true) {
      setUpdatedData((prevData) => [
        ...prevData.slice(0, index),
        { ...prevData[index], permission: false },
        ...prevData.slice(index + 1),
      ]);
    } else if (index !== -1 && updatedData[index].permission === false) {
      setUpdatedData((prevData) => [
        ...prevData.slice(0, index),
        { ...prevData[index], permission: true },
        ...prevData.slice(index + 1),
      ]);
    }
    console.log(val[1], { state });
    setPermissionLoading(true);
    const form_data = new FormData();
    form_data.append("user", val[1]);
    form_data.append("state", state);
    SubscriptionServices.UpdatePermissions(form_data).then((res) => {
      setPermissionLoading(false);
      if (res?.status === 200) {
        successToast(res.msg);
      } else {
        errorToast(res.msg);
      }
    });
  };

  return (
    <div className="container d-flex d-flex-wrap justify-content-center">
      <div
        className="col-md-10 col-lg-8 col-12 shadow-lg bg-body p-3 mb-2 rounded"
        style={{
          marginTop: "2rem",
        }}
      >
        <div className="accordion" id="accordionExample">
          {!userLoading &&
            userData.length > 0 &&
            userData.map((val, index) => (
              <div className="accordion-item" key={index}>
                <h4 className="accordion-header" id={`heading${index}`}>
                  <button
                    className={`accordion-button ${
                      openAccordionIndex === index ? "collapsed" : ""
                    }`}
                    type="button"
                    onClick={() => handleAccordionToggle(index)}
                    aria-expanded={
                      openAccordionIndex === index ? "true" : "false"
                    }
                    aria-controls={`collapse${index}`}
                  >
                    {`${val[0]} ${val[1]}`}
                  </button>
                </h4>
                <div
                  id={`collapse${index}`}
                  className={`accordion-collapse collapse ${
                    openAccordionIndex === index ? "show" : ""
                  }`}
                  aria-labelledby={`heading${index}`}
                  data-bs-parent="#accordionExample"
                >
                  {stateData.map((stateVal, indexState) => (
                    <div key={indexState} className="ms-2">
                      <input
                        type="checkbox"
                        className="form-box ms-2"
                        disabled={permissionLoading === true}
                        checked={Boolean(
                          updatedData.find(
                            (checkVal) =>
                              checkVal.user_id === val[1] &&
                              checkVal.state_id === stateVal &&
                              checkVal.permission === true
                          )
                        )}
                        onChange={(e) => handleChange(e, stateVal, val)}
                      />
                      <span className="ms-3">{stateVal}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>

        {arrayLength > 10 && (
          <div className="d-flex justify-content-center mt-4">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
              

                {arrayLength > 10 && (
                  <div className="d-flex justify-content-center">
                    <nav aria-label="Page navigation example">
                      <ul className="pagination">
                        {currentPage > 1 && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#"
                              onClick={() => paginate(1)}
                            >
                              First
                            </a>
                          </li>
                        )}
                        {currentPage > 1 && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#"
                              onClick={() => paginate(currentPage - 1)}
                            >
                              {"<"}
                            </a>
                          </li>
                        )}

                        {Array.from(
                          { length: Math.min(pageCount, 7) },
                          (_, i) => {
                            const pageNumber =
                              currentPage - Math.floor(7 / 2) + i;
                            return (
                              pageNumber > 0 &&
                              pageNumber <= pageCount && (
                                <li
                                  key={pageNumber}
                                  className={`page-item ${
                                    currentPage === pageNumber ? "active" : ""
                                  }`}
                                >
                                  <a
                                    className="page-link"
                                    href="#"
                                    onClick={() => handlePageChange(pageNumber)}
                                  >
                                    {pageNumber}
                                  </a>
                                </li>
                              )
                            );
                          }
                        )}

                        {currentPage < pageCount && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#"
                              onClick={() => paginate(currentPage + 1)}
                            >
                              {">"}
                            </a>
                          </li>
                        )}

                        {currentPage < pageCount && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#"
                              onClick={() => paginate(pageCount)}
                            >
                              Last
                            </a>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                )}
              </ul>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
};

export default Permissions;
