import axios from "axios";
import { errorToast } from "../../react-toastfiy/toast";

class CountService {
  GetPOApprovalPendingCount = async (formData) => {
    return axios({
      method: "post",
      url: `/implements/get-po-approval-pending-count`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetWarrantyClaimApprovalPendingCount = async (formData) => {
    return axios({
      method: "post",
      url: `/implements/get-warranty-claim-approval-pending-count`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetPaymentVerificationPendingCount = async (formData) => {
    return axios({
      method: "post",
      url: `/implements/get-payment-verification-pending-count`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetDispatchPendingCount = async (formData) => {
    return axios({
      method: "post",
      url: `/implements/get-dispatch-pending-count`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  OrderReceiveConfirmationPending = async (formData) => {
    return axios({
      method: "post",
      url: `/implements/order-receive-confirmation-pending`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  PaymentPendingCount = async (formData) => {
    return axios({
      method: "post",
      url: `/implements/payment-pending-count`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  WarrantyCustomerSatisfactionUploadedPendingCount = async (formData) => {
    return axios({
      method: "post",
      url: `/implements/warranty-customer-satisfaction-uploaded-pending-count`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  InvoiceVerificationPendingCount = async (formData) => {
    return axios({
      method: "post",
      url: `/implements/invoice-verification-pending-count`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  InventoryTransferDispatchPendingCount = async (formData) => {
    return axios({
      method: "post",
      url: `/implements/inventory-transfer-dispatch-pending-count`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  ManufacturerOrdersCount = async (formData) => {
    return axios({
      method: "post",
      url: `/implements/manufacturer-orders-count`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  CustomerSatisfactionProofVerificationPendingCount = async (formData) => {
    return axios({
      method: "post",
      url: `/implements/customer-satisfaction-proof-verification-pending-count`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetApprovalPendingLeaveRequestCount = async (formData) => {
    return axios({
      method: "post",
      url: `users/get-approval-pending-leave-requests-count`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetBLDOVerifiedPendingBLBHCount = async (formData) => {
    return axios({
      method: "post",
      url: `operations/get-bl-do-verified-pending-blbh-count`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GlobalDataCountAPI = async (formData) => {
    return axios({
      method: "post",
      url: `operations/global-data-count-api`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
}

export default new CountService();
