function indianCurrencyFormater(amount, maximumFractionDigits = 2) {
  const locale = "en-IN";
  const options = {
    style: "currency",
    currency: "INR",
    maximumFractionDigits,
  };

  if (!isNaN(parseInt(amount))) {
    amount = amount.toString();
    amount = amount.replaceAll(",", "");

    const formatter = new Intl.NumberFormat(locale, options);
    const output = formatter.format(parseFloat(amount));
    return output;
  } else {
    return "";
  }
}

function currencyToNumber(amount) {
  return parseFloat(amount?.toString()?.replace(/[^0-9.-]/g, ""));
}

export { indianCurrencyFormater, currencyToNumber };
