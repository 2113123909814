import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import SearchBox from "../../common/SearchBox";
import { useLocation, useHistory, Link } from "react-router-dom";
import auctionService from "../../services/auction/auction.service";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import app from "../../auctionFirebaseConfig";
import { indianCurrencyFormater } from "../../helpers/functions/currencyFormater";
import {
  getDatabase,
  ref,
  onValue,
  onChildChanged,
  onChildAdded,
  off,
} from "firebase/database";
import "firebase/database";
import Swal from "sweetalert2";
import jwtDecode from "jwt-decode";
import axios from "axios";

const ViewAuctionAsset = () => {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwtDecode(currentUser);
  const phone = user?.phone;
  const source = axios.CancelToken.source();
  useEffect(() => {
    document.title = "View Assets";
  }, []);
  const db = getDatabase(app);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uid = searchParams.get("uid");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [auctionData, setAuctionData] = useState([]);
  const [filterAuctionData, setFilterAuctionData] = useState([]);
  const [showFullPrice, setShowFullPrice] = useState("");

  const headers = [
    { header: "Reference No", dataKey: "reference_no" },
    { header: "Image", dataKey: "image_paths" },
    { header: "Reg No", dataKey: "reg_no" },
    { header: "Make Model", dataKey: "make_model" },
    { header: "Manufacture Year", dataKey: "mfg_yr" },
    { header: "Yard Name", dataKey: "yard_name" },
    { header: "Start Price", dataKey: "start_price" },
    { header: "Current Price", dataKey: "current_price" },
    { header: "Reserve Price", dataKey: "recommended_bid" },
    { header: "No. Of Bids", dataKey: "no_of_bids" },
    { header: "Financier Id", dataKey: "financier_id" },
    { header: "Chassis No", dataKey: "chassis_no" },
    { header: "Old Customer Name", dataKey: "old_customer_name" },
  ];

  useEffect(() => {
    try {
      if (uid !== "" && uid !== undefined) {
        setLoading(true);
        const form_data = new FormData();
        form_data.append("uid", uid);
        auctionService.GetViewAuctionAssets(form_data, source).then((res) => {
          if (res?.length >= 0) {
            const addedData = ref(db, "live_auction/" + uid);
            onValue(addedData, (snapshot) => {
              const data = snapshot?.val();
              if (data && (data !== "") & (data !== null)) {
                const updatedAuctionData = res?.map((item) => {
                  const referenceNo = item?.reference_no;
                  const dataKeys = Object?.keys(data);
                  const isReferenceInData = dataKeys?.includes(referenceNo);
                  if (isReferenceInData) {
                    const newData = data[referenceNo];
                    return {
                      ...item,
                      current_price: newData?.latest_bid,
                      ["no_of_bids"]: newData?.no_of_bids,
                    };
                  } else {
                    return item;
                  }
                });
                setLoading(false);
                setAuctionData(updatedAuctionData);
                setFilterAuctionData(updatedAuctionData);
              } else {
                setAuctionData(res);
                setFilterAuctionData(res);
                setLoading(false);
              }
            });
          } else {
            errorToast(res);
            setLoading(false);
            setFilterAuctionData([]);
          }
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, []);

  console.log({ filterAuctionData });

  useEffect(() => {
    const changeDataRef = ref(db, "live_auction/" + uid);

    const handleChildChanged = onChildChanged(changeDataRef, (snapshot) => {
      const updatedData = snapshot.val();
      setAuctionData((prevData) => ({
        ...prevData,
        [snapshot.key]: updatedData,
      }));
    });

    const handleChildAdded = onChildAdded(changeDataRef, (snapshot) => {
      const newData = snapshot.val();
      setAuctionData((prevData) => ({
        ...prevData,
        [snapshot.key]: newData,
      }));
    });

    return () => {
      off(changeDataRef, handleChildAdded);
      off(changeDataRef, handleChildChanged);
    };
  }, []);

  const handleDelete = (data) => {
    console.log({ data });
    const form_data = new FormData();
    form_data.append("ref_no", data);
    form_data.append("uid", uid);
    Swal.fire({
      title: "Are you sure?",
      html: `<p>You want to delete ${data} Asset!</p>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await auctionService.DeleteAsset(form_data, source);
          if (response?.status === 200) {
            // console.log("first")
            successToast("Asset Delete Successfully");
            setLoading(true);
            const form_data = new FormData();
            form_data.append("uid", uid);
            auctionService
              .GetViewAuctionAssets(form_data, source)
              .then((res) => {
                if (res?.length >= 0) {
                  const addedData = ref(db, "live_auction/" + uid);
                  onValue(addedData, (snapshot) => {
                    const data = snapshot?.val();
                    if (data && (data !== "") & (data !== null)) {
                      const updatedAuctionData = res?.map((item) => {
                        const referenceNo = item?.reference_no;
                        const dataKeys = Object?.keys(data);
                        const isReferenceInData =
                          dataKeys?.includes(referenceNo);
                        if (isReferenceInData) {
                          const newData = data[referenceNo];
                          return {
                            ...item,
                            current_price: newData?.latest_bid,
                            ["no_of_bids"]: newData?.no_of_bids,
                          };
                        } else {
                          console.log({ item });
                          return item;
                        }
                      });
                      console.log({ updatedAuctionData });
                      setLoading(false);
                      setAuctionData(updatedAuctionData);
                      setFilterAuctionData(updatedAuctionData);
                    } else {
                      setAuctionData(res);
                      setFilterAuctionData(res);
                      setLoading(false);
                    }
                  });
                } else {
                  errorToast(res);
                  setLoading(false);
                  setFilterAuctionData([]);
                }
              });
          } else {
            errorToast(response?.msg);
            console.log(response.msg);
          }
        } catch (error) {
          console.log(error);
          errorToast(error?.message);
        }
      },
    });
  };

  const handleSearch = (e) => {
    const { value } = e.target;

    const searchData = auctionData.filter((val) => {
      return String(val?.reference_no)
        .toLowerCase()
        .includes(value.toLowerCase());
    });

    if (value) {
      setFilterAuctionData(searchData);
    } else {
      setFilterAuctionData(auctionData);
    }
  };

  const handleShowPrice = (e, reference_no) => {
    e.preventDefault();
    try {
      const form_data = new FormData();
      form_data.append("uid", uid);
      form_data.append("ref_no", reference_no);
      form_data.append("phone", phone);
      auctionService.CheckBidAmount(form_data).then((res) => {
        if (res?.status === 200) {
          setFilterAuctionData((prevData) =>
            Object.entries(prevData).map(([key, item], index) =>
              item.reference_no === reference_no
                ? { ...item, current_price: res?.data?.bid }
                : item
            )
          );
          setAuctionData((prevData) =>
            Object.entries(prevData).map(([key, item], index) =>
              item.reference_no === reference_no
                ? { ...item, current_price: res?.data?.bid }
                : item
            )
          );
          setShowFullPrice(reference_no);
        } else if (res?.status === 400) {
          setFilterAuctionData((prevData) =>
            Object.entries(prevData).map(([key, item], index) =>
              item.reference_no === reference_no
                ? { ...item, current_price: 0 }
                : item
            )
          );
          setAuctionData((prevData) =>
            Object.entries(prevData).map(([key, item], index) =>
              item.reference_no === reference_no
                ? { ...item, current_price: 0 }
                : item
            )
          );
          setShowFullPrice(reference_no);
        } else {
          errorToast(res?.msg);
        }
      });
    } catch (error) {}
  };

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <div
          className="d-flex justify-content-center container"
          style={{ minHeight: "80vh", alignItems: "center" }}
        >
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <>
          <div className="container table-responsive">
            <div className="row mt-3">
              <div
                className="col-md-5 col-lg-3 col-12"
                style={{ marginBottom: "17px", marginTop: "-10px" }}
              >
                <i
                  className="bi bi-arrow-left-circle"
                  onClick={() =>
                    history.push(`/meratractor/auctions/modify?uid=${uid}`)
                  }
                  style={{
                    fontSize: "xx-large",
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                ></i>
              </div>

              <div
                className="col-md-5 col-lg-3 col-12 ms-auto me-1 "
                style={{ marginBottom: "17px" }}
              >
                <SearchBox
                  allData={auctionData}
                  setFilteredData={setFilterAuctionData}
                />
              </div>
            </div>

            <div
              className="table-responsive text-center"
              style={{ minHeight: "5vh", maxHeight: "80vh" }}
            >
              <table className=" table table-hover table-bordered">
                <thead className="thead-dark">
                  <tr
                    className="text-center"
                    style={{
                      fontSize: "small",
                      position: "sticky",
                      top: 0,
                      zIndex: 2,
                    }}
                  >
                    <th> Sr. No</th>
                    {headers.map((column) => (
                      <th className="col" key={column.header}>
                        {column.header}
                      </th>
                    ))}
                    <th
                      style={{
                        fontSize: "small",
                        position: "sticky",
                        top: 0,
                        right: 0,
                        zIndex: 3,
                      }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(filterAuctionData) &&
                  Object.entries(filterAuctionData).length === 0 ? (
                    <div className=" text-danger text-center mt-2 "></div>
                  ) : (
                    Object.entries(filterAuctionData).map(
                      (rowData, rowIndex) => (
                        <tr key={rowIndex}>
                          <td>{rowIndex + 1}</td>
                          {headers.map((header, index) => (
                            <>
                              {header.dataKey === "image_paths" ? (
                                <td>{parseInt(rowData[1][header?.dataKey])}</td>
                              ) : header.dataKey === "reference_no" ? (
                                <td
                                  className="text-primary"
                                  onClick={() => {
                                    history.push(
                                      `/meratractor/auctions/modify/asset?ref_no=${rowData[1]["reference_no"]}&uid=${uid}`
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <b>{rowData[1][header?.dataKey]}</b>
                                </td>
                              ) : header.dataKey === "current_price" ? (
                                <td
                                  onClick={(e) =>
                                    handleShowPrice(e, rowData[1]?.reference_no)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <b>
                                    {showFullPrice === rowData[1]?.reference_no
                                      ? indianCurrencyFormater(
                                          rowData[1][header.dataKey],
                                          0
                                        )
                                      : indianCurrencyFormater(
                                          Number(
                                            String(
                                              rowData[1][header.dataKey]
                                            ).slice(0, 0) + "0000"
                                          )
                                        ).replace(/0/g, "X")}
                                  </b>
                                </td>
                              ) : header.dataKey === "start_price" ? (
                                <td>
                                  {indianCurrencyFormater(
                                    rowData[1][header?.dataKey],
                                    0
                                  )}
                                </td>
                              ) : header.dataKey === "recommended_bid" ? (
                                <td>
                                  {indianCurrencyFormater(
                                    rowData[1][header?.dataKey],
                                    0
                                  )}
                                </td>
                              ) : header.dataKey === "no_of_bids" ? (
                                <td>{rowData[1][header?.dataKey] || 0}</td>
                              ) : (
                                <td>{rowData[1][header?.dataKey]}</td>
                              )}
                            </>
                          ))}

                          <td
                            style={{
                              fontSize: "small",
                              position: "sticky",
                              top: 0,
                              right: 0,
                              zIndex: 1,
                            }}
                          >
                            <button
                              className="btn btn-danger"
                              type="button"
                              onClick={() =>
                                handleDelete(rowData[1]["reference_no"])
                              }
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </table>
              {filterAuctionData && filterAuctionData.length === 0 && (
                <div className="text-danger text-center mt-2 ">
                  Assets not found.
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ViewAuctionAsset;
