import { useEffect } from "react";
import { useState } from "react";
import countsService from "../services/implements/counts.service";
import jwtDecode from "jwt-decode";
import { errorToast } from "../react-toastfiy/toast";
import auctionService from "../services/auction/auction.service";

export default function usePaymentPendingCount(active) {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  const [PaymentPendingCount, setPaymentPendingCount] = useState("0");
  useEffect(() => {
    try {
      const form_data = new FormData();
      // if (!["	Auction Representative"].includes(user.role)) {
      form_data.append("phone", user?.phone);
      // }
      auctionService.PaymentPendingCount(form_data).then((res) => {
        if (res.status === 200) {
          setPaymentPendingCount(res?.count || "0");
        } else {
          errorToast(res?.msg);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [active]);

  return PaymentPendingCount;
}
