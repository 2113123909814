import Modal from "react-bootstrap/Modal";
import "../../css/dashboard.css";
import "../../css/common.css";

import * as Icon from "react-bootstrap-icons";

import ErrorHandler from "../../common/ErrorHandler";
import { indianCurrencyFormater } from "../../helpers/functions/currencyFormater";

function QuickView(props) {
  const { state, id, onHide, data } = props;
  const isCenter = window.innerWidth < 600;

  try {
    return (
      <>
        <Modal
          {...props}
          size="md"
          backdrop="static"
          aaria-labelledby="contained-modal-title-vcenter"
          className="top-right-modal"
          scrollable
          centered={isCenter}
        >
          <Modal.Header closeButton>
            <h5> {data["dealership"] + " - " + data["dealership_location"]}</h5>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-6 col-sm-6">
                <p>
                  <b>Make & Model</b>
                </p>
              </div>

              <div className="col-6 col-sm-6">
                <p>{data["make"] + " " + data["model"]}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-sm-6">
                <p>
                  <b>state</b>
                </p>
              </div>

              <div className="col-6 col-sm-6">
                <p>{data["state"]}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-sm-6">
                <p>
                  <b>State Head</b>
                </p>
              </div>

              <div className="col-6 col-sm-6">
                <p>{data["sh"]}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-sm-6">
                <p>
                  <b>Territory Manager</b>
                </p>
              </div>

              <div className="col-6 col-sm-6">
                <p>{data["tm_name"]}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-sm-6">
                <p>
                  <b>Financier</b>
                </p>
              </div>

              <div className="col-6 col-sm-6">
                <p>{data["financier"]}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-sm-6">
                <p>
                  <b>Manufacturing Year</b>
                </p>
              </div>

              <div className="col-6 col-sm-6">
                <p>{data["mfg_year"]}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-sm-6">
                <p>
                  <b>Chassis No</b>
                </p>
              </div>

              <div className="col-6 col-sm-6">
                <p>{data["chassis_no"]}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-sm-6">
                <p>
                  <b>Payment</b>
                </p>
              </div>

              {/* <div className="col-6 col-sm-6">
                <p>{data["payment"]}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-sm-6">
                <p>
                  <b>Sold</b>
                </p>
              </div>

              <div className="col-6 col-sm-6">
                <p>{data["sold"]}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-sm-6">
                <p>
                  <b>Loan No.</b>
                </p>
              </div>

              <div className="col-6 col-sm-6">
                <p>{data["loan_no"]}</p>
              </div> */}
            </div>
            <div className="row">
              <div className="col-6 col-sm-6">
                <p>
                  <b>Cost of Purchase</b>
                </p>
              </div>

              <div className="col-6 col-sm-6">
                <p>{indianCurrencyFormater(data["cost_of_purchase"], 0)}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-sm-6">
                <p>
                  <b>Registered Vehicle</b>
                </p>
              </div>

              <div className="col-6 col-sm-6">
                <p>{data["registered_vehicle"]}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-sm-6">
                <p>
                  <b>Registration No.</b>
                </p>
              </div>

              <div className="col-6 col-sm-6">
                <p>{data["reg_no"]}</p>
              </div>
            </div>

            {data["ReleaseDoc"] !== undefined && (
              <div className="row">
                <div className="col-6 col-sm-6">
                  <p>
                    <b>Bond and KYC Download</b>
                  </p>
                </div>

                <div className="col-6 col-sm-6">
                  <a className="downloadFile" href={data["ReleaseDoc"]}>
                    <Icon.Download
                      size={"2em"}
                      style={{
                        fontSize: "2em !important",
                        paddingTop: "3px",
                      }}
                    />
                  </a>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
}

export default QuickView;
