import React, { useEffect } from "react";
import CardBlock from "../../../common/CardBlock";
// import category from "../../../img/category.svg";
// import inventory from "../../../img/inventory.svg";
// import so_manage from "../../img/so_manage.svg";
// import mfg from "../../../img/mfg.svg";
// import list from "../../../img/list_tp.jpeg";
// import spares from "../../../img/tools-line.svg";

function PlanHomePage() {
  useEffect(() => {
    document.title = "rapibid Home";
  }, []);
  return (
    <>
      <div className="container mt-2">
        <h1>Manage Plans</h1>
        <hr />
      </div>
      <div className="container container-body">
        <CardBlock
          name="Create Plans"
          // logo={category}
          to="/rapidbid/manage-plan/create-plan"
        />
        <CardBlock
          name="View Published Plans"
          //  logo={spares}
          to="/rapidbid/manage-plan/view-published_plans"
        />
         <CardBlock
          name="View Draft Plans"
          //  logo={spares}
          to="/rapidbid/manage-plan/view-draft_plans"
        />
      </div>
    </>
  );
}

export default PlanHomePage;
