import jwtDecode from "jwt-decode";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactLoading from "react-loading";
import Select from "react-select";
import {
  currencyToNumber,
  indianCurrencyFormater,
} from "../../helpers/functions/currencyFormater";
import { formatDate } from "../../helpers/functions/dateFormatter";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import stateService from "../../services/state.service";
import userService from "../../services/user.service";

function AddEditAuctionUsers(props) {
  const refreshToken = localStorage.getItem("refreshToken");
  const user = jwtDecode(refreshToken);
  const { data, edit_form, set_fetch_data: setFetchData } = props;
  const initialValues = {
    first_name: "",
    remarks: "",
    middle_name: "",
    plan_id: "",
    plan_tenure: "",
    plan_term: "",
    last_name: "",
    state: "",
    phone: "",
    email: "",
    password: "",
    confirm_password: "",
    aadhar: "",
    pan: "",
    front_aadhar_file: "",
    back_aadhar_file: "",
    pan_file: "",
    address: "",
    reporting_personal: user?.user_id,
    assign_cluster: [],
    account_number: "",
    ifsc: "",
    bank_name: "",
    branch_name: "",
    account_name: "",
    is_blocked: false,
    allow_attendance_mark: "",
    assigned_states_designations: [],
    user_designation: 49,
    pincode: "",
    is_active: false,
    payment_amount: "",
    payment_proof: "",
    payment_date: formatDate(new Date()),
    utr: "",
    is_channel_financed: false,
    tc_upfront_margin: 0,
    tc_credit_limit: 0,
  };
  const [userFormData, setUserFormData] = useState(
    edit_form ? data : initialValues
  );
  console.log({ userFormData });
  const [loadingSubmitBtn, setLoadingSubmitBtn] = useState(false);
  const [statesLoading, setStatesLoading] = useState(false);
  const [allStates, setAllStates] = useState([]);
  const [allPlan, setAllPlan] = useState([]);
  const [allTenure, setAllTenure] = useState([]);
  const [debounceVal, setDebounceVal] = useState("");
  const [userDataKey, setUserDataKey] = useState({});
  const [loading, setLoading] = useState(false);
  const [userData_is_available, setUserData_is_available] = useState(false);

  function debounce(cb, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const debouncedChange = useCallback(
    debounce((inputValue) => {
      setDebounceVal(inputValue);
    }, 500),
    []
  );

  const handleChangeDebounce = (e) => {
    const inputValue = e.target.value;
    debouncedChange(inputValue);
    setUserFormData((prev) => ({
      ...prev,
      ["phone"]: inputValue,
    }));
  };

  const handleChange = (e) => {
    let { name, value, files } = e.target;

    if (
      [
        "front_aadhar_file",
        "back_aadhar_file",
        "pan_file",
        "payment_proof",
      ].includes(name)
    ) {
      setUserFormData((prev) => ({ ...prev, [name]: files[0] }));
    } else if (name === "is_subscribed" && value === false) {
      setUserFormData((prev) => ({
        ...prev,
        [name]: value,
        subscription_date: "",
        subscription_tenure: "",
      }));
    } else {
      setUserFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setUserFormData((prev) => {
      return {
        ...prev,
        [name]: value.toString().trim(),
      };
    });
  };

  useEffect(() => {
    try {
      document.title = `${edit_form ? "Edit" : "Add"} User`;
      setStatesLoading(true);
      stateService.getAllState().then((res) => {
        if (res && res?.length !== 0) {
          const all_state = res.map((state) => ({
            label: state?.state_name,
            value: state?.id,
          }));
          setAllStates(all_state);
          setStatesLoading(false);
          if (!edit_form) {
            const aucRepState = all_state.filter(
              (val) => val?.label === user?.state
            );
            if (aucRepState?.length !== 0) {
              setUserFormData((prev) => ({
                ...prev,
                state: aucRepState[0]?.value,
              }));
            }
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const foundState = allStates.filter(
      (state) => state.value?.toString() === userFormData?.state?.toString()
    );
    if (foundState?.length === 1) {
      setUserFormData((prev) => ({
        ...prev,
        assigned_states_designations: [
          { state: foundState[0]?.label, designations: ["Auction Buyer"] },
        ],
      }));
    }
  }, [userFormData?.state]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoadingSubmitBtn(true);
      const userData = structuredClone(userFormData);

      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      let current_Time = `${hours}:${minutes}:${seconds}`;

      let form_data = new FormData();
      if (edit_form) {
        form_data.append("id", userData.id);
        form_data.append("is_user_rejected", false);
        form_data.append("re_apply", true);
        // if (userData?.is_user_approved) {
        //   form_data.append("is_franchised", userData.is_franchised);
        //   form_data.append("is_auction_admin", userData.is_auction_admin);
        // form_data.append("is_active", userData.is_active);
        // }
      }
      form_data.append("plan_id", userData.plan_id);
      form_data.append("plan_term ", JSON.stringify(userData?.plan_term));
      form_data.append("user_added_by", user?.phone);
      form_data.append("first_name", userData.first_name);
      form_data.append("middle_name", userData.middle_name);
      form_data.append("last_name", userData.last_name);
      form_data.append("email", userData.email);
      form_data.append("phone", userData.phone);
      form_data.append("aadhar", userData.aadhar);
      form_data.append("pan", userData.pan);
      form_data.append("front_aadhar_file", userData.front_aadhar_file);
      form_data.append("back_aadhar_file", userData.back_aadhar_file);
      form_data.append("payment_proof", userData.payment_proof);
      form_data.append("payment_date", userData.payment_date);
      form_data.append(
        "subscription_date",
        `${userData.payment_date} ${current_Time}`
      );
      form_data.append("payment_amount", userData.payment_amount);
      form_data.append("utr", userData.utr);
      form_data.append("pan_file", userData.pan_file);
      form_data.append("state", userData.state);
      form_data.append("pincode", userData.pincode);
      form_data.append("address", userData.address);
      form_data.append("account_number", userData.account_number);
      form_data.append("ifsc", userData.ifsc);
      form_data.append("bank_name", userData.bank_name);
      form_data.append("remarks", userData?.remarks ? userData?.remarks : "");
      form_data.append("branch_name", userData.branch_name);
      form_data.append("account_name", userData.account_name);
      form_data.append("is_blocked", userData.is_blocked);
      form_data.append("allow_attendance_mark", userData.allow_attendance_mark);
      form_data.append(
        "assigned_states_designations",
        JSON.stringify(userData?.assigned_states_designations)
      );
      form_data.append(
        "user_designation",
        edit_form
          ? userData?.user_designation?.value
          : userData?.user_designation
      );
      form_data.append("reporting_personal_id", user?.user_id);
      form_data.append(
        "assign_cluster",
        JSON.stringify(userData.assign_cluster)
      );

      form_data?.append("is_channel_financed", userData?.is_channel_financed);

      if (userData?.is_channel_financed) {
        form_data.append("tc_upfront_margin", userData?.tc_upfront_margin);
        form_data.append("tc_credit_limit", userData?.tc_credit_limit);
      }
      let serviceAPI = userService.CreateUser;
      if (edit_form) {
        serviceAPI = userService.ReApplyRejectedUser;
      }
      const response = await serviceAPI(form_data);
      setLoadingSubmitBtn(false);

      if (response.status === 200) {
        props.onHide();
        setFetchData(true);
        successToast(response.msg);
      } else {
        errorToast(response.msg);
      }
    } catch (error) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (debounceVal.length === 10) {
      const fetchData = async () => {
        setLoading(true);

        let formData = new FormData();
        formData.append("phone", debounceVal);
        const response = await userService.GetUserExists(formData);

        setLoading(false);
        if (response?.status === 200) {
          setUserData_is_available(true);

          const matchingState = allStates.find(
            (state) => state.value === response.data.state
          );

          const defaultData = {
            account_number: "",
            ifsc: "",
            bank_name: "",
            branch_name: "",
            account_name: "",
            pan_file: "",
            is_blocked: false,
            allow_attendance_mark: "",
            assigned_states_designations: [
              {
                state: matchingState?.label,
                designations: ["Auction Buyer"],
              },
            ],
            user_designation: 49,
            reporting_personal_id: 521,
            assign_cluster: [],
          };
          const user_Form_Data = {
            ...defaultData,
            ...response?.data,
          };
          const emptyKeys = Object.entries(user_Form_Data)
            .filter(
              ([key, value]) =>
                value === "" ||
                value === null ||
                value === "-" ||
                (Array.isArray(value) && value.length === 0)
            )
            .reduce((acc, [key, value]) => {
              acc[key] = value;
              return acc;
            }, {});
          setUserDataKey(emptyKeys);
          setUserFormData(user_Form_Data);
        } else {
          errorToast(response?.msg);
          setUserData_is_available(false);
          setUserDataKey(initialValues);
          setUserFormData({
            ...initialValues,
            ["phone"]: debounceVal,
          });
        }
      };
      fetchData();
    }
  }, [debounceVal, allStates]);

  useEffect(() => {
    const fetchData = async () => {
      let formData = new FormData();
      formData.append("platform_type", "Auction");
      const response = await userService.GetPlanList(formData);

      if (response?.status === 200) {
        const plan_list = response?.data.map((plan) => ({
          label: plan?.plan_name,
          value: plan,
        }));
        setAllPlan(plan_list);
      } else {
        errorToast(response?.msg);
      }
    };
    fetchData();
  }, []);

  const handleChangeSelectPlan = (e) => {
    setUserFormData((prev) => ({
      ...prev,
      ["plan_id"]: e.value?.plan_id,
      ["plan_tenure"]: "",
      ["payment_amount"]: "",
    }));
    if (e.value?.plan_term) {
      const planTermEntries = Object.entries(e.value.plan_term)
        .filter(([key, value]) => value !== "")
        .map(([key, value]) => ({
          value: value,
          label: `${key}`,
        }));

      setAllTenure(planTermEntries);
    }
  };

  const handleChangeSelectPlanTenure = (e) => {
    const formattedLabel = e.label.replace(/\s+/g, "_");
    if (e.label === "1 month") {
      setUserFormData((prev) => ({
        ...prev,
        ["plan_term"]: { [formattedLabel]: e.value },
        ["plan_tenure"]: e.label,
        ["payment_amount"]: e.value,
      }));
    } else {
      setUserFormData((prev) => ({
        ...prev,
        ["plan_term"]: { [`${formattedLabel}s`]: e.value },
        ["plan_tenure"]: e.label,
        ["payment_amount"]: e.value,
      }));
    }
  };

  try {
    return (
      <>
        {/* <div className="container">{JSON.stringify(userFormData)}</div> */}
        <Modal
          size="lg"
          backdrop="static"
          aaria-labelledby="contained-modal-title-vcenter"
          centered
          show={props.show}
          onHide={props.onHide}
        >
          <Modal.Header closeButton={!loadingSubmitBtn}>
            {" "}
            <h2 className=" text-center">{`${
              edit_form ? "Edit" : "Add"
            } User`}</h2>
          </Modal.Header>
          <form className="col-12 was-validated" onSubmit={handleSubmit}>
            <Modal.Body>
              <div
                className="container d-flex justify-content-center"
                style={{ overflowY: "scroll", maxHeight: "70vh" }}
              >
                <div className="d-flex align-items-center gap-3 flex-wrap">
                  <div className="col-12">
                    <label htmlFor="phone" className=" form-label mb-0">
                      Phone (WhatsApp number)
                    </label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      value={userFormData?.phone}
                      onChange={(e) => {
                        const cleanedValue = e.target.value
                          .slice(0, 10)
                          .replaceAll(/\D+/g, "");
                        e.target.value = cleanedValue;
                        handleChangeDebounce(e);
                      }}
                      onBlur={handleBlur}
                      className=" form-control"
                      placeholder="Enter phone number"
                      pattern="[0-9]{10}"
                      required
                      style={{
                        paddingRight: loading ? "30px" : "10px", // Adjust padding to make space for the spinner
                      }}
                    />

                    {userFormData?.phone?.length > 0 &&
                      userFormData?.phone?.length < 10 && (
                        <span className=" text-danger">
                          Phone number length must be 10
                        </span>
                      )}

                    {loading && (
                      <div className=" d-flex justify-content-start ">
                        <ReactLoading
                          type={"bubbles"}
                          color={"gray"}
                          height={1}
                          width={55}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    <label htmlFor="first_name" className=" form-label mb-0">
                      First name
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      id="first_name"
                      value={userFormData?.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=" form-control"
                      placeholder="Enter first name"
                      required
                      autoCapitalize="characters"
                      disabled={
                        loading || userDataKey?.first_name === undefined
                      }
                      isLoading={loading}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="middle_name" className=" form-label mb-0">
                      Middle name
                    </label>
                    <input
                      type="text"
                      name="middle_name"
                      id="middle_name"
                      value={userFormData?.middle_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=" form-control"
                      placeholder="Enter middle name"
                      disabled={
                        loading || userDataKey?.middle_name === undefined
                      }
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="last_name" className=" form-label mb-0">
                      Last name
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      id="last_name"
                      value={userFormData?.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=" form-control"
                      placeholder="Enter last name"
                      required
                      disabled={loading || userDataKey?.last_name === undefined}
                      loading={loading}
                    />
                  </div>

                  <div className="col-12">
                    <label htmlFor="email" className=" form-label mb-0">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={userFormData?.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=" form-control"
                      placeholder="Enter email address"
                      disabled={loading || userDataKey?.email === undefined}
                      required
                    />
                  </div>

                  <div className="col-12">
                    <label htmlFor="address" className=" form-label mb-0">
                      Address
                    </label>
                    <textarea
                      type="text"
                      name="address"
                      id="address"
                      value={userFormData?.address}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      className=" form-control"
                      placeholder="Enter address"
                      required
                      disabled={loading || userDataKey?.address === undefined}
                    />
                  </div>

                  <div className="col-12">
                    <label htmlFor="pincode" className=" form-label mb-0">
                      Pincode
                    </label>
                    <input
                      type="text"
                      name="pincode"
                      id="pincode"
                      value={userFormData?.pincode}
                      onChange={(e) => {
                        const cleanedValue = e.target.value
                          .slice(0, 6)
                          .replaceAll(/\D+/g, "");
                        e.target.value = cleanedValue;
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      className=" form-control"
                      placeholder="Enter pincode"
                      pattern="[0-9]{6}"
                      required
                      disabled={loading || userDataKey?.pincode === undefined}
                    />
                    {userFormData?.pincode?.length > 0 && (
                      <span className=" invalid-feedback">
                        Pincode must have 6 digits
                      </span>
                    )}
                  </div>
                  <div className="col-12">
                    <label htmlFor="state" className=" form-label mb-0">
                      Select state
                    </label>

                    <Select
                      options={allStates}
                      value={allStates?.filter(
                        (state) => state?.value === userFormData?.state
                      )}
                      onChange={(option) => {
                        setUserFormData((prev) => ({
                          ...prev,
                          state: option?.value,
                        }));
                      }}
                      isLoading={statesLoading}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="aadhar" className=" form-label mb-0">
                      AADHAAR
                    </label>
                    <input
                      type="text"
                      name="aadhar"
                      id="aadhar"
                      value={userFormData?.aadhar}
                      onChange={(e) => {
                        const cleanedValue = e.target.value
                          .slice(0, 12)
                          .replaceAll(/\D+/g, "");
                        e.target.value = cleanedValue;
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      className=" form-control"
                      placeholder="Enter AADHAAR"
                      pattern="[0-9]{12}"
                      required
                      disabled={loading || userDataKey?.aadhar === undefined}
                    />
                    {userFormData?.aadhar?.length > 0 && (
                      <span className=" invalid-feedback">
                        AADHAAR must have 12 digits
                      </span>
                    )}
                  </div>
                  <div className="col-12">
                    <label
                      htmlFor="front_aadhar_file"
                      className=" form-label mb-0"
                    >
                      Upload front side of AADHAAR
                    </label>
                    <input
                      type="file"
                      name="front_aadhar_file"
                      id="front_aadhar_file"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className=" form-control"
                      required={!userFormData?.front_aadhar_file}
                      disabled={
                        loading || userDataKey?.front_aadhar_file === undefined
                      }
                    />
                    {!!userFormData?.front_aadhar_file && (
                      <div className="d-flex">
                        <p className="text-danger me-1 mb-0">
                          Uploaded file :{" "}
                        </p>
                        <a
                          href={
                            typeof userFormData?.front_aadhar_file === "object"
                              ? URL.createObjectURL(
                                  userFormData?.front_aadhar_file
                                )
                              : userFormData?.front_aadhar_file
                          }
                          target="_blank"
                          style={{ textDecoration: "none" }}
                          rel="noreferrer"
                        >
                          <i class="bi bi-cloud-arrow-down-fill"></i> Download
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    <label
                      htmlFor="back_aadhar_file"
                      className=" form-label mb-0"
                    >
                      Upload back side of AADHAAR
                    </label>
                    <input
                      type="file"
                      name="back_aadhar_file"
                      id="back_aadhar_file"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className=" form-control"
                      required={!userFormData?.back_aadhar_file}
                      disabled={
                        loading || userDataKey?.back_aadhar_file === undefined
                      }
                    />
                    {!!userFormData?.back_aadhar_file && (
                      <div className="d-flex">
                        <p className="text-danger me-1 mb-0">
                          Uploaded file :{" "}
                        </p>
                        <a
                          href={
                            typeof userFormData?.back_aadhar_file === "object"
                              ? URL.createObjectURL(
                                  userFormData?.back_aadhar_file
                                )
                              : userFormData?.back_aadhar_file
                          }
                          target="_blank"
                          style={{ textDecoration: "none" }}
                          rel="noreferrer"
                        >
                          <i class="bi bi-cloud-arrow-down-fill"></i> Download
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    <label htmlFor="pan" className=" form-label mb-0">
                      PAN
                    </label>
                    <input
                      type="text"
                      name="pan"
                      id="pan"
                      value={userFormData?.pan}
                      onChange={(e) => {
                        const cleanedValue = e.target.value
                          .slice(0, 10)
                          .replaceAll(/[^a-zA-Z0-9]/g, "")
                          .toUpperCase();
                        e.target.value = cleanedValue;
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      className=" form-control"
                      placeholder="Enter PAN"
                      pattern="[a-zA-Z0-9]{10}"
                      required
                      disabled={loading || userDataKey?.pan === undefined}
                    />
                    {userFormData?.pan?.length > 0 && (
                      <span className=" invalid-feedback">
                        PAN must be length of 10
                      </span>
                    )}
                  </div>
                  <div className="col-12">
                    <label htmlFor="pan_file" className=" form-label mb-0">
                      Upload PAN
                    </label>
                    <input
                      type="file"
                      name="pan_file"
                      id="pan_file"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className=" form-control"
                      required={!userFormData?.pan_file}
                      disabled={loading || userDataKey?.pan_file === undefined}
                    />
                    {!!userFormData?.pan_file && (
                      <div className="d-flex">
                        <p className="text-danger me-1 mb-0">
                          Uploaded file :{" "}
                        </p>
                        <a
                          href={
                            typeof userFormData?.pan_file === "object"
                              ? URL.createObjectURL(userFormData?.pan_file)
                              : userFormData?.pan_file
                          }
                          target="_blank"
                          style={{ textDecoration: "none" }}
                          rel="noreferrer"
                          isDisabled={loading}
                        >
                          <i class="bi bi-cloud-arrow-down-fill"></i> Download
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    <label htmlFor="plan_id" className=" form-label mb-0">
                      Plan Name
                    </label>
                    <Select
                      id="plan_id"
                      options={allPlan}
                      onChange={handleChangeSelectPlan}
                      value={allPlan.find(
                        (option) => option.value === userFormData.plan_id
                      )}
                      placeholder="Plan Name"
                      required
                      isDisabled={loading}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="plan_tenure" className=" form-label mb-0">
                      Plan Tenure
                    </label>
                    <Select
                      id="plan_tenure"
                      options={allTenure}
                      onChange={handleChangeSelectPlanTenure}
                      value={allTenure.filter(
                        (option) => option.label === userFormData.plan_tenure
                      )}
                      placeholder="Plan Tenure"
                      required
                      isDisabled={loading}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="payment_date" className=" form-label mb-0">
                      Payment date
                    </label>
                    <input
                      type="date"
                      name="payment_date"
                      id="payment_date"
                      value={userFormData?.payment_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=" form-control"
                      required
                      disabled={loading}
                    />
                  </div>
                  <div className="col-12">
                    <label
                      htmlFor="payment_amount"
                      className=" form-label mb-0"
                    >
                      Payment amount
                    </label>
                    <input
                      type="text"
                      name="payment_amount"
                      id="payment_amount"
                      value={indianCurrencyFormater(
                        userFormData?.payment_amount,
                        0
                      )}
                      onChange={(e) => {
                        e.target.value = currencyToNumber(e.target.value);
                        handleChange(e);
                      }}
                      className=" form-control"
                      placeholder="Enter payment amount"
                      required
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="utr" className=" form-label mb-0">
                      UTR
                    </label>
                    <input
                      type="text"
                      name="utr"
                      id="utr"
                      value={userFormData?.utr}
                      onChange={(e) => {
                        const cleanedValue = e.target.value.toUpperCase();
                        e.target.value = cleanedValue;
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      className=" form-control"
                      placeholder="Enter UTR number"
                      required
                      disabled={loading}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="payment_proof" className=" form-label mb-0">
                      Upload payment proof
                    </label>
                    <input
                      type="file"
                      name="payment_proof"
                      id="payment_proof"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className=" form-control"
                      required={!userFormData?.payment_proof}
                      disabled={loading}
                    />
                    {!!userFormData?.payment_proof && (
                      <div className="d-flex">
                        <p className="text-danger me-1 mb-0">
                          Uploaded file :{" "}
                        </p>
                        <a
                          href={
                            typeof userFormData?.payment_proof === "object"
                              ? URL.createObjectURL(userFormData?.payment_proof)
                              : userFormData?.payment_proof
                          }
                          target="_blank"
                          style={{ textDecoration: "none" }}
                          rel="noreferrer"
                          disabled={loading}
                        >
                          <i class="bi bi-cloud-arrow-down-fill"></i> Download
                        </a>
                      </div>
                    )}
                  </div>

                  <div className="col-12">
                    <label htmlFor="remarks" className=" form-label mb-0">
                      Remarks
                    </label>
                    <input
                      type="text"
                      name="remarks"
                      id="remarks"
                      value={userFormData?.remarks}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className=" form-control"
                      placeholder="Enter remarks"
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className=" btn btn-secondary"
                onClick={props.onHide}
                disabled={loadingSubmitBtn}
              >
                Close
              </button>
              {loadingSubmitBtn ? (
                <button className=" btn btn-success" disabled>
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button className=" btn btn-success">
                  {edit_form ? "Re-apply" : "Add User"}
                </button>
              )}
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  } catch (error) {
    console.log(error);
  }
}

export default AddEditAuctionUsers;
