import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
import { successToast } from "../../../react-toastfiy/toast";
import { useState } from "react";

const RapibidAddState = (props) => {
  const { data, show, onHide, setstatedata } = props;
  

  // const [buttonLoading, setButtonLoading] = useState(false);
  const [UpdateButtonLoading, setUpdateButtonLoading] = useState(false);
  const [SubmitButtonLoading, setSubmitButtonLoading] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setstatedata((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = () => {
    setSubmitButtonLoading(true)
    let form_data = new FormData();
    form_data.append("state_name", data.state_name);
    form_data.append("shorthand", data.shorthand);
    SubscriptionServices.CreateState(form_data).then((res) => {
      if (res.status === 200) {
        successToast(res.msg);
        setSubmitButtonLoading(false);
        setTimeout(() => {
          window.location.replace("/rapibid/auction/state");
        }, 500);
      } else {
        console.log(res.msg);
        setSubmitButtonLoading(false);
      }
    });
  };

  const handleUpdate = () => {
    setUpdateButtonLoading(true)
    let form_data = new FormData();
    form_data.append("id", data.id);
    form_data.append("state_name", data.state_name);
    form_data.append("shorthand", data.shorthand);
    SubscriptionServices.UpdateState(form_data).then((res) => {
      if (res.status === 200) {
        successToast(res.msg);
        setUpdateButtonLoading(false);
        setTimeout(() => {
          window.location.replace("/rapibid/auction/state");
        }, 500);
      } else {
        console.log(res.msg);
        setUpdateButtonLoading(false);
      }
    });
  };

  try {
    return (
      <Modal
        {...props}
        size="xs"
        backdrop="static"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        {data && data.id !== "" ? (
          <Modal.Header closeButton>
            <div
              id="example-custom-modal-styling-title"
              className="modal-title-custom"
            >
              <h4>Update State</h4>
            </div>
          </Modal.Header>
        ) : (
          <Modal.Header closeButton>
            <div
              id="example-custom-modal-styling-title"
              className="modal-title-custom"
            >
              <h4>Add State</h4>
            </div>
          </Modal.Header>
        )}

        <Modal.Body>
          <div className="row mt-2">
            <div className="col-3">
              <label>State :</label>
            </div>
            <div className="col-8">
              <input
                className="form-control"
                placeholder="State"
                id="state_name"
                type="text"
                name="state_name"
                value={data?.state_name}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-3">
              <label>Shorthand :</label>
            </div>
            <div className="col-8">
              <input
                className="form-control"
                placeholder="Shorthand"
                id="shorthand"
                type="text"
                name="shorthand"
                value={data?.shorthand}
                onChange={handleChange}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>Close</Button>
          {data && data?.id !== "" ? (
            <>
              {UpdateButtonLoading ? (
                <button
                  className="btn btn-primary mt-2"
                  type="button"
                  disabled
                  id="loadingBtnTp"
                >
                  <span
                    className="spinner-border spinner-border-sm mx-1 px-2"
                    type="submit"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <Button variant="primary" onClick={handleUpdate}>
                  Update
                </Button>
              )}
            </>
          ) : (
            <>
              {SubmitButtonLoading ? (
                <button
                  className="btn btn-primary mt-2"
                  type="button"
                  disabled
                  id="loadingBtnTp"
                >
                  <span
                    className="spinner-border spinner-border-sm mx-1 px-2"
                    type="submit"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <Button variant="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              )}
            </>
          )}
        </Modal.Footer>
      </Modal>
    );
  } catch (error) {
    console.log(error);
  }
};

export default RapibidAddState;
