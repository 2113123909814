import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import SubscriptionServices from "../../services/subscription/subscription.service";
import { validate } from "../../validation/validation";

import {
  SubscriptionSchema,
  SubscriptionSchemaAddOn,
  SubscriptionSchemaStatePermission,
  SubscriptionSchemaUpgrade,
} from "../../validation/schema";
import { Joi } from "joi-browser";

const SubscriptionCreatePlans = () => {
  const location = useLocation();
  let initialValue = {
    plan_name: "",

    add_on_plans: [],
    plan_type: "",
    plan_category: "",
    upgrade_from: "",
    upgrade_to: "",
    upgrade_cost: "",
    platform_type: "",
    plan_term: {
      monthly: "",
      quarterly: "",
      yearly: "",
    },
    included_items: {
      data: [],
    },
    description: {
      data: [],
    },
    excluded_items: {
      data: [],
    },
    module_permissions: {
      is_buyer: false,
      is_seller: false,
      is_auction_approval: false,
    },
    state_permissions: {
      user_state: false,
      multistate: {
        selected: false,
        total_add_on: "",
      },
      all_state_access: false,
    },
    plan_type_state: "",
    allow_trials: {
      selected: false,
      trial_days: "",
    },
    is_recommended: false,
    is_active: false,
    is_disabled: false,
    is_free: false,
  };
  const [planData, setPlanData] = useState(initialValue);
  const [planList, setPlanList] = useState([]);
  const [item, setItem] = useState({ included_items: "" });
  const [atLeastOneCheckboxSelected, setAtLeastOneCheckboxSelected] =
    useState(false);
  const [
    modulePermissionAtLeastOneCheckboxSelected,
    setModulePermissionAtLeastOneCheckboxSelected,
  ] = useState(false);
  const [loadingSubmitBtn, setLoadingSubmitBtn] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [description, setDescription] = useState({ description_item: "" });
  const [excludedItem, setExcludedItem] = useState({ excluded_items: "" });
  const [billingCycle, setBillingCycle] = useState([]);
  const [multiStatePermission, setMultiStatePermission] = useState([]);
  const [freeTrail, setFreeTrail] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [planId, setPlanId] = useState();

  useEffect(() => {
    try {
      const idFromURL = location?.pathname.split(":")[1]?.split("&")[0];
      if (idFromURL !== undefined) {
        const plan_id = idFromURL;
        setPlanId(plan_id);
        let form_data = new FormData();
        form_data.append("plan_id", plan_id);
        SubscriptionServices.GetSubscriptionPlanListById(form_data).then(
          (res) => {
            if (res?.status === 200) {
              setPlanData(res.data);
              if (res) {
                let planTermData = res?.data?.plan_term;
                let free_trail = res?.data?.allow_trials;
                let multi_state_permission =
                  res?.data?.state_permissions?.multistate;
                setMultiStatePermission(
                  multi_state_permission?.selected === true ? "multi_state" : []
                );
                let keys = Object.keys(planTermData);

                setFreeTrail(
                  free_trail?.selected === true ? "allow_trials" : []
                );
                let filteredKeys = keys.filter((key) => {
                  let value = planTermData[key];
                  return value !== undefined && value !== "";
                });

                setBillingCycle([...filteredKeys]);

                if (
                  res?.data?.module_permissions?.is_auction_approval === true
                ) {
                  setModulePermissionAtLeastOneCheckboxSelected(true);
                } else if (res?.data?.module_permissions?.is_buyer === true) {
                  setModulePermissionAtLeastOneCheckboxSelected(true);
                } else if (res?.data?.module_permissions?.is_seller === true) {
                  setModulePermissionAtLeastOneCheckboxSelected(true);
                }
                if (res?.data?.state_permissions?.all_state_access === true) {
                  setAtLeastOneCheckboxSelected(true);
                } else if (res?.data?.state_permissions?.user_state === true) {
                  setAtLeastOneCheckboxSelected(true);
                } else if (
                  res?.data?.state_permissions?.multistate?.selected === true
                ) {
                  setAtLeastOneCheckboxSelected(true);
                }
                let form_data = new FormData();
                form_data.append("platform_type", res?.data?.platform_type);
                SubscriptionServices.GetSubscriptionTenorListByPlatform(
                  form_data
                ).then((res) => {
                  if (res?.status === 200) {
                    let duration_List = res?.data?.map((entry, index) => ({
                      value: `${entry.duration} month`,
                    }));
                    duration_List.sort((a, b) => {
                      return parseInt(a.value) - parseInt(b.value);
                    });
                    setDurationList(duration_List);
                 
                  }
                });
              }
            }
          }
        );
      }
    } catch (error) {
      errorToast(error);
    }
  }, [location?.pathname]);

  useEffect(() => {
    try {
      if (planData?.plan_type === "state-permission") {
        SubscriptionServices.GetAllStateList().then((res) => {
          if (res?.length !== 0) {
            let state_list = Object.entries(res).map(([key, entry], index) => ({
              label: `${entry?.state_name} (${entry?.shorthand})`,
              value: entry,
            }));
            setStateList(state_list);
          }
        });
      } else if (planData?.plan_type === "add-on") {
        SubscriptionServices?.GetAllNewPublishedPlan().then((res) => {
          if (res?.status === 200) {
            let plan_new_list = Object.entries(res?.data).map(
              ([key, value], index) => ({
                label: value?.plan_name,
                value: value,
              })
            );

            setPlanList(plan_new_list);
          }
        });
      }
    } catch (error) {
      errorToast(error);
    }
  }, [planData?.plan_type]);

  const handleBillingCycleChange = (e) => {
    const { value, checked, name } = e.target;

    if (billingCycle.includes(value)) {
      setBillingCycle(billingCycle.filter((item) => item !== value));
    } else {
      setBillingCycle([...billingCycle, value]);
    }
    if (!checked) {
      setPlanData((prevState) => ({
        ...prevState,
        plan_term: {
          ...prevState?.plan_term,
          [value]: "",
        },
      }));
    }
  };

  const PlanType = [
    {
      label: "New",
      value: "new",
    },
    {
      label: "Add-on",
      value: "add-on",
    },
    {
      label: "Upgrade",
      value: "upgrade",
    },
    {
      label: "State Permission",
      value: "state-permission",
    },
  ];
  const platformType = [
    {
      label: "Rapibid",
      value: "Rapibid",
    },
    {
      label: "Auction",
      value: "Auction",
    },
    {
      label: "ERP",
      value: "ERP",
    },
  ];

  const PlanCategory = [
    {
      label: "Normal",
      value: "normal",
    },
    {
      label: "Corporate",
      value: "corporate",
    },
  ];

  const getPlanType = (e, name) => {
    setPlanData((prevState) => {
      return {
        ...prevState,
        [name]: e?.value,
      };
    });
  };
  const getPlanCategory = (e) => {
    setPlanData((prevState) => {
      return {
        ...prevState,
        plan_category: e?.value,
      };
    });
  };
  const handleChange = (e) => {
    const { name, value } = e?.target;

    setPlanData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangePlanTerm = (e) => {
    const { name, value } = e?.target;
    setPlanData((prevState) => {
      return {
        ...prevState,
        plan_term: {
          ...prevState?.plan_term,
          [name]: value,
        },
      };
    });
  };

  const addDescription = () => {
    const newItem = description?.description_item?.trim()?.toLowerCase();

    if (newItem !== "") {
      if (
        !planData?.description?.data
          ?.map((item) => item?.toLowerCase())
          ?.includes(newItem)
      ) {
        setPlanData((prevState) => {
          return {
            ...prevState,
            description: {
              data: [
                ...prevState?.description?.data,
                description?.description_item,
              ],
            },
          };
        });
        setDescription({ description_item: "" });
      } else {
        setDescription({ description_items: "" });
      }
    } else {
      errorToast("Blank Value Not Allowed");
    }
  };

  const handleRemoveDescription = (item) => {
    const updatedItems = planData?.description?.data.filter(
      (Value) => Value !== item
    );

    setPlanData((prevState) => {
      return {
        ...prevState,
        description: {
          data: [...updatedItems],
        },
      };
    });
  };

  const addItem = () => {
    const newItem = item.included_items.trim().toLowerCase();
    if (newItem !== "") {
      if (
        !planData?.included_items?.data
          ?.map((item) => item?.toLowerCase())
          .includes(newItem)
      ) {
        setPlanData((prevState) => {
          return {
            ...prevState,
            included_items: {
              data: [...prevState.included_items?.data, item?.included_items],
            },
          };
        });
        setItem({ included_items: "" });
      } else {
        setItem({ included_items: "" });
      }
    } else {
      errorToast("Blank Value Not Allowed");
    }
  };

  const handleChangeDescription = (e) => {
    const { name, value } = e.target;

    setDescription((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeIncludedItem = (e) => {
    const { name, value } = e.target;

    setItem((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const handleRemoveIncludedItem = (item) => {
    const updatedItems = planData?.included_items?.data.filter(
      (Value) => Value !== item
    );

    setPlanData((prevState) => {
      return {
        ...prevState,
        included_items: {
          data: [...updatedItems],
        },
      };
    });
  };

  const addExcludedItem = () => {
    const newItem = excludedItem.excluded_items.trim().toLowerCase();
    if (newItem !== "") {
      const isIncludedItem = planData?.included_items?.data
        .map((value) => value.toLowerCase())
        .includes(newItem);
      const isExcludedItem = planData?.excluded_items?.data
        .map((value) => value.toLowerCase())
        .includes(newItem);

      if (!isIncludedItem && !isExcludedItem) {
        setPlanData((prevState) => {
          return {
            ...prevState,
            excluded_items: {
              data: [
                ...prevState.excluded_items.data,
                excludedItem.excluded_items,
              ],
            },
          };
        });
        setExcludedItem({ excluded_items: "" });
      } else {
        setExcludedItem({ excluded_items: "" });
      }
    } else {
      errorToast("Blank Value Not Allowed");
    }
  };

  const handleChangeExcludedItem = (e) => {
    const { name, value } = e.target;

    setExcludedItem((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleRemoveExcludedItem = (item) => {
    const updatedItems = planData?.excluded_items?.data?.filter(
      (Value) => Value !== item
    );

    setPlanData((prevState) => {
      return {
        ...prevState,
        excluded_items: {
          data: [...updatedItems],
        },
      };
    });
  };

  const handleChangeSpecialPermission = (e) => {
    const { name, checked } = e.target;

    setPlanData((prevState) => {
      return {
        ...prevState,
        [name]: checked,
      };
    });
  };

  const handleFreeTrail = (e) => {
    const value = e.target.id;
    const checked = e.target.checked;

    if (checked) {
      setFreeTrail(value);
      setPlanData((prevState) => {
        return {
          ...prevState,
          allow_trials: {
            selected: true,
            trial_days: "",
          },
        };
      });
    } else {
      setPlanData((prevState) => {
        return {
          ...prevState,
          allow_trials: {
            selected: false,
            trial_days: "",
          },
        };
      });
      setFreeTrail();
    }
  };

  const handleMultiStatePermission = (e) => {
    const value = e.target.id;
    const checked = e.target.checked;
    setAtLeastOneCheckboxSelected(checked);

    if (checked) {
      setMultiStatePermission(value);
      setPlanData((prevState) => {
        return {
          ...prevState,
          state_permissions: {
            ...prevState.state_permissions,
            multistate: {
              selected: true,
              total_add_on: "",
            },
          },
        };
      });
    } else {
      setMultiStatePermission();
      setPlanData((prevState) => {
        return {
          ...prevState,
          state_permissions: {
            ...prevState.state_permissions,
            multistate: {
              selected: false,
              total_add_on: "",
            },
          },
        };
      });
    }
  };

  const handleChangeStatePermission = (e) => {
    const { name, checked, value } = e.target;
    if (name === "all_state_access" || name === "user_state") {
      setAtLeastOneCheckboxSelected(checked);
    }

    if (name === "multi_state") {
      setPlanData((prevState) => {
        return {
          ...prevState,
          state_permissions: {
            ...prevState.state_permissions,
            multistate: {
              selected: true,
              total_add_on: value,
            },
          },
        };
      });
    } else if (name === "user_state") {
      setPlanData((prevState) => {
        return {
          ...prevState,
          state_permissions: {
            ...prevState.state_permissions,
            [name]: checked,
          },
        };
      });
    } else if (name === "all_state_access") {
      setPlanData((prevState) => {
        return {
          ...prevState,
          state_permissions: {
            ...prevState.state_permissions,
            [name]: checked,
            multistate: {
              selected: false,
              total_add_on: "",
            },
            user_state: false,
          },
        };
      });
    }
  };

  const handleTrailDays = (e) => {
    const { value } = e.target;

    setPlanData((prevState) => {
      return {
        ...prevState,
        allow_trials: {
          selected: true,
          trial_days: value,
        },
      };
    });
  };

  const handleChangePermission = (e) => {
    const { name, checked } = e.target;
    setModulePermissionAtLeastOneCheckboxSelected(checked);

    setPlanData((prevState) => {
      return {
        ...prevState,
        module_permissions: {
          ...prevState.module_permissions,
          [name]: checked,
        },
      };
    });
  };

  const handleSaveDraft = async (e) => {
    e.preventDefault();

    setLoadingSubmitBtn(true);
    let schemaCheck;
    if (planData.plan_type === "state-permission") {
      schemaCheck = SubscriptionSchemaStatePermission;
    } else if (planData.plan_type === "upgrade") {
      schemaCheck = SubscriptionSchemaUpgrade;
    } else if (planData.plan_type === "add-on") {
      schemaCheck = SubscriptionSchemaAddOn;
    } else {
      schemaCheck = SubscriptionSchema;
    }
    var errors = validate(planData, schemaCheck);
    setFormErrors(errors || {});

    if (errors) {
      let error_values = Object.values(errors);
      errorToast(error_values[0]);
      setLoadingSubmitBtn(false);
      return;
    } else if (planData.plan_type === "state-permission") {
      if (errors) {
        let error_values = Object.values(errors);
        errorToast(error_values[0]);
        setLoadingSubmitBtn(false);
        return;
      } else if (!modulePermissionAtLeastOneCheckboxSelected) {
        let error_values = "Please select at least one checkbox for Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      }
    } else if (planData.plan_type === "upgrade") {
      if (errors) {
        let error_values = Object.values(errors);
        errorToast(error_values[0]);
        setLoadingSubmitBtn(false);
        return;
      } else if (!modulePermissionAtLeastOneCheckboxSelected) {
        let error_values = "Please select at least one checkbox for Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      } else if (!atLeastOneCheckboxSelected) {
        let error_values =
          "Please select at least one checkbox for State Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      }
    } else if (planData.plan_type === "add-on") {
      if (errors) {
        let error_values = Object.values(errors);
        errorToast(error_values[0]);
        setLoadingSubmitBtn(false);
        return;
      } else if (!modulePermissionAtLeastOneCheckboxSelected) {
        let error_values = "Please select at least one checkbox for Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      } else if (!atLeastOneCheckboxSelected) {
        let error_values =
          "Please select at least one checkbox for State Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      }
    } else if (planData.plan_type === "new") {
      if (errors) {
        let error_values = Object.values(errors);
        errorToast(error_values[0]);
        setLoadingSubmitBtn(false);
        return;
      } else if (!modulePermissionAtLeastOneCheckboxSelected) {
        let error_values = "Please select at least one checkbox for Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      } else if (!atLeastOneCheckboxSelected) {
        let error_values =
          "Please select at least one checkbox for State Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      }
    }

    try {
      let form_data = new FormData();
      form_data.append("draft", true);
      form_data.append("plan_name", planData.plan_name);
      form_data.append("platform_type", planData.platform_type);
      form_data.append("add_on_plans", JSON.stringify(planData.add_on_plans));
      form_data.append("plan_type", planData.plan_type);
      form_data.append("plan_category", planData.plan_category);
      form_data.append("upgrade_from", planData.upgrade_from);
      form_data.append("upgrade_to", planData.upgrade_to);
      form_data.append("upgrade_cost", planData.upgrade_cost);
      form_data.append("is_recommended", planData.is_recommended);
      form_data.append("is_active", planData.is_active);
      form_data.append("is_disabled", planData.is_disabled);
      form_data.append("is_free", planData.is_free);
      form_data.append("plan_term", JSON.stringify(planData.plan_term));
      form_data.append(
        "included_items",
        JSON.stringify(planData.included_items)
      );
      form_data.append("description", JSON.stringify(planData.description));
      form_data.append(
        "excluded_items",
        JSON.stringify(planData.excluded_items)
      );
      form_data.append(
        "module_permissions",
        JSON.stringify(planData.module_permissions)
      );
      form_data.append(
        "state_permissions",
        JSON.stringify(planData.state_permissions)
      );
      form_data.append("allow_trials", JSON.stringify(planData.allow_trials));

      SubscriptionServices.SubscriptionFormCreation(form_data).then((res) => {
        if (res.status === 200) {
          successToast(res.msg);
          setButtonLoading(false);
          setTimeout(() => {
            window.location.replace(
              "/subscription/manage-plan/view-draft_plans"
            );
          }, 500);
        } else {
          setButtonLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      errorToast(error.message);
    }
  };

  const handlePublish = async (e) => {
    e.preventDefault();
    setLoadingSubmitBtn(true);

    let schemaCheck;
    if (planData.plan_type === "state-permission") {
      schemaCheck = SubscriptionSchemaStatePermission;
    } else if (planData.plan_type === "upgrade") {
      schemaCheck = SubscriptionSchemaUpgrade;
    } else if (planData.plan_type === "add-on") {
      schemaCheck = SubscriptionSchemaAddOn;
    } else {
      schemaCheck = SubscriptionSchema;
    }
    var errors = validate(planData, schemaCheck);

    setFormErrors(errors || {});

    if (errors) {
      let error_values = Object.values(errors);
      errorToast(error_values[0]);
      console.log(error_values[0]);
      setLoadingSubmitBtn(false);
      return;
    } else if (planData.plan_type === "state-permission") {
      if (errors) {
        let error_values = Object.values(errors);
        errorToast(error_values[0]);
        setLoadingSubmitBtn(false);
        return;
      } else if (!modulePermissionAtLeastOneCheckboxSelected) {
        let error_values = "Please select at least one checkbox for Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      }
    } else if (planData.plan_type === "upgrade") {
      if (errors) {
        let error_values = Object.values(errors);
        errorToast(error_values[0]);
        setLoadingSubmitBtn(false);
        return;
      } else if (!modulePermissionAtLeastOneCheckboxSelected) {
        let error_values = "Please select at least one checkbox for Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      } else if (!atLeastOneCheckboxSelected) {
        let error_values =
          "Please select at least one checkbox for State Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      }
    } else if (planData.plan_type === "add-on") {
      if (errors) {
        let error_values = Object.values(errors);
        errorToast(error_values[0]);
        setLoadingSubmitBtn(false);
        return;
      } else if (!modulePermissionAtLeastOneCheckboxSelected) {
        let error_values = "Please select at least one checkbox for Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      } else if (!atLeastOneCheckboxSelected) {
        let error_values =
          "Please select at least one checkbox for State Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      }
    } else if (planData.plan_type === "new") {
      if (errors) {
        let error_values = Object.values(errors);

        errorToast(error_values[0]);
        setLoadingSubmitBtn(false);
        return;
      } else if (!modulePermissionAtLeastOneCheckboxSelected) {
        let error_values = "Please select at least one checkbox for Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      } else if (!atLeastOneCheckboxSelected) {
        let error_values =
          "Please select at least one checkbox for State Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      }
    }

    try {
      let form_data = new FormData();
      form_data.append("draft", false);
      form_data.append("plan_name", planData.plan_name);
      form_data.append("platform_type", planData.platform_type);
      form_data.append("plan_type_state", planData.plan_type_state);
      form_data.append("add_on_plans", JSON.stringify(planData.add_on_plans));
      form_data.append("plan_type", planData.plan_type);
      form_data.append("plan_category", planData.plan_category);
      form_data.append("upgrade_from", planData.upgrade_from);
      form_data.append("upgrade_to", planData.upgrade_to);
      form_data.append("upgrade_cost", planData.upgrade_cost);
      form_data.append("is_recommended", planData.is_recommended);
      form_data.append("is_active", planData.is_active);
      form_data.append("is_disabled", planData.is_disabled);
      form_data.append("is_free", planData.is_free);
      form_data.append("plan_term", JSON.stringify(planData.plan_term));
      form_data.append(
        "included_items",
        JSON.stringify(planData.included_items)
      );
      form_data.append("description", JSON.stringify(planData.description));
      form_data.append(
        "excluded_items",
        JSON.stringify(planData.excluded_items)
      );
      form_data.append(
        "module_permissions",
        JSON.stringify(planData.module_permissions)
      );
      form_data.append(
        "state_permissions",
        JSON.stringify(planData.state_permissions)
      );
      form_data.append("allow_trials", JSON.stringify(planData.allow_trials));

      SubscriptionServices.SubscriptionFormCreation(form_data).then((res) => {
        if (res?.status === 200) {
          successToast(res.msg);
          setButtonLoading(false);
          setTimeout(() => {
            window.location.replace(
              "/subscription/manage-plan/view-published_plans"
            );
          }, 500);
        } else {
          console.log(res.msg);
          setButtonLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      errorToast(error.message);
    }
  };

  const handleUpdateSubscriptionPlan = (e, name) => {
    e.preventDefault();
    setLoadingSubmitBtn(true);
    let schemaCheck;
    if (planData.plan_type === "state-permission") {
      schemaCheck = SubscriptionSchemaStatePermission;
    } else if (planData.plan_type === "upgrade") {
      schemaCheck = SubscriptionSchemaUpgrade;
    } else if (planData.plan_type === "add-on") {
      schemaCheck = SubscriptionSchemaAddOn;
    } else {
      schemaCheck = SubscriptionSchema;
    }

    var errors = validate(planData, schemaCheck);
    setFormErrors(errors || {});

    if (errors) {
      let error_values = Object.values(errors);
      errorToast(error_values[0]);
      setLoadingSubmitBtn(false);
      return;
    } else if (planData.plan_type === "state-permission") {
      if (errors) {
        let error_values = Object.values(errors);
        errorToast(error_values[0]);
        setLoadingSubmitBtn(false);
        return;
      } else if (!modulePermissionAtLeastOneCheckboxSelected) {
        let error_values = "Please select at least one checkbox for Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      }
    } else if (planData.plan_type === "upgrade") {
      if (errors) {
        let error_values = Object.values(errors);
        errorToast(error_values[0]);
        setLoadingSubmitBtn(false);
        return;
      } else if (!modulePermissionAtLeastOneCheckboxSelected) {
        let error_values = "Please select at least one checkbox for Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      } else if (!atLeastOneCheckboxSelected) {
        let error_values =
          "Please select at least one checkbox for State Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      }
    } else if (planData.plan_type === "add-on") {
      if (errors) {
        let error_values = Object.values(errors);
        errorToast(error_values[0]);
        setLoadingSubmitBtn(false);
        return;
      } else if (!modulePermissionAtLeastOneCheckboxSelected) {
        let error_values = "Please select at least one checkbox for Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      } else if (!atLeastOneCheckboxSelected) {
        let error_values =
          "Please select at least one checkbox for State Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      }
    } else if (planData.plan_type === "new") {
      if (errors) {
        let error_values = Object.values(errors);
        errorToast(error_values[0]);
        setLoadingSubmitBtn(false);
        return;
      } else if (!modulePermissionAtLeastOneCheckboxSelected) {
        let error_values = "Please select at least one checkbox for Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      } else if (!atLeastOneCheckboxSelected) {
        let error_values =
          "Please select at least one checkbox for State Permission";
        errorToast(error_values);
        setLoadingSubmitBtn(false);
        return;
      }
    }

    try {
      setButtonLoading(true);
      if (name === "published") {
        let form_data = new FormData();
        form_data.append("draft", false);
        form_data.append("plan_id", planId);
        form_data.append("plan_type_state", planData.plan_type_state);
        form_data.append("platform_type", planData.platform_type);
        form_data.append("add_on_plans", JSON.stringify(planData.add_on_plans));
        form_data.append("plan_name", planData.plan_name);
        form_data.append("plan_type", planData.plan_type);
        form_data.append("plan_category", planData.plan_category);
        form_data.append("upgrade_from", planData.upgrade_from);
        form_data.append("upgrade_to", planData.upgrade_to);
        form_data.append("upgrade_cost", planData.upgrade_cost);
        form_data.append("is_recommended", planData.is_recommended);
        form_data.append("is_active", planData.is_active);
        form_data.append("is_disabled", planData.is_disabled);
        form_data.append("is_free", planData.is_free);
        form_data.append("plan_term", JSON.stringify(planData.plan_term));
        form_data.append(
          "included_items",
          JSON.stringify(planData.included_items)
        );
        form_data.append("description", JSON.stringify(planData.description));
        form_data.append(
          "excluded_items",
          JSON.stringify(planData.excluded_items)
        );
        form_data.append(
          "module_permissions",
          JSON.stringify(planData.module_permissions)
        );
        form_data.append(
          "state_permissions",
          JSON.stringify(planData.state_permissions)
        );
        form_data.append("allow_trials", JSON.stringify(planData.allow_trials));

        SubscriptionServices.UpdateSubscriptionPlan(form_data).then((res) => {
          if (res?.status === 200) {
            successToast(res.msg);
            setButtonLoading(false);
            setTimeout(() => {
              window.location.replace(
                "/subscription/manage-plan/view-published_plans"
              );
            }, 500);
          } else {
            console.log(res.msg);
            setButtonLoading(false);
          }
        });
      } else {
        let form_data = new FormData();
        form_data.append("draft", true);
        form_data.append("plan_id", planId);
        form_data.append("plan_name", planData.plan_name);
        form_data.append("add_on_plans", JSON.stringify(planData.add_on_plans));
        form_data.append("plan_type_state", planData.plan_type_state);
        form_data.append("plan_type", planData.plan_type);
        form_data.append("plan_category", planData.plan_category);
        form_data.append("upgrade_from", planData.upgrade_from);
        form_data.append("upgrade_to", planData.upgrade_to);
        form_data.append("upgrade_cost", planData.upgrade_cost);
        form_data.append("is_recommended", planData.is_recommended);
        form_data.append("is_active", planData.is_active);
        form_data.append("is_disabled", planData.is_disabled);
        form_data.append("is_free", planData.is_free);
        form_data.append("plan_term", JSON.stringify(planData.plan_term));
        form_data.append(
          "included_items",
          JSON.stringify(planData.included_items)
        );
        form_data.append("description", JSON.stringify(planData.description));
        form_data.append(
          "excluded_items",
          JSON.stringify(planData.excluded_items)
        );
        form_data.append(
          "module_permissions",
          JSON.stringify(planData.module_permissions)
        );
        form_data.append(
          "state_permissions",
          JSON.stringify(planData.state_permissions)
        );
        form_data.append("allow_trials", JSON.stringify(planData.allow_trials));

        SubscriptionServices.UpdateSubscriptionPlan(form_data).then((res) => {
          if (res.status === 200) {
            successToast(res.msg);
            setButtonLoading(false);
            setTimeout(() => {
              window.location.replace("/rapidbid/manage-plan/view-draft_plans");
            }, 500);
          } else {
            console.log(res.msg);
            setButtonLoading(false);
          }
        });
      }
    } catch (error) {
      console.log(error);
      errorToast(error.message);
    }
  };

  const handleState = (e, name) => {
    setPlanData((prevState) => {
      return {
        ...prevState,
        [name]: e.value.id,
      };
    });
  };

  const handleAssignedPlanNewPlanList = (selectedOptions) => {
    const newPlanIds = selectedOptions.map((item) => item.value.plan_id);

    setPlanData((prevState) => {
      // const existingPlanIds = Array.isArray(prevState.add_on_plans)
      //   ? prevState.add_on_plans
      //   : [];

      const uniquePlanIds = [...new Set([...newPlanIds])];

      return {
        ...prevState,
        add_on_plans: uniquePlanIds,
      };
    });
  };

  const handlePlatform = (e, name) => {
    setPlanData((prevState) => {
      return {
        ...prevState,
        [name]: e?.value,
        plan_term: "",
      };
    });
    setBillingCycle([]);

    if (e?.value !== "") {
      let form_data = new FormData();
      form_data.append(name, e?.value);
      SubscriptionServices.GetSubscriptionTenorListByPlatform(form_data).then(
        (res) => {
          if (res?.status === 200) {
            let duration_List = res?.data?.map((entry, index) => ({
              value: `${entry.duration} month`,
            }));
            duration_List.sort((a, b) => {
              return parseInt(a.value) - parseInt(b.value);
            });

            setDurationList(duration_List);
            if (duration_List.length >= 0) {
              duration_List.map((val) => {
                setPlanData((prevState) => ({
                  ...prevState,
                  plan_term: {},
                }));
              });
            }
          }
        }
      );
    }
  };
  useEffect(() => {
    if (planData.plan_term !== "") {
    }
  }, [planData.plan_term]);



  return (
    <>
      <div className="container d-flex d-flex-wrap justify-content-center ">
        <div
          className=" col-md-9 col-12 shadow-lg bg-body p-3 mb-2  rounded"
          style={{
            marginTop: "2rem",
          }}
        >
          {planId ? (
            planData.length !== 0 && planData.draft === true ? (
              <h2 className="text-center">Update Draft Subscription Plan</h2>
            ) : (
              <h2 className="text-center">
                Update Published Subscription Plan
              </h2>
            )
          ) : (
            <h2 className="text-center">Create Subscription Plan</h2>
          )}

          <hr className="soften mt-2" />
          <form>
            <h6>Basic Information</h6>
            <div className="row mt-3">
              <div className="col-md-6 mb-3 form-group">
                <label htmlFor="name">Plan Name</label>
                <input
                  className="form-control"
                  placeholder="Enter Plan Name"
                  type="text"
                  name="plan_name"
                  value={planData.plan_name}
                  onChange={handleChange}
                />
                {formErrors.name && (
                  <div className="alert alert-danger mt-2">
                    {formErrors.name}
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-3 form-group">
                <label htmlFor="name">Platform</label>
                <Select
                  options={platformType}
                  onChange={(e) => handlePlatform(e, "platform_type")}
                  value={platformType.find(
                    (option) => option.value === planData.platform_type
                  )}
                  placeholder="Select Platform"
                />
                {formErrors.name && (
                  <div className="alert alert-danger mt-2">
                    {formErrors.name}
                  </div>
                )}
              </div>
            </div>
            <div className="row mt-3">
              {planData?.plan_type === "add-on" ? (
                <div className="col-md-4 mb-3 form-group">
                  <label>Plan Type</label>
                  <Select
                    options={PlanType}
                    onChange={(e) => getPlanType(e, "plan_type")}
                    value={PlanType.find(
                      (option) => option.value === planData.plan_type
                    )}
                    placeholder="Select Plan Type"
                  />
                  {formErrors.type && (
                    <div className="alert alert-danger mt-2">
                      {formErrors.type}
                    </div>
                  )}
                </div>
              ) : (
                <div className="col-md-6 mb-3 form-group">
                  <label>Plan Type</label>
                  <Select
                    options={PlanType}
                    onChange={(e) => getPlanType(e, "plan_type")}
                    value={PlanType.find(
                      (option) => option.value === planData.plan_type
                    )}
                    placeholder="Select Plan Type"
                  />
                </div>
              )}

              {planData?.plan_type === "add-on" && (
                <div className="col-md-4 mb-3 form-group">
                  <label>Assigned Plan</label>

                  <Select
                    isMulti
                    options={planList}
                    onChange={handleAssignedPlanNewPlanList}
                    value={planData?.add_on_plans?.map((selectedPlanId) => {
                      return planList?.find(
                        (option) => option?.value?.plan_id === selectedPlanId
                      );
                    })}
                    placeholder="Select Plan Type"
                  />
                </div>
              )}
              {planData?.plan_type === "add-on" ? (
                <div className="col-md-4 form-group ">
                  <label>Plan Category</label>
                  <Select
                    options={PlanCategory}
                    onChange={getPlanCategory}
                    value={PlanCategory.find(
                      (option) => option?.value === planData?.plan_category
                    )}
                    placeholder="Select Plan Category"
                  />
                </div>
              ) : (
                <div className="col-md-6 form-group ">
                  <label>Plan Category</label>
                  <Select
                    options={PlanCategory}
                    onChange={getPlanCategory}
                    value={PlanCategory.find(
                      (option) => option?.value === planData?.plan_category
                    )}
                    placeholder="Select Plan Category"
                  />
                </div>
              )}
            </div>

            <hr />

            {planData.plan_type === "upgrade" ? (
              <>
                <div className="form-group">
                  <label>Upgraded From</label>
                  <Select
                    options={PlanType}
                    onChange={(e) => getPlanType(e, "upgrade_from")}
                    value={PlanType.find(
                      (option) => option.value === planData.upgrade_from
                    )}
                    placeholder="Select Plan Type"
                  />
                </div>
                <div className="form-group">
                  <label>Upgraded To</label>
                  <Select
                    options={PlanType}
                    onChange={(e) => getPlanType(e, "upgrade_to")}
                    value={PlanType.find(
                      (option) => option.value === planData.upgrade_to
                    )}
                    placeholder="Select Plan Type"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name">Upgrade Cost</label>
                  <input
                    className="form-control"
                    placeholder="Enter Plan Name"
                    type="number"
                    name="upgrade_cost"
                    value={planData.upgrade_cost}
                    onChange={handleChange}
                  />
                </div>
                <hr className="soften my-3" />
              </>
            ) : planData.platform_type ? (
              <>
                <div className="d-flex flex-wrap">
                  <div className="row mt-3">
                    {durationList.map((value, key, index) => (
                      <div
                        key={key}
                        className={`col-12 col-sm-12 col-md-6 col-lg-6 ${
                          key === durationList.length - 1 &&
                          durationList.length % 2 !== 0 &&
                          durationList.length === 1
                            ? " col-md-12 col-lg-12"
                            : ""
                        }`}
                      >
                        <div className="row mb-3">
                          <div className="col-6 col-lg-5 d-flex align-items-center">
                            <input
                              className="time_checkbox mb-3"
                              type="checkbox"
                              id={index}
                              name={value.value}
                              value={value.value}
                              checked={billingCycle.includes(value.value)}
                              onChange={handleBillingCycleChange}
                            />
                            <label
                              htmlFor={index}
                              style={{ marginLeft: "8px" }}
                            >
                              {value.value}
                            </label>
                          </div>
                          <div className="col-6 col-lg-7 mt-2">
                            <input
                              disabled={!billingCycle.includes(value.value)}
                              id={index}
                              className="form-control"
                              placeholder="Enter Plan Cost"
                              type="number"
                              name={value.value}
                              value={
                                planData?.plan_term[value.value] !== undefined
                                  ? planData.plan_term[value.value]
                                  : ""
                              }
                              onChange={handleChangePlanTerm}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <hr className="soften my-3" />
              </>
            ) : null}
            <div className="row mt-3">
              <label htmlFor="name">Description</label>
              <div className="col-10 col-sm-11 col-md-11 form-group">
                <input
                  className="form-control"
                  placeholder="Description"
                  id="description_item"
                  type="text"
                  name="description_item"
                  value={description.description_item}
                  onChange={handleChangeDescription}
                />
              </div>
              <div className="col-1 col-sm-11 col-md-1 form-group">
                <i
                  className="bi bi-plus-circle"
                  name="description_item"
                  value={description.description_item}
                  onClick={addDescription}
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
            </div>
            <div className="form-group">
              {planData?.description?.data?.length !== 0 &&
                planData?.description?.data?.map((item) => (
                  <div key={item} className="item-capsule">
                    <span className="item-text">{item}</span>
                    <i
                      className="bi bi-x"
                      onClick={() => handleRemoveDescription(item)}
                    ></i>
                  </div>
                ))}
            </div>

            <div className="row mt-3">
              <label htmlFor="name">Included Items</label>
              <div className="col-10 col-sm-11 col-md-11 form-group">
                <input
                  className="form-control"
                  placeholder="Included item"
                  id="included_items"
                  type="text"
                  name="included_items"
                  value={item.included_items}
                  onChange={handleChangeIncludedItem}
                />
              </div>
              <div className="col-1 col-sm-11 col-md-1 form-group">
                <i
                  className="bi bi-plus-circle"
                  name="included_items"
                  value={item.included_items}
                  onClick={addItem}
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
            </div>
            <div className="form-group">
              {planData?.included_items?.data?.length !== 0 &&
                planData?.included_items?.data?.map((item) => (
                  <div key={item} className="item-capsule">
                    <span className="item-text">{item}</span>
                    <i
                      className="bi bi-x"
                      onClick={() => handleRemoveIncludedItem(item)}
                    ></i>
                  </div>
                ))}
            </div>

            <div className="row mt-3">
              <label htmlFor="name">Excluded Items</label>
              <div className="col-10 col-sm-11 col-md-11 form-group">
                <input
                  className="form-control"
                  placeholder="Excluded item"
                  id="excluded_items"
                  type="text"
                  name="excluded_items"
                  value={excludedItem.excluded_items}
                  onChange={handleChangeExcludedItem}
                />
              </div>
              <div className="col-1 col-sm-11 col-md-1 form-group">
                <i
                  className="bi bi-plus-circle"
                  name="excluded_items"
                  value={excludedItem.excluded_items}
                  onClick={addExcludedItem}
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
            </div>
            <div className="form-group">
              {planData?.excluded_items?.data?.length !== 0 &&
                planData?.excluded_items?.data?.map((item) => (
                  <div key={item} className="item-capsule">
                    <span className="item-text">{item}</span>
                    <i
                      className="bi bi-x"
                      onClick={() => handleRemoveExcludedItem(item)}
                    ></i>
                  </div>
                ))}
            </div>
            <hr className="soften my-3" />
            <h6>Permissions</h6>
            <div className="d-flex  align-items-center">
              <input
                className="time_checkbox "
                type="checkbox"
                id="buy"
                name="is_buyer"
                // value="buy"
                value={planData.module_permissions.is_buyer}
                checked={planData.module_permissions.is_buyer}
                onChange={handleChangePermission}
              />
              <label
                htmlFor="buy"
                style={{ marginLeft: "8px", marginTop: "4px" }}
                className="custom-control-label col-lg-3 col-md-3 col-2"
              >
                Buy
              </label>
              <input
                className="time_checkbox ms-4"
                type="checkbox"
                id="sell"
                name="is_seller"
                value={planData.module_permissions.is_seller}
                checked={planData.module_permissions.is_seller}
                onChange={handleChangePermission}
              />
              <label
                htmlFor="sell"
                style={{ marginLeft: "8px", marginTop: "4px" }}
                className="custom-control-label col-lg-3 col-md-3 col-2"
              >
                Sell
              </label>

              <input
                className="time_checkbox ms-4"
                type="checkbox"
                id="auction_approval"
                name="is_auction_approval"
                value={planData.module_permissions.is_auction_approval}
                checked={planData.module_permissions.is_auction_approval}
                onChange={handleChangePermission}
              />
              <label
                htmlFor="auction_approval"
                style={{ marginLeft: "8px", marginTop: "4px" }}
                className="custom-control-label col-4"
              >
                Auction Approval
              </label>
            </div>
            <hr className="soften my-3" />
            <h6>State Permissions</h6>
            {planData.plan_type === "state-permission" && (
              <>
                <div className="mb-3 form-group">
                  <Select
                    options={stateList}
                    onChange={(e) => {
                      handleState(e, "plan_type_state");
                    }}
                    value={stateList.find(
                      (option) => option.value === planData.plan_type_state
                    )}
                    placeholder="Select Plan Type"
                  />
                </div>
              </>
            )}
            {planData.plan_type !== "state-permission" && (
              <div className="row mt-3">
                <div className="col-md-12 form-group d-flex justify-content-between">
                  <input
                    disabled={
                      planData.plan_type === "add-on"
                        ? !planData.state_permissions.user_state
                        : false ||
                          planData.state_permissions.all_state_access === true
                    }
                    className="time_checkbox mx-3 mt-3"
                    type="checkbox"
                    id="user_state"
                    name="user_state"
                    value={planData.state_permissions.user_state}
                    checked={planData.state_permissions.user_state}
                    onChange={handleChangeStatePermission}
                  />
                  <label
                    htmlFor="user_state"
                    className="custom-control-label col-lg-3 col-md-4 col-3 mt-3"
                  >
                    User State
                  </label>

                  <input
                    disabled={
                      planData.state_permissions.all_state_access
                        ? !planData.state_permissions.multistate.selected
                        : false
                    }
                    className="time_checkbox mx-2 my-3"
                    type="checkbox"
                    id="multi_state"
                    name="multi_state"
                    value="multi_state"
                    checked={planData.state_permissions.multistate.selected}
                    onChange={handleMultiStatePermission}
                  />
                  <label
                    htmlFor="multi_state"
                    className="custom-control-label col-lg-2 col-md-2 mt-3 col-3"
                  >
                    MultiState :
                  </label>

                  <input
                    disabled={
                      multiStatePermission !== "multi_state" ||
                      planData.state_permissions.multistate.selected === false
                    }
                    className="form-control my-2 mx-1 "
                    placeholder="Enter No. of State"
                    type="number"
                    name="multi_state"
                    value={
                      planData?.state_permissions?.multistate?.total_add_on
                    }
                    onChange={handleChangeStatePermission}
                  />
                </div>
                <div className="col-md-12 col-lg-12 mb-3 form-group d-flex justify-content-between my-3 mx-1">
                  <input
                    className="time_checkbox mx-2 my-3"
                    type="checkbox"
                    id="all_state_access"
                    name="all_state_access"
                    value={planData.state_permissions.all_state_access}
                    checked={planData.state_permissions.all_state_access}
                    onChange={handleChangeStatePermission}
                  />
                  <label
                    htmlFor="all_state"
                    className="custom-control-label col-lg-3 col-md-4 mt-3"
                  >
                    AllState
                  </label>

                  <input
                    disabled={
                      planData.plan_type === "add-on"
                        ? !planData.allow_trials.selected
                        : false
                    }
                    className="time_checkbox mx-2 my-3"
                    type="checkbox"
                    id="allow_trials"
                    name="allow_trials"
                    value="allow_trials"
                    checked={planData.allow_trials.selected}
                    onChange={handleFreeTrail}
                  />
                  <label
                    htmlFor="allow_trials"
                    className="custom-control-label col-lg-2 col-md-2 col-3 my-3"
                  >
                    Allow Free Trail:
                  </label>

                  <input
                    disabled={freeTrail !== "allow_trials"}
                    className="form-control my-2 mx-2"
                    placeholder="Enter Trial Day"
                    // id="trial_days"
                    type="number"
                    name="trial_days"
                    value={planData?.allow_trials?.trial_days}
                    onChange={handleTrailDays}
                  />
                </div>
              </div>
            )}

            <div className="d-flex flex-wrap content-between-center my-3 mx-1 gap-3">
              <div className="d-flex justify-content-end">
                <input
                  className="time_checkbox"
                  type="checkbox"
                  id="recommended"
                  name="is_recommended"
                  value="recommended"
                  checked={planData?.is_recommended}
                  onChange={handleChangeSpecialPermission}
                />
                <label
                  htmlFor="recommended"
                  style={{ marginLeft: "3px", marginTop: "3px" }}
                  className=" form-label"
                >
                  Recommended
                </label>
              </div>
              <div className="d-flex justify-content-end">
                <input
                  className="time_checkbox"
                  type="checkbox"
                  id="active"
                  name="is_active"
                  value="active"
                  checked={planData.is_active}
                  onChange={handleChangeSpecialPermission}
                />
                <label
                  htmlFor="active"
                  style={{ marginLeft: "3px", marginTop: "2px" }}
                  className="form-label"
                >
                  Active
                </label>
              </div>
              <div className="d-flex justify-content-end">
                <input
                  className="time_checkbox"
                  type="checkbox"
                  id="disabled"
                  name="is_disabled"
                  value="is_disabled"
                  checked={planData.is_disabled}
                  onChange={handleChangeSpecialPermission}
                />

                <label
                  htmlFor="disabled"
                  style={{
                    marginLeft: "3px",
                    marginTop: "3px",
                    marginRight: "3px",
                  }}
                  className="form-label"
                >
                  Disabled
                </label>
              </div>
              <div className="d-flex justify-content-end">
                <input
                  className="time_checkbox"
                  type="checkbox"
                  id="free"
                  name="is_free"
                  value="is_free"
                  checked={planData.is_free}
                  onChange={handleChangeSpecialPermission}
                />
                <label
                  htmlFor="free"
                  style={{ marginLeft: "3px", marginTop: "2px" }}
                  className=" form-label"
                >
                  Free
                </label>
              </div>
            </div>
            {planId && planData.draft === true && (
              <div className="float-end mt-3">
                <button
                  className="btn btn-danger my-3 p-2 px-4"
                  type="submit"
                  name="Cancel"
                  // onClick={setPlanData(initialValue)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary mx-3 my-3 py-2"
                  type="submit"
                  name="draft"
                  disabled={buttonLoading}
                  onClick={(e) => {
                    handleUpdateSubscriptionPlan(e, "draft");
                  }}
                >
                  Save as Draft
                </button>
                <button
                  className="btn btn-success my-2 px-3 py-2"
                  type="submit"
                  name="published"
                  disabled={buttonLoading}
                  onClick={(e) => {
                    handleUpdateSubscriptionPlan(e, "published");
                  }}
                >
                  Published
                </button>
              </div>
            )}
            {planId && planData.draft === false && (
              <div className="float-end mt-3">
                <button
                  className="btn btn-danger mx-3 my-3 p-2 px-4"
                  type="submit"
                  name="Cancel"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-success mx-3 my-3 p-2"
                  type="submit"
                  name="published"
                  disabled={
                    planData.plan_name === "" ||
                    planData.platform_type === "" ||
                    planData.plan_type === "" ||
                    planData.plan_category === "" ||
                    planData.plan_term.monthly === "" ||
                    planData.plan_term.quarterly === "" ||
                    planData.plan_term.yearly === "" ||
                    planData.description.data.length === 0 ||
                    planData.included_items.data.length === 0 ||
                    planData.excluded_items.data.length === 0
                  }
                  onClick={(e) => {
                    handleUpdateSubscriptionPlan(e, "published");
                  }}
                >
                  Update
                </button>
              </div>
            )}

            {!planId && (
              <div className="float-end mt-3">
                <button
                  className="btn btn-danger mx-1 my-3 p-2 px-4"
                  type="submit"
                  name="Cancel"
                  // onClick={setPlanData(initialValue)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary mx-1 my-3 py-2"
                  type="submit"
                  disabled={buttonLoading}
                  onClick={handleSaveDraft}
                >
                  Save as Draft
                </button>
                {loadingSubmitBtn ? (
                  <button
                    className="btn btn-primary mt-2"
                    type="button"
                    disabled
                    id="loadingBtnTp"
                  >
                    <span
                      className="spinner-border spinner-border-sm mx-1 my-2 px-3 py-2"
                      type="submit"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </button>
                ) : (
                  <button
                    className="btn btn-success mx-1 my-2 px-3 py-2"
                    type="submit"
                    // disabled={atLeastOneCheckboxSelected}
                    onClick={handlePublish}
                  >
                    Published
                  </button>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default SubscriptionCreatePlans;
