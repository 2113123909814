import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import SubscriptionServices from "../../services/subscription/subscription.service";
import SearchBox from "../../common/SearchBox";
import ReactLoading from "react-loading";

const SubscriptionTransactionHistoryForAllUsers = () => {
  const location = useLocation();
  const { id } = useParams();
  const phone = id.split(":")[1];

  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      header: "Sr. No.",
      dataKey: "no",
    },
    {
      header: "User Name",
      dataKey: "user_name",
    },
    {
      header: "User Phone",
      dataKey: "phone",
    },
    {
      header: "Plan Name",
      dataKey: "plan_name",
    },
    {
      header: "Platform Type",
      dataKey: "platform_type",
    },
    {
      header: "Start Date",
      dataKey: "start_date",
    },
    {
      header: "End Date",
      dataKey: "end_date",
    },
    {
      header: "Plan Type",
      dataKey: "plan_type",
    },
    {
      header: "Final Amount",
      dataKey: "final_amount",
    },
  ];

  let entry;
  useEffect(() => {
    setLoading(true);
    let form_data = new FormData();
    form_data.append("phone", parseInt(phone));
    SubscriptionServices.GetUserTransactionHistory(form_data).then((res) => {
      if (res.status === 200) {
        const renderedData = res.data.map((item, key, index) => ({
          no: key + 1,
          user_name: item.user_name,
          final_amount: item.final_amount,
          start_date: item.start_date,
          end_date: item.end_date,
          plan_id: item.plan_id,
          plan_name: item.plan_name,
          phone: phone,
          platform_type:item.platform_type,
          plan_type:item.plan_type
        }));
        setData(renderedData);
        setFilterData(renderedData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, [phone]);
  return (
    <div className="container mt-3">
      <div className="col-md-12 col-12 col-lg-12 mb-2 ">
        <div className="d-flex flex-wrap justify-content-between align-items-end">
          <div className="col-lg-3 col-12 col-md-4 col-sm-12">
            <h4>Transaction History</h4>
          </div>
          <div className="col-lg-3 col-12 col-md-4 col-sm-12 ms-auto">
            <SearchBox allData={data} setFilteredData={setFilterData} />
          </div>
        </div>
      </div>

      {loading && Object.keys(data).length === 0 ? (
        <div
          className="d-flex justify-content-center container"
          style={{ minHeight: "80vh", alignItems: "center" }}
        >
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
            className="reactLoadingDash"
          />
        </div>
      ) : (
        <>
          {!loading && data.length === 0 ? (
            <div className="container table-responsive mt-2">
              <table className="table table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    {columns?.map((column) => (
                      <th key={column.dataKey}>{column.header}</th>
                    ))}
                  </tr>
                </thead>
              </table>
              <div className="container">
                <p className="text-center text-danger">
                  No transaction has been done by this user till now!
                </p>
              </div>
            </div>
          ) : (
            <>
              <div className="table-responsive mt-3">
                <table className="table table-hover table-bordered text-center table-responsive">
                  <thead>
                    <tr>
                      {columns?.map((column) => (
                        <th key={column.dataKey}>{column.header}</th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {Object?.entries(data)?.map(([key, entry], index) => (
                      <tr key={index}>
                        {columns.map((column) => (
                          <>
                            <td>
                              {column.dataKey === "add_on"
                                ? entry[column.dataKey] === ""
                                  ? "-"
                                  : entry[column.dataKey]
                                : column.dataKey === "state_permission"
                                ? entry[column.dataKey].length === 0
                                  ? "-"
                                  : entry[column.dataKey].join(", ")
                                : entry[column.dataKey]}
                            </td>
                          </>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SubscriptionTransactionHistoryForAllUsers;
