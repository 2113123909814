import axios from "axios";
import jsPDF from "jspdf";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ReactLoading from "react-loading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as XLSX from "xlsx";
import SearchBox from "../../../common/SearchBox";
import { errorToast } from "../../../react-toastfiy/toast";
import auctionService from "../../../services/auction/auction.service";

const UserBalanceList = () => {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  const history = useHistory();
  const source = axios.CancelToken.source();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [indexNo, setIndexNo] = useState({ first_index: "", last_index: "" });
  const [arrayLength, setArrayLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const options = [
    { label: "Name", value: "name" },
    { label: "State", value: "state" },
    { label: "Balance Up", value: "balance up" },
    { label: "Balance Down", value: "balance down" },
  ];

  useEffect(() => {
    try {
      setLoading(true);
      const form_data = new FormData();
      form_data.append("pg_id", currentPage);
      auctionService.GetLedgerBalanceDetails(form_data, source).then((res) => {
        setLoading(false);
        if (Object.entries(res)?.length > 0) {
          setData(res);
          setFilterData(res);
          const lastIndex = Object.values(res).length - 1;
          setIndexNo({
            ["first_index"]: Object.values(res)[0]?.sr_no,
            ["last_index"]: Object.values(res)[lastIndex]?.sr_no,
          });
          const lenTrans = Object.values(res)[0].len_ledger;
          setArrayLength(lenTrans);
        } else {
        }
      });
    } catch (error) {
      errorToast(error.msg);
    }
  }, [currentPage]);

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const handleChangeShortOption = (e) => {
    setSelectedValue(capitalizeWords(e));
    try {
      setLoading(true);
      const form_data = new FormData();
      form_data.append("pg_id", "1");
      form_data.append("sort", e);
      auctionService.GetLedgerBalanceDetails(form_data, source).then((res) => {
        setLoading(false);
        if (Object.entries(res)?.length > 0) {
          setData(res);
          setFilterData(res);
          const lastIndex = Object.values(res).length - 1;
          setIndexNo({
            ["first_index"]: Object.values(res)[0]?.sr_no,
            ["last_index"]: Object.values(res)[lastIndex]?.sr_no,
          });
          const lenTrans = Object.values(res)[0].len_ledger;
          setArrayLength(lenTrans);
        }
      });
    } catch (error) {
      errorToast(error.msg);
    }
  };

  const pageCount = Math.ceil(arrayLength / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, arrayLength);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDownloadPDF = (e) => {
    e.preventDefault();
    auctionService.DownloadAllBalanceList(source).then((res) => {
      if (Object.entries(res)?.length > 0) {
        const doc = new jsPDF({
          orientation: "landscape",
        });

        doc.setFontSize(12);
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const margin = 10;
        const marginHight = 15;
        let yPos = margin;

        const columns = [
          "Sr. No.",
          "Name",
          "Phone",
          "State",
          "State permissions",
          "Balance",
        ];

        const colWidths = {
          "Sr. No.": 15,
          Name: 60,
          Phone: 40,
          State: 40,
          "State permissions": 80,
          Balance: 30,
        };

        const drawTableHeaders = (xPos) => {
          doc.setFont("helvetica", "bold");
          doc.setFontSize(10);
          doc.setFillColor(40, 167, 69); // Green background for headers
          doc.setTextColor(255);
          columns.forEach((column) => {
            doc.setLineWidth(0.1);
            doc.setDrawColor(0);
            doc.setFillColor(40, 167, 69);
            const textWidth =
              (doc.getStringUnitWidth(column) * doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            const textX = xPos + (colWidths[column] - textWidth) / 2;
            const textY = yPos + margin / 2 + 3;
            doc.rect(xPos, yPos, colWidths[column], margin, "F");
            doc.text(textX, textY, column);
            xPos += colWidths[column];
          });

          yPos += margin;
        };

        const drawTableRows = () => {
          let fill = false; // Flag to alternate row colors
          res.forEach((row, key) => {
            if (yPos + margin > pageHeight - margin) {
              doc.addPage();
              yPos = margin;
              drawTableHeaders(margin);
            }

            let xPos = margin;
            const values = [
              key + 1,
              row.name,
              row.phone,
              row.state,
              row.state_per,
              row.balance,
            ];

            doc.setFont("helvetica", "normal");
            doc.setFontSize(10);

            let rowHeight = marginHight;

            values.forEach((value, index) => {
              let textToPrint = value.toString();
              const column = columns[index];
              const colWidth = colWidths[column];
              const splitText = doc.splitTextToSize(textToPrint, colWidth);
              const lineHeight =
                doc.internal.getLineHeight() / doc.internal.scaleFactor;
              const textHeight = splitText.length * lineHeight;

              if (textHeight + margin > rowHeight) {
                rowHeight = textHeight + margin;
              }
              if (fill) {
                doc.setFillColor(245, 245, 245);
              } else {
                doc.setFillColor(255, 255, 255);
              }

              doc.rect(xPos, yPos, colWidth, textHeight + margin, "F");
              doc.setTextColor(0);
              splitText.forEach((line, i) => {
                doc.text(
                  xPos + 2,
                  yPos + margin / 2 + lineHeight * (i + 1),
                  line
                );
              });

              xPos += colWidth;
            });

            fill = !fill;
            yPos += rowHeight;
          });
        };

        drawTableHeaders(margin);
        drawTableRows();

        doc.save(`User_Transactions.pdf`);
      }
    });
  };

  const handleDownloadExcel = (e) => {
    e.preventDefault();
    auctionService.DownloadAllBalanceList(source).then((res) => {
      if (Object.entries(res)?.length > 0) {
        const data = [];
        const columns = [
          "Sr. No.",
          "Name",
          "Phone",
          "State",
          "State permissions",
          "Balance",
        ];
        data.push(columns);
        res.forEach((row, key) => {
          const rowData = [
            key + 1,
            row.name,
            row.phone,
            row.state,
            row.state_per,
            row.balance,
          ];
          data.push(rowData);
        });
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "User Transactions");
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const dataBlob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(dataBlob);
        link.download = "User_Transactions.xlsx";
        link.click();
        URL.revokeObjectURL(link.href);
      }
    });
  };

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div
          className="container 
          table-responsive mt-2 d-flex justify-content-center text-align-center"
          style={{ minHeight: "5vh", maxHeight: "80vh" }}
        >
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <>
          <div className="container responsive mt-2">
            <div className="d-flex justify-content-between">
              <i
                className="bi bi-arrow-left-circle"
                onClick={() => history.push("/meratractor/wallet-home")}
                style={{
                  fontSize: "xx-large",
                  color: "black",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              ></i>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-12">
                <button
                  className="btn btn-primary me-2 mb-2"
                  onClick={handleDownloadPDF}
                >
                  Download PDF
                </button>
                <button
                  className="btn btn-primary ms-2 mb-2"
                  onClick={handleDownloadExcel}
                >
                  Download Excel
                </button>
              </div>
              <DropdownButton
                id="dropdown-basic-button"
                className="col-md-3 col-lg-3 col-12 ms-auto mb-2"
                title={selectedValue || "Select Sort Option"}
                onSelect={(e) => {
                  handleChangeShortOption(e);
                }}
              >
                {options.map((option) => (
                  <Dropdown.Item key={option.value} eventKey={option.value}>
                    {option.label}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <div
                className="col-md-3 col-lg-3 col-12 ms-auto"
                style={{ backgroundColor: "danger" }}
              >
                <SearchBox allData={data} setFilteredData={setFilterData} />
              </div>
            </div>
            <div className="col-md-4 col-12 mt-1">
              Showing {indexNo.first_index} - {indexNo.last_index} of{" "}
              <span style={{ fontWeight: "bold" }}>{arrayLength}</span>
            </div>
          </div>
          <div
            className="container  table-responsive mt-2 text-center"
            style={{ minHeight: "5vh", maxHeight: "100vh" }}
          >
            <table className="table table-hover table-bordered">
              <thead>
                <tr>
                  <th scope="col">Sr No.</th>
                  <th className="text-align-start ms-2">Name</th>
                  <th className="text-align-start">Mobile Number</th>
                  <th scope="col">State</th>
                  <th scope="col">State Permission</th>
                  <th scope="col">Total Balance</th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                Object.entries(filterData) &&
                Object.entries(filterData).length === 0 ? (
                  <div className="text-danger mt-2 "></div>
                ) : (
                  Object.entries(filterData) &&
                  Object.entries(filterData).map(([key, rowData], rowIndex) => (
                    <tr style={{ fontSize: "small" }}>
                      <td>{rowData?.sr_no}</td>
                      <td className="text-align-start ms-2">{rowData?.name}</td>
                      <td className="text-align-start">{rowData?.phone}</td>
                      <td>{rowData?.state}</td>
                      <td>{rowData?.state_per}</td>
                      <td>{rowData?.balance}</td>
                      {/* <td>{rowData?.status}</td> */}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            {data && data?.length === 0 && (
              <div className="text-danger text-center mt-2 ">No Data</div>
            )}

            {arrayLength > 10 && (
              <div className="d-flex justify-content-center">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    {currentPage > 0 && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          to="#"
                          onClick={() => paginate(1)}
                          style={{ cursor: "pointer" }}
                        >
                          First
                        </a>
                      </li>
                    )}
                    {currentPage > 0 && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          to="#"
                          onClick={() => paginate(currentPage - 1)}
                          style={{ cursor: "pointer" }}
                        >
                          {"<"}
                        </a>
                      </li>
                    )}

                    {Array.from({ length: Math.min(pageCount, 7) }, (_, i) => {
                      const pageNumber = currentPage - Math.floor(7 / 2) + i;
                      return (
                        pageNumber > 0 &&
                        pageNumber <= pageCount && (
                          <li
                            key={pageNumber}
                            className={`page-item ${
                              currentPage === pageNumber ? "active" : ""
                            }`}
                          >
                            <a
                              className="page-link"
                              to="#"
                              onClick={() => handlePageChange(pageNumber)}
                            >
                              {pageNumber}
                            </a>
                          </li>
                        )
                      );
                    })}

                    {currentPage < pageCount && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          to="#"
                          onClick={() => paginate(currentPage + 1)}
                        >
                          {">"}
                        </a>
                      </li>
                    )}

                    {currentPage < pageCount && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          to="#"
                          onClick={() => paginate(pageCount)}
                        >
                          Last
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default UserBalanceList;
