import React, { useEffect, useState } from "react";
import CustomTable from "./CustomTable";
import { viewUserHeader } from "./RapidbidHeader";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
import SearchBox from "../../../common/SearchBox";
import { errorToast } from "../../../react-toastfiy/toast";
import Swal from "sweetalert2";

const RapibidUserList = () => {
  let header = viewUserHeader;
  const [loading, setLoading] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [modal, setModal] = useState(false);
  let entry;
  let initialValue = {
    id: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    phone: "",
    state: "",
  };
  const [modalData, setModalData] = useState(initialValue);
  const [filteredUsersData, setFilteredUsersData] = useState([]);
  const data = [
    {
      no: "1",
      name: "Aashish PATEL",
      phone: 9131473433,
      state: "Madhya Pradesh",
    },
    {
      no: "2",
      name: "Aashish Bhosale",
      phone: 9767795855,
      state: "Madhya Pradesh",
    },
    {
      no: "3",
      name: "Abhijeet gavhane",
      phone: 8767228589,
      state: "Maharashtra",
    },
  ];

  useEffect(() => {
    try {
      setLoading(true);
      SubscriptionServices.GetAllUsers().then((res) => {
        if (Object.entries(res).length !== 0) {
          let all_users = Object.entries(res).map(([key, entry], index) => {
            entry["no"] = index + 1;
            entry[
              "name"
            ] = `${entry.first_name} ${entry.middle_name} ${entry.last_name}`;
            return entry;
          });
          if (all_users.length !== 0) {
            setUsersData(all_users);
            setFilteredUsersData(all_users);
            setLoading(false);
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      errorToast(error.message);
    }
  }, []);

  // const handleOpenModal = (e, item) => {
  //   setModal(true);
  //   setModalData(item);
  // };

  const handleOpenModal = (e, entry) => {
    const url = "/rapidbid/manage-user/add-user";
    window.open(url, "_blank");
  };
  const handleDelete = (e, item) => {
    e.preventDefault();
    const name = item.name;
    const id = item.id;
    console.log({name, id})
    let form_data = new FormData();
    form_data.append("id", id);
    const notice = `You want to delete  ${name} user  `;

    Swal.fire({
      title: "Are you sure?",
      html: notice,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await SubscriptionServices.DeleteUser(form_data);

          if (response.status === 200) {
            Swal.fire(
              "Deleted!",
              "Your data has been deleted.",
              "success"
            ).then((result) => {
              result && window.location.replace("/rapidbid/manage-user");
            });
          }
          response.status !== 200 && errorToast(response.msg);
        } catch (error) {
          console.log(error);
          errorToast(error.message);
        }
      },
    });
  };

  return (
    <>
      {/* {modal && (
        <RapibidAddUser
          show={modal}
          data={modalData}
          setstatedata={setModalData}
          // setModal={setModal}
          onHide={() => {
            setModal(false);
          }}
        />
      )} */}
      <div className=" container mt-3">
        <div className="d-flex justify-content-between">
          <h4>User List</h4>

          <button
            className="btn btn-primary float-end my-1"
            onClick={handleOpenModal}
          >
            <i className="bi bi-plus-lg me-1"></i>Add User
          </button>
        </div>

        <div className="col-lg-3 col-12 col-md-4 col-sm-12 ms-auto mb-2">
          <SearchBox
            allData={usersData}
            setFilteredData={setFilteredUsersData}
          />
        </div>

        <CustomTable
          loading={loading}
          columns={header}
          data={filteredUsersData}
          entry={entry}
          handleDelete={handleDelete}
          handleOpenModal={handleOpenModal}
          action={"Users_List"}
        />
      </div>
    </>
  );
};

export default RapibidUserList;
