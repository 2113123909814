import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactLoading from "react-loading";
import { useHistory, useLocation } from "react-router-dom";
import SearchBox from "../../common/SearchBox";
import { indianCurrencyFormater } from "../../helpers/functions/currencyFormater";
import { errorToast } from "../../react-toastfiy/toast";
import auctionService from "../../services/auction/auction.service";
import AddOfflineBidModal from "./AddOfflineBidModal";

const AuctionACR = () => {
  useEffect(() => {
    document.title = "Auction ACR";
  }, []);
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  const phone = user?.phone;
  const role = user?.role;
  const location = useLocation();
  const CurrentURL = location.pathname;
  console.log({ CurrentURL });

  const searchParams = new URLSearchParams(location.search);
  const auction_id = searchParams.get("uid");
  const history = useHistory();
  const headers = [
    { header: "Reference No", dataKey: "reference_no" },
    { header: "Make Model", dataKey: "make_model" },
    { header: "Bidder Name", dataKey: "bidder_name" },
    { header: "Bid", dataKey: "bid" },
    { header: "Date", dataKey: "date" },
    { header: "Year", dataKey: "mfg_yr" },
    { header: "Offline", dataKey: "is_offline" },
    { header: "Yard Name", dataKey: "yard_name" },
  ];
  const [auctionData, setAuctionData] = useState([]);
  const [filterAuctionData, setFilterAuctionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lockLoading, setLockLoading] = useState(false);
  const [unLockLoading, setUnLockLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [addOfflineBidLoading, setAddOfflineBidLoading] = useState(false);
  const [downloadLink, setDownloadLink] = useState("");
  const [isLocked, setIsLocked] = useState(false);
  const [isAdmin, setIsAdmin] = useState("");
  const [showFullPrice, setShowFullPrice] = useState("");
  const source = axios.CancelToken.source();

  useEffect(() => {
    try {
      setLoading(true);
      const form_data = new FormData();
      form_data.append("phone", user.phone);
      auctionService
        .GetAuctionAcr(auction_id, form_data, source)
        .then((res) => {
          if (res?.data?.length >= 0) {
            setLoading(false);
            setAuctionData(res.data);
            setFilterAuctionData(res.data);
            setDownloadLink(res.link);
            setIsLocked(res.is_locked);
            setIsAdmin(res.admin);
          } else {
            setLoading(false);
            errorToast(res?.msg);
            setAuctionData(res.data);
            setFilterAuctionData(res.data);
          }
        });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handlePopUP = (e, rowData) => {
    e.preventDefault();
    setModal(true);
    setModalData(rowData[1].reference_no);
  };

  const handleLockBid = (e) => {
    e.preventDefault();
    try {
      setLockLoading(true);
      const form_data = new FormData();
      form_data.append("uid", auction_id);
      auctionService.LockBid(form_data, source).then((res) => {
        setLockLoading(false);
        if (res?.status === 200) {
          setAddOfflineBidLoading(true);
          setDownloadLink(res.link);
          setIsLocked(true);
        } else {
          setAddOfflineBidLoading(false);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleUnLockBid = (e) => {
    e.preventDefault();
    try {
      setUnLockLoading(true);
      const form_data = new FormData();
      form_data.append("uid", auction_id);
      auctionService.UnLockBid(form_data, source).then((res) => {
        setUnLockLoading(false);
        if (res?.status === 200) {
          setAddOfflineBidLoading(true);
          setDownloadLink("");
          setIsLocked(false);
        } else {
          setAddOfflineBidLoading(false);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadLink = (e, data) => {
    e.preventDefault();
    window.location.href = data;
  };

  const handleRedirect = (e, index) => {
    let data = filterAuctionData[index];
    let ref_no = data?.reference_no;
    e.preventDefault();
    history.push(
      `/meratractor/auctions/modify/view/asset_summary?ref_no=${ref_no}&uid=${auction_id}&prevUrl=${"acr"}`
    );
  };

  const handleRetry = (e) => {
    try {
      setLoading(true);
      const form_data = new FormData();
      form_data.append("phone", user.phone);
      form_data.append("uid", auction_id);
      auctionService.RetryACRGeneration(form_data, source).then((res) => {
        if (res?.data?.length >= 0) {
          setLoading(false);
          setAuctionData(res.data);
          setFilterAuctionData(res.data);
          setDownloadLink(res.link);
        } else {
          setLoading(false);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleShowPrice = (e, reference_no) => {
    e.preventDefault();
    try {
      const form_data = new FormData();
      form_data.append("uid", auction_id);
      form_data.append("ref_no", reference_no);
      form_data.append("phone", phone);
      form_data.append("is_offline", true);
      auctionService.CheckBidAmount(form_data).then((res) => {
        if (res?.status === 200) {
          setAuctionData((prevData) =>
            Object.entries(prevData).map(([key, item], index) =>
              item.reference_no === reference_no
                ? { ...item, bid: res?.data?.bid }
                : item
            )
          );
          setFilterAuctionData((prevData) =>
            Object.entries(prevData).map(([key, item], index) =>
              item.reference_no === reference_no
                ? { ...item, bid: res?.data?.bid }
                : item
            )
          );
          setShowFullPrice(reference_no);
        } else if (res?.status === 400) {
          setAuctionData((prevData) =>
            Object.entries(prevData).map(([key, item], index) =>
              item.reference_no === reference_no ? { ...item, bid: 0 } : item
            )
          );
          setFilterAuctionData((prevData) =>
            Object.entries(prevData).map(([key, item], index) =>
              item.reference_no === reference_no ? { ...item, bid: 0 } : item
            )
          );
          setShowFullPrice(reference_no);
        } else {
          errorToast(res?.msg);
        }
      });
    } catch (error) {}
  };

  return (
    <>
      {modal && (
        <AddOfflineBidModal
          show={modal}
          setModal={setModal}
          ref_no={modalData}
          setAuctionData={setAuctionData}
          uid={auction_id}
          setFilterAuctionData={setFilterAuctionData}
          setDownloadLink={setDownloadLink}
          setIsLocked={setIsLocked}
          setIsAdmin={setIsAdmin}
          onHide={() => {
            setModal(false);
          }}
        />
      )}

      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <div
          className="d-flex justify-content-center container"
          style={{ minHeight: "80vh", alignItems: "center" }}
        >
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <>
          <div className="container  table-responsive  text-center">
            <div className="d-flex justify-content-between mt-1">
              <i
                className="bi bi-arrow-left-circle"
                onClick={() =>
                  history.push(`/meratractor/auctions/modify?uid=${auction_id}`)
                }
                style={{
                  fontSize: "xx-large",
                  color: "black",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              ></i>
            </div>

            <div className=" d-flex justify-content-between align-items-center flex-wrap mb-2 gap-2">
              <div className=" d-flex gap-2 align-items-center">
                {isLocked ? (
                  <OverlayTrigger
                    trigger={["hover"]}
                    overlay={<Tooltip>Please unlock auction to retry</Tooltip>}
                  >
                    <button className="btn btn-primary">Retry</button>
                  </OverlayTrigger>
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={(e) => {
                      if (!isLocked) {
                        handleRetry(e);
                      }
                    }}
                    disabled={isLocked === true}
                  >
                    Retry
                  </button>
                )}
                {!isLocked && downloadLink?.status !== 200 ? (
                  lockLoading ? (
                    <button
                      className="btn btn-primary "
                      type="button"
                      disabled
                      id="loadingBtnTp"
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary "
                      onClick={(e) => handleLockBid(e)}
                      disabled={
                        filterAuctionData && filterAuctionData.length === 0
                      }
                    >
                      Lock Bids
                    </button>
                  )
                ) : (
                  <>
                    <button
                      className="btn btn-primary"
                      onClick={(e) => handleDownloadLink(e, downloadLink)}
                    >
                      Download Link
                    </button>
                    {role !== "Auction Representative" &&
                      (unLockLoading ? (
                        <button
                          className="btn btn-primary "
                          type="button"
                          disabled
                          id="loadingBtnTp"
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading...
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={(e) => handleUnLockBid(e)}
                        >
                          Unlock Bid
                        </button>
                      ))}
                  </>
                )}
              </div>
              <div className="col-md-5 col-lg-3 col-12 ms-auto">
                <SearchBox
                  placeholder="Search..."
                  allData={auctionData}
                  setFilteredData={setFilterAuctionData}
                />
              </div>
            </div>
            <div
              className="table-responsive  text-center"
              style={{ minHeight: "5vh", maxHeight: "80vh" }}
            >
              <table className="table table-hover table-bordered ">
                <thead className="thead-dark">
                  <tr
                    className="text-center"
                    style={{
                      fontSize: "small",
                      position: "sticky",
                      top: 0,
                      zIndex: 4,
                    }}
                  >
                    <th> Sr. No</th>
                    {headers.map((column) => (
                      <th className="col" key={column.header}>
                        {column.header}
                      </th>
                    ))}
                    <th
                      style={{
                        fontSize: "small",
                        position: "sticky",
                        top: 0,
                        zIndex: 5,
                        right: 0,
                      }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {Object.entries(filterAuctionData) &&
                  Object.entries(filterAuctionData).length === 0 ? (
                    <div className="text-danger text-center "></div>
                  ) : (
                    Object.entries(filterAuctionData).map(
                      (rowData, rowIndex, value) => (
                        <tr
                          key={rowIndex}
                          style={{
                            fontSize: "small",
                          }}
                        >
                          <td>{rowIndex + 1}</td>
                          {headers.map((header, index) => (
                            <>
                              {header.dataKey === "reference_no" ? (
                                <td
                                  className="text-primary"
                                  onClick={(e) => {
                                    handleRedirect(e, rowIndex);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <b>{rowData[1][header?.dataKey]}</b>
                                </td>
                              ) : header.dataKey === "bid" ? (
                                <td
                                  onClick={(e) =>
                                    handleShowPrice(e, rowData[1]?.reference_no)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <b>
                                 
                                    {showFullPrice === rowData[1]?.reference_no
                                      ? indianCurrencyFormater(
                                          rowData[1][header.dataKey],
                                          0
                                        )
                                      : indianCurrencyFormater(
                                          Number(
                                            String(
                                              rowData[1][header.dataKey]
                                            ).slice(0, 0) + "0000"
                                          )
                                        ).replace(/0/g, "X")}
                                  </b>
                                </td>
                              ) : (
                                <td>{rowData[1][header?.dataKey]}</td>
                              )}
                            </>
                          ))}
                          <td
                            className="text-center"
                            style={{
                              fontSize: "small",
                              position: "sticky",
                              top: 0,
                              zIndex: 3,
                              right: 0,
                            }}
                          >
                            <button
                              disabled={isLocked === true}
                              className="btn btn-primary"
                              onClick={(e) => {
                                handlePopUP(e, rowData, rowIndex, value);
                              }}
                            >
                              Add Offline Bid
                            </button>
                          </td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </table>
              {filterAuctionData && filterAuctionData.length === 0 && (
                <div className="text-danger text-center  ">No data found</div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AuctionACR;
