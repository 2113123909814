import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../../react-toastfiy/toast";
import Select from "react-select";

const RapibidAddModel = (props) => {
  const { data, show, onHide, setmodeldata } = props;

  const [MakeData, setMakeData] = useState({ make_id: "" });
  const [makeList, setMakeList] = useState([]);
  const [selectLoading, setSelectLoading] = useState(false);
  const [UpdateButtonLoading, setUpdateButtonLoading] = useState(false);
  const [SubmitButtonLoading, setSubmitButtonLoading] = useState(false);

  useEffect(() => {
    try {
      setSelectLoading(true);
      SubscriptionServices.GetAllMake().then((res) => {
        if (Object.entries(res).length !== 0) {
          let make_data = Object.entries(res).map(([key, value], index) => ({
            label: value.name,
            value: value,
          }));
          setMakeList(make_data);
          if (data.make_name !== "") {
            let make_id = make_data.find(
              (option) => option.label === data.make_name
            );
            setmodeldata((prevState) => {
              return {
                ...prevState,
                ["make_id"]: make_id.value.id,
              };
            });
            setSelectLoading(false);
          } else {
            setmodeldata((prevState) => {
              return {
                ...prevState,
                ["make_id"]: make_data[0].value.id,
                ["make_name"]: make_data[0].label,
              };
            });
            setSelectLoading(false);
          }
        }
      });
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setSelectLoading(false);
    }
  }, []);

  const handleChangeMake = (e) => {
    const { id, name } = e.value;
    setmodeldata((prevState) => {
      return {
        ...prevState,
        ["make_name"]: name,
        ["make_id"]: id,
      };
    });
  };
  const handleChangeModel = (e) => {
    const { name, value } = e.target;

    setmodeldata((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = () => {
    setSubmitButtonLoading(true);
    let form_data = new FormData();
    form_data.append("make_id", data.make_id);
    form_data.append("model_name", data.model_name);
    SubscriptionServices.CreateModel(form_data).then((res) => {
      if (res.status === 200) {
        successToast(res.msg);
        setSubmitButtonLoading(false);
        setTimeout(() => {
          window.location.replace("/rapibid/auction/model");
        }, 500);
      } else {
        console.log(res.msg);
        setSubmitButtonLoading(false);
      }
    });
  };

  const handleUpdate = () => {
    setUpdateButtonLoading(true);
    let form_data = new FormData();
    form_data.append("make_id", data.make_id);
    form_data.append("model_name", data.model_name);
    form_data.append("id", data.id);
    SubscriptionServices.UpdateModel(form_data).then((res) => {
      if (res.status === 200) {
        successToast(res.msg);
        setUpdateButtonLoading(false);
        setTimeout(() => {
          window.location.replace("/rapibid/auction/model");
        }, 500);
      } else {
        console.log(res.msg);
        successToast(res.msg);
        setUpdateButtonLoading(false);
      }
    });
  };
  try {
    return (
      <Modal
        {...props}
        size="xs"
        backdrop="static"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <div
            id="example-custom-modal-styling-title"
            className="modal-title-custom"
          >
            {data && data.id !== "" ? (
              <h4>Update Model</h4>
            ) : (
              <h4>Add Model</h4>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-2">
            <div className="col-3">
              <label>Make :</label>
            </div>
            <div className="col-8">
              <Select
                isLoading={selectLoading}
                options={makeList}
                onChange={(e) => handleChangeMake(e, "make_name")}
                value={makeList.find(
                  (option) => option.value.name == data.make_name
                )}
                placeholder="Select Plan Type"
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-3">
              <label>Model :</label>
            </div>
            <div className="col-8">
              <input
                className="form-control"
                placeholder="Model"
                id="model_name"
                type="text"
                name="model_name"
                value={data?.model_name}
                onChange={(e) => handleChangeModel(e)}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
          {data && data?.id !== "" ? (
            <>
              {UpdateButtonLoading ? (
                <button
                  className="btn btn-primary mt-2"
                  type="button"
                  disabled
                  id="loadingBtnTp"
                >
                  <span
                    className="spinner-border spinner-border-sm mx-1 px-2"
                    type="submit"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <Button variant="primary" onClick={handleUpdate}>
                  Update
                </Button>
              )}
            </>
          ) : (
            <>
              {SubmitButtonLoading ? (
                <button
                  className="btn btn-primary mt-2"
                  type="button"
                  disabled
                  id="loadingBtnTp"
                >
                  <span
                    className="spinner-border spinner-border-sm mx-1 px-2"
                    type="submit"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <Button variant="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              )}
            </>
          )}
        </Modal.Footer>
      </Modal>
    );
  } catch (error) {
    console.log(error);
  }
};

export default RapibidAddModel;
