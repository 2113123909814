import React, { useEffect, useState } from "react";

import { DraftPlanData } from "./RapidbidHeader";
import SearchBox from "../../../common/SearchBox";
import CustomTable from "./CustomTable";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
import { errorToast, successToast } from "../../../react-toastfiy/toast";
import Swal from "sweetalert2";

const DraftPlan = () => {
  const header = DraftPlanData;

  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [FilterPlanData, setFilterPlanData] = useState([]);
  useEffect(() => {
    try {
      setLoading(true);
      let form_data = new FormData();
      form_data.append("draft", true);
      SubscriptionServices.GetSubscriptionPlanList(form_data).then((res) => {
        console.log({ res }, res.status);
        let draft_data = Object.entries(res).map(([key, entry], index) => {
          entry["no"] = index + 1;
          return entry;
        });
        console.log({ draft_data });
        if (draft_data.length !== 0) {
          setLoading(false);
          setData(draft_data);
          setFilterPlanData(draft_data);
        } else {
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
    }
  }, []);

  const handleDelete = (e) => {
    e.preventDefault();
    const id = e.target.id;
    let form_data = new FormData();
    form_data.append("plan_id", id);
    const notice = `You won't to delete this subscription plan  ${id}  `;
    Swal.fire({
      title: "Are you sure?",
      text: notice,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const ṛes = await SubscriptionServices.DeleteSubscriptionPlan(
            form_data
          );
          console.log(ṛes);
          if (ṛes.status === 200) {
            Swal.fire(
              "Deleted!",
              "Your data has been deleted.",
              "success"
            ).then((result) => {
              result &&
                window.location.replace(
                  "/rapidbid/manage-plan/view-draft_plans"
                );
            });
          }

          ṛes.status !== 200 && errorToast(ṛes.msg);
        } catch (error) {
          console.log(error);
          errorToast(error.message);
        }
      }
    });
  };

  return (
    <>
      <div className="container mt-3">
        <div className="col-md-12 col-12 col-lg-12 mb-2 ">
          <div className="d-flex flex-wrap justify-content-between align-items-end">
            <div className="col-lg-3 col-12 col-md-4 col-sm-12">
              <h4>Draft Plan List</h4>
            </div>
            <div className="col-lg-3 col-12 col-md-4 col-sm-12 ms-auto">
              <SearchBox allData={data} setFilteredData={setFilterPlanData} />
            </div>
          </div>
        </div>
        <CustomTable
          loading={Loading}
          columns={header}
          data={FilterPlanData}
          action={"DraftPlan"}
          handleDelete={handleDelete}
        />
      </div>
    </>
  );
};

export default DraftPlan;
