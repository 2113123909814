import axios from "axios";

class UserService {
  getAllUsers = (formdata) => {
    return axios({
      method: "post",
      url: `/users/get-all-users`,
      data: formdata,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  CreateUser = (formdata) => {
    return axios({
      method: "post",
      url: `/users/create-user`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  EditUser = (formdata) => {
    return axios({
      method: "post",
      url: `/users/edit-user`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  DeleteUser = (formdata) => {
    return axios({
      method: "post",
      url: `/users/delete-user`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getUserById = (formdata) => {
    return axios({
      method: "post",
      url: `/users/get-user-by-id`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getAllUserData = (formdata) => {
    return axios({
      method: "post",
      url: `/users/get-all-user-data`,
      data: formdata,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  changePassword = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/change-password`,
      data: JSON.stringify(formdata),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getAllRM = async () => {
    return axios({
      method: "post",
      url: `/users/get-all-rm`,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getUserHierarchy = () => {
    return axios({
      method: "post",
      url: `/users/get-users-hierarchy`,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getAccountingUsers = async () => {
    return axios({
      method: "post",
      url: `/users/get-accounting-users`,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getRtoUsers = async () => {
    return axios({
      method: "post",
      url: `/users/get-rto-users`,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getAuctionRepUsers = async () => {
    return axios({
      method: "post",
      url: `/users/get-auction-rep-users`,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getUserByDesignation = async (formdata) => {
    return axios({
      method: "post",
      data: formdata,
      url: `/users/get-users-by-designation`,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getUserByDesignationState = async (formdata) => {
    return axios({
      method: "post",
      data: formdata,
      url: `/users/get-user-by-designation-state`,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getUsersByDesignationState = async (formdata) => {
    return axios({
      method: "post",
      data: formdata,
      url: `/users/get-users-by-designation-state`,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetuserIdByPhone = async (formdata) => {
    return axios({
      method: "post",
      data: formdata,
      url: `/users/get-user-id-by-phone`,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  GetUserAttendanceStatus = async (formdata) => {
    return axios({
      method: "post",
      data: formdata,
      url: `/users/get-attendance-status`,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  MarkUserAttendance = async (formdata) => {
    return axios({
      method: "post",
      data: formdata,
      url: `/users/mark-attendance`,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  SaveAttendanceById = (formdata) => {
    return axios({
      method: "post",
      url: `/users/save-attendance-data`,
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetDesignationWiseAttendance = (formdata) => {
    return axios({
      method: "post",
      url: `/operations/get-designattion-wise-attendance`,
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetUserAttendanceStatusForTodos = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/get-user-attendance-status`,
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  UpdateUserAttendanceStatusForTodos = (formdata) => {
    return axios({
      method: "post",
      url: `/users/update-user-attendance-status`,
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetDesignationWiseAttendanceReportForExcel = (formdata) => {
    return axios({
      method: "post",
      url: `/operations/get-attendance-report-for-excel`,
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetAllowAttendanceMarkStatusUser = (formdata) => {
    return axios({
      method: "post",
      url: `/users/get-allow-attendance-mark-status-user`,
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetAllUsersForAllowAttendance = (formdata) => {
    return axios({
      method: "post",
      url: `/users/get-all-users-for-allow-attendance`,
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  AllowMarkAttendance = (formdata) => {
    return axios({
      method: "post",
      url: `/users/allow-mark-attendance`,
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetUserByUserAdded = (formdata) => {
    return axios({
      method: "post",
      url: `/users/get-user-by-user-added`,
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetIsUserNotApprovedList = (formdata) => {
    return axios({
      method: "post",
      url: `/users/get-is-user-not-approved-list`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formdata,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  UserApproval = (formdata) => {
    return axios({
      method: "post",
      url: `/users/user-approval`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formdata,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  ReApplyRejectedUser = (formdata) => {
    return axios({
      method: "post",
      url: `/users/re-apply-rejected-user`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formdata,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetIsUserNotApprovedCount = () => {
    return axios({
      method: "post",
      url: `/users/get-is-user-not-approved-count`,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  CreateUserInAuction = (formdata) => {
    return axios({
      method: "post",
      url: `/users/create-user-in-auction`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formdata,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  CreateUserInRapibid = (formdata) => {
    return axios({
      method: "post",
      url: `/users/create-user-in-rapidbid`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formdata,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  SignedRCAgreementAndChequeCollectedAction = (formdata) => {
    return axios({
      method: "post",
      url: `/users/signed-rc-agreement-and-cheque-collected-action`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formdata,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetUserExists = (formdata) => {
    return axios({
      method: "post",
      url: `/users/check-and-get-user-exists`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formdata,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  GetPlanList = (formdata) => {
    return axios({
      method: "post",
      url: `/subscriptions/get-all-published-plans-by-platform-list`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formdata,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
}

export default new UserService();
