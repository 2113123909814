import axios from "axios";

class FormService {
  GetAllForms = async () => {
    return axios({
      method: "post",
      url: `/operations/get-forms`,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetData = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-data`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetEditMilestoneData = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/get-edit-milestones-data`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  EditTaskData = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/edit-task-data`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  EditTaskDataTrackPayment = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/edit-task-data-track-payment`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  DeleteTaskData = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/delete-task-data`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  AddTaskData = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/add-task-data`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  AddBLTaskData = async (formData) => {
    return axios({
      method: "post",
      url: `/operations/add-bl-task-data`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
}

export default new FormService();
