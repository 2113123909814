import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RapibidAuctionLive from "./RapibidAuctionLive";
import RapibidAuctionUpcomimg from "./RapibidAuctionUpcomimg";
import RapibidAuctionCompleted from "./RapibidAuctionCompleted";

const RapibidAuctionTabbar = () => {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  const [active, setactive] = useState("auction");

  useEffect(() => {
    document.title = "Dashboard";
    let active_tab = localStorage.getItem("rapidbid_active_tab");
    {
      !!active_tab && setactive(active_tab);
    }
  }, []);
  const handlechangetab = (e) => {
    const id = e.target.getAttribute("id");
    setactive(id);
    localStorage.setItem("rapidbid_active_tab", id);
  };

  try {
    return (
      <>
        <div className="container mt-3">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <Link
                className={active === "live" ? `nav-link active` : `nav-link`}
                to="#"
                id="live"
                onClick={handlechangetab}
              >
                Live
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={
                  active === "upcomimg" ? `nav-link active` : `nav-link`
                }
                to="#"
                id="upcomimg"
                onClick={handlechangetab}
              >
                Upcomimg
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={
                  active === "completed" ? `nav-link active` : `nav-link`
                }
                to="#"
                id="completed"
                onClick={handlechangetab}
              >
                Completed
              </Link>
            </li>
          </ul>
        </div>
        {active === "live" && (
          <>
            <RapibidAuctionLive />
          </>
        )}

        {active === "upcomimg" && (
          <>
            <RapibidAuctionUpcomimg />
          </>
        )}
        {active === "completed" && (
          <>
            <RapibidAuctionCompleted />
          </>
        )}
      </>
    );
  } catch (error) {}
};

export default RapibidAuctionTabbar;
