import React, { useEffect, useState } from "react";
import AddAuction from "../../../img/add-auction.svg";
import SearchAsset from "../../../img/search_asset.svg";
import ViewAuction from "../../../img/view-auction.svg";

import CardBlock from "../../../common/CardBlock";
import ErrorHandler from "../../../common/ErrorHandler";
import { Link, useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";

function ARAuctionHome(props) {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwtDecode(currentUser);
  const history = useHistory();
  const [url, setUrl] = useState(null);
  useEffect(() => {
    document.title = "Home";
  }, []);

  useEffect(() => {
    if (user.role === "Auction Back Office") {
      setUrl(
        `/auction-back-office-dashboard:${user.phone}&state=${user.state}`
      );
    } else if (user.role === "Auction Super Admin") {
      setUrl(`/asaDashboard:${user.phone}&state=${user.state}`);
    } else if (user.role === "Auction Representative") {
      setUrl(`/auction_representative:${user?.phone}&state=${user?.state}`);
    } else {
      setUrl(`/meratractor`);
    }
  }, []);

  try {
    return (
      <>
        <div className="container-fluid shadow-sm">
          <div className="container">
            <div className="row">
              <div className="d-sm-block d-md-none d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                  </div>
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-block d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12 col-md-6 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-6 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-none d-lg-block d-xl-block">
                <div className="row">
                  <div className="col-12 col-md-8 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-4 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-3">
          <div className="d-flex justify-content-between">
            <i
              className="bi bi-arrow-left-circle"
              onClick={() => history.push(url)}
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </div>
        </div>

        <div className="container container-body">
          <CardBlock
            name="Add Auction"
            logo={AddAuction}
            to="/meratractor/auction/add-auction"
          />
          <CardBlock
            name="View Auction"
            logo={ViewAuction}
            to="/auction_representative/auction_home/auction"
          />
          <CardBlock
            name="Search Asset Images"
            logo={SearchAsset}
            to={"/meratractor/auction/search-asset-images"}
          />
        </div>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
}

export default ARAuctionHome;
