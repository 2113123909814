import React, { useEffect, useState } from "react";
import { ModelHeader } from "./RapidbidHeader";
import { Link, useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import RapibidAddModel from "./RapibidAddModel";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
import { errorToast } from "../../../react-toastfiy/toast";
import SearchBox from "../../../common/SearchBox";
import Swal from "sweetalert2";

const Model = () => {
  let header = ModelHeader;
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const initialValue = {
    make_id: "",
    id: "",
    make_name: "",
    model_name: "",
  };

  const [createModelData, setCreateModelData] = useState(initialValue);
  const [modalData, setModalData] = useState([]);
  const [filterAllModel, setFilterAllModel] = useState([]);

  const [MakeData, setMakeData] = useState({ make_id: "" });
  const [makeList, setMakeList] = useState([]);
  const [selectLoading, setSelectLoading] = useState(false);

  useEffect(() => {
    try {
      setLoading(true);
      SubscriptionServices.GetAllModel().then((res) => {
        if (Object.entries(res).length !== 0) {
          let all_make = Object.entries(res).map(([key, entry], index) => {
            entry["no"] = index + 1;
            return entry;
          });
          if (all_make.length !== 0) {
            setLoading(false);
            setModalData(all_make);
            setFilterAllModel(all_make);
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setLoading(false);
    }
  }, []);

  const handleOpenModal = (e, item) => {
    if (item) {
      setModal(true);
      setCreateModelData((prevState) => {
        return {
          ...prevState,
          ["id"]: item.id,
          ["make_name"]: item.make_name,
          ["model_name"]: item.model_name,
        };
      });
    } else {
      setModal(true);
      setCreateModelData((prevState) => {
        return {
          ...prevState,
          ["make_id"]: "",
          ["make_name"]: "",
          ["model_name"]: "",
        };
      });
    }
  };

  // const handleDelete = (e, item) => {
  //   e.preventDefault();
  //   const name = item.model_name;
  //   const id = item.id;
  //   let form_data = new FormData();
  //   form_data.append("id", id);
  //   const notice = `You want to delete  ${name}  `;
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: notice,
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       try {
  //         const ṛes = await SubscriptionServices.DeleteModel(form_data);

  //         if (ṛes.status === 200) {
  //           Swal.fire(
  //             "Deleted!",
  //             "Your data has been deleted.",
  //             "success"
  //           ).then((result) => {
  //             result && window.location.replace("/rapibid/auction/model");
  //           });
  //         }

  //         ṛes.status !== 200 && errorToast(ṛes.msg);
  //       } catch (error) {
  //         console.log(error);
  //         errorToast(error.message);
  //       }
  //     }
  //   });
  // };

  const handleDelete = (e, item) => {
    e.preventDefault();
    const name = item.model_name;
    const id = item.id;
    let form_data = new FormData();
    form_data.append("id", id);
    const notice = `You want to delete  ${name}  `;

    Swal.fire({
      title: "Are you sure?",
      html: notice,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await SubscriptionServices.DeleteModel(form_data);

          if (response.status === 200) {
            Swal.fire(
              "Deleted!",
              "Your data has been deleted.",
              "success"
            ).then((result) => {
              result && window.location.replace("/rapibid/auction/model");
            });
          }
          response.status !== 200 && errorToast(response.msg);
        } catch (error) {
          console.log(error);
          errorToast(error.message);
        }
      },
    });
  };

  return (
    <>
      {modal && (
        <RapibidAddModel
          show={modal}
          data={createModelData}
          MakeData={MakeData}
          makeList={makeList}
          setmodeldata={setCreateModelData}
          onHide={() => {
            setModal(false);
          }}
        />
      )}
      <div className=" container mt-5 my-3 ">
        <div className="d-flex justify-content-between">
          <h4>Manage Models</h4>

          <button
            className="btn btn-primary float-end my-1"
            onClick={handleOpenModal}
          >
            <i className="bi bi-plus-lg me-1"></i>Add Make
          </button>
        </div>
        <div className="col-lg-3 col-12 col-md-4 col-sm-12 ms-auto mb-2">
          <SearchBox allData={modalData} setFilteredData={setFilterAllModel} />
        </div>

        <div
          className="table-responsive"
          style={{ minHeight: "5vh", maxHeight: "70vh" }}
        >
          {loading ? (
            <div className="d-flex justify-content-center">
              <ReactLoading
                type={"bubbles"}
                color={"black"}
                height={100}
                width={100}
              />
            </div>
          ) : (
            <>
              <table className="table table-hover table-bordered">
                <>
                  <thead className="thead-dark">
                    <tr
                      // className="text-center"
                      style={{
                        fontSize: "small",
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      <th>No</th>
                      {!loading &&
                        header &&
                        header.length !== 0 &&
                        header.map((column) => (
                          <th key={column.dataKey}>{column.header}</th>
                        ))}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading &&
                      Object.entries(filterAllModel) &&
                      Object.entries(filterAllModel).length !== 0 &&
                      Object.entries(filterAllModel)?.map(
                        ([key, item], index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.make_name}</td>
                            <td className="col-7">{item.model_name}</td>
                            <td className="col-2">
                              <Link to="#" className="viewEditLink">
                                <i
                                  className="bi bi-pencil-square mx-3"
                                  // id={item.id}
                                  onClick={(e) => handleOpenModal(e, item)}
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </Link>
                              <i
                                className="bi bi-trash mx-3"
                                id={item.id}
                                onClick={(e) => {
                                  handleDelete(e, item);
                                }}
                                style={{ cursor: "pointer" }}
                              ></i>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </>
              </table>
              {!loading &&
                Object.entries(filterAllModel) !== undefined &&
                Object.entries(filterAllModel).length === 0 && (
                  <div className="text-danger text-center mt-2 ">No Data</div>
                )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Model;
