import React, { useEffect } from "react";
import CardBlock from "../../../common/CardBlock";
import AddAuction from "../../../img/addaction.svg";
import ViewAuction from "../../../img/viewAuction.svg";
import Users from "../../../img/users.svg";
import Make from "../../../img/make.svg";
import Model from "../../../img/model.svg";
import State from "../../../img/model.svg";

const RapibidAuctionHome = () => {

    useEffect(() => {
        document.title = "rapibid Auction Home";
      }, []);

  return (
   <>
    <div className="container mt-2">
        <h1>Rapibid Auction Home</h1>
        <hr />
      </div>
      <div className="container container-body">
      <CardBlock
          name="Add Auction"
          logo={AddAuction}
          to="/rapidbid/manage-auction/manage-add-auction"
        />
        <CardBlock
          name="View Auction"
          logo={ViewAuction}
          to="/rapidbid/manage-auction/manage-view-auction"
        />
        
       
      </div>
   </>
  )
}

export default RapibidAuctionHome
