import React, { useEffect } from "react";
import CardBlock from "../../../common/CardBlock";
// import category from "../../../img/category.svg";
// import inventory from "../../../img/inventory.svg";
// import so_manage from "../../img/so_manage.svg";
// import mfg from "../../../img/mfg.svg";
// import list from "../../../img/list_tp.jpeg";
// import spares from "../../../img/tools-line.svg";

function SubscriptionHomePage() {
  useEffect(() => {
    document.title = "rapibid Home";
  }, []);
  return (
    <>
      <div className="container mt-2">
        <h1>Manage Subscription</h1>
        <hr />
      </div>
      <div className="container container-body">
        <CardBlock
          name="Plans"
          // logo={category}
          to="/rapidbid/manage-plan"
        />
        <CardBlock
          name="Active Expired User Plans"
          //  logo={spares}
          to="/rapidbid/manage-users-analysis"
        /> 
        
        <CardBlock
          name="Refund Approval Pending"
          // logo={mfg}
          to="/rapidbid/manage-refund-approval-pending"
        />
        <CardBlock
          name="User Transaction History"
          // logo={mfg}
          to="/rapidbid/manage-user-transaction-history"
        />
      </div>
    </>
  );
}

export default SubscriptionHomePage;
