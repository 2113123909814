export const DraftPlanData = [
  {
    header: "Sr. No.",
    dataKey: "no",
  },
  {
    header: "Plan Name",
    dataKey: "plan_name",
  },
  {
    header: "Plan Id",
    dataKey: "plan_id",
  },
  {
    header: "Platform",
    dataKey: "platform_type",
  },
  {
    header: "Plan Type",
    dataKey: "plan_type",
  },
  {
    header: "Plan Category",
    dataKey: "plan_category",
  },
];

export const PublishedPlanData = [
  {
    header: "Sr. No.",
    dataKey: "no",
  },
  {
    header: "Plan Name",
    dataKey: "plan_name",
  },
  {
    header: "Plan Id",
    dataKey: "plan_id",
  },
  {
    header: "Platform",
    dataKey: "platform_type",
  },
  {
    header: "Plan Type",
    dataKey: "plan_type",
  },
  {
    header: "Plan Category",
    dataKey: "plan_category",
  },
];

export const ActiveSubUserHeader = [
  {
    header: "Sr. No",
    dataKey: "no",
  },

  {
    header: "Plan Name",
    dataKey: "plan_name",
  },
  {
    header: "Plan Type",
    dataKey: "plan_type",
  },

  {
    header: "Plan Category",
    dataKey: "plan_category",
  },
  {
    header: "Plan Start Date",
    dataKey: "start_date",
  },
  {
    header: "Plan End Date",
    dataKey: "end_date",
  },
];

export const viewUserHeader = [
  { header: "Sr. No.", dataKey: "no" },
  { header: "Name", dataKey: "name" },
  { header: "Phone", dataKey: "phone" },
  { header: "State", dataKey: "state" },
];

export const MakeHeader = [
  { header: "Make", dataKey: "make" },
  { header: "Logo", dataKey: "logo" },
];
export const ModelHeader = [
  { header: "Make", dataKey: "make" },
  { header: "Model", dataKey: "model" },
];
export const stateHeader = [
  { header: "Sr. No.", dataKey: "no" },
  { header: "State", dataKey: "state_name" },
  { header: "Shorthand", dataKey: "shorthand" },
];
export const AuctionSummaryHeader = [
  {
    header: "No",
    dataKey: "no",
  },
  {
    header: "Bidder Name",
    dataKey: "bidder_name",
  },
  {
    header: "Bidder Phone",
    dataKey: "bidder_phone",
  },
  {
    header: "Pan",
    dataKey: "pan",
  },
  {
    header: "Bid",
    dataKey: "bid",
  },
  {
    header: "Date",
    dataKey: "date",
  },
];

export const TenorHeader = [
  {
    header: "No",
    dataKey: "no",
  },
  {
    header: "Duration",
    dataKey: "duration",
  },
  {
    header: "Platform Type",
    dataKey: "platform_type",
  },
];


