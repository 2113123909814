function TableLoading({ rows = 0, cols = 0 }) {
  return Array(rows)
    .fill(0)
    .map((v, i) => {
      return (
        <tr key={`${i}_table_loading_row`} style={{ cursor: "wait" }}>
          {Array(cols)
            .fill(0)
            .map((v, j) => {
              return (
                <td key={`${j}_table_loading_column`}>
                  <p className="placeholder-glow mb-0">
                    <span className="placeholder col-12"></span>
                  </p>
                </td>
              );
            })}
        </tr>
      );
    });
}

export default TableLoading;
