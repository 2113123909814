import jwt from "jwt-decode";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { logout } from "../helpers/functions/logout";

function YardOwnerNavbar() {
  const [routeTo, setrouteTo] = useState("/yard-owner/yard-owner-home");
  const token = localStorage.getItem("refreshToken");
  const user = jwt(token);
  const navHamburgerBtn = useRef(null);

  return (
    <>
      <ToastContainer />

      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to={routeTo}>
            Meratractor ERP
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            ref={navHamburgerBtn}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to={routeTo}
                  onClick={() => {
                    window.innerWidth < 992 && navHamburgerBtn.current.click();
                  }}
                >
                  Home
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={`/yard-owner/dashboard`}
                  onClick={() => {
                    window.innerWidth < 992 && navHamburgerBtn.current.click();
                  }}
                >
                  Dashboard
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={`/yard-owner/stock-inward`}
                  onClick={() => {
                    window.innerWidth < 992 && navHamburgerBtn.current.click();
                  }}
                >
                  Stock Inward
                </Link>
              </li>
            </ul>

            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={`/select-designation`}
                  onClick={() => {
                    window.innerWidth < 992 && navHamburgerBtn.current.click();
                  }}
                >
                  Roles
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={`/Profile/${user.phone}`}
                  onClick={() => {
                    window.innerWidth < 992 && navHamburgerBtn.current.click();
                  }}
                >
                  Profile
                </Link>
              </li>

              <li className="nav-item">
                <button className="nav-link border-0 bg-light" onClick={logout}>
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default YardOwnerNavbar;
