import axios from "axios";
import jsPDF from "jspdf";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Link, useHistory } from "react-router-dom";
import DatePicker from "../../../../common/DatePicker";
import SearchBox from "../../../../common/SearchBox";
import { formatDate } from "../../../../helpers/functions/dateFormatter";
import { errorToast } from "../../../../react-toastfiy/toast";
import auctionService from "../../../../services/auction/auction.service";

const ViewAllTransactions = () => {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwtDecode(currentUser);
  const history = useHistory();
  const source = axios.CancelToken.source();
  const headers = [
    { header: "Sr. No", dataKey: "sr_no" },
    { header: "Date", dataKey: "date" },
    { header: "Name", dataKey: "user_name" },
    { header: "Phone", dataKey: "user_phone" },
    { header: "Description", dataKey: "description" },
    { header: "Transaction Type", dataKey: "transaction_type" },
    { header: "Amount", dataKey: "amount" },
    { header: "	Status", dataKey: "status" },
  ];
  const [indexNo, setIndexNo] = useState({ first_index: "", last_index: "" });
  const [initialRender, setInitialRender] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [arrayLength, setArrayLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 580);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 580);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const singleuploadbreadcrumb = {
    marginLeft: isSmallScreen ? "0px" : "auto",
    marginBottom: isSmallScreen ? "12px" : "",
  };

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const date = currentDate.getDate();

    let firstDayOfMonth = formatDate(new Date(year, month - 1, date + 1));
    let lastDayOfMonth = formatDate(new Date(year, month, date));

    setStartDate(firstDayOfMonth);
    setEndDate(lastDayOfMonth);
  }, []);

  const pageCount = Math.ceil(arrayLength / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, arrayLength);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (initialRender) {
      fetchData();
    } else if (!initialRender) {
      handleSubmit();
    }
  }, [currentPage]);

  const handleSubmit = (e) => {
    try {
      setButtonLoading(true);
      setInitialRender(false);
      const form_data = new FormData();
      form_data.append("pagination", true);
      form_data.append("pg_id", currentPage);
      form_data.append("sdate", startDate);
      form_data.append("edate", endDate);
      auctionService.GetAllTransactionbyDate(form_data, source).then((res) => {
        setButtonLoading(false);
        if (
          !res?.msg &&
          Object.values(res).length &&
          Object.values(res).length > 0
        ) {
          setData(res);
          setFilterData(res);
          const lastIndex = Object.values(res).length - 1;
          setIndexNo({
            ["first_index"]: Object.values(res)[0]?.sr_no,
            ["last_index"]: Object.values(res)[lastIndex]?.sr_no,
          });
          const lenTrans = Object.values(res)[0].len_trans;
          setArrayLength(lenTrans);
        } else {
          errorToast(res?.msg);
          setData([]);
          setFilterData([]);
          setArrayLength(0);
        }
      });
    } catch (error) {
      errorToast(error.msg);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setInitialRender(true);
      const form_data = new FormData();
      form_data.append("pagination", true);
      form_data.append("pg_id", currentPage);
      const res = await auctionService.GetAllTransaction(form_data, source);
      setLoading(false);
      if (res && Object.values(res).length > 0) {
        setData(res);
        setFilterData(res);
        setIndexNo({
          ["first_index"]: Object.values(res)[0]?.sr_no,
          ["last_index"]: Object.values(res)[9]?.sr_no,
        });
        const lenTrans = Object.values(res)[0].len_trans;

        setArrayLength(lenTrans);
      }
    } catch (error) {
      errorToast(error.msg);
    }
  };

  const generatePDF = (e, userData) => {
    e.preventDefault();
    const form_data = new FormData();
    form_data.append("edate", endDate);
    form_data.append("sdate", startDate);
    auctionService.DownloadViewAllTransaction(form_data, source).then((res) => {
      if (res && Object.values(res).length > 0) {
        let userPhone = data.phone;
        let userName = data.name;

        const doc = new jsPDF({
          orientation: "landscape",
        });
        doc.setFontSize(12);
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const margin = 15;
        let yPos = margin;
        const columns = [
          "Sr. No.",
          "Date",
          "Name",
          "Phone",
          "Description",
          "Transaction Type",
          "Amount",
          "Status",
        ];
        const colWidths = {
          "Sr. No.": 15,
          Date: 20,
          Name: 25,
          Phone: 30,
          Description: 80,
          "Transaction Type": 30,
          Amount: 35,
          Status: 35,
        };
        const drawTableHeaders = (xPos) => {
          doc.setFont("helvetica", "bold");
          doc.setFontSize(10);
          doc.setFillColor(40, 167, 69);
          doc.setTextColor(255);
          columns.forEach((column) => {
            const colWidth = colWidths[column];
            doc.setLineWidth(0.1);
            doc.setDrawColor(0);
            doc.setFillColor(40, 167, 69);
            const textWidth =
              (doc.getStringUnitWidth(column) * doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            const textX = xPos + (colWidth - textWidth) / 2;
            const textY = yPos + margin / 2 + 3;
            doc.rect(xPos, yPos, colWidth, margin, "F");
            doc.text(textX, textY, column);

            xPos += colWidth;
          });

          yPos += margin;
        };
        const drawTableRows = () => {
          let fill = false;
          const rowHeightBase = margin + 10;
          const columnSpacing = 3;
          Object.entries(res).forEach(([_, row], key) => {
            const values = [
              key + 1,
              row?.date,
              row?.user_name,
              row?.user_phone,
              row?.description,
              row?.transaction_type,
              row?.amount,
              row?.status,
            ];

            let xPos = margin;

            let maxRowHeight = rowHeightBase;
            values.forEach((value, index) => {
              const column = columns[index];
              const colWidth = colWidths[column];
              const textToPrint = value?.toString() || "";

              const splitText = doc.splitTextToSize(textToPrint, colWidth);
              const lineHeight =
                doc.internal.getLineHeight() / doc.internal.scaleFactor;
              const textHeight = splitText.length * lineHeight;

              maxRowHeight = Math.max(maxRowHeight, textHeight + rowHeightBase);
            });

            if (yPos + maxRowHeight > pageHeight - margin) {
              doc.addPage();
              yPos = margin;
              drawTableHeaders(margin);
            }

            values.forEach((value, index) => {
              const column = columns[index];
              const colWidth = colWidths[column];
              const textToPrint = value?.toString() || "";

              let additionalSpacing = 0;
              if (column === "Date" || column === "Name") {
                additionalSpacing = columnSpacing;
              } else if (column === "Description") {
                additionalSpacing = columnSpacing + 2;
              }
              doc.setFont("helvetica", "normal");
              doc.setFontSize(8);
              const splitText = doc.splitTextToSize(textToPrint, colWidth);
              const lineHeight =
                doc.internal.getLineHeight() / doc.internal.scaleFactor;
              const textHeight = splitText.length * lineHeight;

              doc.setFillColor(fill ? 245 : 255);
              doc.rect(
                xPos,
                yPos,
                colWidth + additionalSpacing,
                maxRowHeight,
                "F"
              );

              const textY =
                yPos + (maxRowHeight - textHeight) / 2 + lineHeight / 2;

              doc.setTextColor(0);
              doc.text(xPos + 2, textY, splitText);

              xPos += colWidth + additionalSpacing;
            });

            fill = !fill;
            yPos += maxRowHeight;
          });
        };
        drawTableHeaders(margin);
        drawTableRows();
        doc.save(
          `User_Transactions_${userName}_${userPhone}_${startDate}_${endDate}.pdf`
        );
      } else {
        errorToast(res?.msg);
      }
    });
  };

  const generateExcel = (e, data) => {
    e.preventDefault();
    const form_data = new FormData();
    form_data.append("edate", endDate);
    form_data.append("sdate", startDate);
    auctionService.DownloadViewAllTransaction(form_data, source).then((res) => {
      if (res && Object.entries(res).length > 0) {
        let csvContent = "data:text/csv;charset=utf-8,";

        const columns = [
          "Sr. No.",
          "Date",
          "Name",
          "Phone",
          "Description",
          "Transaction Type",
          "Amount",
          "Balance",
          "Status",
        ];

        csvContent += columns.join(",") + "\n";

        Object.entries(res).forEach(([key, row], index) => {
          const values = [
            index + 1,
            row.date,
            row.user_name,
            row.user_phone,
            row.description,
            row.transaction_type,
            row.amount,
            row.balance,
            row.status,
          ];

          const adjustedValues = values.map((value) => {
            return `"${value.toString().replace(/"/g, '""')}"`;
          });
          csvContent += adjustedValues.join(",") + "\n";
        });
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "View_User_Transactions.csv");
        document.body.appendChild(link);
        link.click();
      } else {
        errorToast(res?.msg);
      }
    });
  };

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container responsive mt-2 ms-auto ">
        <div className="row mt-3 mb-2">
          <span className="d-flex justify-content-between col-1">
            <i
              className="bi bi-arrow-left-circle "
              onClick={() => history.push("/meratractor/wallet/multi-user-txn")}
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </span>
          <div className="col-10 mt-2 float-start ms-2">
            <h4 className=""> View All Transaction</h4>
          </div>
        </div>
      </div>

      <form className="was-validated container">
        <div className="row d-flex d-flex-wrap justify-content-center mt-3">
          <div
            className={
              isSmallScreen
                ? "col-6 mb-3 mt-2"
                : "col-4 col-md-3 col-lg-2 col-xl-2 mb-3"
            }
          >
            <DatePicker
              date={startDate}
              id="start_date"
              name="start_date"
              placeholder="Start Date"
              setDate={(e) => {
                setStartDate(e);
              }}
              label="Start Date"
              isRequired={false}
            />
          </div>
          <div
            className={
              isSmallScreen
                ? "col-6 mb-3 mt-2"
                : "col-4 col-md-3 col-lg-2 col-xl-2 mb-3"
            }
          >
            <DatePicker
              date={endDate}
              id="end_date"
              name="end_date"
              placeholder="End Date"
              setDate={(e) => {
                setEndDate(e);
              }}
              label="End Date"
              isRequired={false}
            />
          </div>
          <div className={isSmallScreen ? "col-5 mt-2" : "col-3 mt-4"}>
            {buttonLoading ? (
              <button
                className={
                  isSmallScreen
                    ? "btn btn-primary col-10"
                    : "btn btn-primary col-12 col-md-10 col-lg-8 col-xl-8"
                }
                type="button"
                disabled
                id="loadingBtnTp"
              >
                <span
                  className="spinner-border spinner-border-sm me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            ) : (
              <button
                className={
                  isSmallScreen
                    ? "btn btn-primary col-10"
                    : "btn btn-primary col-12 col-md-10 col-lg-8 col-xl-8"
                }
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
              >
                Submit
              </button>
            )}
          </div>
          <hr className="soften mt-2" />
        </div>
      </form>

      {buttonLoading || loading ? (
        <div
          className="d-flex justify-content-center container"
          style={{ minHeight: "80vh", alignItems: "center" }}
        >
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <>
          <div className="container">
            <div className="row ">
              <div
                className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-2"
                style={{ marginLeft: "2px", marginBottom: "13px" }}
              >
                Showing {indexNo.first_index} - {indexNo.last_index} of
                <span style={{ fontWeight: "bold", marginLeft: "2px" }}>
                  {arrayLength}
                </span>
              </div>
              <div
                className="col-md-4 col-lg-3 col-12"
                style={singleuploadbreadcrumb}
              >
                <SearchBox allData={data} setFilteredData={setFilterData} />
              </div>
            </div>
            <div
              className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
              style={{ marginLeft: "2px", marginBottom: "2px" }}
            >
              Download :
              <span>
                <Link
                  className="ms-4 me-3"
                  onClick={(e) => {
                    generatePDF(e, filterData);
                  }}
                >
                  PDF
                </Link>
                |
                <Link
                  className="ms-3"
                  onClick={(e) => {
                    generateExcel(e, filterData);
                  }}
                >
                  Excel
                </Link>
              </span>
            </div>
          </div>
          <div
            className="container  table-responsive mt-2 text-center"
            style={{ minHeight: "5vh", maxHeight: "80vh" }}
          >
            <table className="table table-hover table-bordered">
              <thead className="thead-dark">
                <tr
                  className="text-center"
                  style={{ fontSize: "small", position: "sticky", top: 0 }}
                >
                  {headers?.map((column) => (
                    <th className="col" key={column.header}>
                      {column.header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.entries(filterData) &&
                Object.entries(filterData).length === 0 ? (
                  <div className="text-danger mt-2 "></div>
                ) : (
                  Object.entries(filterData).map(([key, rowData], rowIndex) => (
                    <tr style={{ fontSize: "small" }}>
                      <td className="text-align-start">{rowData?.sr_no}</td>
                      <td className="text-align-start">{rowData?.date}</td>
                      <td className="text-align-start">{rowData?.user_name}</td>
                      <td className="text-align-start">
                        {rowData?.user_phone}
                      </td>
                      <td className="text-align-start">
                        {rowData?.description}
                      </td>
                      <td className="text-align-start">
                        {rowData?.transaction_type}
                      </td>
                      <td
                        style={{
                          color:
                            rowData.transaction_type === "D"
                              ? "red"
                              : rowData.transaction_type === "C"
                              ? "green"
                              : "black",
                        }}
                      >
                        {rowData.transaction_type === "C"
                          ? rowData?.amount
                          : `-${rowData?.amount}`}
                      </td>

                      <td>{rowData?.status}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            {data && data?.length === 0 && (
              <div className="text-danger text-center mt-2 ">No Data Found</div>
            )}

            {arrayLength > 10 && (
              <div className="d-flex justify-content-center">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    {currentPage > 1 && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="#"
                          onClick={() => paginate(1)}
                        >
                          First
                        </a>
                      </li>
                    )}
                    {currentPage > 1 && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="#"
                          onClick={() => paginate(currentPage - 1)}
                        >
                          {"<"}
                        </a>
                      </li>
                    )}

                    {Array.from({ length: Math.min(pageCount, 7) }, (_, i) => {
                      const pageNumber = currentPage - Math.floor(7 / 2) + i;
                      return (
                        pageNumber > 0 &&
                        pageNumber <= pageCount && (
                          <li
                            key={pageNumber}
                            className={`page-item ${
                              currentPage === pageNumber ? "active" : ""
                            }`}
                          >
                            <a
                              className="page-link"
                              href="#"
                              onClick={() => handlePageChange(pageNumber)}
                            >
                              {pageNumber}
                            </a>
                          </li>
                        )
                      );
                    })}

                    {currentPage < pageCount && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="#"
                          onClick={() => paginate(currentPage + 1)}
                        >
                          {">"}
                        </a>
                      </li>
                    )}

                    {currentPage < pageCount && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="#"
                          onClick={() => paginate(pageCount)} // Directly jump to the last page
                        >
                          Last
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ViewAllTransactions;
