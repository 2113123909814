import React, { useEffect, useState } from "react";
import ErrorHandler from "../../../common/ErrorHandler";
import ARCompletedAuction from "./ARCompletedAuction";
import ARLiveAuction from "./ARLiveAuction";
import ARUpcomimgAuction from "./ARUpcomimgAuction";
import { useHistory } from "react-router-dom";

const ARViewAuction = () => {
  const history = useHistory();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 430);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 430);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const singleuploadbreadcrumb = {
    height: isSmallScreen ? "60px" : "30px",
    borderRadius: "0",
  };

  const singleuploadbreadcrumbMargin = {
    marginTop: isSmallScreen ? "-14px" : "-12px",
    marginBottom: isSmallScreen ? "14px" : "",
  };

  const [active, setactive] = useState("live");
  useEffect(() => {
    document.title = "Auction";
    let active_tab = localStorage.getItem("active_tab");
  }, []);

  const handlechangetab = (e) => {
    const id = e.target.getAttribute("id");
    setactive(id);
    localStorage.setItem("active_tab", id);
  };

  try {
    return (
      <>
        <div className="container mt-3">
          <div className="d-flex justify-content-between">
            <i
              className="bi bi-arrow-left-circle"
              onClick={() => history.goBack()}
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </div>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={
                  active === "live"
                    ? `nav-link active btn btn-link`
                    : `nav-link btn btn-link`
                }
                id="live"
                onClick={handlechangetab}
              >
                Live
              </button>
            </li>

            <li className="nav-item">
              <button
                className={
                  active === "upcoming"
                    ? `nav-link active btn btn-link`
                    : `nav-link btn btn-link`
                }
                id="upcoming"
                onClick={handlechangetab}
              >
                Upcoming
              </button>
            </li>

            <li className="nav-item">
              <button
                className={
                  active === "completed"
                    ? `nav-link active btn btn-link`
                    : `nav-link btn btn-link`
                }
                id="completed"
                onClick={handlechangetab}
              >
                Completed
              </button>
            </li>
          </ul>
        </div>

        {active === "live" && (
          <>
            <ARLiveAuction />
          </>
        )}

        {active === "upcoming" && (
          <>
            <ARUpcomimgAuction />
          </>
        )}

        {active === "completed" && (
          <>
            <ARCompletedAuction />
          </>
        )}
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
};

export default ARViewAuction;
