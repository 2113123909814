import React, { useEffect, useState } from "react";

import axios from "axios";
import jwtDecode from "jwt-decode";
import ReactLoading from "react-loading";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import fillFormImf from "../../img/undraw_forms_re_pkrt.svg";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import auctionService from "../../services/auction/auction.service";

const EditAssetDetails = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 486);
  const [isScreen, setIsScreen] = useState(window.innerWidth < 500);
  const source = axios.CancelToken.source();

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 486);
    setIsScreen(window.innerWidth < 500);
  };

  useEffect(() => {
    document.title = "Edit Asset";
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const singleuploadbreadcrumb = {
    height: isSmallScreen ? "60px" : "30px",
    borderRadius: "0",
  };

  const singleuploadbreadcrumbMargin = {
    marginTop: isSmallScreen ? "-14px" : "-12px",
    marginBottom: isSmallScreen ? "14px" : "",
  };

  const token = localStorage.getItem("refreshToken");
  const history = useHistory();
  const user = jwtDecode(token);
  const phone = user?.phone;

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const asset_id = searchParams.get("ref_no");
  const uid = searchParams.get("uid");
  const url = `${asset_id}&uid=${uid}`;

  const optionAuctionType = [
    {
      value: "open",
      label: "Open",
    },
    {
      value: "online",
      label: "Online",
    },
  ];
  const initalValue = {
    reference_no: "",
    reg_no: "",
    make_model: "",
    eng_no: "",
    chassis_no: "",
    mfg_yr: "",
    yard_name: "",
    rc_status: "",
    insurance_status: "",
    tax_status: "",
    note: "",
    start_price: "",
    recommended_bid: "",
    old_customer_name: "",
  };

  const [data, setData] = useState();
  const [SEGTYPE, SETSEGTYPE] = useState([]);
  const [financier, setFinancier] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [segmentLoading, setSegmentLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    try {
      setLoading(true);
      const form_data = new FormData();
      form_data.append("ref_no", asset_id);
      form_data.append("uid", uid);
      auctionService.GetAssetDetails(url, form_data, source).then((res) => {
        if (Object.entries(res).length > 0) {
          setLoading(false);
          setData(res);
        } else {
          setLoading(false);
        }
      });
    } catch (error) {
      errorToast(error?.msg);
    }
  }, []);

  const handleSubmit = (e, type) => {
    e.preventDefault();
    try {
      setButtonLoading(true);
      const validationErrors = {};

      if (!data.reg_no)
        validationErrors.reg_no = "Registration Number is required";
      if (!data.make_model)
        validationErrors.make_model = "Make & Model is required";
      if (!data.eng_no) validationErrors.eng_no = "Engine No. is required";
      if (!data.chassis_no)
        validationErrors.chassis_no = "Chassis No. is required";
      if (!data.mfg_yr || isNaN(data.mfg_yr) || data.mfg_yr <= 0)
        validationErrors.mfg_yr = "Valid Manufacturing Year is required";
      if (!data.yard_name) validationErrors.yard_name = "Yard Name is required";
      if (!data.rc_status) validationErrors.rc_status = "RC Status is required";
      if (!data.insurance_status)
        validationErrors.insurance_status = "Insurance Status is required";
      if (!data.tax_status)
        validationErrors.tax_status = "Tax Status is required";
      if (!data.note) validationErrors.note = "Note is required";
      if (!data.start_price || isNaN(data.start_price) || data.start_price <= 0)
        validationErrors.start_price = "Valid Start Price is required";
      if (!data.recommended_bid)
        validationErrors.recommended_bid = "Recommended Bid is required";
      // if (!data.old_customer_name)
      //   validationErrors.old_customer_name = "Old Customer Name is required";

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }

      const form_data = new FormData();
      form_data.append("ref_no", asset_id);
      form_data.append("reg_no", data?.reg_no);
      form_data.append("make_model", data.make_model);
      form_data.append("eng_no", data.eng_no);
      form_data.append("chassis_no", data.chassis_no);
      form_data.append("mfg_yr", data.mfg_yr);
      form_data.append("yard_name", data.yard_name);
      form_data.append("rc_status", data.rc_status);
      form_data.append("insurance_status", data.insurance_status);
      form_data.append("tax_status", data.tax_status);
      form_data.append("note", data.note);
      form_data.append("fuel_type", data.fuel_type);
      form_data.append("yard_address", data.yard_address);
      form_data.append("start_price", data.start_price);
      form_data.append("recommended_bid", data.recommended_bid);
      form_data.append("old_customer_name", data.old_customer_name);
      form_data.append("uid", uid);
      auctionService.UpdateAssetDetails(form_data, source).then((res) => {
        setButtonLoading(false);
        if (res?.status === 200) {
          successToast("Asset update successfully");
          history.push(`/meratractor/auctions/modify/asset?ref_no=${url}`);
        } else {
          errorToast(res.msg);
        }
      });
    } catch (error) {
      errorToast(error);
      console.log(error);
    }
  };

  const handleDelete = () => {
    const form_data = new FormData();
    form_data.append("ref_no", asset_id);
    form_data.append("uid", uid);
    Swal.fire({
      title: "Are you sure?",
      html: `<p>You want to delete ${asset_id} asset!</p>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await auctionService.DeleteAsset(form_data, source);
          if (response?.status === 200) {
            successToast("Asset Deleted successfully");
            history.push(`/meratractor/auctions/modify/asset?uid=${uid}`);
          } else {
            errorToast(response.msg);
          }
        } catch (error) {
          console.log(error);
          errorToast(error?.message);
        }
      },
    });
  };

  return (
    <>
      <div className="container-fluid shadow-sm mb-2">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container responsive mt-2 ms-auto ">
        <div className="row mt-3 mb-2">
          <span className="d-flex justify-content-between col-1">
            <i
              className="bi bi-arrow-left-circle"
              onClick={() =>
                history.push(
                  `/meratractor/auctions/modify/asset?ref_no=${asset_id}&uid=${uid}`
                )
              }
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </span>
          <div className="col-10 mt-2 float-start ms-2">
            <h4 className="d-flex  mb-2">{asset_id}</h4>
            <h5>Edit Asset Details</h5>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="d-flex justify-content-center">
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={10}
            width={55}
          />
        </div>
      ) : (
        <>
          <div className="container d-flex d-flex-wrap justify-content-between ">
            <div
              className={`col-md-6 col-12 p-3 mb-2 rounded ${
                !isScreen ? " bg-body" : ""
              }`}
            >
              <form className=" ms-auto" onSubmit={handleSubmit}>
                <div className="row mt-3">
                  <div className="col-md-6 mb-3 form-group">
                    <label for="reference_no">Reference No.</label>

                    <input
                      className="form-control"
                      placeholder="Reference No."
                      id="reference_no"
                      type="text"
                      value={data?.reference_no}
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          ["reference_no"]: e?.target?.value,
                        }))
                      }
                      disabled
                      // required
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label for="reg_no">Registration Number</label>
                    <input
                      className="form-control"
                      placeholder="Registration Number"
                      id="reg_no"
                      type="text"
                      value={data?.reg_no}
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          ["reg_no"]: e?.target?.value,
                        }))
                      }
                      required
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6 mb-3 form-group">
                    <label for="make_model">Make & Model</label>
                    <input
                      className="form-control"
                      placeholder="Make & Model"
                      id="make_model"
                      type="text"
                      value={data?.make_model}
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          ["make_model"]: e?.target?.value,
                        }))
                      }
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label for="eng_no">Engine No.</label>
                    <input
                      className="form-control"
                      placeholder="Engine No."
                      id="eng_no"
                      type="text"
                      value={data?.eng_no}
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          ["eng_no"]: e?.target?.value,
                        }))
                      }
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6 mb-3 form-group">
                    <label for="chassis_no">Chassis No.</label>
                    <input
                      className="form-control"
                      placeholder="State"
                      id="chassis_no"
                      type="text"
                      name="chassis_no"
                      value={data?.chassis_no}
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          ["chassis_no"]: e?.target?.value,
                        }))
                      }
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label for="mfg_yr">Manfacturing Year</label>
                    <input
                      className="form-control"
                      placeholder="Manfacturing Year"
                      id="mfg_yr"
                      type="text"
                      name="mfg_yr"
                      value={data?.mfg_yr}
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          ["mfg_yr"]: e?.target?.value,
                        }))
                      }
                      required
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6 mb-3 form-group">
                    <label for="yard_name">Yard Name</label>
                    <input
                      className="form-control"
                      placeholder="Yard Name"
                      id="yard_name"
                      type="text"
                      name="yard_name"
                      value={data?.yard_name}
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          ["yard_name"]: e?.target?.value,
                        }))
                      }
                      required
                    />
                  </div>

                  <div className="col-md-6 form-group">
                    <label for="rc_status">RC Status</label>
                    <input
                      className="form-control"
                      placeholder="RC Status"
                      id="rc_status"
                      type="text"
                      name="rc_status"
                      value={data?.rc_status}
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          ["rc_status"]: e?.target?.value,
                        }))
                      }
                      required
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6 mb-3 form-group">
                    <label for="insurance_status">Insurance Status</label>
                    <input
                      type="text"
                      className="form-control"
                      id="insurance_status"
                      name="insurance_status"
                      placeholder="Insurance Status"
                      value={data?.insurance_status}
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          ["insurance_status"]: e?.target?.value,
                        }))
                      }
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3 form-group">
                    <label for="tax_status">Tax Status</label>
                    <input
                      type="text"
                      className="form-control"
                      id="tax_status"
                      name="text"
                      placeholder="Tax Status"
                      value={data?.tax_status}
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          ["tax_status"]: e?.target?.value,
                        }))
                      }
                      required
                    />
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-6 mb-3 form-group">
                    <label for="description">Note</label>
                    <input
                      className="form-control"
                      placeholder="Note"
                      id="note"
                      type="text"
                      name="note"
                      value={data?.note}
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          ["note"]: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label for="description">Fuel Type</label>
                    <input
                      className="form-control"
                      placeholder="Fuel Type"
                      id="fuel_type."
                      type="text"
                      name="fuel_type."
                      value={data?.fuel_type}
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          ["fuel_type"]: e.target.value,
                        }))
                      }
                      // required
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6 mb-3 form-group">
                    <label for="description">Yard Address</label>

                    <input
                      className="form-control"
                      placeholder="Yard Address"
                      id="yard_address"
                      type="text"
                      name="yard_address"
                      value={data?.yard_address}
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          ["yard_address"]: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label for="description">Start Price</label>
                    <input
                      className="form-control"
                      placeholder="Start Price"
                      id="start_price"
                      type="number"
                      name="start_price"
                      value={data?.start_price}
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          ["start_price"]: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6 mb-3 form-group">
                    <label for="description">Recommended Bid</label>
                    <input
                      className="form-control"
                      placeholder="Recommended Bid"
                      id="recommended_bid"
                      type="text"
                      name="recommended_bid"
                      value={data?.recommended_bid}
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          ["recommended_bid"]: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>

                  <div className="col-md-6 form-group">
                    <label for="description">Old Customer Name</label>
                    <input
                      className="form-control"
                      placeholder="Old Customer Name"
                      id="old_customer_name"
                      type="text"
                      name="old_customer_name"
                      value={data?.old_customer_name}
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          ["old_customer_name"]: e.target.value,
                        }))
                      }
                      // required
                    />
                  </div>
                </div>

                <div className="d-flex  justify-content-center  mt-4 gap-2">
                  {buttonLoading ? (
                    <button
                      className="btn btn-primary col-4"
                      type="button"
                      disabled
                      id="loadingBtnTp"
                    >
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <>
                      <button
                        className="btn btn-primary  col-4"
                        type="submit"
                        // onClick={(e) => handleSubmit(e, "edit")}
                      >
                        Submit
                      </button>
                    </>
                  )}

                  <button
                    className="btn btn-danger  col-4"
                    type="button"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                </div>
              </form>
            </div>
            <div className="ms-2">
              <img
                src={fillFormImf}
                alt="Fill Form Img"
                width="80%"
                height="80%"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditAssetDetails;
