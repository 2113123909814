// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: "AIzaSyDOa0xtlcfiFp-yWYIB2vO6RyiP5dPhwx0",
  // authDomain: "erp-testing-7f3d9.firebaseapp.com",
  // projectId: "meratractor-50ad0",
  // storageBucket: "erp-testing-7f3d9.appspot.com",
  // messagingSenderId: "248808874879",
  // appId: "1:582160070988:web:2137a615d6335b8bd277e9",
  // measurementId: "G-1YJ9QH77RK",
  apiKey: "AIzaSyDOa0xtlcfiFp-yWYIB2vO6RyiP5dPhwx0",
  authDomain: "",
  databaseURL: "https://meratractor-50ad0.firebaseio.com",
  projectId: "meratractor-50ad0",
  storageBucket: "",
  messagingSenderId: "",
  appId: "1:582160070988:web:2137a615d6335b8bd277e9",
  measurementId: "G-1YJ9QH77RK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;
