import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState, useRef } from "react";
import ReactLoading from "react-loading";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { indianCurrencyFormater } from "../../helpers/functions/currencyFormater";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import auctionService from "../../services/auction/auction.service";
import SearchBox from "../../common/SearchBox";

const AssetSummary = () => {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  const phone = user?.phone;
  const history = useHistory();
  const location = useLocation();
  const source = axios.CancelToken.source();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 486);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 486);
  };

  useEffect(() => {
    document.title = "Asset Summary";
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const singleuploadbreadcrumb = {
    height: isSmallScreen ? "60px" : "30px",
    borderRadius: "0",
  };

  const singleuploadbreadcrumbMargin = {
    marginTop: isSmallScreen ? "-14px" : "-12px",
    marginBottom: isSmallScreen ? "14px" : "",
  };
  const searchParams = new URLSearchParams(location.search);
  const asset_id = searchParams.get("ref_no");
  const uid = searchParams.get("uid");
  const prevURL = searchParams.get("prevUrl");
  const url = `${asset_id}&uid=${uid}`;

  const headers = [
    { header: "Reference No", dataKey: "reference_no" },
    { header: "Make & Model", dataKey: "make_model" },
    { header: "Bidder Name", dataKey: "bidder_name" },
    { header: "Bidder Phone", dataKey: "bidder_phone" },
    { header: "Bidder Pan", dataKey: "Bidder Pan" },
    { header: "Bid", dataKey: "max_bid" },
    { header: "Date", dataKey: "date" },
  ];

  const [auctionData, setAuctionData] = useState([]);
  const [filterAuctionData, setFilterAuctionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showFullPrice, setShowFullPrice] = useState("");

  useEffect(() => {
    try {
      setLoading(true);
      const form_data = new FormData();
      form_data.append("uid", uid);
      form_data.append("ref_no", asset_id);
      auctionService
        .GetAssetSummary(asset_id, form_data, source)
        .then((res) => {
          setLoading(false);
          if (res?.status === 200) {
            const data = res?.data;
            const newData = data.map((item) => ({
              ...item,
              bidder_name: item["Bidder Name"],
              bidder_phone: item["Bidder Phone"],
              make_model: item["Make Model"],
              reference_no: item["Reference No"],
            }));
            data.map((val) => val);
            setAuctionData(newData);
            setFilterAuctionData(newData);
          }
        });
    } catch (error) {
      console.error(error);
    }
  }, []);
console.log({ auctionData });
  const handleDelete = (e, data, index) => {
    e.preventDefault();
    try {
      const form_data = new FormData();
      form_data.append("asset_id", data?.id);
      form_data.append("live_auction_id", data?.live_auction_id);
      Swal.fire({
        title: "Delete Bid?",
        html: `<p>Do you really want to delete this bid !</p>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes,delete it!",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            const response = await auctionService.DeleteBid(form_data, source);
            if (response.status === 200) {
              successToast(response?.msg);
              // window.location.replace();
              window.location.reload();
            } else {
              errorToast(response?.msg);
            }
          } catch (error) {
            errorToast(error?.message);
          }
        },
      });
    } catch (error) {
      console.log(error);
      errorToast(error);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;

    const searchData = auctionData.filter((val) => {
      return String(val?.bidder_phone)
        .toLowerCase()
        .includes(value.toLowerCase());
    });

    if (value) {
      setFilterAuctionData(searchData);
    } else {
      setFilterAuctionData(auctionData);
    }
  };

  const handleBackButton = (e) => {
    e.preventDefault();
    if (prevURL === "auctionSummary") {
      history.push(`/meratractor/auctions/auction_summary?uid=${uid}`);
    } else if (prevURL === "acr") {
      history.push(`/meratractor/auctions/acr?uid=${uid}`);
    } else {
      history.push(
        `/meratractor/auctions/modify/asset?ref_no=${asset_id}&uid=${uid}`
      );
    }
  };

  const handleShowPrice = (e) => {
    e.preventDefault();
    try {
      const form_data = new FormData();
      form_data.append("type", "summary");
      form_data.append("uid", uid);
      form_data.append("ref_no", asset_id);
      form_data.append("phone", phone);
      auctionService.CheckBidAmount(form_data).then((res) => {
        if (res?.status === 200) {
          setAuctionData((prevData) =>
            prevData.map((item, index) => ({
              ...item,
              ["max_bid"]: res.data[index]?.bid || item.max_bid,
            }))
          );  
          setFilterAuctionData((prevData) =>
            prevData.map((item, index) => ({
              ...item,
              ["max_bid"]: res.data[index]?.bid || item.max_bid,
            }))
          );
          // setFilterAuctionData((prevData) =>
          //   prevData.map((item, index) => ({
          //     ...item,
          //     ["max_bid"]: res?.data.map(
          //       (val, ind) => index === ind && val?.bid
          //     ),
          //   }))
          // );
          // setFilterAuctionData((prevData) =>
          //   prevData.map((item) =>
          //     item.reference_no === reference_no
          //       ? { ...item, ["max_bid"]: res?.data?.bid }
          //       : item
          //   )
          // );
          setShowFullPrice(asset_id);
        } else if (res?.status === 400) {
          setAuctionData((prevData) =>
            prevData.map((item) => ({ ...item, ["max_bid"]: 0 }))
          );
          setFilterAuctionData((prevData) =>
            prevData.map((item) => ({ ...item, ["max_bid"]: 0 }))
          );
          setShowFullPrice(asset_id);
        } else {
          errorToast(res?.msg);
        }
      });
    } catch (error) {}
  };

  console.log({ filterAuctionData });

  return (
    <>
      <div className="container-fluid shadow-sm mb-2">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container ">
        <div className="row mt-2 mb-2">
          <div className=" col-12 col-md-2 col-lg-2">
            <i
              className="bi bi-arrow-left-circle"
              onClick={handleBackButton}
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </div>
          <div className="col-12 col-md-3 col-lg-3">
            <h5 className="mt-2">{asset_id}</h5>
          </div>
          <div className="col-12 col-md-3 col-lg-2 mb-2">
            <button
              className="btn btn-primary"
              onClick={(e) => handleShowPrice(e)}
            >
              Show Bid Price
            </button>
          </div>
          <div className="col-md-4 col-12 mt-1 col-lg-5">
            <SearchBox
              allData={auctionData}
              setFilteredData={setFilterAuctionData}
            />
          </div>
        </div>
      </div>

      {loading ? (
        <div
          className="d-flex justify-content-center container"
          style={{ minHeight: "80vh", alignItems: "center" }}
        >
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <>
          <div className="container table-responsive">
            <div
              className="table-responsive text-center"
              style={{ minHeight: "5vh", maxHeight: "80vh" }}
            >
              <table className=" table table-hover table-bordered">
                <thead className="thead-dark">
                  <tr
                    className="text-center"
                    style={{ fontSize: "small", position: "sticky", top: 0 }}
                  >
                    <th> Sr. No</th>
                    {headers.map((column) => (
                      <th className="col" key={column.header}>
                        {column.header}
                      </th>
                    ))}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(filterAuctionData) &&
                  Object.entries(filterAuctionData).length === 0 ? (
                    <div className=" text-danger text-center mt-2 "></div>
                  ) : (
                    Object.entries(filterAuctionData).map(
                      (rowData, rowIndex) => (
                        <tr
                          key={rowIndex}
                          style={{
                            fontSize: "small",
                          }}
                        >
                          <td>{rowIndex + 1}</td>
                          {headers.map((header, index) => (
                            <>
                              {header.dataKey === "max_bid" ? (
                                <td key={index}>
                                  <b>
                                    {showFullPrice === rowData[1]?.reference_no
                                      ? indianCurrencyFormater(
                                          rowData[1][header.dataKey],
                                          0
                                        )
                                      : indianCurrencyFormater(
                                          Number(
                                            String(
                                              rowData[1][header.dataKey]
                                            ).slice(0, 0) + "0000"
                                          )
                                        ).replace(/0/g, "X")}
                                  </b>
                                </td>
                              ) : (
                                <td key={index}>
                                  {rowData[1][header.dataKey]}
                                </td>
                              )}
                            </>
                          ))}
                          <td>
                            <button
                              className="btn btn-danger"
                              onClick={(e) => {
                                handleDelete(e, rowData[1], rowIndex);
                              }}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </table>
              {filterAuctionData && filterAuctionData.length === 0 && (
                <div className="text-danger text-center mt-2 ">
                  No auction took place for {asset_id}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AssetSummary;
