import React, { useEffect } from "react";
import CardBlock from "../../common/CardBlock";
import { useLocation, useParams } from "react-router-dom";
import Documents from "../../img/add_assets.svg";
import AddImage from "../../img/image_upload.png";
import EditImage from "../../img/image_folder.png";
import AuctionSummary from "../../img/summary.svg";
import EditAuction from "../../img/viewAuction.svg";
import DownloadDoc from "../../img/download-docs.svg";

const RapibidEditAuctionHome = () => {
  const location = useLocation();
  const  asset_id  = location.pathname.split(":")[1]?.split("&")[0];
  console.log({ asset_id, location }, location.pathname);
  useEffect(() => {
    document.title = "rapibid Edit Auction Home";
  }, []);
  return (
    <>
      <div className="container mt-2">
        <h1>Manage Edit Auction</h1>
        <hr />
      </div>
      <div className="container container-body">
        <CardBlock
          name="Add Documents"
          logo={Documents}
          to={`/rapidbid/manage-auction/manage-add-asset-docs/${asset_id}`}
        />
        <CardBlock
          name="Add Images"
           logo={AddImage}
          to={`/rapidbid/manage-auction/manage-asset-img-add/${asset_id}`}
        />
        <CardBlock
          name="Edit Images"
           logo={EditImage}
          to={`/rapidbid/manage-auction/manage-asset-img-edit/${asset_id}`}
        />
        <CardBlock
          name="Edit Auction"
           logo={EditAuction}
          to={`/rapidbid/manage-auction/manage-edit-auction/${asset_id}`}
        />
        <CardBlock
          name="Auction Summary"
           logo={AuctionSummary}
          to={`/rapidbid/manage-auction/manage-auction-summary/${asset_id}`}
        />
        <CardBlock
          name="Download Documents"
           logo={DownloadDoc}
          to={`/rapidbid/manage-auction/manage-download-docs/${asset_id}`}
        />
      </div>
    </>
  );
};

export default RapibidEditAuctionHome;
