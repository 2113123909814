import Joi from "joi-browser";

export const validate = (formValues,schema) => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(formValues, schema, options);
    if (error === null) return null;

    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };