import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import jwtDecode from "jwt-decode";
import ReactLoading from "react-loading";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
import Auctionpng from "../../../img/auction.png";
import Auction from "../../../img/auction.svg";
import { errorToast, successToast } from "../../../react-toastfiy/toast";

const AuctionEditImage = () => {
  const location = useLocation();
  const asset = location.pathname.split("/")[4]?.split("&")[0];
  const [asset_id, setAsset_id] = useState(asset);
  const [files, setFiles] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [editPrimary, setEditPrimary] = useState([]);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [edit, setEdit] = useState([]);
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  let phone = user.phone;

  useEffect(() => {
    if (asset_id !== "") {
      setLoading(true);
      let form_data = new FormData();
      form_data.append("asset_id", asset_id);
      SubscriptionServices.GetAssetImage(form_data).then((res) => {
        if (res.status === 404) {
          setLoading(false);
        } else {
          setData(res);
          setLoading(false);
        }
      });
    }
  }, [asset_id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let deleteIds = [];
    deleteIds = edit.map((item) => item.toDelete);
    setSubmitButtonLoading(true);
    if (asset_id !== "" && deleteIds.length !== 0) {
      let form_data = new FormData();
      form_data.append("asset_id", asset_id);
      deleteIds.forEach((id) => {
        form_data.append("toDelete", id);
      });
      SubscriptionServices.EditAssetImageDelete(form_data).then((res) => {
        setSubmitButtonLoading(false);
        if (res.status === 200) {
          successToast(res.msg);
          setTimeout(() => {
            window.location.href = window.location.href;
          }, 500);
        } else {
          errorToast(res.msg);
        }
      });
    } else if (asset_id !== "") {
      let form_data = new FormData();
      form_data.append("asset_id", asset_id);
      form_data.append("primary", editPrimary);
      deleteIds.forEach((id) => {
        form_data.append("toDelete", id);
      });
      SubscriptionServices.EditAssetImageDelete(form_data).then((res) => {
        setSubmitButtonLoading(false);
        if (res.status === 200) {
          successToast(res.msg);
          setTimeout(() => {
            window.location.href = window.location.href;
          }, 500);
        } else {
        }
      });

      SubscriptionServices.EditAssetImagePrimary(form_data).then((res) => {
        setSubmitButtonLoading(false);
        if (res.status === 200) {
          successToast(res.msg);
          setTimeout(() => {
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 500);
          });
        } else {
        }
      });
    }
  };

  const handleRedirect = (e) => {
    e.preventDefault();

    // /rapidbid/manage-auction/manage-asset-img-add/:id

    window.location.replace(
      `/rapidbid/manage-auction/manage-asset-img-add/:${asset_id}`
    );
  };
  console.log(
    { checkedItems, edit, data },
    Object.values(checkedItems)[1] == true
  );

  return (
    <>
      <div className=" container mt-3">
        {loading && Object.entries(data).length === 0 ? (
          <div
            className="d-flex justify-content-between container"
            style={{ minHeight: "80vh", alignItems: "center" }}
          >
            <ReactLoading
              type={"bubbles"}
              color={"black"}
              height={100}
              width={100}
              className="reactLoadingDash "
            />
          </div>
        ) : (
          <>
            {Object.entries(data).length === 0 ? (
              <div className="text-center text-danger">
                No image Uploaded :
                <Link onClick={handleRedirect}>
                  click on this link to upload images
                </Link>
              </div>
            ) : (
              <>
                <div className="d-flex flex-wrap justify-content-center flex-wrap col-12 col-sm-10 col-md-10 col-lg-9 col-xl-8 ms-auto me-auto">
                  {Object.entries(data).map(([key, value], index) => (
                    <div
                      key={index}
                      className="card mx-2 mb-2"
                      style={{ minWidth: "16rem", minHeight: "32rem" }}
                    >
                      <img
                        src={value["url"]}
                        className="card-img-top"
                        alt="auction"
                      />
                      <div className="card-body">
                        <div className="form-check">
                          <label className="form-check-label">
                            Make this image primary
                          </label>
                          <input
                            style={{ cursor: "pointer" }}
                            className="form-check-input"
                            type="radio"
                            checked={value["primary"]}
                            onChange={(e) => {
                              const newData = { ...data };
                              newData[key].primary = e.target.checked;
                              for (const itemKey in newData) {
                                if (itemKey !== key) {
                                  newData[itemKey].primary = false;
                                }
                              }
                              setData(newData);
                              setEditPrimary(value.id);
                            }}
                          />
                        </div>
                        <div className="form-check">
                          <label className="form-check-label">
                            Check To delete
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={checkedItems[value["id"]]}
                            onChange={(e) => {
                              setCheckedItems((prevState) => ({
                                ...prevState,
                                [value["id"]]: e.target.checked,
                              }));
                              setEdit((prevState) => {
                                return [
                                  ...prevState,
                                  { ["toDelete"]: value.id },
                                ];
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="text-center mt-4">
                 

                  <div class="d-grid gap-2 mx-auto px-3 col-2 rounded">
                    {submitButtonLoading ? (
                      <button
                        className="btn btn-success"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                    ) : (
                      <button
                        class="btn btn-success"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AuctionEditImage;
