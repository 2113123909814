import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import SubscriptionServices from "../../services/subscription/subscription.service";

//CSS
import "react-toastify/dist/ReactToastify.css";
import ErrorHandler from "../../common/ErrorHandler";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const AddTenureModalPopup = (props) => {
  const { show, onHide, createDurationData, setCreateDurationData } = props;

  const platformType = [
    {
      label: "Rapibid",
      value: "Rapibid",
    },
    {
      label: "Auction",
      value: "Auction",
    },
    {
      label: "ERP",
      value: "ERP",
    },
  ];

  const handleSubmit = async (e) => {
    try {
      if (
        createDurationData?.platform_type !== "" ||
        createDurationData?.duration !== ""
      ) {
        e.preventDefault();
        let formData = new FormData();
        formData.append("platform_type", createDurationData?.platform_type);
        formData.append("duration", createDurationData?.duration);
        const response = await SubscriptionServices.CreateTenorList(formData);
        if (response.status === 200) {
          successToast(response.msg);
          setTimeout(() => {
            window.location.replace("/subscription/manage-platform-tenure");
          }, 500);
        }
        response.status !== 200 && errorToast(response.msg);
      }
    } catch (error) {
      console.log(error);
      errorToast(error?.message);
    }
  };
  const handleUpdate = async (e) => {
    try {
      e.preventDefault();

      let formData = new FormData();
      formData.append("platform_type", createDurationData?.platform_type);
      formData.append("duration", createDurationData?.duration);
      formData.append("duration_id", createDurationData?.duration_id);
      const response = await SubscriptionServices.UpdateTenorList(formData);
      if (response.status === 200) {
        successToast(response.msg);
        setTimeout(() => {
          window.location.replace("/subscription/manage-platform-tenor");
        }, 500);
      }
      response.status !== 200 && errorToast(response.msg);
    } catch (error) {
      console.log(error);
      errorToast(error?.message);
    }
  };

  const getPlanType = (e, name) => {
    setCreateDurationData((prevState) => {
      return {
        ...prevState,
        [name]: e?.value,
      };
    });

    const handleUpdate = async (e) => {
      try {
        e.preventDefault();

        let formData = new FormData();
        formData.append("platform_type", createDurationData?.platform_type);
        formData.append("duration", createDurationData?.duration);
        formData.append("duration_id", createDurationData?.duration_id);
        const response = await SubscriptionServices.CreateTenorList(formData);
        if (response.status === 200) {
          successToast(response.msg);
          setTimeout(() => {
            window.location.replace("/subscription/manage-platform-tenure");
          }, 500);
        }
        response.status !== 200 && errorToast(response.msg);
      } catch (error) {
        console.log(error);
        errorToast(error?.message);
      }
    };
  };

  try {
    return (
      <Modal
        {...props}
        size="md"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {createDurationData?.duration_id ? " Edit Tenure " : "Add Tenure"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-2">
            <div className="col-md-12 mb-3 form-group">
              <label htmlFor="name">Platform Type</label>
              <Select
                options={platformType}
                onChange={(e) => getPlanType(e, "platform_type")}
                value={platformType.find(
                  (option) => option.value === createDurationData?.platform_type
                )}
                placeholder="Select Platform Type"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3 form-group">
              <label htmlFor="name">Duration</label>
              <input
                type="number"
                className="form-control"
                name="duration"
                placeholder="Enter Duration"
                value={createDurationData?.duration}
                onChange={(e) =>
                  setCreateDurationData({
                    ...createDurationData,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>
            Close
          </Button>
          {createDurationData?.duration_id ? (
            <Button onClick={handleUpdate}>Update</Button>
          ) : (
            <Button onClick={handleSubmit}>add Tenure</Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
};

export default AddTenureModalPopup;
