import React, { useEffect } from "react";
import CardBlock from "../../common/CardBlock";
import Auction from "../../img/auction.svg";
import category from "../../img/category.svg";
import Users from "../../img/users.svg";
import Make from "../../img/make.svg";
import Model from "../../img/model.svg";
import State from "../../img/model.svg";

function RapidbidHomePage() {
  useEffect(() => {
    document.title = "rapibid Home";
  }, []);
  return (
    <>
      <div className="container mt-2">
        <h1>Rapibid Home</h1>
        <hr />
      </div>
      <div className="container container-body">
        <CardBlock
          name="Subscription"
          // logo={inventory}
          to="/rapidbid/manage-subscription"
        />
        <CardBlock name="Users" logo={Users} to="/rapidbid/manage-user" />

        <CardBlock
          name="Auction"
          logo={Auction}
          to="/rapidbid/manage-auction"
        />
        <CardBlock name="Make" logo={Make} to="/rapibid/auction/make" />
        <CardBlock name="Model" logo={Model} to="/rapibid/auction/model" />
        <CardBlock name="State" logo={State} to="/rapibid/auction/state" />
        <CardBlock
          name="Dashboard"
          //  logo={spares}
          to="/rapibid/auction/dashboard"
        />

        <CardBlock
          name="Permission"
          // logo={so_manage}
          to="/rapibid/auction/permission"
        />
        <CardBlock
          name="User Login Details"
          // logo={so_manage}
          to="/rapibid/auction/user-login-details"
        />
        <CardBlock
          name="Logout App User"
          // logo={inventory}
          to="/rapibid/auction/render-logout-users"
        />
      </div>
    </>
  );
}

export default RapidbidHomePage;
