import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Joi from "joi-browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/common.css";

import LoginImg from "../img/login.png";

import { errorToast } from "../react-toastfiy/toast";
import authService from "../services/auth.service";
import jwt from "jwt-decode";
import auctionService from "../services/auction/auction.service";

function Login() {
  // AuthService.login();
  // UserService.getUsers();

  const initialvalues = { phone: "", password: "" };
  const [formValues, setFormValues] = useState(initialvalues);
  const [formErrors, setFormErrors] = useState({});
  const [isloading, setisloading] = useState(false);

  const form = useRef(null);

  let history = useHistory();

  const schema = {
    phone: Joi.string().length(10).required().label("Phone"),
    password: Joi.string().min(4).max(20).required().label("Password"),
  };

  useEffect(() => {
    document.title = "Login";
  }, []);

  const handlechange = (e) => {
    const { name, value } = e.target;
    // let errorData = { ...formErrors };
    setFormValues({ ...formValues, [name]: value });
    // const errorMessage = validateProperty(e);
    // if (errorMessage) {
    //   errorData[name] = errorMessage;
    // } else {
    //   delete errorData[name];
    // }
    // let formData = { ...formValues };
    // formData[name] = value;
    // setFormValues(formData);
    // setFormErrors(errorData);
    // console.log(formValues, formErrors);
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(formValues, schema, options);
    if (error === null) return null;

    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const validateProperty = (event) => {
    const { name, value } = event.target;
    const obj = { [name]: value };
    const subSchema = { [name]: schema[name] };
    const result = Joi.validate(obj, subSchema);
    const { error } = result;
    return error ? error.details[0].message : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisloading(true);
    // var errors = validate(userdata, schema);
    // setFormErrors(errors || {});

    // if (errors) return;
    const formdata = new FormData(form.current);
    var errors = validate();
    setFormErrors(errors || {});

    if (errors) {
      setisloading(false);
      return;
    }
    try {
      // make axios post request
      const response = await authService.login(formdata);

      if (response.status === 200) {
        const token = localStorage.getItem("refreshToken");
        if (token) {
          const user = jwt(token);
          const user_designations_business = user?.assigned_states_designations
            ?.map(({ state, designations }) =>
              designations?.map(({ designation, business }) => business)
            )
            .flat();

          const is_auction_user =
            user_designations_business?.includes("Auction") ||
            user_designations_business?.includes("Tech") ||
            user_designations_business?.includes("Back Office");

          if (is_auction_user) {
            const res = await auctionService.MeratractorLogin(formdata);
            if (res.status === 200) {
              if (
                user?.assigned_states_designations?.length > 1 ||
                user?.assigned_states_designations[0]?.designations?.length > 1
              ) {
                window.location.replace("/select-designation");
              } else {
                window.location.replace("/");
              }
            } else {
              console.log(res);
              errorToast(res.msg);
              if (
                user?.assigned_states_designations?.length > 1 ||
                user?.assigned_states_designations[0]?.designations?.length > 1
              ) {
                window.location.replace("/select-designation");
              } else {
                window.location.replace("/");
              }
            }
          } else {
            if (
              user?.assigned_states_designations?.length > 1 ||
              user?.assigned_states_designations[0]?.designations?.length > 1
            ) {
              window.location.replace("/select-designation");
            } else {
              window.location.replace("/");
            }
          }
        }
      }
      if (response.status !== 200) {
        setisloading(false);
        errorToast(response.msg);
      }
    } catch (error) {
      setisloading(false);
      console.log(error);
    }
  };

  const styleObj = {
    width: "130%",
    height: "100%",
  };

  return (
    <>
      <ToastContainer />
      <div
        className="container-fluid border d-flex justify-content-center align-items-center"
        style={{ height: "90%" }}
      >
        <div
          className="container border loginPage"
          style={{
            margin: "0",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className="row">
            <div className="col-md-7 d-none d-md-block .d-md-none .d-lg-block">
              <img src={LoginImg} alt="" style={styleObj} />
            </div>
            <div className="col-lg-5">
              <form ref={form} className="col-12" onSubmit={handleSubmit}>
                <div className="d-flex flex-column justify-content-center">
                  <label className="labels text-center my-2">Login</label>
                  <div className="form-group">
                    <label className="">Phone</label>
                    <input
                      type="number"
                      className="form-control shadow bg-white rounded"
                      name="phone"
                      placeholder="Enter Phone"
                      value={formValues.phone}
                      onChange={handlechange}
                    />
                  </div>
                  {formErrors.phone && (
                    <div className="alert alert-danger">{formErrors.phone}</div>
                  )}
                  <div className="form-group">
                    <label className="">Password</label>
                    <input
                      type="password"
                      className="form-control shadow bg-white rounded"
                      name="password"
                      placeholder="Enter password"
                      value={formValues.password}
                      onChange={handlechange}
                    />
                  </div>
                  {formErrors.password && (
                    <div className="alert alert-danger">
                      {formErrors.password}
                    </div>
                  )}

                  <div className="mt-4 d-flex justify-content-center">
                    {isloading ? (
                      <button
                        type="submit"
                        disabled
                        className="btn btn-primary btn-block shadow mb-2"
                      >
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        disabled={validate()}
                        className="btn btn-primary btn-block shadow mb-2"
                      >
                        Login
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="auth-wrapper">
        <div className="auth-inner">
          <form
            ref={form}
            className="col-6 col-sm-3 col-md-12 col-lg-12"
            onSubmit={handleSubmit}
          >
            <h3>Sign In</h3>
            <div className="form-group">
              <label>Phone</label>
              <input
                type="number"
                className="form-control"
                name="phone"
                placeholder="phone"
                value={formValues.phone}
                onChange={handlechange}
              />
            </div>
            {formErrors.phone && (
              <div className="alert alert-danger">{formErrors.phone}</div>
            )}
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                name="password"
                placeholder="Enter password"
                value={formValues.password}
                onChange={handlechange}
              />
            </div>
            {formErrors.password && (
              <div className="alert alert-danger">{formErrors.password}</div>
            )}
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  Remember me
                </label>
              </div>
            </div>
            <button
              type="submit"
              disabled={validate()}
              className="btn btn-primary btn-block"
            >
              Submit
            </button>

            <div className="forgot-password text-center">
              Create an{" "}
              <Link className="navbar-brand" to={"/sign-up"}>
                account
              </Link>{" "}
              to register!!!
              <br />
              Forgot{" "}
              <Link className="navbar-brand" to={"/sign-up"}>
                password?
              </Link>
            </div>
          </form>
        </div>
      </div> */}
    </>
  );
}

export default Login;
