import React from "react";

const DatePicker = (props) => {
  const {
    date,
    setDate,
    name,
    id,
    label,
    placeholder,
    isRequired,
    minDate = "",
    maxDate = "",
  } = props;

  return (
    <>
      <label htmlFor={id} className="form-label mb-0">
        {label}:{isRequired && <span className="text-danger">*</span>}
      </label>
      <input
        type="date"
        className="form-control"
        id={id}
        name={name}
        placeholder={placeholder}
        onChange={(e) => {
          setDate(e.target.value);
        }}
        value={date}
        required={isRequired}
        min={minDate}
        max={maxDate}
      />
    </>
  );
};

export default DatePicker;
