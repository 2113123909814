import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import addImageIcon from "../../img/image-add-line.svg";
import { errorToast } from "../../react-toastfiy/toast";
import auctionService from "../../services/auction/auction.service";
import axios from "axios";

const SearchAssetImage = () => {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  const history = useHistory();
  const source = axios.CancelToken.source();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 450);
  const [isSmall, setIsSmall] = useState(window.innerWidth < 770);
  useEffect(() => {
    document.title = "Search Asset Images";
  }, []);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 450);
    setIsSmall(window.innerWidth < 770);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const singleuploadbreadcrumb = {
    height: isSmallScreen ? "60px" : "30px",
    borderRadius: "0",
  };

  const singleuploadbreadcrumbMargin = {
    marginTop: isSmallScreen ? "-14px" : "-12px",
    marginBottom: isSmallScreen ? "14px" : "",
  };

  const [data, setData] = useState();
  const [checkedItemsEditPrimary, setCheckedItemsEditPrimary] = useState([]);
  const [imageData, setImageData] = useState();
  const [img, setImg] = useState();
  const [loading, setLoading] = useState(false);
  const [uploadoading, setUploadoading] = useState(false);
  console.log({ imageData });

  const handleSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    const form_data = new FormData();
    form_data.append("ref_no", data);
    auctionService.GetAssetPictures(data, form_data, source).then((res) => {
      if (res.length >= 0) {
        res.sort((a, b) => b.id - a.id);
        setImageData(res);
        setLoading(false);
      } else {
        errorToast(res?.msg);
        setLoading(false);
      }
    });
  };
  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setData(e.target.value);
  };



  const handleSetImg = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    console.log({ file, data });
    setImg(file);
    const form_data = new FormData();
    form_data.append("ref_id", data);
    form_data.append("asset_images", file);
    form_data.append("uid", "");
    auctionService.UploadAssetPictures(data, form_data, source).then((res) => {
      if (res?.status === 200) {
        let updatedData = [...res.images_data];
        let mergedData = [...imageData, ...updatedData];
        mergedData.sort((a, b) => b.id - a.id);

        setImageData(mergedData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleDelete = (e, index, value) => {
    e.preventDefault();

    const RemovedData = imageData[value];
    const form_data = new FormData();
    form_data.append("toDelete", index.id);
    Swal.fire({
      title: "Are you sure?",
      html: `<p>You want to delete this picture!</p>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await auctionService.DeleteAssetPictures(
            form_data,
            source
          );
          if (response?.status === 200) {
            let updatedData = imageData.filter(
              (item, index) => index !== value
            );
            setImageData(updatedData);
          } else {
            console.log(response.msg);
          }
        } catch (error) {
          console.log(error);
          errorToast(error?.message);
        }
      },
    });
  };
  const handlePrimaryImage = (e, value, imageDataIndex) => {
    e.preventDefault();

    const form_data = new FormData();
    form_data.append("primary", value?.id);

    Swal.fire({
      title: "Are you sure?",
      html: `<p>You want to make primary this picture!</p>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await auctionService.SetAssetPicturesPrimary(
            form_data,
            source
          );
          if (response?.status === 200) {
            const newData = imageData.map((img, index) => ({
              ...img,
              primary: index === imageDataIndex,
            }));
            setCheckedItemsEditPrimary(newData);
            setImageData(newData);
          } else {
            console.log(response.msg);
          }
        } catch (error) {
          console.log(error);
          errorToast(error?.message);
        }
      },
    });
  };

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none ">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 className="float-end" style={{ fontSize: "28px" }}>
                    {user.role}
                  </h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container responsive mt-2 ms-auto ">
        <div className="row mt-3 mb-2">
          <span className="d-flex justify-content-between col-1">
            <i
              className="bi bi-arrow-left-circle "
              onClick={() => history.push("/meratractor/auction-home")}
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </span>

          <div className="col-10 mt-2 float-start ms-2">
            <h4 className="">Enter The Reference Number</h4>
          </div>
        </div>
      </div>

      <div className="container-fluid d-flex d-flex-wrap justify-content-center rounded">
        <div
          className={
            (imageData && imageData?.length >= 0) || isSmall
              ? "col-12 col-md-12  bg-body  rounded"
              : "col-12 col-md-6  bg-body  rounded mt-5"
          }
        >
          <form className="ms-auto" onSubmit={handleSearch}>
            <div className="form-group d-flex d-flex d-flex-wrap justify-content-center ">
              <div
                className={
                  (imageData && imageData?.length >= 0) || isSmall
                    ? "col-7 col-sm-7 col-md-6 col-lg-5 me-2 "
                    : "col-7 col-sm-7 col-md-6 col-lg-9 me-2"
                }
              >
                <input
                  className="form-control"
                  placeholder="Enter Ref No."
                  type="text"
                  name="ref_no"
                  value={data}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  required
                />
              </div>
              {loading ? (
                <button
                  className={
                    imageData && imageData.length >= 0
                      ? "btn btn-primary col-5 col-sm-3 col-md-2 col-lg-1"
                      : "btn btn-primary col-5 col-sm-3 col-md-2 col-lg-2"
                  }
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border  spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className={
                    imageData && imageData.length >= 0
                      ? "btn btn-primary col-5 col-sm-3 col-md-2 col-lg-1"
                      : "btn btn-primary col-5 col-sm-3 col-md-2 col-lg-2"
                  }
                  type="submit"
                  // onClick={(e) => handleSearch(e)}
                >
                  Search
                </button>
              )}
            </div>
            <div className=" container mt-3">
              {loading ? (
                <div
                  className="d-flex justify-content-between container"
                  style={{ minHeight: "80vh", alignItems: "center" }}
                >
                  <ReactLoading
                    type={"bubbles"}
                    color={"black"}
                    height={100}
                    width={100}
                    className="reactLoadingDash "
                  />
                </div>
              ) : (
                <>
                  {imageData && imageData.length >= 0 && (
                    <>
                      <div className="row mt-4">
                        <div className="container">
                          <div className="row">
                            <div className=" col-12 col-sm-3 col-md-4 col-lg-3">
                              <img
                                src={addImageIcon}
                                alt="preview"
                                style={{
                                  height: "18rem",
                                  width: "100%",
                                  overflow: "hidden",
                                  objectFit: "contain",
                                  marginBottom: "2rem",
                                  opacity: "0.5",
                                  backgroundColor: "ButtonShadow",
                                  // zIndex:"5"
                                }}
                                type="file"
                                className="img-thumbnail"
                                onChange={(e) => setImg(e.target.files[0])}
                              />
                              <label
                                htmlFor="defective_item_photo"
                                className="btn btn-primary col-12"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "-4rem",
                                  width: "100%",
                                }}
                              >
                                Upload
                              </label>

                              <input
                                type="file"
                                src={img}
                                id={"defective_item_photo"}
                                className="form-control"
                                hidden
                                onChange={(e) => handleSetImg(e)}
                                accept="image/*"
                              />
                            </div>

                            {imageData &&
                              imageData.map((value, imageDataIndex, key) => (
                                <div className="col-12 col-sm-3 col-md-4 col-lg-3 form-group mb-4">
                                  <div
                                    className="imagePreview"
                                    style={{
                                      position: "relative",
                                      height: "20rem",
                                    }}
                                  >
                                    <i
                                      className="bi bi-x  del"
                                      onClick={(e) =>
                                        handleDelete(e, value, imageDataIndex)
                                      }
                                      style={{
                                        position: "absolute",
                                        top: "-15px",
                                        right: "-15px",
                                        fontSize: "24px",
                                        height: "30px",
                                        width: "30px",
                                        cursor: "pointer",
                                        color: "white",
                                        backgroundColor: "#E97451",
                                        zIndex: "5",
                                        borderRadius: "70%",
                                        backgroundPosition: "center",
                                      }}
                                    ></i>
                                    <img
                                      src={value?.image_link}
                                      className="card-img-top"
                                      alt="auction"
                                      style={{
                                        width: "100%",
                                        height: "16rem",
                                      }}
                                    />

                                    <div className="card-body">
                                      <div className="form-check mt-4 ms-2">
                                        <label className="form-check-label ">
                                          Set as primary
                                        </label>
                                        <input
                                          style={{ cursor: "pointer" }}
                                          className="form-check-input"
                                          type="radio"
                                          checked={value["primary"]}
                                          onChange={(e) => {
                                            handlePrimaryImage(
                                              e,
                                              value,
                                              imageDataIndex
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SearchAssetImage;
