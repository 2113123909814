import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../../css/dashboard.css";
// import dashboardService from '../../services/dashboard.service';
import jwt from "jwt-decode";
import { useParams } from "react-router-dom";
import ErrorHandler from "../../common/ErrorHandler";
import { errorToast } from "../../react-toastfiy/toast";
import dashboardService from "../../services/dashboard.service";
import AuctionReport from "../auction/AuctionReport";

function ARDashboardReportTabbar() {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwt(currentUser);

  let location = useLocation();
  let history = useHistory();

  //   let operationName = "";

  const [columnNames, setColumnNames] = useState([]);
  const [addTaskShow, setAddTaskShow] = useState(false);
  //   const [addmodalShow, setAddModalShow] = useState(false);
  const [TableModalShow, setTableModalShow] = useState(false);
  const [TableTitle, setTableTitle] = useState("");
  const [TableData, setTableData] = useState({});
  const [myTask, setMyTask] = useState([]);
  const [releasePending, setReleasePending] = useState([]);
  const [bankNocTTO, setBankNocTTO] = useState([]);
  const [name, setName] = useState("");
  const [desig, setDesig] = useState("");
  const [active, setactive] = useState("auction");

  const [topRightModal, setTopRightModal] = useState(false);
  const [quickViewData, setQuickViewData] = useState({});

  let { id } = useParams();
  const formdata = new FormData();
  formdata.append("id", id);

  const [phone, setPhone] = useState(id.split(":")[1].split("&")[0]);
  const [stateName, setStateName] = useState(
    id.split(":")[1].split("&")[1].split("=")[1]
  );

  let formData = new FormData();
  formData.append("id", "id");

  useEffect(() => {
    try {
      document.title = "Dashboard";
      let userDetails = new FormData();
      userDetails.append("phone", phone);
      dashboardService.getUserDetailsByPhone(userDetails).then((res) => {
        setName(res.data.name + "(" + res.data.designation + ")");
        setDesig(res.data.designation);
        let desig = res.data.designation;

        if (desig.includes("AR")) {
          const myTask = {};
          myTask["phone"] = phone;
          myTask["state"] = stateName;

          dashboardService.getAuctionPersonnelRelease(myTask).then((res) => {
            if (res.data.length == 0) {
              res = ["blank"];
              setReleasePending(res);
            } else {
              setReleasePending(res.data);
            }
          });

          dashboardService.getBankNocAndTTODashboard(myTask).then((res) => {
            if (res.data.length == 0) {
              res = ["blank"];
              setBankNocTTO(res);
            } else {
              setBankNocTTO(res.data);
            }
          });
        }
      });
    } catch (error) {
      console.error(error);
      errorToast(error?.message);
    }
    // setDashboardData(data);
  }, []);

  const handlechangetab = (e) => {
    const id = e.target.getAttribute("id");

    setactive(id);
    localStorage.setItem("active_report_tab", id);
  };

  useEffect(() => {
    const lastActiveTab = localStorage.getItem("active_report_tab");
    if (lastActiveTab) {
      setactive(lastActiveTab);
    }
  }, []);

  try {
    return (
      <>
        <>
          <div className="container mt-3">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <button
                  className={
                    active === "auction"
                      ? `nav-link active btn btn-link`
                      : `nav-link btn btn-link`
                  }
                  id="auction"
                  onClick={handlechangetab}
                >
                  Auction
                </button>
              </li>
            </ul>
          </div>
          {active === "auction" && (
            <>
              <AuctionReport />
            </>
          )}
        </>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
}

export default ARDashboardReportTabbar;
