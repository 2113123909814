import React, { useEffect, useState } from "react";
import AddAuction from "../../../img/add-auction.svg";
import SearchAsset from "../../../img/search_asset.svg";
import ViewAuction from "../../../img/view-auction.svg";

import CardBlock from "../../../common/CardBlock";
import ErrorHandler from "../../../common/ErrorHandler";
import { Link, useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";

function ARUserHome(props) {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwtDecode(currentUser);
  const history = useHistory();
  const [url, setUrl] = useState(null);
  useEffect(() => {
    document.title = "Home";
  }, []);

  useEffect(() => {
    if (user.role === "") {
      setUrl(
        `/auction_representative:${user.phone}&state=${user.state}`
      );
    } else if (user.role === "Auction Representative") {
      setUrl(
        `/auction_representative:${user.phone}&state=${user.state}`
      );
    }
  }, []);

  try {
    return (
      <>
        <div className="container mt-3">
          <div className="d-flex justify-content-between">
            <i
              className="bi bi-arrow-left-circle"
              // onClick={() => history.goBack()}
              onClick={() => history.push(url)}
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </div>
        </div>

        <div className="container container-body">
          <CardBlock
            name="Add/Edit User"
            logo={AddAuction}
            to="/auction_representative/user_home/add-user"
          />
          <CardBlock
            name="Wallet"
            logo={ViewAuction}
            to={`/meratractor/wallet-home`}
          />
        </div>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
}

export default ARUserHome;
