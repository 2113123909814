import React, { useEffect, useState } from "react";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
import SearchBox from "../../../common/SearchBox";
import ReactLoading from "react-loading";
import { Link, useHistory } from "react-router-dom";
import Model from "./Model";
import { errorToast } from "../../../react-toastfiy/toast";

const RapibidAuctionLive = () => {
  const [auctionData, setAuctionData] = useState([]);
  const [filteredAuctionData, setFilteredAuctionData] = useState([]);
  const [auctionDataLoading, setAuctionDataLoading] = useState(false);
  useEffect(() => {
    setAuctionDataLoading(true);
    let form_data = new FormData();
    form_data.append("key", "live");
    SubscriptionServices.GetAuction(form_data).then((res) => {
      let auction = res.live;
      console.log({ auction, res }, auction.length);
      if (auction.status === 404) {
        errorToast(auction.msg);
      } else if (auction.length !== 0) {
        console.log({ auction });
        setAuctionDataLoading(false);
        setAuctionData(auction);
        setFilteredAuctionData(auction);
      }
    });
  }, []);

  return (
    <>
      <div className="container mt-3">
        {auctionDataLoading && Object.entries(filteredAuctionData).length === 0 ? (
          <div
            className="d-flex justify-content-between container"
            style={{ minHeight: "80vh", alignItems: "center" }}
          >
            <ReactLoading
              type={"bubbles"}
              color={"black"}
              height={100}
              width={100}
              className="reactLoadingDash "
            />
          </div>
        ) : (
          <>
            {filteredAuctionData.length === 0 ? (
              <div className="container">
                <p className="text-center text-danger">No Data</p>
              </div>
            ) : (
              <>
                {!auctionDataLoading &&
                  filteredAuctionData &&
                  filteredAuctionData !== undefined &&
                  filteredAuctionData?.length !== 0 &&
                  filteredAuctionData?.map((value, key, index) => (
                    <div className="d-flex flex-wrap justify-content-between">
                      <div
                        className="card col-md-8 col-12 col-sm-12 col-lg-8 mt-3 form-group"
                        key={index}
                      >
                        <div className="card-header">
                          <div className="d-flex flex-wrap justify-content-between gap-2 mt-2">
                            <h4 className="ms-2">
                              <Link
                                to={`/rapidbid/manage-auction/edit-auction-home/:${value.asset_id}`}
                              >
                                {value.asset_id}
                              </Link>
                            </h4>
                            <div className="ms-auto">
                              <i
                                className="bi bi-trash mx-3 my-3"
                                // onClick={(e) => handleDelete(e, rowData.id)}
                                style={{ cursor: "pointer" }}
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <h5 className="card-title">{`${value.make} - ${value.model}`}</h5>
                          <p className="card-text">{value.state}</p>
                          <a href="#" className="btn btn-primary me-2 mt-2">
                            {value.start_time}
                          </a>
                          <a href="#" className="btn btn-danger ms-2 mt-2">
                            {value.end_time}
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default RapibidAuctionLive;
