import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
import { errorToast, successToast } from "../../../react-toastfiy/toast";
import Back from "../../../img/back.png";
import { Front } from "react-bootstrap-icons";
import AuctionvehicalImgModal from "./AuctionvehicalImgModal";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const EditAuctionModalData = (props) => {
  const {
    data,
    show,
    onHide,
    setmakedata,
    handlesubmit,
    stateList,
    setaddAuctionData,
    addAuctionData,
    StateName,
    setStateName,
    addAuctionList,
    setaddAuctionList,
    setStageOne,
    stageOne,
    setStageTwo,
    stageTwo,
    setStageThree,
    stageThree,
    yearLoading,
    setYearLoading,
    TractorBrand,
    setTractorBrand,
    setBrandLoading,
    years,
    setYears,
    regLoading,
    setregLoading,
    brandLoading,
    modalLoading,
    setModalLoading,
    TractorModal,
    setTractorModal,
    locationLoading,
    setLocationLoading,
    noteLoading,
    setNoteLoading,
    dateTimeLoading,
    setDateTimeLoading,
    startTimeLoading,
    setStartTimeLoading,
    minuteLoading,
    setMinuteLoading,
    recommendedLoading,
    setRecommendedLoading,
    setLoanRefNoLoading,
    loanRefNoLoading,
    imageLoading,
    setImageLoading,
    setModal,
  } = props;

  const [UpdateButtonLoading, setUpdateButtonLoading] = useState(false);
  const [imgModal, setImgModal] = useState(false);
  const [imgModalData, setImgModalData] = useState([]);
  const [SubmitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [selectedFrontFile, setSelectedFrontFile] = useState([]);
  const [selectedBackFile, setSelectedBackFile] = useState([]);
  const [selectedTyreFile, setSelectedTyreFile] = useState([]);
  const [selectedSideFile, setSelectedSideFile] = useState([]);
  const [selectedEngineFile, setSelectedEngineFile] = useState([]);
  const [selectedMiscFile, setSelectedMiscFile] = useState([]);
  const [flag, setFlag] = useState("");
  // const [selectedFrontFile, setSelectedFrontFile] = useState(null);
  const fileInputRef = useRef(null);
  const handlePrev = (e) => {
    e.preventDefault();
    setImageLoading(true);
    console.log({ addAuctionList });
    if (loanRefNoLoading && addAuctionList.loan_ref_no !== "") {
      console.log("loanRefNoLoading");
      setStateName((prevState) => {
        const newState = { ...prevState };
        const keys = Object.keys(newState);
        const lastKey = keys[keys.length - 1];
        delete newState[lastKey];
        return newState;
      });
      setaddAuctionData((prevState) => {
        return {
          ...prevState,
          ["loan_ref_no"]: "",
        };
      });
      setaddAuctionList((prevState) => {
        return {
          ...prevState,
          ["loan_ref_no"]: "",
        };
      });
      setLoanRefNoLoading(false);
    } else if (recommendedLoading && addAuctionList.recommendedBid !== "") {
      setLoanRefNoLoading(true);
      setRecommendedLoading(false);
      console.log("recommanded");
      setStateName((prevState) => {
        const newState = { ...prevState };
        const keys = Object.keys(newState);
        const lastKey = keys[keys.length - 1];
        delete newState[lastKey];
        return newState;
      });

      setaddAuctionData((prevState) => {
        return {
          ...prevState,
          ["recommendedBid"]: "",
        };
      });
      setaddAuctionList((prevState) => {
        return {
          ...prevState,
          ["recommendedBid"]: "",
        };
      });
    } else if (minuteLoading && addAuctionList.minutes !== "") {
      setRecommendedLoading(true);
      setMinuteLoading(false);
      console.log("minutes");

      setStateName((prevState) => {
        const newState = { ...prevState };
        const keys = Object.keys(newState);
        const lastKey = keys[keys.length - 1];
        delete newState[lastKey];
        return newState;
      });
      setaddAuctionData((prevState) => {
        return {
          ...prevState,
          ["minutes"]: "",
        };
      });
      setaddAuctionList((prevState) => {
        return {
          ...prevState,
          ["minutes"]: "",
        };
      });
    } else if (startTimeLoading && addAuctionList.start_time !== "") {
      setMinuteLoading(true);
      console.log("start_time");
      setStateName((prevState) => {
        const newState = { ...prevState };
        const keys = Object.keys(newState);
        const lastKey = keys[keys.length - 1];
        delete newState[lastKey];
        return newState;
      });
      setaddAuctionData((prevState) => {
        return {
          ...prevState,
          ["start_time"]: "",
        };
      });
      setaddAuctionList((prevState) => {
        return {
          ...prevState,
          ["start_time"]: "",
        };
      });
      setStartTimeLoading(false);
    } else if (dateTimeLoading && addAuctionList.date !== "") {
      setStartTimeLoading(true);
      console.log("date");
      setStateName((prevState) => {
        const newState = { ...prevState };
        const keys = Object.keys(newState);
        const lastKey = keys[keys.length - 1];
        delete newState[lastKey];
        return newState;
      });
      setaddAuctionData((prevState) => {
        return {
          ...prevState,
          ["date"]: "",
        };
      });
      setaddAuctionList((prevState) => {
        return {
          ...prevState,
          ["date"]: "",
        };
      });
      setDateTimeLoading(false);
    } else if (noteLoading && addAuctionList.note !== "") {
      setDateTimeLoading(true);
      console.log("note");
      setStateName((prevState) => {
        const newState = { ...prevState };
        const keys = Object.keys(newState);
        const lastKey = keys[keys.length - 1];
        delete newState[lastKey];
        return newState;
      });
      setaddAuctionData((prevState) => {
        return {
          ...prevState,
          ["note"]: "",
        };
      });
      setaddAuctionList((prevState) => {
        return {
          ...prevState,
          ["note"]: "",
        };
      });
      setNoteLoading(false);
    } else if (locationLoading && addAuctionList.location !== "") {
      setNoteLoading(true);
      console.log("Location");
      setStateName((prevState) => {
        const newState = { ...prevState };
        const keys = Object.keys(newState);
        const lastKey = keys[keys.length - 1];
        delete newState[lastKey];
        return newState;
      });
      setaddAuctionData((prevState) => {
        return {
          ...prevState,
          ["location"]: "",
        };
      });
      setaddAuctionList((prevState) => {
        return {
          ...prevState,
          ["location"]: "",
        };
      });
      setLocationLoading(false);
    } else if (regLoading && addAuctionList.reg_no !== "") {
      setLocationLoading(true);
      console.log("Registration");
      setStateName((prevState) => {
        const newState = { ...prevState };
        const keys = Object.keys(newState);
        const lastKey = keys[keys.length - 1];
        delete newState[lastKey];
        return newState;
      });
      setaddAuctionData((prevState) => {
        return {
          ...prevState,
          ["reg_no"]: "",
        };
      });
      setaddAuctionList((prevState) => {
        return {
          ...prevState,
          ["reg_no"]: "",
        };
      });
      setregLoading(false);
    } else if (yearLoading && addAuctionList.year !== "") {
      setregLoading(true);
      console.log("Year");
      setStateName((prevState) => {
        const newState = { ...prevState };
        const keys = Object.keys(newState);
        const lastKey = keys[keys.length - 1];
        delete newState[lastKey];
        return newState;
      });
      setaddAuctionData((prevState) => {
        return {
          ...prevState,
          ["year"]: "",
        };
      });
      setaddAuctionList((prevState) => {
        return {
          ...prevState,
          ["year"]: "",
        };
      });
      setYearLoading(false);
    } else if (stageThree && addAuctionList.model !== "") {
      setYearLoading(true);
      console.log("Model");
      setStateName((prevState) => {
        const newState = { ...prevState };
        const keys = Object.keys(newState);
        const lastKey = keys[keys.length - 1];
        delete newState[lastKey];
        return newState;
      });
      setaddAuctionData((prevState) => {
        return {
          ...prevState,
          ["model_id"]: "",
        };
      });
      setaddAuctionList((prevState) => {
        return {
          ...prevState,
          ["model"]: "",
        };
      });
      setStageThree(false);
    } else if (stageTwo && addAuctionList.make !== "") {
      setStageThree(true);
      console.log("Make");
      setStateName((prevState) => {
        const newState = { ...prevState };
        const keys = Object.keys(newState);
        const lastKey = keys[keys.length - 1];
        delete newState[lastKey];
        return newState;
      });
      setaddAuctionData((prevState) => {
        return {
          ...prevState,
          ["make_id"]: "",
        };
      });
      setaddAuctionList((prevState) => {
        return {
          ...prevState,
          ["make"]: "",
        };
      });
      setStageTwo(false);
    } else if (stageOne && addAuctionList.state !== "") {
      setStageTwo(true);
      console.log("State");
      setStateName((prevState) => {
        const newState = { ...prevState };
        const keys = Object.keys(newState);
        const lastKey = keys[keys.length - 1];
        delete newState[lastKey];
        return newState;
      });
      setaddAuctionData((prevState) => {
        return {
          ...prevState,
          ["state_id"]: "",
        };
      });
      setaddAuctionList((prevState) => {
        return {
          ...prevState,
          ["state"]: "",
        };
      });
      setStageOne(false);
    } else if (addAuctionList.state === "") {
      setModal(false);
    }
  };

  const handlselectState = (e, item, index) => {
    let name = index.state_name;

    setaddAuctionData((prevState) => {
      return {
        ...prevState,
        ["state_id"]: index.id,
      };
    });
    setStateName((prevState) => {
      return {
        ...prevState,
        ["state"]: name,
      };
    });
    setaddAuctionList((prevState) => {
      return {
        ...prevState,
        ["state"]: name,
      };
    });

    setStageOne(true);
    setStageTwo(false);
  };

  const handlselectMake = (e, item, index) => {
    let name = index.name;

    setaddAuctionData((prevState) => {
      return {
        ...prevState,
        ["make_id"]: index.id,
        ["model_id"]: "",
      };
    });
    setaddAuctionList((prevState) => {
      return {
        ...prevState,
        ["make"]: name,
        ["model"]: "",
      };
    });
    setStateName((prevState) => {
      return {
        ...prevState,
        ["make"]: name,
        ["model"]: "",
      };
    });
    setStageTwo(true);
    setStageThree(false);
  };
  const handlselectModel = (e, item, index) => {
    let name = index.model_name;
    setaddAuctionData((prevState) => {
      return {
        ...prevState,
        ["model_id"]: index.id,
      };
    });
    setaddAuctionList((prevState) => {
      return {
        ...prevState,
        ["model"]: name,
      };
    });
    setStateName((prevState) => {
      return {
        ...prevState,
        ["model"]: name,
      };
    });
    setStageThree(true);
    setYearLoading(false);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log({ name, value }, "handleChange");
    setaddAuctionData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    setaddAuctionList((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeEndDate = (e) => {
    const { value, name } = e.target;

    setaddAuctionData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    setaddAuctionList((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const handleNextReg = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log({ name, value });
    if (addAuctionData.reg_no) {
      setStateName((prevState) => {
        return {
          ...prevState,
          ["reg_no"]: addAuctionData.reg_no,
        };
      });
      setregLoading(true);
      setLocationLoading(false);
    } else {
      errorToast("Blank Field Not Allowed");
    }
  };
  const handleNextLocation = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (addAuctionData.location) {
      setStateName((prevState) => {
        return {
          ...prevState,
          ["location"]: addAuctionData.location,
        };
      });
      setLocationLoading(true);
      setNoteLoading(false);
    } else {
      errorToast("Blank Field Not Allowed");
    }

    // setStageThree(true);
  };
  const handleNextNote = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log({ addAuctionData }, addAuctionData.note);
    if (addAuctionData.note) {
      setStateName((prevState) => {
        return {
          ...prevState,
          ["note"]: addAuctionData.note,
        };
      });
      setDateTimeLoading(false);
      setNoteLoading(true);
    } else {
      errorToast("Blank Field Not Allowed");
    }

    // setStageThree(true);
  };
  const handleNextDate = (e) => {
    e.preventDefault();
    if (addAuctionData.date) {
      setStateName((prevState) => {
        return {
          ...prevState,
          ["start_date"]: addAuctionData.date,
        };
      });
      setDateTimeLoading(true);
      setStartTimeLoading(false);
    } else {
      errorToast("Blank Field Not Allowed");
    }
  };
  const handleNextTime = (e) => {
    e.preventDefault();
    if (addAuctionData.start_time) {
      setStateName((prevState) => {
        return {
          ...prevState,
          ["start_time"]: addAuctionData.start_time,
        };
      });
      setStartTimeLoading(true);
      setMinuteLoading(false);
    } else {
      errorToast("Blank Field Not Allowed");
    }
  };
  const handleNextMinutes = (e) => {
    e.preventDefault();

    if (addAuctionData.minutes) {
      setStateName((prevState) => {
        return {
          ...prevState,
          ["minutes"]: addAuctionData.minutes,
        };
      });
      // setDateTimeLoading(true);
      setMinuteLoading(true);
      setRecommendedLoading(false);
    } else {
      errorToast("Blank Field Not Allowed");
    }
  };
  const handleNextRecommended = (e) => {
    e.preventDefault();

    setStateName((prevState) => {
      return {
        ...prevState,
        ["recommendedBid"]: addAuctionData.recommendedBid,
      };
    });

    setRecommendedLoading(true);
    setLoanRefNoLoading(false);
  };
  const handleNextLoanRefNo = (e) => {
    e.preventDefault();

    setStateName((prevState) => {
      return {
        ...prevState,
        ["loan_ref_no"]: addAuctionData.loan_ref_no,
      };
    });

    setLoanRefNoLoading(true);
    setImageLoading(false);
  };

  const openFrontDialog = (e, id) => {
    // const fileInput = document.getElementById("front-file-input");
    // fileInput.click();
  };

  const handleImgFileChange = (e, name) => {
    const files = Array.from(e.target.files);
    const file = e.target.files;
    if (selectedFrontFile.length + files.length < 7) {
      setSelectedFrontFile((prevState) => {
        return [...prevState, file[0]];
      });
    } else {
      errorToast("Max 6 Img can Uploaded");
    }
  };
  const handleImgBackFileChange = (e, name) => {
    const files = Array.from(e.target.files);
    if (selectedBackFile.length + e.target.files.length < 6) {
      setSelectedBackFile((prevState) => {
        return [...prevState, ...files];
      });
    } else {
      errorToast("Max 6 Img can Uploaded");
    }
  };
  const handleImgTyreFileChange = (e, name) => {
    const files = Array.from(e.target.files);
    if (selectedTyreFile.length + e.target.files.length < 6) {
      setSelectedTyreFile((prevState) => {
        return [...prevState, ...files];
      });
    } else {
      errorToast("Max 6 Img can Uploaded");
    }
  };
  const handleImgSideFileChange = (e, name) => {
    const files = Array.from(e.target.files);
    if (selectedSideFile.length + e.target.files.length < 6) {
      setSelectedSideFile((prevState) => {
        return [...prevState, ...files];
      });
    } else {
      errorToast("Max 6 Img can Uploaded");
    }
  };
  const handleImgEngineFileChange = (e, name) => {
    const files = Array.from(e.target.files);
    if (selectedEngineFile.length + e.target.files.length < 6) {
      setSelectedEngineFile((prevState) => {
        return [...prevState, ...files];
      });
    } else {
      errorToast("Max 6 Img can Uploaded");
    }
  };
  const handleImgMiscFileChange = (e, name) => {
    const files = Array.from(e.target.files);
    if (selectedMiscFile.length + e.target.files.length < 6) {
      setSelectedMiscFile((prevState) => {
        return [...prevState, ...files];
      });
    } else {
      errorToast("Max 6 Img can Uploaded");
    }
  };

  const handlselectYear = (e, year) => {
    setaddAuctionData((prevState) => {
      return {
        ...prevState,
        ["year"]: year,
      };
    });
    setaddAuctionList((prevState) => {
      return {
        ...prevState,
        ["year"]: year,
      };
    });
    setStateName((prevState) => {
      return {
        ...prevState,
        ["year"]: year,
      };
    });

    setYearLoading(true);
    setregLoading(false);
  };
  const handleNextImgFile = (e) => {
    setaddAuctionData((prevState) => {
      return {
        ...prevState,
        ["front_img"]: selectedFrontFile,
        ["back_img"]: selectedBackFile,
        ["tyre_img"]: selectedTyreFile,
        ["side_img"]: selectedSideFile,
        ["engine_img"]: selectedEngineFile,
        ["misc_img"]: selectedMiscFile,
      };
    });
    setaddAuctionList((prevState) => {
      return {
        ...prevState,
        ["front_img"]: selectedFrontFile,
        ["back_img"]: selectedBackFile,
        ["tyre_img"]: selectedTyreFile,
        ["side_img"]: selectedSideFile,
        ["engine_img"]: selectedEngineFile,
        ["misc_img"]: selectedMiscFile,
      };
    });

    setImageLoading(true);
    setModal(false);
  };

  useEffect(() => {
    if (
      addAuctionData.state_id !== undefined &&
      addAuctionData.state_id !== ""
    ) {
      setBrandLoading(true);
      SubscriptionServices.GetAllMake().then((res) => {
        if (Object.entries(res).length !== 0) {
          setBrandLoading(false);
          setTractorBrand(res);
        }
      });
    }
  }, []);
  useEffect(() => {
    console.log({ StateName }, StateName.make);
    setModalLoading(true);

    let form_data = new FormData();
    form_data.append("id", addAuctionData.make_id);
    SubscriptionServices.GetAllModelNameById(form_data).then((res) => {
      if (Object.entries(res).length !== 0) {
        setModalLoading(false);
        setTractorModal(res);
      }
    });
  }, [StateName.make]);
  useEffect(() => {
    if (addAuctionList?.years !== "") {
      setYearLoading(false);
      const currentYear = new Date().getFullYear();
      const last20Years = Array.from(
        { length: 20 },
        (_, index) => currentYear - index
      );
      setYears(last20Years);
      setYearLoading(true);
    }
  }, []);

  const handlePils = (e, index, item) => {
    e.preventDefault();
    console.log({ e, index, item });
    if (index === 0) {
      setStageOne(false);
      setStageTwo(true);
      setStageThree(true);
      setYearLoading(true);
      setregLoading(true);
      setLocationLoading(true);
      setNoteLoading(true);
      setDateTimeLoading(true);
      setStartTimeLoading(true);
      setStartTimeLoading(true);
      setMinuteLoading(true);
      setRecommendedLoading(true);
      setLoanRefNoLoading(true);
    } else if (index === 1) {
      console.log("aditya");
      setStageOne(true);
      setStageTwo(false);
      setStageThree(true);
      setYearLoading(true);
      setregLoading(true);
      setLocationLoading(true);
      setNoteLoading(true);
      setDateTimeLoading(true);
      setStartTimeLoading(true);
      setStartTimeLoading(true);
      setMinuteLoading(true);
      setRecommendedLoading(true);
      setLoanRefNoLoading(true);
    } else if (index === 2) {
      setStageOne(true);
      setStageTwo(true);
      setStageThree(false);
      setYearLoading(true);
      setregLoading(true);
      setLocationLoading(true);
      setNoteLoading(true);
      setDateTimeLoading(true);
      setStartTimeLoading(true);
      setStartTimeLoading(true);
      setMinuteLoading(true);
      setRecommendedLoading(true);
      setLoanRefNoLoading(true);
    } else if (index === 3) {
      setStageOne(true);
      setStageTwo(true);
      setStageThree(true);
      setYearLoading(false);
      setregLoading(true);
      setLocationLoading(true);
      setNoteLoading(true);
      setDateTimeLoading(true);
      setStartTimeLoading(true);
      setStartTimeLoading(true);
      setMinuteLoading(true);
      setRecommendedLoading(true);
      setLoanRefNoLoading(true);
    } else if (index === 4) {
      setStageOne(true);
      setStageTwo(true);
      setStageThree(true);
      setYearLoading(true);
      setregLoading(false);
      setLocationLoading(true);
      setNoteLoading(true);
      setDateTimeLoading(true);
      setStartTimeLoading(true);
      setStartTimeLoading(true);
      setMinuteLoading(true);
      setRecommendedLoading(true);
      setLoanRefNoLoading(true);
    } else if (index === 5) {
      setStageOne(true);
      setStageTwo(true);
      setStageThree(true);
      setYearLoading(true);
      setregLoading(true);
      setLocationLoading(false);
      setNoteLoading(true);
      setDateTimeLoading(true);
      setStartTimeLoading(true);
      setStartTimeLoading(true);
      setMinuteLoading(true);
      setRecommendedLoading(true);
      setLoanRefNoLoading(true);
    } else if (index === 6) {
      setStageOne(true);
      setStageTwo(true);
      setStageThree(true);
      setYearLoading(true);
      setregLoading(true);
      setLocationLoading(true);
      setNoteLoading(false);
      setDateTimeLoading(true);
      setStartTimeLoading(true);
      setStartTimeLoading(true);
      setMinuteLoading(true);
      setRecommendedLoading(true);
      setLoanRefNoLoading(true);
    } else if (index === 7) {
      setStageOne(true);
      setStageTwo(true);
      setStageThree(true);
      setYearLoading(true);
      setregLoading(true);
      setLocationLoading(true);
      setNoteLoading(true);
      setDateTimeLoading(false);
      setStartTimeLoading(true);
      setStartTimeLoading(true);
      setMinuteLoading(true);
      setRecommendedLoading(true);
      setLoanRefNoLoading(true);
    } else if (index === 8) {
      setStageOne(true);
      setStageTwo(true);
      setStageThree(true);
      setYearLoading(true);
      setregLoading(true);
      setLocationLoading(true);
      setNoteLoading(true);
      setDateTimeLoading(true);
      setStartTimeLoading(false);
      setMinuteLoading(true);
      setRecommendedLoading(true);
      setLoanRefNoLoading(true);
    } else if (index === 9) {
      setStageOne(true);
      setStageTwo(true);
      setStageThree(true);
      setYearLoading(true);
      setregLoading(true);
      setLocationLoading(true);
      setNoteLoading(true);
      setDateTimeLoading(true);
      setStartTimeLoading(true);
      setMinuteLoading(false);
      setRecommendedLoading(true);
      setLoanRefNoLoading(true);
    } else if (index === 10) {
      setStageOne(true);
      setStageTwo(true);
      setStageThree(true);
      setYearLoading(true);
      setregLoading(true);
      setLocationLoading(true);
      setNoteLoading(true);
      setDateTimeLoading(true);
      setStartTimeLoading(true);
      setMinuteLoading(true);
      setRecommendedLoading(false);
      setLoanRefNoLoading(true);
    } else if (index === 11) {
      setStageOne(true);
      setStageTwo(true);
      setStageThree(true);
      setYearLoading(true);
      setregLoading(true);
      setLocationLoading(true);
      setNoteLoading(true);
      setDateTimeLoading(true);
      setStartTimeLoading(true);
      setMinuteLoading(true);
      setRecommendedLoading(true);
      setLoanRefNoLoading(false);
    }
  };

  try {
    return (
      <>
        {imgModal && (
          <AuctionvehicalImgModal
            show={imgModal}
            setImgModal={setImgModal}
            selectedFile={imgModalData}
            setImgModalData={setImgModalData}
            flag={flag}
            setselectedFrontFile={setSelectedFrontFile}
            selectedFrontFile={selectedFrontFile}
            selectedBackFile={selectedBackFile}
            selectedTyreFile={selectedTyreFile}
            setselectedBackFile={setSelectedBackFile}
            setselectedTyreFile={setSelectedTyreFile}
            setselectedSideFile={setSelectedSideFile}
            setselectedEngineFile={setSelectedEngineFile}
            setselectedMiscFile={setSelectedMiscFile}
            onHide={() => {
              setImgModal(false);
            }}
          />
        )}

        <Modal
          {...props}
          size="lg"
          backdrop="static"
          aaria-labelledby="contained-modal-title-vcenter"
          dialogClassName="modal-90w"
          centered
          fullscreen
        >
          <div className="col-12 form-group">
            <div className=" mx-2 my-2">
              <img
                className=""
                src={Back}
                alt="Back"
                width="3%"
                height="3%"
                onClick={handlePrev}
              />
              {Object.values(StateName)?.length !== 0 &&
                Object.values(StateName)?.map((item, index) => (
                  <div key={item} className="item-capsule ">
                    <span
                      className=" item-text"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        handlePils(e, index, item);
                      }}
                    >
                      {item}
                    </span>
                  </div>
                ))}
            </div>
          </div>

          <Modal.Header closeButton>
            <div
              id="example-custom-modal-styling-title"
              className="modal-title-custom container"
            >
              <h4>Edit Auction</h4>
            </div>
          </Modal.Header>

          <Modal.Body>
            {!stageOne && (
              <div className="table mt-3 container ">
                <table className="table table-hover table-responsive">
                  <thead
                    className="table-secondary"
                    style={{ fontSize: "25px" }}
                  >
                    <tr>
                      <th>State</th>
                    </tr>
                  </thead>

                  {stateList &&
                    Object.entries(stateList).length !== 0 &&
                    Object.entries(stateList).map(([key, value], index) => (
                      <tbody key={index}>
                        <tr>
                          <td
                            className={
                              value.state_name === StateName.state
                                ? "bg-primary"
                                : ""
                            }
                            onClick={(e) => handlselectState(e, index, value)}
                          >
                            {value.state_name}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                </table>
              </div>
            )}
            {console.log({ stageTwo, TractorBrand })}
            {!stageTwo &&
              addAuctionList.state !== undefined &&
              addAuctionList.state !== "" && (
                <div className="table mt-3 container">
                  <table className="table table-hover table-responsive">
                    <thead
                      className="table-secondary"
                      style={{ fontSize: "25px" }}
                    >
                      <tr>
                        <th>Select Tractor Brand</th>
                      </tr>
                    </thead>
                    {console.log({ stageTwo, TractorBrand, StateName })}
                    {TractorBrand &&
                      Object.entries(TractorBrand).length !== 0 &&
                      Object.entries(TractorBrand).map(
                        ([key, value], index) => (
                          <tbody key={index}>
                            <tr>
                              <td
                                onClick={(e) =>
                                  handlselectMake(e, index, value)
                                }
                                className={
                                  value.name === StateName.make
                                    ? "bg-primary"
                                    : ""
                                }
                              >
                                {value.name}
                              </td>
                            </tr>
                          </tbody>
                        )
                      )}
                  </table>
                </div>
              )}

            {!stageThree &&
              addAuctionList.make !== undefined &&
              addAuctionList.make !== "" && (
                <div className="table mt-3 container">
                  <table className="table table-hover table-responsive">
                    <thead
                      className="table-secondary"
                      style={{ fontSize: "25px" }}
                    >
                      <tr>
                        <th>Select Tractor Modal</th>
                      </tr>
                    </thead>

                    {TractorModal &&
                      Object.entries(TractorModal).length !== 0 &&
                      Object.entries(TractorModal).map(
                        ([key, value], index) => (
                          <tbody key={index}>
                            <tr>
                              <td
                                className={
                                  value.model_name === StateName.model
                                    ? "bg-primary"
                                    : ""
                                }
                                onClick={(e) =>
                                  handlselectModel(e, index, value)
                                }
                              >
                                {value.model_name}
                              </td>
                            </tr>
                          </tbody>
                        )
                      )}
                  </table>
                </div>
              )}
            {console.log({ StateName })}
            {!yearLoading &&
              addAuctionList.model !== undefined &&
              addAuctionList.model !== "" && (
                <div className="table mt-3 container">
                  <table className="table table-hover table-responsive">
                    <thead
                      className="table-secondary"
                      style={{ fontSize: "25px" }}
                    >
                      <tr>
                        <th>Select Tractor Year</th>
                      </tr>
                    </thead>
                    <tbody>
                      {years.map((year) => (
                        <tr key={year}>
                          <td
                            className={
                              year == StateName.year ? "bg-primary" : ""
                            }
                            onClick={(e) => handlselectYear(e, year)}
                          >
                            {year}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            {console.log({ regLoading })}
            {!regLoading &&
              addAuctionList.year !== undefined &&
              addAuctionList.year !== "" && (
                <div className="table mt-3 container">
                  <label>Enter Registration Number</label>
                  <input
                    className="form-control"
                    placeholder="Enter Registration Number"
                    type="text"
                    name="reg_no"
                    value={addAuctionList.reg_no}
                    onChange={handleChange}
                  />
                  <Button
                    variant="primary mt-2"
                    isDisabled={
                      addAuctionData.reg_no == "" ||
                      addAuctionData.reg_no == undefined
                        ? true
                        : false
                    }
                    onClick={handleNextReg}
                  >
                    Next
                  </Button>
                </div>
              )}
            {!locationLoading &&
              addAuctionList.reg_no !== undefined &&
              addAuctionList.reg_no !== "" && (
                <div className="table mt-3 container">
                  <label>Enter Location</label>
                  <input
                    className="form-control"
                    placeholder="Enter Location"
                    type="text"
                    name="location"
                    value={addAuctionList.location}
                    // onClick={handleselectTractor}
                    onChange={handleChange}
                  />
                  <Button variant="primary mt-2" onClick={handleNextLocation}>
                    Next
                  </Button>
                </div>
              )}
            {!noteLoading &&
              addAuctionList.location !== undefined &&
              addAuctionList.location !== "" && (
                <div className="table mt-3 container">
                  <label>Enter Note</label>
                  <input
                    className="form-control"
                    placeholder="Enter Note"
                    type="text"
                    name="note"
                    value={addAuctionList.note}
                    // onClick={handleselectTractor}
                    onChange={handleChange}
                  />
                  <Button variant="primary mt-2" onClick={handleNextNote}>
                    Next
                  </Button>
                </div>
              )}

            {!dateTimeLoading &&
              addAuctionList.note !== undefined &&
              addAuctionList.note !== "" && (
                <div className="table mt-3 container">
                  <label>Enter Start Date</label>
                  <input
                    className="form-control"
                    id="date"
                    name="date"
                    placeholder="Date"
                    type="date"
                    value={StateName.start_date}
                    onChange={(e) => handleChangeEndDate(e)}
                  />
                  <Button variant="primary mt-2" onClick={handleNextDate}>
                    Next
                  </Button>
                </div>
              )}
            {console.log({ startTimeLoading }, addAuctionList)}
            {!startTimeLoading &&
              StateName.start_date !== undefined &&
              StateName.start_date !== "" && (
                <div className="table mt-3 container">
                  <label>Enter Start Time</label>
                  <input
                    className="form-control"
                    id="start_time"
                    name="start_time"
                    placeholder="Date"
                    type="time"
                    value={StateName.start_time}
                    onChange={(e) => handleChangeEndDate(e)}
                  />
                  <Button variant="primary mt-2" onClick={handleNextTime}>
                    Next
                  </Button>
                </div>
              )}

            {!minuteLoading &&
              StateName.start_time !== undefined &&
              StateName.start_time !== "" && (
                <div className="table mt-3 container">
                  <label>Enter Minutes</label>
                  <input
                    className="form-control"
                    id="minutes"
                    name="minutes"
                    placeholder="Minutes"
                    type="number"
                    value={addAuctionList.minutes}
                    onChange={(e) => handleChange(e)}
                  />
                  <Button variant="primary mt-2" onClick={handleNextMinutes}>
                    Next
                  </Button>
                </div>
              )}
            {!recommendedLoading &&
              addAuctionList.minutes !== undefined &&
              addAuctionList.minutes !== "" && (
                <div className="table mt-3 container">
                  <label>Enter Recommended Bid</label>
                  <input
                    className="form-control"
                    id="recommendedBid"
                    name="recommendedBid"
                    placeholder="Recommended bid"
                    type="number"
                    value={addAuctionList.recommendedBid}
                    onChange={(e) => handleChange(e)}
                  />
                  <Button
                    variant="primary mt-2"
                    onClick={handleNextRecommended}
                  >
                    Next
                  </Button>
                </div>
              )}
            {!loanRefNoLoading &&
              addAuctionList.recommendedBid !== undefined &&
              addAuctionList.recommendedBid !== "" && (
                <div className="table mt-3 container">
                  <label>Enter Loan Reference No</label>
                  <input
                    className="form-control"
                    id="loan_ref_no"
                    name="loan_ref_no"
                    placeholder="Loan Reference No"
                    type="number"
                    value={StateName.loanRefNo}
                    onChange={(e) => handleChange(e)}
                  />
                  <Button variant="primary mt-2" onClick={handleNextLoanRefNo}>
                    Next
                  </Button>
                </div>
              )}
            {!imageLoading &&
              addAuctionList.loan_ref_no !== undefined &&
              addAuctionList.loan_ref_no !== "" && (
                <div className="container mt-2">
                  <div className="col-12 form-box">
                    <h4>Upload Tractor Images</h4>
                    <div className="row addImgRow mb-1 mt-3">
                      <div className="col-3 addImgPad me-2">
                        <div className="frontImageBox">
                          <div
                            className="myGallery"
                            id="front"
                            onClick={(e) => {
                              openFrontDialog(e, "front");
                            }}
                          >
                            <div className="image-upload ">
                              <div className="row">
                                <div className="col-3">
                                  <p>Front</p>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-2 ms-auto">
                                  <label for="front-file-input">
                                    <i className="bi bi-plus"></i>
                                  </label>

                                  <input
                                    type="file"
                                    id="front-file-input"
                                    name="front-file-input"
                                    multiple
                                    onChange={(e) =>
                                      handleImgFileChange(e, "front")
                                    }
                                    hidden
                                  />
                                </div>
                              </div>
                              <div>
                                {selectedFrontFile.map((value, index) => (
                                  <img
                                    key={index}
                                    // src={value ? value.name : ""}
                                    src={
                                      value
                                        ? URL.createObjectURL(value, index)
                                        : ""
                                    }
                                    id={`front${index}`}
                                    alt="img"
                                    show={selectedFrontFile.length < 6}
                                    onClick={(e) => {
                                      setImgModal(true);
                                      setImgModalData(selectedFrontFile);
                                      setFlag("front");
                                    }}
                                    style={{
                                      margin: "end",
                                      height: "30px",
                                      width: "30px",
                                    }}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-3 addImgPad ms-4 me-2">
                        <div className="frontImageBox">
                          <div
                            className="myGallery"
                            id="back"
                            onClick={(e) => {
                              openFrontDialog(e, "back");
                            }}
                          >
                            <div className="image-upload ">
                              <div className="row">
                                <div className="col-3">
                                  <p>Back</p>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-2 ms-auto">
                                  <label for="back-file-input">
                                    <i className="bi bi-plus"></i>
                                  </label>

                                  <input
                                    type="file"
                                    id="back-file-input"
                                    name="back-file-input"
                                    multiple
                                    onChange={(e) =>
                                      handleImgBackFileChange(e, "back")
                                    }
                                    hidden
                                  />
                                </div>
                              </div>
                              <div>
                                {selectedBackFile.map((value, index) => (
                                  <img
                                    src={
                                      value
                                        ? URL.createObjectURL(value, index)
                                        : ""
                                    }
                                    id={`back${index}`}
                                    alt="img"
                                    show={
                                      selectedBackFile.length < 6 ? true : false
                                    }
                                    onClick={(e) => {
                                      setImgModal(true);
                                      setImgModalData(selectedBackFile);
                                      setFlag("back");
                                    }}
                                    style={{
                                      margin: "end",
                                      height: "30px",
                                      width: "30px",
                                    }}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-3 addImgPad ms-4 me-2">
                        <div className="frontImageBox">
                          <div
                            className="myGallery"
                            id="tyre"
                            onClick={(e) => {
                              openFrontDialog(e, "tyre");
                            }}
                          >
                            <div className="image-upload ">
                              <div className="row">
                                <div className="col-3">
                                  <p>Tyre</p>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-2 ms-auto">
                                  <label for="tyre-file-input">
                                    <i className="bi bi-plus"></i>
                                  </label>

                                  <input
                                    type="file"
                                    id="tyre-file-input"
                                    name="tyre-file-input"
                                    multiple
                                    onChange={(e) =>
                                      handleImgTyreFileChange(e, "tyre")
                                    }
                                    hidden
                                  />
                                </div>
                              </div>
                              <div>
                                {selectedTyreFile.map((value, index) => (
                                  <img
                                    src={
                                      value
                                        ? URL.createObjectURL(value, index)
                                        : ""
                                    }
                                    id={`tyre${index}`}
                                    alt="img"
                                    onClick={(e) => {
                                      setImgModal(true);
                                      setImgModalData(selectedTyreFile);
                                      setFlag("tyre");
                                    }}
                                    style={{
                                      margin: "end",
                                      height: "30px",
                                      width: "30px",
                                    }}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row addImgRow mb-1 mt-3">
                      <div className="col-3 addImgPad me-2">
                        <div className="frontImageBox">
                          <div
                            className="myGallery"
                            id="side"
                            onClick={(e) => {
                              openFrontDialog(e, "side");
                            }}
                          >
                            <div className="image-upload ">
                              <div className="row">
                                <div className="col-3">
                                  <p>Side</p>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-2 ms-auto">
                                  <label for="side-file-input">
                                    <i className="bi bi-plus"></i>
                                  </label>

                                  <input
                                    type="file"
                                    id="side-file-input"
                                    name="side-file-input"
                                    // value={selectedFrontFile.find((val)=>val)}
                                    multiple
                                    onChange={(e) =>
                                      handleImgSideFileChange(e, "side")
                                    }
                                    hidden
                                  />
                                </div>
                              </div>
                              <div>
                                {selectedSideFile.map((value, index) => (
                                  <img
                                    key={index}
                                    src={
                                      value
                                        ? URL.createObjectURL(value, index)
                                        : ""
                                    }
                                    id={`side${index}`}
                                    alt="img"
                                    onClick={(e) => {
                                      setImgModal(true);
                                      setImgModalData(selectedSideFile);
                                      setFlag("side");
                                    }}
                                    style={{
                                      margin: "end",
                                      height: "30px",
                                      width: "30px",
                                    }}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-3 addImgPad ms-4 me-2">
                        <div className="frontImageBox">
                          <div
                            className="myGallery"
                            id="engine"
                            onClick={(e) => {
                              openFrontDialog(e, "engine");
                            }}
                          >
                            <div className="image-upload ">
                              <div className="row">
                                <div className="col-3">
                                  <p>Engine</p>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-2 ">
                                  <label for="engine-file-input">
                                    <i className="bi bi-plus"></i>
                                  </label>

                                  <input
                                    type="file"
                                    id="engine-file-input"
                                    name="engine-file-input"
                                    multiple
                                    onChange={(e) =>
                                      handleImgEngineFileChange(e, "engine")
                                    }
                                    hidden
                                  />
                                </div>
                              </div>
                              <div>
                                {selectedEngineFile.map((value, index) => (
                                  <img
                                    src={
                                      value
                                        ? URL.createObjectURL(value, index)
                                        : ""
                                    }
                                    id={`engine${index}`}
                                    alt="img"
                                    onClick={(e) => {
                                      setImgModal(true);
                                      setImgModalData(selectedEngineFile);
                                      setFlag("engine");
                                    }}
                                    style={{
                                      margin: "end",
                                      height: "30px",
                                      width: "30px",
                                    }}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-3 addImgPad ms-4 me-2">
                        <div className="frontImageBox">
                          <div
                            className="myGallery"
                            id="misc"
                            onClick={(e) => {
                              openFrontDialog(e, "misc");
                            }}
                          >
                            <div className="image-upload ">
                              <div className="row">
                                <div className="col-3">
                                  <p>Misc</p>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-2 ms-auto">
                                  <label for="misc-file-input">
                                    <i className="bi bi-plus"></i>
                                  </label>

                                  <input
                                    type="file"
                                    id="misc-file-input"
                                    name="misc-file-input"
                                    multiple
                                    onChange={(e) =>
                                      handleImgMiscFileChange(e, "misc")
                                    }
                                    hidden
                                  />
                                </div>
                              </div>
                              <div>
                                {selectedMiscFile.map((value, index) => (
                                  <img
                                    src={
                                      value
                                        ? URL.createObjectURL(value, index)
                                        : ""
                                    }
                                    id={`misc${index}`}
                                    alt="img"
                                    onClick={(e) => {
                                      setImgModal(true);
                                      setImgModalData(selectedMiscFile);
                                      setFlag("misc");
                                    }}
                                    style={{
                                      margin: "end",
                                      height: "30px",
                                      width: "30px",
                                    }}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Button variant="primary mt-3" onClick={handleNextImgFile}>
                      Submit
                    </Button>
                  </div>
                </div>
              )}

            {/* <div class="row mt-3">
            <label>Upload Tractor Images</label>
            <div class="row addImgRow">
              <div class="col-4 addImgPad">
                <div class="frontImageBox">
                  <div class="myGallery image-box" id="front-gallery"></div>
                  <input
                    type="file"
                    id="front-file-input"
                    name="front-file-input"
                    multiple
                    onchange="handleFileInputChange('front-gallery')"
                  />
                  <label for="front-file-input" class="image-upload">
                    <i class="bi bi-plus"></i>
                  </label>
                </div>
              </div>
            </div>
            <button
              class="btn btn-primary mt-2"
              onclick="handleNextLoanRefNo()"
            >
              Next
            </button>
          </div> */}
          </Modal.Body>

          {/* <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
          {data && data?.id !== "" ? (
            <>
              {UpdateButtonLoading ? (
                <button
                  className="btn btn-primary mt-2"
                  type="button"
                  disabled
                  id="loadingBtnTp"
                >
                  <span
                    className="spinner-border spinner-border-sm mx-1 px-2"
                    type="submit"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <Button
                  variant="primary"
                  // onClick={handleUpdate}
                >
                  Update
                </Button>
              )}
            </>
          ) : (
            <>
              {SubmitButtonLoading ? (
                <button
                  className="btn btn-primary mt-2"
                  type="button"
                  disabled
                  id="loadingBtnTp"
                >
                  <span
                    className="spinner-border spinner-border-sm mx-1 px-2"
                    type="submit"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <Button
                  variant="primary"
                  // onClick={handleSubmit}
                >
                  Submit
                </Button>
              )}
            </>
          )}
        </Modal.Footer> */}
        </Modal>
      </>
    );
  } catch (error) {
    console.log(error);
  }
};

export default EditAuctionModalData;
