import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import jwtDecode from "jwt-decode";
import ReactLoading from "react-loading";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
import { errorToast, successToast } from "../../../react-toastfiy/toast";

const AuctionDownloadDocuments = () => {
  const location = useLocation();
  const asset = location.pathname.split("/")[4]?.split("&")[0];
  const [asset_id, setAsset_id] = useState(asset);
  const [files, setFiles] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [editPrimary, setEditPrimary] = useState([]);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [edit, setEdit] = useState([]);
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  let phone = user.phone;

  useEffect(() => {
    if (asset_id !== "") {
      setLoading(true);
      let form_data = new FormData();
      form_data.append("asset_id", asset_id);
      SubscriptionServices.GetDownloadDocs(form_data).then((res) => {
        if (res.status === 404) {
          console.log({ res });
          setLoading(false);
        } else {
          console.log({ res });

          setData(res);
          setLoading(false);
        }
      });
    }
  }, [asset_id]);
  console.log({ data });

  const handleDownload = (e, value) => {
    e.preventDefault();
    const { file_name, url } = value;
    
    const link = document.createElement('a');
    link.href = url;
    link.download = file_name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className=" container mt-3">
        <div class="d-grid gap-2 mx-auto px-3 col-3 rounded">
          <h3>Download Documents</h3>
        </div>

        <div className="d-flex flex-wrap justify-content-center flex-wrap col-12 col-sm-10 col-md-10 col-lg-9 col-xl-8 ms-auto me-auto"></div>

        <div className="text-center mt-4">
          <div className="d-grid gap-2 mx-auto px-3 col-2 rounded">
            {Object.entries(data).map(([key, value], index) => (
              <button
                className="btn btn-success"
                type="submit"
                onClick={(e) => {
                  handleDownload(e, value);
                }}
                key={index}
              >
                {`Document ${index + 1}`}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AuctionDownloadDocuments;
