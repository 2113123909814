import axios from "axios";
import jwtDecode from "jwt-decode";
import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import auctionService from "../../services/auction/auction.service";

function AddOfflineBidModal(props) {
  const {
    onHide,
    uid,
    ref_no,
    setModal,
    setAuctionData,
    setFilterAuctionData,
    setDownloadLink,
    setIsLocked,
    setIsAdmin,
  } = props;
  const history = useHistory();
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  const initialValue = {
    name: "",
    phone: "",
    bid_value: "",
  };
  const source = axios.CancelToken.source();
  const [data, setData] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [debounceVal, setDebounceVal] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [fetchData, setFetchData] = useState(false);
  const [namefield, setNameField] = useState(false);
  const [phoneField, setPhoneField] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const form_data = new FormData();
      form_data.append("uid", uid);
      form_data.append("name", data.name);
      form_data.append("phone", data.phone);
      form_data.append("bid", data.bid_value);
      form_data.append("ref_no", ref_no);
      form_data.append("typeData", "");
      auctionService.UpdateAuctionSummary(form_data, source).then((res) => {
        setLoading(false);
        if (res.status === 200) {
          successToast(res?.msg);
          setModal(false);
          setLoading(true);
          const form_data = new FormData();
          form_data.append("phone", user?.phone);
          auctionService.GetAuctionAcr(uid, form_data, source).then((res) => {
            if (res?.data?.length >= 0) {
              setLoading(false);
              setAuctionData(res.data);
              setFilterAuctionData(res.data);
              setDownloadLink(res.link);
              setIsLocked(res.is_locked);
              setIsAdmin(res.admin);
            } else {
              setLoading(false);
              errorToast(res?.msg);
              setAuctionData(res.data);
              setFilterAuctionData(res.data);
            }
          });
        } else {
          errorToast(res?.msg);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    try {
      if (debounceVal !== "") {
        if (data?.phone !== "") {
          setPhoneField(true);
          const form_data = new FormData();
          form_data.append("phone", debounceVal);
          auctionService
            .GetBidderNameAndPhone(form_data, source)
            .then((res) => {
              setLoading(false);
              if (Object.values(res).length >= 0) {
                setSuggestions(Object.values(res));
                setData((prev) => ({
                  ...prev,
                  ["name"]: Object.values(res)[0]?.name,
                }));
              } else {
                errorToast(res?.msg);
              }
            });
        } else if (data?.name !== "") {
          setNameField(true);
          const form_data = new FormData();
          form_data.append("name", debounceVal);
          auctionService
            .GetBidderNameAndPhone(form_data, source)
            .then((res) => {
              setLoading(false);
              if (Object.values(res).length >= 0) {
                setSuggestions(Object.values(res));
                setData((prev) => ({
                  ...prev,
                  ["phone"]: Object.values(res)[0]?.phone,
                }));
              } else {
                errorToast(res?.msg);
              }
            });
        }
      } else {
        setData(initialValue);
      }
    } catch (error) {
      errorToast(error.msg);
    }
  }, [debounceVal]);

  function debounce(cb, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const debouncedChange = useCallback(
    debounce((inputValue) => {
      setDebounceVal(inputValue);
    }, 900),
    []
  );

  const handleChange = (e, name, key) => {
    const inputValue = e.target.value;

    const parts = inputValue.includes("-")
      ? inputValue.split("-")
      : [inputValue, ""];

    const value = parts[0];
    const phoneValue = (parts[1] || "").trim();
    const nameValue = parts[1] || "";

    if (name === "name") {
      setData((prev) => ({
        ...prev,
        [name]: value,
        ["phone"]: phoneValue,
      }));
      if (phoneValue !== "") {
        console.log("user selected");
      } else {
        debouncedChange(value);
      }
    } else if (name === "phone") {
      setData((prev) => ({
        ...prev,
        [name]: value,
        ["name"]: nameValue,
      }));
      debouncedChange(value);
    }
  };

  try {
    return (
      <Modal
        {...props}
        size="md"
        backdrop="static"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <h6>Add Offline Bid</h6>
        </Modal.Header>
        <Modal.Body>
          <div className="container table-responsive mt-2">
            <div className="col-12 mb-3">
              <lable>Phone</lable>
              <input
                className="form-control"
                type="number"
                list="phone"
                value={data.phone}
                onChange={(e) => {
                  handleChange(e, "phone");
                }}
              />
              {phoneField && (
                <datalist id="phone">
                  {suggestions &&
                    suggestions.length >= 0 &&
                    suggestions.map((value, key) => (
                      <option value={value.phone}>
                        {value.name} - {value.phone}
                      </option>
                    ))}
                </datalist>
              )}
            </div>
            <div className="col-12 my-3">
              <lable>Name</lable>
              <input
                className="form-control"
                type="text"
                list="name"
                value={data.name}
                onChange={(e) => {
                  handleChange(e, "name");
                }}
              />
              {namefield && (
                <datalist id="name">
                  {suggestions &&
                    suggestions.length >= 0 &&
                    suggestions.map((value, key) => (
                      <option
                        key={key}
                        value={`${value.name} - ${value.phone}`}
                      >
                        {value?.name} - {value?.phone}
                      </option>
                    ))}
                </datalist>
              )}
            </div>
            <div className="col-12 my-3">
              <lable>Bid Value</lable>
              <input
                className="form-control"
                type="number"
                onChange={(e) =>
                  setData((prev) => ({
                    ...prev,
                    ["bid_value"]: e?.target?.value,
                  }))
                }
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>
            Close
          </Button>

          {loading ? (
            <button
              className="btn btn-primary mt-2"
              type="button"
              disabled
              id="loadingBtnTp"
            >
              <span
                className="spinner-border spinner-border-sm me-1"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          ) : (
            <Button
              onClick={handleSubmit}
              disabled={
                data?.bid_value === "" ||
                data?.phone === "" ||
                data?.name === ""
              }
            >
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  } catch (error) {
    console.log(error);
  }
}

export default AddOfflineBidModal;
