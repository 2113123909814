import React, { useEffect, useState } from "react";
import EditAsset from "../../img/image_folder.png";
import AddAuction from "../../img/image_upload.png";
import summary from "../../img/summary.svg";
import ViewAuction from "../../img/view-auction.svg";

import { Link, useLocation, useHistory } from "react-router-dom";
import CardBlock from "../../common/CardBlock";
import ErrorHandler from "../../common/ErrorHandler";
import jwtDecode from "jwt-decode";

const ModifyAssets = () => {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  useEffect(() => {
    document.title = "Manage Auction Details";
  }, []);
  const history = useHistory();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 370);
  const location = useLocation();
  const CurrentURL = location.pathname;
  console.log({ CurrentURL });

  localStorage.setItem("currentUrl", CurrentURL);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 370);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const singleuploadbreadcrumb = {
    height: isSmallScreen ? "60px" : "30px",
    borderRadius: "0",
  };

  const singleuploadbreadcrumbMargin = {
    marginTop: isSmallScreen ? "-14px" : "-12px",
    marginBottom: isSmallScreen ? "14px" : "",
  };

  const searchParams = new URLSearchParams(location.search);
  const asset_id = searchParams.get("ref_no");
  const uid = searchParams.get("uid");
  const url = `${asset_id}&uid=${uid}`;

  try {
    return (
      <>
        <div className="container-fluid shadow-sm">
          <div className="container">
            <div className="row">
              <div className="d-sm-block d-md-none d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                  </div>
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-block d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12 col-md-6 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-6 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-none d-lg-block d-xl-block">
                <div className="row">
                  <div className="col-12 col-md-8 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-4 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container responsive mt-2 ">
          <div className="row mt-3 mb-2">
            <div className="d-flex justify-content-between col-1">
              <i
                className="bi bi-arrow-left-circle"
                onClick={() =>
                  history.push(`/meratractor/auctions/view/assets?uid=${uid}`)
                }
                style={{
                  fontSize: "xx-large",
                  color: "black",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              ></i>
            </div>
            <div className="col-10 mt-2 float-start ms-2">
              <h5 className="">{asset_id}</h5>
            </div>
          </div>
        </div>

        <div className="container container-body">
          <CardBlock
            name="Add Asset Image"
            logo={AddAuction}
            to={`/meratractor/auctions/modify/upload_asset_images?ref_no=${url}`}
          />

          <CardBlock
            name="Edit Asset Details"
            logo={ViewAuction}
            to={`/meratractor/auctions/modify/edit/asset?ref_no=${url}`}
          />
          <CardBlock
            name="View/Edit Asset Image"
            logo={EditAsset}
            to={`/meratractor/auctions/modify/view/asset_images?ref_no=${url}`}
          />
          <CardBlock
            name="Asset Summary"
            logo={summary}
            to={`/meratractor/auctions/modify/view/asset_summary?ref_no=${url}`}
          />
        </div>
      </>
    );
  } catch (error) {
    console.log(error);
    return <ErrorHandler error={error} />;
  }
};
export default ModifyAssets;
