import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
import Select from "react-select";
import { errorToast, successToast } from "../../../react-toastfiy/toast";
import Button from "react-bootstrap/Button";

const RapibidAddUser = () => {
  const { id } = useParams();
  console.log({ id });

  let initialValue = {
    first_name: "",
    middle_name: "",
    last_name: "",
    phone: "",
    password: "",
    is_admin: false,
    is_seller: false,
    is_auction_approval: false,
    is_viewer: false,
    is_active: false,
    email: "",
    aadhar: "",
    pan: "",
    city: "",
    district: "",
    address: "",
    state: "",
    pincode: "",
  };
  const [userData, setUserData] = useState(initialValue);
  const [stateData, setStateData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [loadingPincode, setloadingPincode] = useState(false);
  const [UpdateButtonLoading, setUpdateButtonLoading] = useState(false);
  const [SubmitButtonLoading, setSubmitButtonLoading] = useState(false);
  const RunPincodeUseEffect = useRef(false);

  const isPasswordMatch =
    userData.password !== "" && userData.password === userData.confirm_password;

  useEffect(() => {
    try {
      if (id && id !== "") {
        const userId = id.replace(":", "");
        console.log({ userId });
        setLoading(true);
        setStateLoading(true);
        let form_data = new FormData();
        form_data.append("id", userId);
        SubscriptionServices.GetUsersById(form_data).then((res) => {
          if (Object.entries(res).length !== 0) {
            setUserData(res);
            SubscriptionServices.GetAllState().then((response) => {
              let state_list = Object.entries(response).map(
                ([key, value], index) => ({
                  label: value.state_name,
                  value: value,
                })
              );
              setStateData(state_list);
              let state_id = state_list.find(
                (value) => value.label == res.state
              );
              console.log({ state_id, res });
              setUserData((prevState) => {
                return {
                  ...prevState,
                  state: state_id.value.id,
                };
              });
              setStateLoading(false);
            });

            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      }
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setStateLoading(false);
      setLoading(false);
    }
  }, [id]);

  async function getDataFromPincode(pincode) {
    console.log({ pincode });
    try {
      setloadingPincode(true);
      let response = await fetch(
        "https://api.postalpincode.in/pincode/" + pincode,
        {
          method: "GET",
          // headers: headersList
        }
      );
      console.log({ response });

      let data = await response.json();
      console.log("Aditya 1", { data });
      if (data[0].Status === "Success") {
        console.log("Aditya", { data });
        console.log("api call success");

        const State = data[0].PostOffice[0].State;
        console.log({ State });
        const District = data[0].PostOffice[0].District;
        console.log({ District });
        let tehsils = [];
        data[0].PostOffice.map((tehsilData) => {
          tehsils.push({ value: tehsilData.Name, label: tehsilData.Name });
        });

        setUserData((prevState) => ({
          ...prevState,
          state: State,
          district: District,
          city: tehsils,
        }));
        setloadingPincode(false);
      } else {
        console.log(data[0].Message);
        errorToast("Invalid Pincode");
        setloadingPincode(false);
      }
    } catch (error) {
      console.log(error);
      errorToast(error.message);
    }
  }

  useEffect(() => {
    if (RunPincodeUseEffect.current) {
      if (userData?.pincode !== undefined || userData?.pincode !== "") {
        if (userData?.pincode.length === 6) {
          setUserData((prevState) => ({
            ...prevState,
            state: "",
            district: "",
            city: "",
          }));
          getDataFromPincode(userData?.pincode);
        }
      }
    }
  }, [userData?.pincode]);

  useEffect(() => {
    try {
      setStateData([]);
      setUserData((prevState) => {
        return {
          ...prevState,
          ["state"]: "",
        };
      });
      if (userData?.pincode !== undefined || userData?.pincode !== "") {
        if (userData?.pincode.length === 6) {
          setStateLoading(true);

          SubscriptionServices.GetAllState().then((response) => {
            let state_list = Object.entries(response).map(
              ([key, value], index) => ({
                label: value.state_name,
                value: value,
              })
            );
            if (id && id !== "") {
              setStateData(state_list);
            } else {
              setStateData(state_list);
              // setUserData((prevState) => {
              //   return {
              //     ...prevState,
              //     ["state"]: state_list[0].label,
              //   };
              // });
            }
            setStateLoading(false);
          });
        }
      }
    } catch (error) {
      console.log(error);
      errorToast(error.message);
      setStateLoading(false);
    }
  }, [userData?.pincode]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log({ name, value });

    setUserData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const handleChangeState = (e) => {
    const { id } = e.value;
    console.log({ id }, e.value.id);

    setUserData((prevState) => {
      return {
        ...prevState,
        state: id,
      };
    });
  };

  const handleChangeCheckedBox = (e) => {
    const { name, checked } = e.target;
    console.log({ name, checked });

    setUserData((prevState) => {
      return {
        ...prevState,
        [name]: checked,
      };
    });
  };
  console.log({ userData, stateData });

  const handleSubmit = () => {
    console.log("Aditya");
    setSubmitButtonLoading(true);
    let form_data = new FormData();
    form_data.append("first_name", userData?.first_name);
    form_data.append("middle_name", userData?.middle_name);
    form_data.append("last_name", userData?.last_name);
    form_data.append("phone", userData?.phone);
    form_data.append("state", userData?.state);
    form_data.append("password", userData?.password);
    form_data.append("is_admin", userData?.is_admin);
    form_data.append("is_seller", userData?.is_seller);
    form_data.append("is_auction_approval", userData?.is_auction_approval);
    form_data.append("is_viewer", userData?.is_viewer);
    form_data.append("is_active", userData?.is_active);
    form_data.append("email", userData?.email);
    form_data.append("aadhar", userData?.aadhar);
    form_data.append("pan", userData?.pan);
    form_data.append("city", userData?.city);
    form_data.append("district", userData?.district);
    form_data.append("address", userData?.address);
    SubscriptionServices.CreateUser(form_data).then((res) => {
      console.log({ res });
      if (res.status === 200) {
        console.log({ res });
        successToast(res.msg);
        setSubmitButtonLoading(false);
        setTimeout(() => {
          window.location.replace("/rapidbid/manage-user");
        }, 500);
      } else {
        console.log(res.msg);
        setSubmitButtonLoading(false);
      }
    });
  };
  const handleUpdate = () => {
    console.log("Aditya");
    setSubmitButtonLoading(true);
    let form_data = new FormData();
    form_data.append("id", userData?.id);
    form_data.append("first_name", userData?.first_name);
    form_data.append("middle_name", userData?.middle_name);
    form_data.append("last_name", userData?.last_name);
    form_data.append("phone", userData?.phone);
    form_data.append("state", userData?.state);
    form_data.append("is_active", userData?.is_active);
    form_data.append("is_admin", userData?.is_admin);
    form_data.append("is_seller", userData?.is_seller);
    form_data.append("is_auction_approval", userData?.is_auction_approval);
    form_data.append("is_viewer", userData?.is_viewer);
    form_data.append("email", userData?.email);
    form_data.append("aadhar", userData?.aadhar);
    form_data.append("pan", userData?.pan);
    form_data.append("city", userData?.city);
    form_data.append("district", userData?.district);
    form_data.append("address", userData?.address);
    SubscriptionServices.UpdateUser(form_data).then((res) => {
      if (res.status === 200) {
        successToast(res.msg);
        setSubmitButtonLoading(false);
        setTimeout(() => {
          window.location.replace("/rapidbid/manage-user");
        }, 500);
      } else {
        console.log(res.msg);
        setSubmitButtonLoading(false);
      }
    });
  };

  return (
    <>
      <div className="container d-flex d-flex-wrap justify-content-center ">
        <div
          className=" col-md-9 col-12 shadow-lg bg-body p-3 mb-2  rounded"
          style={{
            marginTop: "2rem",
          }}
        >
          {id && id !== "" ? (
            <h2 className="text-center">Update User</h2>
          ) : (
            <h2 className="text-center">Create User</h2>
          )}
          <hr className="soften mt-2" />
          <form
            id="userform"
            method="POST"
            enctype="multipart/form-data"
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-md-4 mb-3">
                <label >First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="first_name"
                  name="first_name"
                  value={userData?.first_name}
                  onChange={handleChange}
                  placeholder="First Name"
                />
              </div>
              <div className="col-md-4 mb-3">
                <label >Middle Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="middlename"
                  name="middle_name"
                  value={userData?.middle_name}
                  onChange={handleChange}
                  placeholder="Middle Name"
                />
              </div>
              <div className="col-md-4">
                <label >Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastname"
                  name="last_name"
                  onChange={handleChange}
                  value={userData?.last_name}
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 mb-3">
                <label >Email ID</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  value={userData?.email}
                  placeholder="Email"
                />
              </div>
              <div className="col-md-6">
                <label >Phone no</label>
                <input
                  type="text"
                  className="form-control phone"
                  id="phone"
                  name="phone"
                  value={userData?.phone}
                  onChange={handleChange}
                  placeholder="Phone"
                />
              </div>
            </div>
            <div className="row mt-3">
              {/* <div className="col-md-6 mb-3">
                <label for="password" >
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label for="password2" >
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirm_password"
                  onChange={handleChange}
                  placeholder="Confirm Password"
                  required
                />
              </div> */}
              {id && id == "" && (
                <>
                  {" "}
                  <div className="col-md-6 mb-3">
                    <label htmlFor="password" >
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder="Password"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="password2" >
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      className={`form-control ${
                        !isPasswordMatch ? "is-invalid" : ""
                      }`}
                      id="password2"
                      name="confirm_password"
                      onChange={handleChange}
                      placeholder="Confirm Password"
                      required
                    />
                    {!isPasswordMatch && userData.password !== "" && (
                      <div className="invalid-feedback">
                        Passwords do not match.
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="row mt-3">
              <div className="col-md-4 mb-3">
                <label >State</label>
                <Select
                  isLoading={stateLoading}
                  options={stateData}
                  onChange={(e) => handleChangeState(e)}
                  value={stateData.find(
                    (option) => option.value.id === userData?.state
                  )}
                  placeholder="Select Plan Type"
                />
              </div>

              <div className="col-md-7 ms-auto">
                <label >Address</label>
                <textarea
                  type="text"
                  className="form-control"
                  id="address"
                  name="address"
                  rows="1"
                  value={userData?.address}
                  onChange={handleChange}
                  placeholder="Address"
                ></textarea>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4 mb-3">
                <label >City</label>
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  value={userData?.city}
                  onChange={handleChange}
                  name="city"
                  placeholder="City"
                />
              </div>
              <div className="col-md-4 mb-3">
                <label >District</label>
                <input
                  type="text"
                  className="form-control"
                  id="district"
                  value={userData?.district}
                  onChange={handleChange}
                  name="district"
                  placeholder="District"
                />
              </div>
              <div className="col-md-4 mb-3">
                <label >Pin Code</label>
                <input
                  type="number"
                  className="form-control"
                  id="pincode"
                  // value={userData?.pincode}
                  value={userData?.pincode}
                  onChange={(e) => (
                    handleChange(e), (RunPincodeUseEffect.current = true)
                  )}
                  // onChange={handleChange}
                  name="pincode"
                  placeholder="Pin Code"
                />
              </div>
              <div className="col-md-6 mb-3 me-auto">
                <label >Aadhar</label>
                <input
                  type="number"
                  className="form-control"
                  id="aadhar"
                  value={userData?.aadhar}
                  onChange={handleChange}
                  name="aadhar"
                  placeholder="Aadhar"
                />
              </div>
              <div className="col-md-6 ms-auto">
                <label >Pan</label>
                <input
                  type="text"
                  className="form-control"
                  id="pan"
                  value={userData?.pan}
                  onChange={handleChange}
                  name="pan"
                  placeholder="Pan"
                />
              </div>
            </div>

            {/* <div className="row mt-3 custom-control custom-checkbox">
                  <div className="col-md-3">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="is_admin"
                      name="is_admin"
                    />
                    <label className="custom-control-label" for="is_admin">
                      is_admin
                    </label>
                  </div>
                  <div className="col-md-3">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="is_seller"
                      name="is_seller"
                    />
                    <label className="custom-control-label" for="is_seller">
                      is_seller
                    </label>
                  </div>
                  <div className="col-md-3">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="is_auction_approval"
                      name="is_auction_approval"
                    />
                    <label
                      className="custom-control-label"
                      for="is_auction_approval"
                    >
                      is_auction_approval
                    </label>
                  </div>
                  <div className="col-md-3">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="is_viewer"
                      name="is_viewer"
                    />
                    <label className="custom-control-label" for="is_viewer">
                      is_viewer
                    </label>
                  </div>
                </div> */}

            <div className="d-flex flex-wrap content-between-center col-12 col-sm-12 col-md-12 col-lg-12 my-3 mx-1 gap-3">
              <div className="d-flex justify-content-end">
                <input
                  className="time_checkbox"
                  type="checkbox"
                  id="is_admin"
                  name="is_admin"
                  value={userData?.is_admin}
                  checked={userData?.is_admin}
                  onChange={handleChangeCheckedBox}
                />
                <label
                  htmlFor="recommended"
                  style={{ marginLeft: "3px", marginTop: "3px" }}
                  className=" form-label"
                >
                  is_admin
                </label>
              </div>
              <div className="d-flex justify-content-end">
                <input
                  className="time_checkbox"
                  type="checkbox"
                  id="is_seller"
                  name="is_seller"
                  value={userData?.is_seller}
                  checked={userData?.is_seller}
                  onChange={handleChangeCheckedBox}
                />
                <label
                  htmlFor="active"
                  style={{ marginLeft: "3px", marginTop: "2px" }}
                  className="form-label"
                >
                  is_seller
                </label>
              </div>
              <div className="d-flex justify-content-end">
                <input
                  className="time_checkbox"
                  type="checkbox"
                  id="is_auction_approval"
                  name="is_auction_approval"
                  value={userData?.is_auction_approval}
                  checked={userData?.is_auction_approval}
                  onChange={handleChangeCheckedBox}
                />

                <label
                  htmlFor="disabled"
                  style={{
                    marginLeft: "3px",
                    marginTop: "3px",
                    marginRight: "3px",
                  }}
                  className="form-label"
                >
                  is_auction_approval
                </label>
              </div>
              <div className="d-flex justify-content-end">
                <input
                  className="time_checkbox"
                  type="checkbox"
                  id="is_viewer"
                  name="is_viewer"
                  value={userData?.is_viewer}
                  checked={userData?.is_viewer}
                  onChange={handleChangeCheckedBox}
                />
                <label
                  htmlFor="free"
                  style={{ marginLeft: "3px", marginTop: "2px" }}
                  className=" form-label"
                >
                  is_viewer
                </label>
              </div>
              <div className="d-flex justify-content-end">
                <input
                  className="time_checkbox"
                  type="checkbox"
                  id="is_active"
                  name="is_active"
                  value={userData?.is_active}
                  checked={userData?.is_active}
                  onChange={handleChangeCheckedBox}
                />
                <label
                  htmlFor="free"
                  style={{ marginLeft: "3px", marginTop: "2px" }}
                  className=" form-label"
                >
                  is_active
                </label>
              </div>
            </div>

            <div className=" mt-3 text-center">
              <Button variant="danger me-2">Cancel</Button>

              {id && id !== "" ? (
                <>
                  {UpdateButtonLoading ? (
                    <button
                      className="btn btn-primary mt-2"
                      type="button"
                      disabled
                      id="loadingBtnTp"
                    >
                      <span
                        className="spinner-border spinner-border-sm mx-1 px-2"
                        type="submit"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <Button variant="primary" onClick={handleUpdate}>
                      Update
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {SubmitButtonLoading ? (
                    <button
                      className="btn btn-primary mt-2"
                      type="button"
                      disabled
                      id="loadingBtnTp"
                    >
                      <span
                        className="spinner-border spinner-border-sm mx-1 px-2"
                        type="submit"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <Button variant="primary" onClick={handleSubmit}>
                      Submit
                    </Button>
                  )}
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default RapibidAddUser;
