import React, { useEffect } from "react";
import UserWallet from "../../../img/add_user_wallet.png";

import CardBlock from "../../../common/CardBlock";
import ErrorHandler from "../../../common/ErrorHandler";

function SegmentHome(props) {
  useEffect(() => {
    document.title = "Home";
  }, []);

  try {
    return (
      <>
        <div className="container container-body">
          <CardBlock
            name="Manage Segment"
            logo={UserWallet}
            to="/meratractor/manage-segments"
          />
          {/* <CardBlock
            name="Admin section"
            logo={LogoutUser}
            to="/admin_user/auction/segment/"
          /> */}
        </div>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
}

export default SegmentHome;
