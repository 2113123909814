import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useHistory, useLocation } from "react-router-dom";
import SearchBox from "../../common/SearchBox";
import { indianCurrencyFormater } from "../../helpers/functions/currencyFormater";
import { errorToast } from "../../react-toastfiy/toast";
import auctionService from "../../services/auction/auction.service";

const AuctionSummary = () => {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwtDecode(currentUser);
  const phone = user?.phone;
  const source = axios.CancelToken.source();
  useEffect(() => {
    document.title = "Auction Summary";
  }, []);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 580);
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 580);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const singleuploadbreadcrumb = {
    marginLeft: isSmallScreen ? "-10px" : "auto",
    marginBottom: isSmallScreen ? "12px" : "",
  };

  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const auction_id = searchParams.get("uid");

  const headers = [
    { header: "Reference No", dataKey: "reference_no" },
    { header: "Auction ID", dataKey: "auction_id" },
    { header: "Seller Name", dataKey: "seller_name" },
    { header: "Make Model", dataKey: "make_model" },
    { header: "Registration No", dataKey: "reg_no" },
    { header: "Year", dataKey: "Year" },
    {
      header: "location",
      dataKey: "location",
    },
    { header: "Engine No", dataKey: "eng_no" },
    { header: "Chassis No", dataKey: "chassis_no" },
    { header: "Max Bid", dataKey: "max_bid" },
    { header: "Bidder Name", dataKey: "bidder_name" },
    { header: "Bidder Phone", dataKey: "bidder_phone" },
    { header: "Bidder Pan", dataKey: "Bidder Pan" },
    { header: "Date", dataKey: "date" },
    { header: "Franchise", dataKey: "is_franchised" },
    { header: "Subscription Status", dataKey: "Subscription Status" },
    {
      header: "Last Subscribed bidder name",
      dataKey: "Last Subscribed bidder name",
    },
    {
      header: "Last Subscribed bidder phone",
      dataKey: "Last Subscribed bidder phone",
    },
  ];

  const [auctionData, setAuctionData] = useState([]);
  const [filterAuctionData, setFilterAuctionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadLink, setDownloadLink] = useState("");
  const [showFullPrice, setShowFullPrice] = useState("");

  useEffect(() => {
    try {
      setLoading(true);
      const form_data = new FormData();
      form_data.append("uid", auction_id);
      auctionService.GetAuctionSummary(form_data, source).then((res) => {
        setLoading(false);
        if (res.status === 200) {
          const newResponse = res.data.map((item) => ({
            ...item,
            reference_no: item["Reference No"],
            auction_id: item["Auction ID"],
            seller_name: item["Seller Name"],
            bidder_name: item["Bidder Name"],
            bidder_phone: item["Bidder Phone"],
            make_model: item["Make Model"],
            year: item["Year"],
          }));
          setAuctionData(newResponse);
          setFilterAuctionData(newResponse);
          setDownloadLink(res.link);
        } else {
          errorToast(res?.msg);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleDownload = (e) => {
    e.preventDefault();
    window.location.href = downloadLink;
  };
  const handleShowPrice = (e, reference_no) => {
    e.preventDefault();
    try {
      const form_data = new FormData();
      form_data.append("uid", auction_id);
      form_data.append("ref_no", reference_no);
      form_data.append("phone", phone);
      auctionService.CheckBidAmount(form_data).then((res) => {
        if (res?.status === 200) {
          setFilterAuctionData((prevData) =>
            Object.entries(prevData).map(([key, item], index) =>
              item.reference_no === reference_no
                ? { ...item, current_price: res?.data?.bid }
                : item
            )
          );
          setAuctionData((prevData) =>
            Object.entries(prevData).map(([key, item], index) =>
              item.reference_no === reference_no
                ? { ...item, current_price: res?.data?.bid }
                : item
            )
          );

          setShowFullPrice(reference_no);
        } else if (res?.status === 400) {
          setFilterAuctionData((prevData) =>
            Object.entries(prevData).map(([key, item], index) =>
              item.reference_no === reference_no
                ? { ...item, current_price: 0 }
                : item
            )
          );
          setAuctionData((prevData) =>
            Object.entries(prevData).map(([key, item], index) =>
              item.reference_no === reference_no
                ? { ...item, current_price: 0 }
                : item
            )
          );
          setShowFullPrice(reference_no);
        } else {
          errorToast(res?.msg);
        }
      });
    } catch (error) {}
  };

  return (
    <>
      <div className="container-fluid shadow-sm ms-2">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <div
          className="d-flex justify-content-center container"
          style={{ minHeight: "80vh", alignItems: "center" }}
        >
          <ReactLoading
            type={"bubbles"}
            color={"black"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <>
          <div className="container table-responsive">
            <div
              className="d-flex justify-content-between mb-2 mt-3"
              style={{ marginLeft: "12px" }}
            >
              <div
                className="col-md-5 col-lg-3 col-12"
                style={{ marginBottom: "17px", marginTop: "-10px" }}
              >
                <i
                  className="bi bi-arrow-left-circle"
                  onClick={() =>
                    history.push(
                      `/meratractor/auctions/modify?uid=${auction_id}`
                    )
                  }
                  style={{
                    fontSize: "xx-large",
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </div>
            <div className="d-flex-wrap justify-content-between">
              <div className="row ms-2">
                {Object.entries(filterAuctionData).length > 0 &&
                  Object.entries(filterAuctionData) && (
                    <button
                      className="btn btn-primary col-12 col-sm-5 col-md-3 col-lg-2 col-xl-2 me-2"
                      style={{ marginLeft: "4px", marginBottom: "13px" }}
                      onClick={handleDownload}
                    >
                      Download Excel
                    </button>
                  )}
                <div
                  className="col-md-4 col-lg-3 col-12 mb-3 me-3"
                  style={singleuploadbreadcrumb}
                >
                  <SearchBox
                    placeholder="Search by Reference No..."
                    allData={auctionData}
                    setFilteredData={setFilterAuctionData}
                  />
                </div>
              </div>
            </div>

            <div
              className="container-fluid  table-responsive text-center"
              style={{ minHeight: "5vh", maxHeight: "80vh" }}
            >
              <table className="table table-hover table-bordered">
                <thead className="thead-dark">
                  <tr
                    className="text-center"
                    style={{ fontSize: "small", position: "sticky", top: 0 }}
                  >
                    <th> Sr. No</th>
                    {headers.map((column) => (
                      <th className="col" key={column.header}>
                        {column.header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(filterAuctionData) &&
                  Object.entries(filterAuctionData).length === 0 ? (
                    <div className=" text-danger text-center"></div>
                  ) : (
                    Object.entries(filterAuctionData).map(
                      (rowData, rowIndex) => (
                        <tr
                          key={rowIndex}
                          style={{
                            fontSize: "small",
                          }}
                        >
                          <td>{rowIndex + 1}</td>
                          {headers.map((header, index) => (
                            <>
                              {header.dataKey === "reference_no" ? (
                                <td
                                  key={index}
                                  className="text-primary"
                                  onClick={() => {
                                    history.push(
                                      `/meratractor/auctions/modify/view/asset_summary?ref_no=${
                                        rowData[1]["reference_no"]
                                      }&uid=${auction_id}&prevUrl=${"auctionSummary"}`
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <b>{rowData[1][header.dataKey]}</b>
                                </td>
                              ) : header.dataKey === "max_bid" ? (
                                <td
                                  onClick={(e) =>
                                    handleShowPrice(e, rowData[1]?.reference_no)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <b>
                                    {showFullPrice === rowData[1]?.reference_no
                                      ? indianCurrencyFormater(
                                          rowData[1][header.dataKey],
                                          0
                                        )
                                      : indianCurrencyFormater(
                                          Number(
                                            String(
                                              rowData[1][header.dataKey]
                                            ).slice(0, 0) + "0000"
                                          )
                                        ).replace(/0/g, "X")}
                                  </b>
                                </td>
                              ) : (
                                <td key={index}>
                                  {rowData[1][header.dataKey]}
                                </td>
                              )}
                            </>
                          ))}
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </table>
              {Object.entries(filterAuctionData) &&
                Object.entries(filterAuctionData).length === 0 && (
                  <div className="text-danger text-center">No data found.</div>
                )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AuctionSummary;
