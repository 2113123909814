import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ErrorHandler from "../../common/ErrorHandler";
import CompletedAuction from "./CompletedAuction";
import LiveAuction from "./LiveAuction";
import UpcomimgAuction from "./UpcomimgAuction";
import jwtDecode from "jwt-decode";

const ViewAuction = () => {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwtDecode(currentUser);
  const history = useHistory();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 430);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 430);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const singleuploadbreadcrumb = {
    height: isSmallScreen ? "60px" : "30px",
    borderRadius: "0",
  };

  const singleuploadbreadcrumbMargin = {
    marginTop: isSmallScreen ? "-14px" : "-12px",
    marginBottom: isSmallScreen ? "14px" : "",
  };

  const [active, setactive] = useState("live");
  useEffect(() => {
    document.title = "View Auctions";
    let active_tab = localStorage.getItem("active_tab");
  }, []);

  const handlechangetab = (e) => {
    const id = e.target.getAttribute("id");
    setactive(id);
    localStorage.setItem("active_tab", id);
  };

  try {
    return (
      <>
        <div className="container-fluid shadow-sm">
          <div className="container">
            <div className="row">
              <div className="d-sm-block d-md-none d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                  </div>
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-block d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12 col-md-6 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-6 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-none d-lg-block d-xl-block">
                <div className="row">
                  <div className="col-12 col-md-8 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-4 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div className="d-flex justify-content-between">
            <i
              className="bi bi-arrow-left-circle"
              onClick={() => history.push("/meratractor/auction-home")}
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </div>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={
                  active === "live"
                    ? `nav-link active btn btn-link`
                    : `nav-link btn btn-link`
                }
                id="live"
                onClick={handlechangetab}
              >
                Live
              </button>
            </li>

            <li className="nav-item">
              <button
                className={
                  active === "upcoming"
                    ? `nav-link active btn btn-link`
                    : `nav-link btn btn-link`
                }
                id="upcoming"
                onClick={handlechangetab}
              >
                Upcoming
              </button>
            </li>

            <li className="nav-item">
              <button
                className={
                  active === "completed"
                    ? `nav-link active btn btn-link`
                    : `nav-link btn btn-link`
                }
                id="completed"
                onClick={handlechangetab}
              >
                Completed
              </button>
            </li>
          </ul>
        </div>

        {active === "live" && (
          <>
            <LiveAuction />
          </>
        )}

        {active === "upcoming" && (
          <>
            <UpcomimgAuction />
          </>
        )}

        {active === "completed" && (
          <>
            <CompletedAuction />
          </>
        )}
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
};

export default ViewAuction;
