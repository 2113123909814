import React, { useEffect } from "react";
import CardBlock from "../../common/CardBlock";

function SubscriptionHome() {
  useEffect(() => {
    document.title = "rapibid Home";
  }, []);
  return (
    <>
      <div className="container mt-2">
        <h1>Manage Subscription</h1>
        <hr />
      </div>
      <div className="container container-body">
        <CardBlock
          name="Plans"
          // logo={category}
          to="/subscription/manage-plan"
        />
        <CardBlock
          name="Active Expired User Plans"
          //  logo={spares}
          to="/subscription/manage-users-analysis"
        />

        <CardBlock
          name="Refund Approval Pending"
          // logo={mfg}
          to="/subscription/manage-refund-approval-pending"
        />
        <CardBlock
          name="User Transaction History"
          // logo={mfg}
          to="/subscription/manage-user-transaction-history"
        />
        <CardBlock
          name="Tenure"
          // logo={mfg}
          to="/subscription/manage-platform-tenure"
        />
      </div>
    </>
  );
}

export default SubscriptionHome;
