import "../../css/dashboard.css";
import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import _ from "lodash";
import dashboardService from "../../services/dashboard.service";
import { errorToast, successToast } from "./../../react-toastfiy/toast";
import jwt from "jwt-decode";

import * as Icon from "react-bootstrap-icons";
import ErrorHandler from "../../common/ErrorHandler";
import formService from "../../services/form.service";
import Swal from "sweetalert2";

function UpdateMilestoneDash(props) {
  const {
    state,
    id,
    onHide,
    milestone_data,
    operation_id,
    activity_id,
    task_id,
    phone,
    otherData,
    milestone_id,
    completed,
    dashboardFlag,
    stateName,
    isDisabledEdit,
    url,
    case_data,
  } = props;

  const [milestoneData, setMilestoneData] = useState({});
  const [loading, setloading] = useState(false);
  const [saveloading, setSaveloading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  console.log({case_data})

  useEffect(() => {
    try {
      let tempOption = {};
      tempOption["data"] = {};
      Object.entries(milestone_data.data_to_be_collected).map(
        ([key, value]) => {
          if (milestone_data.data_to_be_collected[key].input_type == "File") {
            if (milestone_data.data_to_be_collected[key].input_value == "") {
              tempOption[milestone_data.data_to_be_collected[key].input_label] =
                "";
              tempOption.data[
                milestone_data.data_to_be_collected[key].input_label
              ] = "";
            } else {
              tempOption[milestone_data.data_to_be_collected[key].input_label] =
                milestone_data.data_to_be_collected[key].input_value;
              tempOption.data[
                milestone_data.data_to_be_collected[key].input_label
              ] = milestone_data.data_to_be_collected[key].input_value;
            }
            // tempOption[milestone_data.data_to_be_collected[key].input_label] = ""
          } else if (
            milestone_data.data_to_be_collected[key].input_type == "Date"
          ) {
            if (milestone_data.data_to_be_collected[key].input_value == "") {
              tempOption[milestone_data.data_to_be_collected[key].input_label] =
                "";
              tempOption.data[
                milestone_data.data_to_be_collected[key].input_label
              ] = "";
            } else {
              tempOption[milestone_data.data_to_be_collected[key].input_label] =
                milestone_data.data_to_be_collected[key].input_value;
              tempOption.data[
                milestone_data.data_to_be_collected[key].input_label
              ] = milestone_data.data_to_be_collected[key].input_value;
            }
          } else if (
            milestone_data.data_to_be_collected[key].input_type == "TextField"
          ) {
            if (milestone_data.data_to_be_collected[key].input_value == "") {
              tempOption[milestone_data.data_to_be_collected[key].input_label] =
                "";
              tempOption.data[
                milestone_data.data_to_be_collected[key].input_label
              ] = "";
            } else {
              tempOption[milestone_data.data_to_be_collected[key].input_label] =
                milestone_data.data_to_be_collected[key].input_value;
              tempOption.data[
                milestone_data.data_to_be_collected[key].input_label
              ] = milestone_data.data_to_be_collected[key].input_value;
            }
          } else if (
            milestone_data.data_to_be_collected[key].input_type == "Select"
          ) {
            if (milestone_data.data_to_be_collected[key].input_value == "") {
              tempOption[milestone_data.data_to_be_collected[key].input_label] =
                "";
              tempOption.data[
                milestone_data.data_to_be_collected[key].input_label
              ] = "";
            } else {
              tempOption[milestone_data.data_to_be_collected[key].input_label] =
                milestone_data.data_to_be_collected[key].input_value;
              tempOption.data[
                milestone_data.data_to_be_collected[key].input_label
              ] = milestone_data.data_to_be_collected[key].input_value;
            }
          } else if (
            milestone_data.data_to_be_collected[key].input_type == "Checkbox"
          ) {
            if (milestone_data.data_to_be_collected[key].input_value == "") {
              tempOption[milestone_data.data_to_be_collected[key].input_label] =
                "";
              tempOption.data[
                milestone_data.data_to_be_collected[key].input_label
              ] = "";
            } else {
              tempOption[milestone_data.data_to_be_collected[key].input_label] =
                milestone_data.data_to_be_collected[key].input_value;
              tempOption.data[
                milestone_data.data_to_be_collected[key].input_label
              ] = milestone_data.data_to_be_collected[key].input_value;
            }
          } else {
            tempOption.data[
              milestone_data.data_to_be_collected[key].input_label
            ] = "";
          }
        }
        
      );
      if (dashboardFlag) {
        tempOption["milestone_id"] = milestone_id;
      } else {
        tempOption["milestone_id"] = milestone_data.milestone_id;
      }

      tempOption["operation_id"] = operation_id;
      tempOption["activity_id"] = activity_id;
      tempOption["task_id"] = task_id;
      tempOption["phone"] = phone;
      setMilestoneData(tempOption);
    } catch (error) {
      console.error(error);
      errorToast(error?.message);
    }
  }, []);

  const handleChangeTextField = (e, key) => {
    try {
      const { name, value } = e.target;
      let temptaskDataVal = _.cloneDeep(milestoneData);
      temptaskDataVal.data[name] = value;
      milestone_data.data_to_be_collected[key].input_value = value;
      setMilestoneData(temptaskDataVal);
    } catch (error) {
      console.error(error);
      errorToast(error?.message);
    }
  };

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      let temptaskDataVal = _.cloneDeep(milestoneData);
      temptaskDataVal.data[name] = value;
      temptaskDataVal[name] = value;
      setMilestoneData(temptaskDataVal);
      Object.entries(milestone_data.data_to_be_collected).map(
        ([key, index]) => {
          if (milestone_data.data_to_be_collected[key].input_label == name) {
            milestone_data.data_to_be_collected[key].input_value = value;
          }
        }
      );
    } catch (error) {
      console.error(error);
      errorToast(error?.message);
    }
  };

  const handleChangeFile = (e) => {
    try {
      let temptaskDataVal = _.cloneDeep(milestoneData);
      temptaskDataVal[e.target.name] = e.target.files[0];
      setMilestoneData(temptaskDataVal);
    } catch (error) {
      console.error(error);
      errorToast(error?.message);
    }
  };

  const validation = (defaultValue) => {
    let data = milestoneData.data;
    let returnError = {};
    Object.entries(data).map(([key, index]) => {
      let milestoneDataKey = key;
      Object.entries(defaultValue).map(([key, index]) => {
        if (milestoneDataKey == key) {
          if (defaultValue[key] != data[milestoneDataKey]) {
            returnError[key] =
              '"' + defaultValue[key] + '"' + " should be selected";
          }
        }
      });
    });

    return returnError;
  };

  const handleSave = async (e) => {
    try {
      let formData = new FormData();
      setSaveloading(true);
      Object.keys(milestoneData).map((key, i) => {
        if (key == "data") {
          formData.append(key, JSON.stringify(milestoneData[key]));
        } else {
          formData.append(key, milestoneData[key]);
        }
      });

      // make axios post request
      const response = await dashboardService.saveDataToBeCollected(formData);

      if (response.status === 200) {
        successToast(response.msg);
        setSaveloading(false);
        window.location.replace(window.location.href);
      }
      response.status !== 200 && errorToast(response.msg);
    } catch (error) {
      console.log(error);
      setSaveloading(false);
      errorToast(error?.message);
    }
  };

  const handleMarkAsCompleted = async (e) => {
    e.preventDefault();

    try {
      let validate = {};
      Object.entries(milestone_data.data_to_be_collected).map(
        ([key, index]) => {
          if (
            milestone_data.data_to_be_collected[key].input_type == "Select" &&
            milestone_data.data_to_be_collected[key].isRequiredValue
          ) {
            validate[milestone_data.data_to_be_collected[key].input_label] =
              milestone_data.data_to_be_collected[key].isRequiredData;
          }
        }
      );

      var errors = validation(validate);
      setFormErrors(errors || {});

      if (Object.keys(errors).length !== 0) return;
      setloading(true);
      let formData = new FormData();
      Object.keys(milestoneData).map((key, i) => {
        if (key == "data") {
          formData.append(key, JSON.stringify(milestoneData[key]));
        } else {
          formData.append(key, milestoneData[key]);
        }
      });

      // make axios post request
      const response = await dashboardService.markAsCompleted(formData);

      if (response.status === 200) {
        if (dashboardFlag) {
          let formData = new FormData();
          formData.append("task_id", task_id);
          formData.append("data", JSON.stringify({ release: "yes" }));
          const res = await formService.EditTaskData(formData);

          if (res.status === 200) {
            setloading(false);
            successToast(res.msg);
            if (
              milestone_data["user_designation"]["label"] ===
              "Territory Manager"
            ) {
              window.location.replace(
                "/tmDashboard:" + phone + "&state=" + stateName
              );
            } else {
              window.location.replace(
                "/auction_representative:" + phone + "&state=" + stateName
              );
            }
          }
        } else if (!isDisabledEdit) {
          window.location.replace(url);
        } else {
          if (response.status === 200) {
            if (response.activity_completed_flag === true) {
              setloading(false);
              successToast(response.msg);
              Swal.fire({
                title: "Task Completed",
                icon: "success",
              }).then((result) => window.location.replace("/"));
            } else {
              setloading(false);
              successToast(response.msg);
              window.location.reload();
            }
          }
        }
      }
      if (response.status !== 200) {
        errorToast(response.msg);
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      errorToast(error?.message);
      setloading(false);
    }
  };
  const queryString = require("query-string");
  const parsed = queryString.parse(window.location.pathname);

  const token = localStorage.getItem("refreshToken");
  const user = jwt(token);

  try {
    return (
      <>
        <Modal
          {...props}
          size="lg"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <div className="fs-2 d-flex justify-content-start">
              {milestone_data.milestone_name}
            </div>
            {/* <Modal.Title id="contained-modal-title-vcenter">
                {milestone_data.milestone_name}
              </Modal.Title> */}
          </Modal.Header>
          <form onSubmit={handleMarkAsCompleted} className="">
            <Modal.Body>
              {Object.entries(milestone_data.data_to_be_collected).map(
                ([key], index) => {
                  let isConditionalHidden = milestone_data?.data_to_be_collected[key]?.isConditionalHidden
                  let conditionalhiddenDataCondition_key = milestone_data?.data_to_be_collected[key]?.conditionalhiddenDataCondition?.condition_key
                  let conditionalhiddenDataCondition_value = milestone_data?.data_to_be_collected[key]?.conditionalhiddenDataCondition?.condition_value
                  
                  let isConditionalNotRequired = milestone_data?.data_to_be_collected[key]?.isConditionalNotRequired
                  let conditionalnotRequiredDataCondition_key = milestone_data?.data_to_be_collected[key]?.conditionalnotRequiredDataCondition?.condition_key
                  let conditionalnotRequiredDataCondition_value = milestone_data?.data_to_be_collected[key]?.conditionalnotRequiredDataCondition?.condition_value
                
                  // if (!isConditionalHidden && case_data[conditionalhiddenDataCondition_key] && case_data[conditionalhiddenDataCondition_key] !== conditionalhiddenDataCondition_value){
                  if (isConditionalHidden && case_data[conditionalhiddenDataCondition_key] === conditionalhiddenDataCondition_value){
                    return;
                  }
                  else{
                  if (
                    milestone_data.data_to_be_collected[key].input_type ==
                    "TextField"
                  ) {
                    if (completed || otherData) {
                      return (
                        <div className="row mb-2 pt-2" key={index}>
                          <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                            {
                              milestone_data.data_to_be_collected[key]
                                .input_label
                            }
                          </div>
                          <div className="col-md-6 col">
                            <input
                              disabled={isDisabledEdit ? true : false}
                              type="text"
                              className="form-control"
                              placeholder={
                                "Enter " +
                                milestone_data.data_to_be_collected[key]
                                  .input_label
                              }
                              name={
                                milestone_data.data_to_be_collected[key]
                                  .input_label
                              }
                              value={
                                milestone_data.data_to_be_collected[key]
                                  .input_value
                              }
                              onChange={(e) => handleChangeTextField(e, key)}
                            />
                          </div>
                        </div>
                      );
                    } else {
                      if (milestone_data.data_to_be_collected[key].isRequired) {
                        return (
                          <div className="row mb-2 pt-2" key={index}>
                            <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                              {
                                milestone_data.data_to_be_collected[key]
                                  .input_label
                              }
                              {(isConditionalNotRequired && case_data[conditionalnotRequiredDataCondition_key] === conditionalnotRequiredDataCondition_value) ? <span></span> : <span className="text-danger">*</span>}
                            </div>
                            <div className="col-md-6 col">
                              <input
                                type="text"
                                required = {isConditionalNotRequired && case_data[conditionalnotRequiredDataCondition_key] === conditionalnotRequiredDataCondition_value ? false : true}
                                className="form-control"
                                placeholder={
                                  "Enter " +
                                  milestone_data.data_to_be_collected[key]
                                    .input_label
                                }
                                name={
                                  milestone_data.data_to_be_collected[key]
                                    .input_label
                                }
                                value={
                                  milestone_data.data_to_be_collected[key]
                                    .input_value
                                }
                                onChange={(e) => handleChangeTextField(e, key)}
                              />
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className="row mb-2 pt-2" key={index}>
                            <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                              {
                                milestone_data.data_to_be_collected[key]
                                  .input_label
                              }
                            </div>
                            <div className="col-md-6 col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={
                                  "Enter " +
                                  milestone_data.data_to_be_collected[key]
                                    .input_label
                                }
                                name={
                                  milestone_data.data_to_be_collected[key]
                                    .input_label
                                }
                                value={
                                  milestone_data.data_to_be_collected[key]
                                    .input_value
                                }
                                onChange={(e) => handleChangeTextField(e, key)}
                              />
                            </div>
                          </div>
                        );
                      }
                    }
                  } else if (
                    milestone_data.data_to_be_collected[key].input_type ==
                    "Select"
                  ) {
                    if (completed || otherData) {
                      return (
                        <div className="row mb-2 pt-2" key={index}>
                          <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                            {
                              milestone_data.data_to_be_collected[key]
                                .input_label
                            }
                          </div>
                          <div className="col-md-6 col">
                            <select
                              disabled={isDisabledEdit ? true : false}
                              className="form-select w-100"
                              name={
                                milestone_data.data_to_be_collected[key]
                                  .input_label
                              }
                              value={
                                milestone_data.data_to_be_collected[key]
                                  .input_value
                              }
                              onChange={handleChange}
                            >
                              {milestone_data.data_to_be_collected[
                                key
                              ].input_options.map((data) => (
                                <option key={data.id} value={data.id}>
                                  {data.data}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      );
                    } else {
                      if (milestone_data.data_to_be_collected[key].isRequired) {
                        return (
                          <div className="row mb-2 pt-2" key={index}>
                            <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                              {
                                milestone_data.data_to_be_collected[key]
                                  .input_label
                              }
                              {(isConditionalNotRequired && case_data[conditionalnotRequiredDataCondition_key] === conditionalnotRequiredDataCondition_value) ? <span></span> : <span className="text-danger">*</span>}

                            </div>
                            <div className="col-md-6 col">
                              <select
                                className="form-select w-100"
                                required = {isConditionalNotRequired && case_data[conditionalnotRequiredDataCondition_key] === conditionalnotRequiredDataCondition_value ? false : true}
                                title="Select"
                                // onInvalid={"setCustomValidity('Please Select Release Order = Yes to continue')"}
                                // onSelect={"setCustomValidity()"}
                                name={
                                  milestone_data.data_to_be_collected[key]
                                    .input_label
                                }
                                value={
                                  milestone_data.data_to_be_collected[key]
                                    .input_value
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  Select{" "}
                                  {
                                    milestone_data.data_to_be_collected[key]
                                      .input_label
                                  }
                                </option>
                                {milestone_data.data_to_be_collected[
                                  key
                                ].input_options.map((data) => (
                                  <option key={data.id} value={data.id}>
                                    {data.data}
                                  </option>
                                ))}
                              </select>
                              {formErrors[
                                milestone_data.data_to_be_collected[key]
                                  .input_label
                              ] && (
                                <div className="alert alert-danger mt-2">
                                  {
                                    formErrors[
                                      milestone_data.data_to_be_collected[key]
                                        .input_label
                                    ]
                                  }
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className="row mb-2 pt-2" key={index}>
                            <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                              {
                                milestone_data.data_to_be_collected[key]
                                  .input_label
                              }
                            </div>
                            <div className="col-md-6 col">
                              <select
                                className="form-select w-100"
                                name={
                                  milestone_data.data_to_be_collected[key]
                                    .input_label
                                }
                                value={
                                  milestone_data.data_to_be_collected[key]
                                    .input_value
                                }
                                onChange={handleChange}
                              >
                                {milestone_data.data_to_be_collected[
                                  key
                                ].input_options.map((data) => (
                                  <option key={data.id} value={data.id}>
                                    {data.data}
                                  </option>
                                ))}
                              </select>
                              {formErrors[
                                milestone_data.data_to_be_collected[key]
                                  .input_label
                              ] && (
                                <div className="alert alert-danger mt-2">
                                  {
                                    formErrors[
                                      milestone_data.data_to_be_collected[key]
                                        .input_label
                                    ]
                                  }
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      }
                    }
                  } else if (
                    milestone_data.data_to_be_collected[key].input_type ==
                    "Checkbox"
                  ) {
                    if (completed || otherData) {
                      return (
                        <div className="row mb-2 pt-2" key={index}>
                          <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                            {
                              milestone_data.data_to_be_collected[key]
                                .input_label
                            }
                          </div>
                          <div
                            className="col-md-7 col"
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form>
                              {milestone_data.data_to_be_collected[
                                key
                              ].input_options.map((data) => (
                                <Form.Check
                                  inline
                                  disabled={isDisabledEdit ? true : false}
                                  label={data.data}
                                  name={
                                    milestone_data.data_to_be_collected[key]
                                      .input_label
                                  }
                                  type="checkbox"
                                  id={data.id}
                                  key={data.id}
                                  value={
                                    milestone_data.data_to_be_collected[key]
                                      .input_value
                                  }
                                />
                              ))}
                            </Form>
                          </div>
                        </div>
                      );
                    } else {
                      if (milestone_data.data_to_be_collected[key].isRequired) {
                        return (
                          <div className="row mb-2 pt-2" key={index}>
                            <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                              {
                                milestone_data.data_to_be_collected[key]
                                  .input_label
                              }
                              {(isConditionalNotRequired && case_data[conditionalnotRequiredDataCondition_key] === conditionalnotRequiredDataCondition_value) ? <span></span> : <span className="text-danger">*</span>}

                            </div>
                            <div
                              className="col-md-7 col"
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <Form>
                                {milestone_data.data_to_be_collected[
                                  key
                                ].input_options.map((data) => (
                                  <Form.Check
                                  required = {isConditionalNotRequired && case_data[conditionalnotRequiredDataCondition_key] === conditionalnotRequiredDataCondition_value ? false : true}
                                    inline
                                    label={data.data}
                                    name={
                                      milestone_data.data_to_be_collected[key]
                                        .input_label
                                    }
                                    type="checkbox"
                                    id={data.id}
                                    key={data.id}
                                    value={
                                      milestone_data.data_to_be_collected[key]
                                        .input_value
                                    }
                                  />
                                ))}
                              </Form>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className="row mb-2 pt-2" key={index}>
                            <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                              {
                                milestone_data.data_to_be_collected[key]
                                  .input_label
                              }
                            </div>
                            <div
                              className="col-md-7 col"
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <Form>
                                {milestone_data.data_to_be_collected[
                                  key
                                ].input_options.map((data) => (
                                  <Form.Check
                                    inline
                                    label={data.data}
                                    name={
                                      milestone_data.data_to_be_collected[key]
                                        .input_label
                                    }
                                    type="checkbox"
                                    id={data.id}
                                    key={data.id}
                                    value={
                                      milestone_data.data_to_be_collected[key]
                                        .input_value
                                    }
                                  />
                                ))}
                              </Form>
                            </div>
                          </div>
                        );
                      }
                    }
                  } else if (
                    milestone_data.data_to_be_collected[key].input_type ==
                    "Date"
                  ) {
                    if (completed || otherData) {
                      return (
                        <div className="row mb-2 pt-2" key={index}>
                          <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                            {
                              milestone_data.data_to_be_collected[key]
                                .input_label
                            }
                          </div>
                          <div className="col-md-6 col">
                            <Form.Group>
                              <Form.Control
                                type="date"
                                disabled={isDisabledEdit ? true : false}
                                name={
                                  milestone_data.data_to_be_collected[key]
                                    .input_label
                                }
                                value={
                                  milestone_data.data_to_be_collected[key]
                                    .input_value
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      );
                    } else {
                      if (milestone_data.data_to_be_collected[key].isRequired) {
                        return (
                          <div className="row mb-2 pt-2" key={index}>
                            <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                              {
                                milestone_data.data_to_be_collected[key]
                                  .input_label
                              }
                              {(isConditionalNotRequired && case_data[conditionalnotRequiredDataCondition_key] === conditionalnotRequiredDataCondition_value) ? <span></span> : <span className="text-danger">*</span>}
                            </div>
                            <div className="col-md-6 col">
                              <Form.Group>
                                <Form.Control
                                  type="date"
                                  required = {isConditionalNotRequired && case_data[conditionalnotRequiredDataCondition_key] === conditionalnotRequiredDataCondition_value ? false : true}
                                  name={
                                    milestone_data.data_to_be_collected[key]
                                      .input_label
                                  }
                                  value={
                                    milestone_data.data_to_be_collected[key]
                                      .input_value
                                  }
                                  onChange={handleChange}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className="row mb-2 pt-2" key={index}>
                            <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                              {
                                milestone_data.data_to_be_collected[key]
                                  .input_label
                              }
                            </div>
                            <div className="col-md-6 col">
                              <Form.Group>
                                <Form.Control
                                  type="date"
                                  name={
                                    milestone_data.data_to_be_collected[key]
                                      .input_label
                                  }
                                  value={
                                    milestone_data.data_to_be_collected[key]
                                      .input_value
                                  }
                                  onChange={handleChange}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        );
                      }
                    }
                  } else if (
                    milestone_data.data_to_be_collected[key].input_type ==
                    "Number"
                  ) {
                    if (completed || otherData) {
                      return (
                        <div className="row mb-2 pt-2" key={index}>
                          <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                            {
                              milestone_data.data_to_be_collected[key]
                                .input_label
                            }
                          </div>
                          <div className="col-md-6 col">
                            <Form.Group>
                              <Form.Control
                                type="number"
                                disabled={isDisabledEdit ? true : false}
                                placeholder={
                                  "Enter " +
                                  milestone_data.data_to_be_collected[key]
                                    .input_label
                                }
                                name={
                                  milestone_data.data_to_be_collected[key]
                                    .input_label
                                }
                                value={
                                  milestone_data.data_to_be_collected[key]
                                    .input_value
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      );
                    } else {
                      if (milestone_data.data_to_be_collected[key].isRequired) {
                        return (
                          <div className="row mb-2 pt-2" key={index}>
                            <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                              {
                                milestone_data.data_to_be_collected[key]
                                  .input_label
                              }
                              {(isConditionalNotRequired && case_data[conditionalnotRequiredDataCondition_key] === conditionalnotRequiredDataCondition_value) ? <span></span> : <span className="text-danger">*</span>}
                            </div>
                            <div className="col-md-6 col">
                              <Form.Group>
                                <Form.Control
                                  type="number"
                                  required = {isConditionalNotRequired && case_data[conditionalnotRequiredDataCondition_key] === conditionalnotRequiredDataCondition_value ? false : true}
                                  name={
                                    milestone_data.data_to_be_collected[key]
                                      .input_label
                                  }
                                  placeholder={
                                    "Enter " +
                                    milestone_data.data_to_be_collected[key]
                                      .input_label
                                  }
                                  value={
                                    milestone_data.data_to_be_collected[key]
                                      .input_value
                                  }
                                  onChange={handleChange}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className="row mb-2 pt-2" key={index}>
                            <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                              {
                                milestone_data.data_to_be_collected[key]
                                  .input_label
                              }
                            </div>
                            <div className="col-md-6 col">
                              <Form.Group>
                                <Form.Control
                                  type="number"
                                  name={
                                    milestone_data.data_to_be_collected[key]
                                      .input_label
                                  }
                                  placeholder={
                                    "Enter " +
                                    milestone_data.data_to_be_collected[key]
                                      .input_label
                                  }
                                  value={
                                    milestone_data.data_to_be_collected[key]
                                      .input_value
                                  }
                                  onChange={handleChange}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        );
                      }
                    }
                  } else if (
                    milestone_data.data_to_be_collected[key].input_type ==
                    "File"
                  ) {
                    if (
                      milestone_data.data_to_be_collected[key].input_value == ""
                    ) {
                      if (completed || otherData) {
                        return (
                          <div className="row mb-2 pt-2">
                            <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                              {
                                milestone_data.data_to_be_collected[key]
                                  .input_label
                              }
                            </div>
                            <div className="col-md-6 col">
                              <Form.Group>
                                <Form.Control
                                  type="file"
                                  disabled={isDisabledEdit ? true : false}
                                  name={
                                    milestone_data.data_to_be_collected[key]
                                      .input_label
                                  }
                                  onChange={handleChangeFile}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        );
                      } else {
                        if (
                          milestone_data.data_to_be_collected[key].isRequired
                        ) {
                          return (
                            <div className="row mb-2 pt-2">
                              <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                                {
                                  milestone_data.data_to_be_collected[key]
                                    .input_label
                                }
                              {(isConditionalNotRequired && case_data[conditionalnotRequiredDataCondition_key] === conditionalnotRequiredDataCondition_value) ? <span></span> : <span className="text-danger">*</span>}

                              </div>
                              <div className="col-md-6 col">
                                {milestone_data.data_to_be_collected[key]
                                  .input_value != "" ? (
                                  <Form.Group>
                                    <Form.Control
                                      type="file"
                                      name={
                                        milestone_data.data_to_be_collected[key]
                                          .input_label
                                      }
                                      onChange={handleChangeFile}
                                    />
                                    <Icon.FileEarmarkRichtext
                                      size={"3em"}
                                      style={{
                                        fontSize: "3em !important",
                                        paddingTop: "5px",
                                      }}
                                    />{" "}
                                    <a
                                      className="downloadFile"
                                      href={
                                        milestone_data.data_to_be_collected[key]
                                          .input_value
                                      }
                                    >
                                      Download
                                    </a>
                                  </Form.Group>
                                ) : (
                                  <Form.Group>
                                    <Form.Control
                                      type="file"
                                      required = {isConditionalNotRequired && case_data[conditionalnotRequiredDataCondition_key] === conditionalnotRequiredDataCondition_value ? false : true}
                                      name={
                                        milestone_data.data_to_be_collected[key]
                                          .input_label
                                      }
                                      onChange={handleChangeFile}
                                    />
                                  </Form.Group>
                                )}
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div className="row mb-2 pt-2">
                              <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                                {
                                  milestone_data.data_to_be_collected[key]
                                    .input_label
                                }
                              </div>
                              <div className="col-md-6 col">
                                {/* {phone == user.phone && !otherData ? (
                                  <Form.Group>
                                    <Form.Control
                                      type="file"
                                      name={
                                        milestone_data.data_to_be_collected[key]
                                          .input_label
                                      }
                                      onChange={handleChangeFile}
                                    />
                                  </Form.Group>
                                ) : ( */}
                                <Form.Group>
                                  <Form.Control
                                    type="file"
                                    name={
                                      milestone_data.data_to_be_collected[key]
                                        .input_label
                                    }
                                    onChange={handleChangeFile}
                                  />
                                </Form.Group>
                                {/* )} */}
                              </div>
                            </div>
                          );
                        }
                      }
                    } else {
                      if (completed || otherData) {
                        return (
                          <div className="row mb-2 pt-2">
                            <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                              {
                                milestone_data.data_to_be_collected[key]
                                  .input_label
                              }
                            </div>
                            <div className="col-md-6 col">
                              <Form.Group>
                                <Form.Control
                                  type="file"
                                  disabled={isDisabledEdit ? true : false}
                                  name={
                                    milestone_data.data_to_be_collected[key]
                                      .input_label
                                  }
                                  onChange={handleChangeFile}
                                />
                                <Icon.FileEarmarkRichtext
                                  size={"3em"}
                                  style={{
                                    fontSize: "3em !important",
                                    paddingTop: "5px",
                                  }}
                                />{" "}
                                <a
                                  className="downloadFile"
                                  href={
                                    milestone_data.data_to_be_collected[key]
                                      .input_value
                                  }
                                >
                                  Download
                                </a>
                              </Form.Group>
                            </div>
                          </div>
                        );
                      } else {
                        if (
                          milestone_data.data_to_be_collected[key].isRequired
                        ) {
                          return (
                            <div className="row mb-2 pt-2">
                              <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                                {
                                  milestone_data.data_to_be_collected[key]
                                    .input_label
                                }
                              </div>
                              <div className="col-md-6 col">
                                {milestone_data.data_to_be_collected[key]
                                  .input_value != "" ? (
                                  <Form.Group>
                                    <Form.Control
                                      type="file"
                                      name={
                                        milestone_data.data_to_be_collected[key]
                                          .input_label
                                      }
                                      onChange={handleChangeFile}
                                    />
                                    <Icon.FileEarmarkRichtext
                                      size={"3em"}
                                      style={{
                                        fontSize: "3em !important",
                                        paddingTop: "5px",
                                      }}
                                    />{" "}
                                    <a
                                      className="downloadFile"
                                      href={
                                        milestone_data.data_to_be_collected[key]
                                          .input_value
                                      }
                                    >
                                      Download
                                    </a>
                                  </Form.Group>
                                ) : (
                                  <Form.Group>
                                    <Form.Control
                                      type="file"
                                      required
                                      name={
                                        milestone_data.data_to_be_collected[key]
                                          .input_label
                                      }
                                      onChange={handleChangeFile}
                                    />
                                  </Form.Group>
                                )}
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div className="row mb-2 pt-2">
                              <div className="offset-md-2 col-md-2 d-flex justify-content-start">
                                {
                                  milestone_data.data_to_be_collected[key]
                                    .input_label
                                }
                              </div>
                              <div className="col-md-6 col">
                                {milestone_data.data_to_be_collected[key]
                                  .input_value != "" ? (
                                  <Form.Group>
                                    <Form.Control
                                      type="file"
                                      name={
                                        milestone_data.data_to_be_collected[key]
                                          .input_label
                                      }
                                      onChange={handleChangeFile}
                                    />
                                    <Icon.FileEarmarkRichtext
                                      size={"3em"}
                                      style={{
                                        fontSize: "3em !important",
                                        paddingTop: "5px",
                                      }}
                                    />{" "}
                                    <a
                                      className="downloadFile"
                                      href={
                                        milestone_data.data_to_be_collected[key]
                                          .input_value
                                      }
                                    >
                                      Download
                                    </a>
                                  </Form.Group>
                                ) : (
                                  <Form.Group>
                                    <Form.Control
                                      type="file"
                                      required
                                      name={
                                        milestone_data.data_to_be_collected[key]
                                          .input_label
                                      }
                                      onChange={handleChangeFile}
                                    />
                                  </Form.Group>
                                )}
                              </div>
                            </div>
                          );
                        }
                      }
                    }
                  }
                }
              }
              )}
            </Modal.Body>

            {!isDisabledEdit && parsed.edit && (
              <Modal.Footer>
                {saveloading ? (
                  <Button className="btn-primary" disabled>
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Saving...
                  </Button>
                ) : (
                  <Button className="btn-primary" onClick={handleSave}>
                    Save
                  </Button>
                )}
              </Modal.Footer>
            )}

            {phone == user.phone &&
              completed == false &&
              !otherData &&
              !dashboardFlag && (
                <Modal.Footer>
                  {/* <Button onClick={onHide}>Close</Button> */}
                  {/* {saveloading ? (
                    <Button className="btn-primary" disabled>
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Saving...
                    </Button>
                  ) : (
                    <Button
                      className="btn-primary"
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  )} */}
                  {loading ? (
                    <Button variant="success" disabled>
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </Button>
                  ) : (
                    <Button type="submit" className="btn-success">
                      Mark as Completed
                    </Button>
                  )}
                  {/* <Button variant="success" onClick={handleSave}>Save</Button> */}
                </Modal.Footer>
              )}
            {dashboardFlag && (
              <Modal.Footer>
                {/* <Button onClick={onHide}>Close</Button> */}
                {/* {saveloading ? (
                  <Button variant="success" disabled>
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </Button>
                ) : (
                  <Button
                    className="btn-primary"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                )} */}
                {loading ? (
                  <Button variant="success" disabled>
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </Button>
                ) : (
                  <Button type="submit" className="btn-success">
                    Mark as Completed
                  </Button>
                )}
                {/* <Button variant="success" onClick={handleSave}>Save</Button> */}
              </Modal.Footer>
            )}
          </form>
        </Modal>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
}

export default UpdateMilestoneDash;
