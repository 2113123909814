import React, { useEffect } from "react";
import AddAuction from "../../img/add-auction.svg";
import SearchAsset from "../../img/search_asset.svg";
import ViewAuction from "../../img/view-auction.svg";

import jwtDecode from "jwt-decode";
import { useHistory } from "react-router-dom";
import CardBlock from "../../common/CardBlock";
import ErrorHandler from "../../common/ErrorHandler";

function AuctionHome(props) {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwtDecode(currentUser);
  const history = useHistory();
  useEffect(() => {
    document.title = "Meratractor | Auction Home";
  }, []);

  try {
    return (
      <>
        <div className="container-fluid shadow-sm">
          <div className="container">
            <div className="row">
              <div className="d-sm-block d-md-none d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                  </div>
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-block d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12 col-md-6 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-6 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-none d-lg-block d-xl-block">
                <div className="row">
                  <div className="col-12 col-md-8 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-4 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container responsive mt-2 ">
          <div className="row mt-3 mb-2">
            <div className="d-flex justify-content-between col-1">
              <i
                className="bi bi-arrow-left-circle"
                onClick={() => history.push("/meratractor")}
                style={{
                  fontSize: "xx-large",
                  color: "black",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              ></i>
            </div>
            <div className="col-10 mt-2 float-start ms-2">
              <h4 className=""> Auction Home</h4>
            </div>
          </div>
        </div>

        <div className="container container-body">
          <CardBlock
            name="Add Auction"
            logo={AddAuction}
            to="/meratractor/auction/add-auction"
          />
          <CardBlock
            name="View Auction"
            logo={ViewAuction}
            to="/meratractor/auction/view-auction"
          />
          <CardBlock
            name="Search Asset Images"
            logo={SearchAsset}
            to={"/meratractor/auction/search-asset-images"}
          />
        </div>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
}

export default AuctionHome;
