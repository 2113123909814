import React, { useEffect, useState } from "react";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
import SearchBox from "../../../common/SearchBox";
import ReactLoading from "react-loading";
import { Link, useHistory } from "react-router-dom";

const RapibidAuctionCompleted = () => {
  const header = [
    { header: "Auction Id", dataKey: "asset_id" },
    { header: "Make", dataKey: "make" },
    { header: "Model", dataKey: "model" },
    { header: "Start Date", dataKey: "start_time" },
    { header: "End Date", dataKey: "end_time" },
    { header: "State", dataKey: "state" },
  ];

  const [auctionData, setAuctionData] = useState([]);
  const [filteredAuctionData, setFilteredAuctionData] = useState([]);
  const [auctionDataLoading, setAuctionDataLoading] = useState(false);
  useEffect(() => {
    setAuctionDataLoading(true);
    let form_data = new FormData();
    form_data.append("key", "completed");
    SubscriptionServices.GetAuction(form_data).then((res) => {
      console.log({res})
      let auction = res?.completed;
      
      if (Object.entries(auction).length !== 0) {
        console.log({ auction });
        setAuctionDataLoading(false);
        setAuctionData(auction);
        setFilteredAuctionData(auction);
      }
    });
  }, []);

  return (
    <>
      <div className=" container mt-3">
        <div className="col-lg-3 col-12 col-md-4 col-sm-12 ms-auto mb-2">
          <SearchBox
            allData={auctionData}
            setFilteredData={setFilteredAuctionData}
          />
        </div>

        {auctionDataLoading && Object.keys(filteredAuctionData).length === 0 ? (
          <div
            className="d-flex justify-content-between container"
            style={{ minHeight: "80vh", alignItems: "center" }}
          >
            <ReactLoading
              type={"bubbles"}
              color={"black"}
              height={100}
              width={100}
              className="reactLoadingDash "
            />
          </div>
        ) : (
          <>
            {filteredAuctionData.length === 0 ? (
              <div className="container table-responsive mt-2">
                <table className="table table-bordered thead-dark table-hover">
                  <thead>
                    <tr>
                      {/* {header?.map((column) => (
                        <th key={column.dataKey}>{column.header}</th>
                      ))} */}
                      <th>Auction ID</th>
                      <th>Make</th>
                      <th>Model</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>State</th>
                    </tr>
                  </thead>
                </table>
                <div className="container">
                  <p className="text-center text-danger">No Data</p>
                </div>
              </div>
            ) : (
              <>
                <div className="table-responsive mt-3">
                  <table className="table table-hover thead-dark table-responsive">
                    <thead>
                      <tr >
                        <th>Auction ID</th>
                        <th>Make</th>
                        <th>Model</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>State</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object?.entries(filteredAuctionData)?.map(
                        ([key, entry], index) => (
                          <tr key={index}>
                            {/* {header.map((column) => (
                              <td>{entry[column.dataKey]}</td>
                            ))} */}
                            <td>
                              <Link to={`/rapidbid/manage-auction/edit-auction-home/:${entry["asset_id"]}`}>{entry["asset_id"]}</Link>
                            </td>
                            <td>{entry["make"]}</td>
                            <td>{entry["model"]}</td>
                            <td>{entry["start_time"]}</td>
                            <td>{entry["end_time"]}</td>
                            <td>{entry["state"]}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default RapibidAuctionCompleted;
