import React, { useEffect, useState } from "react";
import SubscriptionServices from "../../../services/rapidbid/subscription.service";
import { errorToast } from "../../../react-toastfiy/toast";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";
import SearchBox from "../../../common/SearchBox";
import CustomTable from "./CustomTable";

const RefundApprovalPendingList = () => {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  const history = useHistory();
  let { id } = useParams();

  const [model, setModel] = useState(false);
  const [Loading, setLoading] = useState(false);

  // console.log({ user, id });

  const header = [
    {
      header: "Sr. No.",
      dataKey: "no",
    },
    {
      header: "User Name",
      dataKey: "user_name",
    },
    {
      header: "User Phone",
      dataKey: "user_phone",
    },
    {
      header: "Plan Id",
      dataKey: "plan_id",
    },
    {
      header: "Plan Name",
      dataKey: "plan_name",
    },
    {
      header: "Order Id",
      dataKey: "order_id",
    },
    {
      header: "Refund Transaction Id",
      dataKey: "refund_transaction_id",
    },{
      header: "Refund Reason",
      dataKey: "refund_reason",
    },{
      header: "Refund Amount",
      dataKey: "refund_amount",
    },
  ];

  const [data, setData] = useState([]);
  const [FilterPlanData, setFilterPlanData] = useState([]);
  const [modalDataList, setModalDataList] = useState([]);

  let entry;
  useEffect(() => {
    try {
      setLoading(true);
      SubscriptionServices.GetAllRefundApprovalRequestList().then((res) => {
        if (res.status === 200) {
          console.log({ res });
          setLoading(false);
          //   console.log({ res }, res.data);

          const renderedData = res.data.map((item, index) => ({
            no: index + 1,
            user_name: item.user_name,
            user_phone: item.user_phone,
            plan_id: item.plan_id,
            plan_name: item.plan_name,
            order_id: item.order_id,
            refund_transaction_id: item.refund_transaction_id,
            refund_reason: item.refund_reason,
            refund_amount: item.refund_amount,
          }));
          //   console.log({ renderedData });

          setData(renderedData);
          setFilterPlanData(renderedData);
        }
      });
    } catch (error) {
      console.log(error);
      errorToast(error.msg);
      setLoading(false);
    }
  }, []);
  // console.log({ FilterPlanData, data });



  const handleApproval = (e, entry) => {
    e.preventDefault();
    const { value, name } = e.target;

    let form_data = new FormData();
    if (name == "Approved") {
      const notice = `You want to Approve this subscription plan`;
      let form_data = new FormData();
      form_data.append("phone", entry.user_phone);
      form_data.append("refund_status", "accepted");
      form_data.append("order_id", entry.order_id);
      form_data.append("refund_transaction_id", entry.refund_transaction_id);
      Swal.fire({
        title: "Are you sure?",
        text: notice,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Approve it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const ṛes =
              await SubscriptionServices.ApprovalRefundApprovalRequest(
                form_data
              );
            if (ṛes.status === 200) {
              Swal.fire(name, `Your data has been Accepted`, "success").then(
                (result) => {
                  result &&
                    window.location.replace(`/risk-management-dashbaord${id}`);
                }
              );
            }

            ṛes.status !== 200 && errorToast(ṛes.msg);
          } catch (error) {
            console.log(error);
            errorToast(error.message);
          }
        }
      });
    } else {
      const notice = `You want to reject this subscription plan`;
      form_data.append("phone", entry.user_phone);
      form_data.append("refund_status", "rejected");
      form_data.append("order_id", entry.order_id);
      form_data.append("refund_transaction_id", entry.refund_transaction_id);
      Swal.fire({
        title: "Are you sure?",
        text: notice,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Reject it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const ṛes =
              await SubscriptionServices.ApprovalRefundApprovalRequest(
                form_data
              );
            //   console.log(ṛes);
            if (ṛes.status === 200) {
              Swal.fire(name, `Your data has been Rejected`, "success").then(
                (result) => {
                  result &&
                    window.location.replace(`/risk-management-dashbaord${id}`);
                }
              );
            }

            ṛes.status !== 200 && errorToast(ṛes.msg);
          } catch (error) {
            console.log(error);
            errorToast(error.message);
          }
        }
      });
    }
  };
  console.log({FilterPlanData})

  return (
    <>
      <div className="container mt-3">
        <div className="col-md-12 col-12 col-lg-12 mb-2 ">
          <div className="d-flex flex-wrap justify-content-between align-items-end">
            <div className="col-lg-3 col-12 col-md-4 col-sm-12">
              <h4>Refund Approval Pending</h4>
            </div>
            <div className="col-lg-3 col-12 col-md-4 col-sm-12 ms-auto">
              <SearchBox allData={data} setFilteredData={setFilterPlanData} />
            </div>
          </div>
        </div>
        <CustomTable
          columns={header}
          loading={Loading}
          entry={entry}
          data={FilterPlanData}
          model={setModel}
          setModel={setModel}
          handleApproval={handleApproval}
          action={"Refund Approval pending"}
        />
      </div>
    </>
  );
};

export default RefundApprovalPendingList;
