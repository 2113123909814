import { useEffect, useState } from "react";
import BottomDrawer from "../../common/Drawer/BottomDrawer";
import SearchBoxWithCustomQuery from "../../common/SearchBoxWithCustomQuery";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

function AuctionAdminFilters(props) {
  const {
    auctions,
    setFilteredAuctionData,
    filteredAuctionData,
    loading,
    children,
    no_data_text,
    set_is_no_filter_applied = null,
    brand_key = "brand_name",
  } = props;

  //Filters
  const [brandsList, setBrandsList] = useState([]);
  const [statesList, setStatesList] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState({
    states: {},
    brands: {},
    acr: "",
  });
  const [currentActiveFilter, setCurrentActiveFilter] = useState("");
  const [showMobileFilterModal, setShowMobileFilterModal] = useState(false);
  const [noFilters, setNoFilters] = useState(
    (Object.values(selectedFilters?.brands).length === 0 ||
      Object.values(selectedFilters?.brands).every((val) => !val)) &&
      (Object.values(selectedFilters?.states).length === 0 ||
        Object.values(selectedFilters?.states).every((val) => !val)) &&
      !selectedFilters.acr
  );

  useEffect(() => {
    const is_no_filters =
      (Object.values(selectedFilters?.brands).length === 0 ||
        Object.values(selectedFilters?.brands).every((val) => !val)) &&
      (Object.values(selectedFilters?.states).length === 0 ||
        Object.values(selectedFilters?.states).every((val) => !val)) &&
      !selectedFilters.acr;

    setNoFilters(is_no_filters);
    if (set_is_no_filter_applied) {
      set_is_no_filter_applied(is_no_filters);
    }
  }, [selectedFilters]);

  useEffect(() => {
    const brands_list = [];
    const states_list = [];

    auctions?.forEach((auction) => {
      const brand = auction?.[brand_key];
      if (!brands_list.includes(brand)) {
        brands_list?.push(brand);
      }

      const state = auction?.state;
      if (!states_list.includes(state)) {
        states_list?.push(state);
      }
    });

    setBrandsList(brands_list?.toSorted());
    setStatesList(states_list?.toSorted());
  }, [auctions]);

  useEffect(() => {
    try {
      let results = [];
      const is_no_filters =
        (Object.values(selectedFilters?.brands).length === 0 ||
          Object.values(selectedFilters?.brands).every((val) => !val)) &&
        (Object.values(selectedFilters?.states).length === 0 ||
          Object.values(selectedFilters?.states).every((val) => !val)) &&
        !selectedFilters.acr;
      if (is_no_filters) {
        results = auctions;
      } else {
        results = auctions?.filter(
          (auction) =>
            Object.entries(selectedFilters?.brands)?.some(
              ([brand, isSelected]) => {
                const financier = auction?.brand_name;
                return (
                  financier
                    ?.toLowerCase()
                    ?.startsWith(brand?.toLowerCase()?.split(" ")[0]) &&
                  isSelected
                );
              }
            ) ||
            Object.entries(selectedFilters?.states)?.some(
              ([state, isSelected]) =>
                auction?.state
                  ?.toLowerCase()
                  ?.startsWith(state?.toLowerCase()) && isSelected
            ) ||
            (selectedFilters.acr
              ? selectedFilters.acr === auction.is_locked
              : false)
        );
      }
      setFilteredAuctionData(results);
    } catch (error) {
      console.log(error);
      return error;
    }
  }, [selectedFilters.brands, selectedFilters.states, selectedFilters.acr]);

  return (
    <>
      {!isMobile && auctions?.length > 0 ? (
        <>
          <div className=" d-flex justify-content-between gap-4 align-items-center mt-2">
            <div className=" d-flex justify-content-start gap-4 align-items-center">
              <div className=" fw-bold d-flex align-self-start">
                {" "}
                <span className=" fw-bold">FILTERS</span>
              </div>
              <div>
                <div className=" d-flex align-items-center gap-3">
                  <button
                    className=" btn btn-light"
                    onClick={() =>
                      setCurrentActiveFilter((prev) =>
                        prev === "brand" ? "" : "brand"
                      )
                    }
                  >
                    {" "}
                    Financier{" "}
                    {currentActiveFilter === "brand" ? (
                      <i className="bi bi-caret-up"></i>
                    ) : (
                      <i className="bi bi-caret-down"></i>
                    )}{" "}
                  </button>
                  <button
                    className=" btn btn-light"
                    onClick={() =>
                      setCurrentActiveFilter((prev) =>
                        prev === "state" ? "" : "state"
                      )
                    }
                  >
                    {" "}
                    State{" "}
                    {currentActiveFilter === "state" ? (
                      <i className="bi bi-caret-up"></i>
                    ) : (
                      <i className="bi bi-caret-down"></i>
                    )}{" "}
                  </button>
                  <button
                    className=" btn btn-light"
                    onClick={() =>
                      setCurrentActiveFilter((prev) =>
                        prev === "acr" ? "" : "acr"
                      )
                    }
                  >
                    {" "}
                    ACR{" "}
                    {currentActiveFilter === "acr" ? (
                      <i className="bi bi-caret-up"></i>
                    ) : (
                      <i className="bi bi-caret-down"></i>
                    )}{" "}
                  </button>

                  {!noFilters ? (
                    <button
                      className=" btn btn-danger"
                      onClick={() =>
                        setSelectedFilters({ brands: {}, states: {} })
                      }
                    >
                      CLEAR ALL
                    </button>
                  ) : null}
                </div>

                {currentActiveFilter === "brand" && (
                  <div className=" d-flex flex-wrap justify-content-start gap-3 shadow p-3 mt-2">
                    {brandsList?.map((brand, i) => {
                      return (
                        <div
                          key={brand + i}
                          className=" d-flex justify-content-start gap-1 align-items-center"
                        >
                          <input
                            type="checkbox"
                            name="brand"
                            id={brand}
                            className=" form-check m-0"
                            checked={selectedFilters?.brands[brand]}
                            onChange={() => {
                              setSelectedFilters((prev) => ({
                                ...prev,
                                brands: {
                                  ...prev.brands,
                                  [brand]: !prev?.brands[brand],
                                },
                              }));
                            }}
                          />
                          <label
                            htmlFor={brand}
                            className=""
                            style={{ cursor: "pointer" }}
                          >
                            {brand}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                )}

                {currentActiveFilter === "state" && (
                  <div className=" d-flex flex-wrap justify-content-start gap-3 shadow p-3 mt-2">
                    {statesList?.map((state) => {
                      return (
                        <div
                          key={state}
                          className=" d-flex justify-content-start gap-1 align-items-center"
                        >
                          <input
                            type="checkbox"
                            id={state}
                            name={state}
                            className=" form-check m-0"
                            checked={selectedFilters?.states[state]}
                            onChange={(e) => {
                              setSelectedFilters((prev) => ({
                                ...prev,
                                states: {
                                  ...prev.states,
                                  [state]: !prev?.states[state],
                                },
                              }));
                            }}
                          />
                          <label
                            htmlFor={state}
                            className=""
                            style={{ cursor: "pointer" }}
                          >
                            {state}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                )}
                {currentActiveFilter === "acr" && (
                  <div className=" d-flex flex-wrap justify-content-start gap-3 shadow p-3 mt-2">
                    <div className=" d-flex justify-content-start gap-1 align-items-center">
                      <input
                        type="checkbox"
                        id={"locked"}
                        name={"locked"}
                        className=" form-check m-0"
                        checked={selectedFilters?.acr === "True"}
                        onChange={(e) => {
                          setSelectedFilters((prev) => ({
                            ...prev,
                            acr: prev.acr === "True" ? "" : "True",
                          }));
                        }}
                      />
                      <label
                        htmlFor={"locked"}
                        className=""
                        style={{ cursor: "pointer" }}
                      >
                        {"Locked"}
                      </label>
                    </div>
                    <div className=" d-flex justify-content-start gap-1 align-items-center">
                      <input
                        type="checkbox"
                        id={"not_locked"}
                        name={"not_locked"}
                        className=" form-check m-0"
                        checked={selectedFilters?.acr === "False"}
                        onChange={(e) => {
                          setSelectedFilters((prev) => ({
                            ...prev,
                            acr: prev.acr === "False" ? "" : "False",
                          }));
                        }}
                      />
                      <label
                        htmlFor={"not_locked"}
                        className=""
                        style={{ cursor: "pointer" }}
                      >
                        {"Not Locked"}
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className=" col-lg-4">
              <SearchBoxWithCustomQuery
                placeholder="Search here..."
                allData={auctions}
                setFilteredData={setFilteredAuctionData}
                return_array={true}
                keysToSearchOn={[
                  "auction_id",
                  "c_auction_id",
                  "financier",
                  "state",
                  "segment",
                ]}
              />
            </div>
          </div>

          {noFilters ? null : (
            <div className=" d-flex gap-2 flex-wrap mt-4">
              {Object.entries(selectedFilters?.brands)?.map(
                ([brand, selected]) => {
                  return selected ? (
                    <div
                      key={brand}
                      className=" border rounded-pill px-2 d-flex align-items-center"
                      style={{ maxWidth: "fit-content" }}
                    >
                      <span>{brand}</span>
                      <i
                        className="bi bi-x fs-5"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedFilters((prev) => ({
                            ...prev,
                            brands: {
                              ...prev.brands,
                              [brand]: false,
                            },
                          }));
                        }}
                      ></i>
                    </div>
                  ) : null;
                }
              )}
              {Object.entries(selectedFilters?.states)?.map(
                ([state, selected]) => {
                  return selected ? (
                    <div
                      key={state}
                      className=" border rounded-pill px-2 d-flex align-items-center"
                      style={{ maxWidth: "fit-content" }}
                    >
                      <span>{state}</span>

                      <i
                        style={{ cursor: "pointer" }}
                        className="bi bi-x fs-5"
                        onClick={() => {
                          setSelectedFilters((prev) => ({
                            ...prev,
                            states: {
                              ...prev.states,
                              [state]: false,
                            },
                          }));
                        }}
                      ></i>
                    </div>
                  ) : null;
                }
              )}

              {selectedFilters.acr && (
                <div
                  className=" border rounded-pill px-2 d-flex align-items-center"
                  style={{ maxWidth: "fit-content" }}
                >
                  <span>
                    {selectedFilters.acr === "True" ? "Locked" : "Not Locked"}
                  </span>

                  <i
                    style={{ cursor: "pointer" }}
                    className="bi bi-x fs-5"
                    onClick={() => {
                      setSelectedFilters((prev) => ({
                        ...prev,
                        acr: "",
                      }));
                    }}
                  ></i>
                </div>
              )}
            </div>
          )}
        </>
      ) : null}

      {isMobile ? (
        <>
          <div className=" col-12 mt-3">
            <SearchBoxWithCustomQuery
              placeholder="Search here..."
              allData={auctions}
              setFilteredData={setFilteredAuctionData}
              return_array={true}
              keysToSearchOn={[
                "auction_id",
                "c_auction_id",
                "financier",
                "state",
                "segment",
              ]}
            />
          </div>
        </>
      ) : null}

      <div className="">{children}</div>

      {isMobile && auctions?.length > 0 ? (
        <>
          <div
            style={{
              position: "fixed",
              bottom: 0,
              zIndex: 3,
              background: "white",
              left: 0,
            }}
            className=" col-12 text-center py-2 border rounded-0"
            onClick={() => {
              setCurrentActiveFilter((prev) => (prev === "" ? "brand" : prev));
              setShowMobileFilterModal(true);
              // document.getElementById("root").classList.toggle("blur");
            }}
          >
            <span className=" position-relative px-2">
              FILTERS{" "}
              {!noFilters && (
                <span className="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle">
                  <span className="visually-hidden">New alerts</span>
                </span>
              )}
            </span>
          </div>
          <BottomDrawer show={showMobileFilterModal}>
            <div
              className=" d-flex flex-column justify-content-between"
              style={{ height: "inherit" }}
            >
              <div
                className=" d-flex justify-content-between container fs-5 py-2 position-sticky top-0 bg-white"
                style={{ zIndex: 9999 }}
              >
                <span className="">FILTERS</span>
                {!noFilters ? (
                  <span
                    className="text-danger click-animation"
                    onClick={() => {
                      setSelectedFilters({ brands: {}, states: {} });
                    }}
                  >
                    CLEAR ALL
                  </span>
                ) : null}
              </div>
              <div className="row flex-grow-1">
                <ul
                  className=" list-group col-3 p-0 rounded-0"
                  style={{ height: "100%" }}
                >
                  <li
                    className={`list-group-item ${
                      currentActiveFilter === "brand" ? "active" : ""
                    }`}
                    onClick={() => setCurrentActiveFilter("brand")}
                  >
                    Financier{" "}
                  </li>
                  <li
                    className={`list-group-item  ${
                      currentActiveFilter === "state" ? "active" : ""
                    }`}
                    onClick={() => setCurrentActiveFilter("state")}
                  >
                    State{" "}
                  </li>
                  <li
                    className={`list-group-item  ${
                      currentActiveFilter === "state" ? "active" : ""
                    }`}
                    onClick={() => setCurrentActiveFilter("acr")}
                  >
                    ACR{" "}
                  </li>
                </ul>
                <div
                  className=" border col-9 pe-2 rounded-0"
                  style={{ overflowY: "auto" }}
                >
                  {currentActiveFilter === "brand" && (
                    <div className=" d-flex flex-wrap justify-content-start flex-column mt-2">
                      {brandsList?.map((brand) => {
                        return (
                          <div
                            key={brand}
                            className=" d-flex justify-content-start gap-2 align-items-center border-bottom"
                          >
                            <input
                              type="checkbox"
                              name="brand"
                              id={brand}
                              className=" form-check m-0"
                              checked={selectedFilters?.brands[brand]}
                              onChange={() => {
                                setSelectedFilters((prev) => ({
                                  ...prev,
                                  brands: {
                                    ...prev.brands,
                                    [brand]: !prev?.brands[brand],
                                  },
                                }));
                              }}
                            />
                            <label
                              htmlFor={brand}
                              className=""
                              style={{ cursor: "pointer" }}
                            >
                              {brand}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {currentActiveFilter === "state" && (
                    <div className=" d-flex flex-wrap justify-content-start flex-column mt-2">
                      {statesList?.map((state) => {
                        return (
                          <div
                            key={state}
                            className=" d-flex justify-content-start gap-2 align-items-center border-bottom"
                          >
                            <input
                              type="checkbox"
                              id={state}
                              name={state}
                              className=" form-check m-0"
                              checked={selectedFilters?.states[state]}
                              onChange={(e) => {
                                setSelectedFilters((prev) => ({
                                  ...prev,
                                  states: {
                                    ...prev.states,
                                    [state]: !prev?.states[state],
                                  },
                                }));
                              }}
                            />
                            <label
                              htmlFor={state}
                              className=""
                              style={{ cursor: "pointer" }}
                            >
                              {state}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {currentActiveFilter === "acr" && (
                    <>
                      <div
                        key={"locked"}
                        className=" d-flex justify-content-start gap-2 align-items-center border-bottom mt-2"
                      >
                        <input
                          type="checkbox"
                          id={"locked"}
                          name={"locked"}
                          className=" form-check m-0"
                          checked={selectedFilters?.acr}
                          onChange={(e) => {
                            setSelectedFilters((prev) => ({
                              ...prev,
                              acr: prev === "True" ? "" : "True",
                            }));
                          }}
                        />
                        <label
                          htmlFor={"locked"}
                          className=""
                          style={{ cursor: "pointer" }}
                        >
                          Locked
                        </label>
                      </div>
                      <div
                        key={"not_locked"}
                        className=" d-flex justify-content-start gap-2 align-items-center border-bottom mt-2"
                      >
                        <input
                          type="checkbox"
                          id={"not_locked"}
                          name={"not_locked"}
                          className=" form-check m-0"
                          checked={selectedFilters?.acr}
                          onChange={(e) => {
                            setSelectedFilters((prev) => ({
                              ...prev,
                              acr: prev === "False" ? "" : "False",
                            }));
                          }}
                        />
                        <label
                          htmlFor={"not_locked"}
                          className=""
                          style={{ cursor: "pointer" }}
                        >
                          Not Locked
                        </label>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div
                className=" container-fluid col-12 d-flex justify-content-center py-2 d-md-none shadow-lg"
                style={{
                  position: "fixed",
                  bottom: 0,
                  zIndex: 3,
                  background: "white",
                  left: 0,
                }}
              >
                <span
                  className="col-6 text-center click-animation col-6 fs-5"
                  onClick={() => {
                    setShowMobileFilterModal(false);
                    document.getElementById("root").classList.toggle("blur");
                  }}
                >
                  Close
                </span>
                |
                <span
                  className="col-6 text-center text-danger click-animation col-6 fs-5"
                  onClick={() => {
                    setShowMobileFilterModal(false);
                    document.getElementById("root").classList.toggle("blur");
                  }}
                >
                  Apply
                </span>
              </div>
            </div>
          </BottomDrawer>
        </>
      ) : null}
    </>
  );
}

export default AuctionAdminFilters;
