import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import SearchBox from "../../common/SearchBox";
import { errorToast } from "../../react-toastfiy/toast";
import SubscriptionServices from "../../services/subscription/subscription.service";

const SubscriptionRefundTransectionHistory = () => {
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [FilterPlanData, setFilterPlanData] = useState([]);
  let entry;
  useEffect(() => {
    try {
      setLoading(true);
      SubscriptionServices.GetAllUsers().then((res) => {
        setLoading(false);
        const renderedData = Object.entries(res).map(([key, item], index) => ({
          no: index + 1,
          name: item?.name,
          user_phone: item.phone,
        }));
        if (renderedData.length !== 0) {
          setData(renderedData);
          setFilterPlanData(renderedData);
        }
      });
    } catch (error) {
      console.log(error);
      errorToast(error.msg);
      setLoading(false);
    }
  }, []);

  const redirectToTransactionHistory = (e, entry) => {
    const url = `/subscription/user-transaction-history/:${entry.user_phone}`;
    window.open(url, "_blank");
  };

  console.log({ FilterPlanData });

  return (
    <>
      <div className="container mt-3">
        <div className="col-md-12 col-12 col-lg-12 mb-2 ">
          <div className="d-flex flex-wrap justify-content-between align-items-end">
            <div className="col-lg-3 col-12 col-md-4 col-sm-12">
              <h4>User Transaction History</h4>
            </div>
            <div className="col-lg-3 col-12 col-md-4 col-sm-12 ms-auto">
              <SearchBox allData={data} setFilteredData={setFilterPlanData} />
            </div>
          </div>
        </div>

        {Loading && Object.keys(FilterPlanData).length === 0 ? (
          <div
            className="d-flex  container"
            style={{ minHeight: "80vh", alignItems: "center" }}
          >
            <ReactLoading
              type={"bubbles"}
              color={"black"}
              height={100}
              width={100}
              className="reactLoadingDash "
            />
          </div>
        ) : (
          <>
            {FilterPlanData.length === 0 ? (
              <div className="container table-responsive mt-2">
                <table className="table table-bordered table-hover">
                  <thead className="thead-dark">
                    <tr>
                      <th>Sr. No.</th>
                      <th>User Name</th>
                      <th>User Phone</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                </table>
                <div className="container">
                  <p className="text-center text-danger">No data</p>
                </div>
              </div>
            ) : (
              <>
                <div className="table-responsive d-flex justify-content-between mt-3">
                  <table className="table table-hover table-bordered table-responsive">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>User Name</th>
                        <th>User Phone</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object?.entries(FilterPlanData)?.map(
                        ([key, entry], index) => (
                          <tr key={index} className="align-middle">
                            <td>{index + 1}</td>
                            <td>{entry.name}</td>
                            <td>{entry.user_phone}</td>

                            <td>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) =>
                                  redirectToTransactionHistory(e, entry)
                                }
                              >
                                View Details
                              </button>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SubscriptionRefundTransectionHistory;
