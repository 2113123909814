import jwtDecode from "jwt-decode";
import React, { useState } from "react";
import { useEffect } from "react";
import ReactLoading from "react-loading";
import { Link, useLocation } from "react-router-dom";
import { formatDate } from "../../helpers/functions/dateFormatter";
import auctionService from "../../services/auction/auction.service";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import Swal from "sweetalert2";
import Select from "react-select";
import DatePicker from "../../common/DatePicker";
import SearchBox from "../../common/SearchBox";

const AuctionReport = (props) => {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  const location = useLocation();
  let phone = user.phone;
  let state = user.state;

  let total_asset_count = 0;
  let total_approval_count = 0;
  let total_payment_count = 0;

  const [AuctionReport, setAuctionReport] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [fetchData, setFetchData] = useState(true);
  const [states, setStates] = useState([]);
  const [selectStates, setSelectStates] = useState({
    state: "",
  });
  const [FilteredDataAuctionReport, setFilteredDataAuctionReport] = useState(
    []
  );
  const [segType, setSegType] = useState([]);
  const [selectSegType, setSelectSegType] = useState({
    segmentType: "",
  });
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    const monthly_summary_start_date =
      location?.state?.monthly_summary_start_date;
    const monthly_summary_end_date = location?.state?.monthly_summary_end_date;
    if (monthly_summary_start_date && monthly_summary_end_date) {
      setStartDate(monthly_summary_start_date);
      setEndDate(monthly_summary_start_date);
    } else {
      // Get the current date
      const currentDate = new Date();

      // Get the year and month from the current date
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();
      const date = currentDate.getDate();

      let firstDayOfMonth = formatDate(new Date(year, month, 6));
      // Calculate the last day of the next month and then subtract one day
      let lastDayOfMonth = formatDate(new Date(year, month + 1, 5));

      if (date <= 5) {
        firstDayOfMonth = formatDate(new Date(year, month - 1, 6));
        lastDayOfMonth = formatDate(new Date(year, month, 5));
      }

      setStartDate(firstDayOfMonth);
      setEndDate(lastDayOfMonth);
      //   localStorage.setItem("firstDayOfMonth", firstDayOfMonth);
      //   localStorage.setItem("lastDayOfMonth", lastDayOfMonth);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    auctionService.GetSegmentType().then((res) => {
      if (res.status === 200) {
        const segTypeOption = res.data.map((segmentType) => {
          return { label: segmentType.name, value: segmentType };
        });
        setSegType(segTypeOption);
        setSelectSegType({
          segmentType: segTypeOption[0]?.value?.id,
        });
      }
    });
  }, []);

  useEffect(() => {
    try {
      if (startDate && endDate && fetchData) {
        setLoading(true);
        setAuctionReport([]);
        setFilteredDataAuctionReport([]);
        let form_data = new FormData();
        form_data.append("phone", phone);
        auctionService.GetState(form_data).then((res) => {
          if (res.status === 200) {
            const StateOption = res.data.map((state) => {
              return { label: state.name, value: state };
            });
            setStates(StateOption);

            let local_storage_state_name = "";
            if (!localStorage.getItem("report_state")) {
              local_storage_state_name = StateOption[0]?.value?.id;

              localStorage.setItem("report_state", StateOption[0]?.value?.id);
            } else {
              local_storage_state_name = localStorage.getItem("report_state");
            }
            setSelectStates({
              state: local_storage_state_name,
            });

            if (local_storage_state_name && selectSegType.segmentType !== "") {
              let form_data = new FormData();
              form_data.append("state", parseInt(local_storage_state_name));
              form_data.append("phone", phone);
              form_data.append("segment_type", selectSegType?.segmentType);
              form_data.append("start_date", `${startDate} 00:01`);
              form_data.append("end_date", `${endDate} 23:59`);
              auctionService.GetAllAuctionReport(form_data).then((res) => {
                setLoading(false);
                setFetchData(false);
                if (res.status === 200) {
                  setAuctionReport(res.data);
                  setFilteredDataAuctionReport(res.data);
                }
              });
            } else {
              setLoading(false);
              setFetchData(false);
            }
          }
        });
      }
    } catch (error) {
      setLoading(false);
      setFetchData(false);
      errorToast(error);
      console.log(error);
    }
  }, [startDate && endDate && fetchData && selectSegType.segmentType]);

  const handleSelectSegmentType = (e) => {
    // localStorage.setItem("segmentType", e.value);
    setSelectSegType({ segmentType: e.value.id });
    setLoading(true);
    setFetchData(true);
    setAuctionReport([]);
    setFilteredDataAuctionReport([]);
    let form_data = new FormData();
    form_data.append("phone", phone);
    form_data.append("end_date", `${endDate} 23:59`);
    form_data.append("start_date", `${startDate} 00:01`);
    form_data.append("state", parseInt(selectStates.state));
    form_data.append("segment_type", parseInt(e.value.id));
    auctionService.GetAllAuctionReport(form_data).then((res) => {
      setFetchData(false);
      setLoading(false);
      if (res.status === 200) {
        setAuctionReport(res.data);
        setFilteredDataAuctionReport(res.data);
      }
    });
  };

  const handleSelectState = (e) => {
    localStorage.setItem("report_state", e.value.id);
    setSelectStates({ state: e.value.id });
    setLoading(true);
    setFetchData(true);
    setAuctionReport([]);
    setFilteredDataAuctionReport([]);
    let form_data = new FormData();

    form_data.append("phone", phone);
    form_data.append("end_date", `${endDate} 23:59`);
    form_data.append("start_date", `${startDate} 00:01`);
    form_data.append("segment_type", selectSegType?.segmentType);
    form_data.append("state", parseInt(e.value.id));
    auctionService.GetAllAuctionReport(form_data).then((res) => {
      setFetchData(false);
      setLoading(false);
      if (res.status === 200) {
        setAuctionReport(res.data);
        setFilteredDataAuctionReport(res.data);
      }
    });
  };

  return (
    <>
      <div className="container mt-4">
        <div className="col-md-12 col-12 col-lg-12 mb-2 ">
          <div className="d-flex flex-wrap justify-content-between align-items-end">
            <div className="col-lg-4 col-12 col-md-6 col-sm-12">
              <span
                className="heading heading5 hind-font medium-font-weight"
                style={{
                  fontSize: "25px",
                  color: "black !important",
                  borderBottom: "1px solid grey",
                }}
              >
                Auction Report
              </span>
            </div>
            <div
              className="col-lg-3 col-12 col-md-4 col-sm-12"
              style={{ paddingRight: "10px" }}
            >
              <SearchBox
                placeholder="Search by Auction Id..."
                style={{ margin: "1px !important", fontSize: "16px" }}
                allData={AuctionReport}
                setFilteredData={setFilteredDataAuctionReport}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 col-12 col-lg-12 mb-2 ">
          <div className="d-flex flex-wrap justify-content-between align-items-end">
            <div
              className="col-lg-3 col-12 col-md-4 col-sm-2 mb-2"
              style={{ paddingRight: "10px" }}
            >
              <Select
                options={segType}
                isDisabled={Loading}
                value={
                  segType &&
                  segType.length !== 0 &&
                  segType.filter(
                    (segmentType) =>
                      // console.log(
                      //   segmentType?.value?.id,
                      //   parseInt(selectSegType?.segmentType)
                      // )
                      segmentType?.value?.id ===
                      parseInt(selectSegType?.segmentType)
                  )[0]
                }
                onChange={handleSelectSegmentType}
              />
            </div>
            <div
              className="col-lg-3 col-12 col-md-4 col-sm-2 mb-2"
              style={{ paddingRight: "10px" }}
            >
              <Select
                options={states}
                isDisabled={Loading}
                value={
                  states &&
                  states.length !== 0 &&
                  states.filter(
                    (state) =>
                      state?.value?.id === parseInt(selectStates?.state)
                  )[0]
                }
                onChange={handleSelectState}
              />
            </div>
            <div className="col-md-12 col-lg-5 col-12 mb-2">
              <div className="d-flex justify-content-between align-items-end gap-1">
                <div className="col-5">
                  <DatePicker
                    date={startDate}
                    id="start_date"
                    name="start_date"
                    placeholder="Start Date"
                    setDate={(e) => {
                      setStartDate((prev) => e);
                    }}
                    label="Start Date"
                    isRequired={false}
                  />
                </div>

                <div className="col-5">
                  <DatePicker
                    date={endDate}
                    id="end_date"
                    name="end_date"
                    placeholder="End Date"
                    setDate={(e) => {
                      setEndDate((prev) => e);
                    }}
                    label="End Date"
                    isRequired={false}
                  />
                </div>
                <button
                  className=" btn btn-primary"
                  onClick={() => setFetchData(true)}
                  disabled={Loading}
                >
                  Go
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {Loading && Object.keys(FilteredDataAuctionReport).length === 0 ? (
        <ReactLoading
          type={"bubbles"}
          color={"black"}
          height={100}
          width={100}
          className="reactLoadingDash"
        />
      ) : (
        <>
          {FilteredDataAuctionReport.length === 0 ? (
            <div className="container table-responsive mt-2">
              <table className="table table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th>No.</th>
                    <th>Auction ID</th>
                    <th>Auction Name(Financier)</th>
                    <th>Asset Count</th>
                    <th>State</th>
                    <th>segment type</th>
                    <th>Statr Date</th>
                    <th>End Date</th>
                    <th>Approval Count</th>
                    <th>Payment Count</th>
                    <th>View</th>
                  </tr>
                </thead>
              </table>
              <div className="container">
                <p className="text-center text-danger">No data</p>
              </div>
            </div>
          ) : (
            <>
              <div
                className="container table-responsive mt-2"
                style={{
                  minHeight: "15rem",
                  maxHeight: "35rem",
                  overflowY: "auto",
                }}
              >
                <table className="table table-bordered table-hover text-center">
                  <thead className="thead-dark">
                    <tr>
                      <th>No.</th>
                      <th>Auction ID</th>
                      <th>Auction Name(Financier)</th>
                      <th>State</th>
                      <th>segment type</th>
                      <th>Statr Date</th>
                      <th>End Date</th>
                      <th>Asset Count</th>
                      <th>Approval Count</th>
                      <th>Payment Count</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(FilteredDataAuctionReport).map(
                      ([key, index]) => {
                        let data = FilteredDataAuctionReport[key];
                        total_asset_count =
                          total_asset_count + data["asset_count"];
                        total_approval_count =
                          total_approval_count + data["is_approved_count"];
                        total_payment_count =
                          total_payment_count + data["payment_pending_count"];

                        return (
                          <tr>
                            <td>{parseInt(key) + 1}</td>
                            <td>
                              <Link
                                to={
                                  "/arDashboard/AuctionReport/AssetListForAuctionReport:" +
                                  data["auction_id"] +
                                  "&endDate=" +
                                  data["end_date"] +
                                  "&startDate=" +
                                  data["start_date"]
                                }
                                style={{
                                  color: "black ",
                                  textDecoration: "none",
                                }}
                              >
                                {data["auction_id"]}
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={
                                  "/arDashboard/AuctionReport/AssetListForAuctionReport:" +
                                  data["auction_id"] +
                                  "&endDate=" +
                                  data["end_date"] +
                                  "&startDate=" +
                                  data["start_date"]
                                }
                                style={{
                                  color: "black ",
                                  textDecoration: "none",
                                }}
                              >
                                {data["name"]}
                              </Link>
                            </td>

                            <td>
                              <Link
                                to={
                                  "/arDashboard/AuctionReport/AssetListForAuctionReport:" +
                                  data["auction_id"] +
                                  "&endDate=" +
                                  data["end_date"] +
                                  "&startDate=" +
                                  data["start_date"]
                                }
                                style={{
                                  color: "black ",
                                  textDecoration: "none",
                                }}
                              >
                                {data["state"]}
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={
                                  "/arDashboard/AuctionReport/AssetListForAuctionReport:" +
                                  data["auction_id"] +
                                  "&endDate=" +
                                  data["end_date"] +
                                  "&startDate=" +
                                  data["start_date"]
                                }
                                style={{
                                  color: "black ",
                                  textDecoration: "none",
                                }}
                              >
                                {data["segment_type"]}
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={
                                  "/arDashboard/AuctionReport/AssetListForAuctionReport:" +
                                  data["auction_id"] +
                                  "&endDate=" +
                                  data["end_date"] +
                                  "&startDate=" +
                                  data["start_date"]
                                }
                                style={{
                                  color: "black ",
                                  textDecoration: "none",
                                }}
                              >
                                {data["start_date"]}
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={
                                  "/arDashboard/AuctionReport/AssetListForAuctionReport:" +
                                  data["auction_id"] +
                                  "&endDate=" +
                                  data["end_date"] +
                                  "&startDate=" +
                                  data["start_date"]
                                }
                                style={{
                                  color: "black ",
                                  textDecoration: "none",
                                }}
                              >
                                {data["end_date"]}
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={
                                  "/arDashboard/AuctionReport/AssetListForAuctionReport:" +
                                  data["auction_id"] +
                                  "&endDate=" +
                                  data["end_date"] +
                                  "&startDate=" +
                                  data["start_date"]
                                }
                                style={{
                                  color: "black ",
                                  textDecoration: "none",
                                }}
                              >
                                {data["asset_count"]}
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={
                                  "/arDashboard/AuctionReport/AssetListForAuctionReport:" +
                                  data["auction_id"] +
                                  "&endDate=" +
                                  data["end_date"] +
                                  "&startDate=" +
                                  data["start_date"]
                                }
                                style={{
                                  color: "black ",
                                  textDecoration: "none",
                                }}
                              >
                                {data["is_approved_count"]}
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={
                                  "/arDashboard/AuctionReport/AssetListForAuctionReport:" +
                                  data["auction_id"] +
                                  "&endDate=" +
                                  data["end_date"] +
                                  "&startDate=" +
                                  data["start_date"]
                                }
                                style={{
                                  color: "black ",
                                  textDecoration: "none",
                                }}
                              >
                                {data["payment_pending_count"]}
                              </Link>
                            </td>

                            <td>
                              <button
                                className="btn btn-success btn-sm"
                                style={{
                                  margin: "2px !important",
                                  marginLeft: "5px",
                                }}
                              >
                                <Link
                                  to={
                                    "/arDashboard/AuctionReport/AssetListForAuctionReport:" +
                                    data["auction_id"] +
                                    "&endDate=" +
                                    data["end_date"] +
                                    "&startDate=" +
                                    data["start_date"]
                                  }
                                  style={{
                                    color: "white ",
                                    textDecoration: "none",
                                  }}
                                >
                                  View Details
                                </Link>
                              </button>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                  <tfoot>
                    {/* {Object.entries(FilteredDataAuctionReport).map(
                      ([key, index]) => {
                        let data = FilteredDataAuctionReport[key];
                        total_asset_count =
                        total_asset_count + data["asset_count"];
                      total_approval_count =
                        total_approval_count + data["is_approved_count"];
                      total_payment_count =
                        total_payment_count +
                        data["payment_pending_count"];

                        return ( */}
                    <tr>
                      <th className="text-center" colSpan={7}>
                        Grand Total
                      </th>
                      <th className="text-center">{total_asset_count}</th>
                      <th className="text-center">{total_approval_count}</th>
                      <th className="text-center">{total_payment_count}</th>
                      <th></th>
                    </tr>
                    {/* )})} */}
                  </tfoot>
                </table>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AuctionReport;
