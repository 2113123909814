import React, { useEffect, useState } from "react";

import axios from "axios";
import jwtDecode from "jwt-decode";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import addUser from "../../img/undraw_add_user_re_5oib.svg";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import auctionService from "../../services/auction/auction.service";

const AddUserWallet = () => {
  const token = localStorage.getItem("refreshToken");
  const history = useHistory();
  const user = jwtDecode(token);
  const source = axios.CancelToken.source();
  const phone = user.phone;

  const location = useLocation();

  const initalValue = {
    phone: "",
    email: "",
    password: "",
    confirm_password: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    aadhar: "",
    pan: "",
    address: "",
    state: "",
  };
  const [data, setData] = useState(initalValue);
  const [stateOptions, setStateOptions] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      setLoading(true);
      auctionService.GetUserStateList(source).then((res) => {
        setLoading(false);
        if (res?.states?.length >= 0) {
          let state_list = res?.states.map((value, key) => ({
            label: value,
            value: value,
          }));
          setStateOptions(state_list);
        }
      });
    } catch (error) {
      errorToast(error.msg);
    }
  }, []);

  const handleChangeState = (e, name) => {
    setData((prev) => ({
      ...prev,
      [name]: e?.value,
    }));
  };
  const handleChange = (e) => {
    e.preventDefault();
    const { value, name } = e.target.value;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const errors = [];
    if (!data?.phone) errors.push("Phone is required.");
    if (!data?.email) errors.push("Email is required.");
    if (!data?.password) errors.push("Password is required.");
    if (!data?.password) errors.push("Password is required.");
    if (!data?.confirm_password) errors.push("Confirm Password is required.");
    if (data?.password !== data?.confirm_password)
      errors.push("Password and Conform password need to be same.");
    if (!data?.first_name) errors.push("First Name is required.");
    if (!data?.middle_name) errors.push("Middle Name is required.");
    if (!data?.last_name) errors.push("Last Name is required.");
    if (!data?.aadhar) errors.push("Aadhar is required.");
    if (!data?.pan) errors.push("PAN is required.");
    if (!data?.address) errors.push("Address is required.");
    if (!data?.state) errors.push("State is required.");
    if (errors.length > 0) {
      setLoading(false);
      Swal.fire({
        html: `<p>${errors.join("<br>")}</p>`,
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }
    const form_data = new FormData();
    form_data.append("phone", data?.phone);
    form_data.append("email", data?.email);
    form_data.append("password", data?.password);
    form_data.append("first_name", data?.first_name);
    form_data.append("middle_name", data?.middle_name);
    form_data.append("last_name", data?.last_name);
    form_data.append("aadhar", data?.aadhar);
    form_data.append("pan", data?.pan);
    form_data.append("address", data?.address);
    form_data.append("state", data?.state);
    auctionService
      .AddUserToWallet(form_data, source)
      .then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          Swal.fire({
            html: `<p>${res.msg}!</p>`,
            icon: "success",
            confirmButtonText: "OK",
            preConfirm: () => successToast(res.msg),
          });
        } else {
          Swal.fire({
            html: `<p>${res.msg}!</p>`,
            icon: "warning",
            confirmButtonText: "OK",
            preConfirm: () => successToast(res.msg),
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        errorToast(error?.message);
      });
  };

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                </div>
                <div className="col-12">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-md-6 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-6 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-4 ">
                  <h2 className="m4">{user.name}</h2>
                </div>
                <div className="col-12 col-md-4 mt-4 mb-4">
                  <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container responsive mt-2 ms-auto ">
        <div className="row mt-3 mb-2">
          <span className="d-flex justify-content-between col-1">
            <i
              className="bi bi-arrow-left-circle "
              onClick={() => history.push("/meratractor")}
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </span>

          <div className="col-10 mt-2 float-start ms-2">
            <h4 className="">Add User with wallet</h4>
          </div>
        </div>
      </div>

      <div className="container d-flex d-flex-wrap justify-content-between ">
        <div className="col-md-7 col-12 rounded   bg-body">
          <hr className="soften mt-2" />
          <form
            className="ms-auto"
            // novalidate
            onSubmit={handleSubmit}
          >
            <div className="row mt-3">
              <div className="col-md-6 mb-3 form-group">
                <label for="phone">Phone</label>
                <input
                  className="form-control"
                  placeholder="Phone"
                  id="phone"
                  type="number"
                  name="phone"
                  value={data?.phone}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["phone"]: e.target.value,
                    }))
                  }
                  onWheel={(e) => e.target.blur()}
                  required
                  //   disabled
                />
              </div>
              <div className="col-md-6 form-group">
                <label for="email">Email</label>
                <input
                  className="form-control"
                  placeholder="Email"
                  id="email"
                  type="email"
                  name="email"
                  value={data?.email}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["email"]: e.target.value,
                    }))
                  }
                  required
                  //   disabled
                />
              </div>
            </div>

            {/* <div className="row mt-3">
              <div className="col-md-6 mb-3 form-group">
                <label for="password">Password</label>
                <input
                  className="form-control"
                  placeholder="Password"
                  id="password"
                  type="text"
                  name="password"
                  //   value={data.state_name}
                  //   onChange={handleChangeDescription}
                  //   required
                  //   disabled
                />
              </div>
              <div className="col-md-6 form-group">
                <label for="confirm_password">Confirm Password</label>
                <input
                  className="form-control"
                  placeholder="Description"
                  id="confirm_password"
                  type="text"
                  name="confirm_password"
                  //   value={data?.description}
                  //   onChange={(e) =>
                  //     setData((prev) => ({
                  //       ...prev,
                  //       ["description"]: e?.target?.value,
                  //     }))
                  //   }
                  // isLoading={segmentLoading}
                />
              </div>
            </div> */}

            <div className="row mt-3">
              <div className="col-md-6 mb-3 form-group">
                <label htmlFor="password">Password</label>
                <input
                  className="form-control"
                  placeholder="Password"
                  id="password"
                  type="password"
                  name="password"
                  value={data?.password}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["password"]: e.target.value,
                    }))
                  }
                  required
                  // disabled
                />
              </div>
              <div className="col-md-6 form-group">
                <label htmlFor="confirm_password">Confirm Password</label>
                <input
                  className={`form-control ${
                    data?.confirm_password &&
                    data?.password !== data?.confirm_password
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Confirm Password"
                  id="confirm_password"
                  type="password"
                  name="confirm_password"
                  value={data?.confirm_password}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["confirm_password"]: e.target.value,
                    }))
                  }
                  // isLoading={segmentLoading}
                  required
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-4 mb-3 form-group">
                <label htmlFor="first_name">First Name</label>
                <input
                  className="form-control"
                  placeholder="First Name"
                  id="first_name"
                  type="text"
                  name="first_name"
                  value={data.first_name}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["first_name"]: e.target.value,
                    }))
                  }
                  required
                  // disabled
                />
              </div>
              <div className="col-md-4 form-group">
                <label htmlFor="middle_name">Middle Name</label>
                <input
                  className="form-control"
                  placeholder="Middle Name"
                  id="middle_name"
                  type="text"
                  name="middle_name"
                  value={data?.middle_name}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["middle_name"]: e.target.value,
                    }))
                  }
                  // isLoading={segmentLoading}
                  required
                />
              </div>
              <div className="col-md-4 form-group">
                <label htmlFor="last_name">Last Name</label>
                <input
                  className="form-control"
                  placeholder="Last Name"
                  id="last_name"
                  type="text"
                  name="last_name"
                  value={data?.last_name}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["last_name"]: e.target.value,
                    }))
                  }
                  // isLoading={segmentLoading}
                  required
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6 mb-3 form-group">
                <label htmlFor="aadhar">Aadhar</label>
                <input
                  className="form-control"
                  placeholder="Aadhar"
                  id="aadhar"
                  type="number"
                  name="aadhar"
                  value={data.aadhar}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["aadhar"]: e.target.value,
                    }))
                  }
                  required
                  // disabled
                />
              </div>
              <div className="col-md-6 form-group">
                <label htmlFor="pan">Pan</label>
                <input
                  className="form-control"
                  placeholder="Pan"
                  id="pan"
                  type="text"
                  name="pan"
                  value={data?.pan}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["pan"]: e.target.value,
                    }))
                  }
                  required
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12 mb-3 form-group">
                <label htmlFor="address">Address</label>
                <textarea
                  className="form-control"
                  placeholder="Address"
                  id="address"
                  type="text"
                  name="address"
                  value={data.address}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      ["address"]: e.target.value,
                    }))
                  }
                  required
                  // disabled
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12 mb-3 form-group">
                <label htmlFor="state">State</label>
                <Select
                  id="state"
                  options={stateOptions}
                  onChange={(e) => handleChangeState(e, "state")}
                  value={stateOptions?.find(
                    (option) => option.value.id === data.state
                  )}
                  placeholder="State"
                  required
                  isLoading={loading}
                />
              </div>
            </div>

            {/* {asset_id !== undefined && asset_id !== "" ? ( */}
            <div className="d-flex  justify-content-center  mt-4">
              {buttonLoading ? (
                <button
                  className="btn btn-primary mt-2"
                  type="button"
                  disabled
                  id="loadingBtnTp"
                >
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className="btn btn-primary  col-4"
                  type="edit"
                  // onClick={(e) => handleSubmit(e)}
                >
                  Submit
                </button>
              )}
            </div>
            {/* ) : (
              <div className="d-flex  justify-content-center  mt-4">
                {buttonLoading ? (
                  <button
                    className="btn btn-primary mt-2"
                    type="button"
                    disabled
                    id="loadingBtnTp"
                  >
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </button>
                ) : (
                  <button
                    className="btn btn-primary  col-4"
                    type="submit"
                    // onClick={(e) => handleSubmit(e, "submit")}
                  >
                    Submit
                  </button>
                )}
              </div>
            )} */}
          </form>
        </div>
        <div className="ms-2">
          <img src={addUser} alt="Fill Form Img" width="80%" height="80%" />
        </div>
      </div>
    </>
  );
};

export default AddUserWallet;
