import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
// import dashboardService from "../../services/dashboard.service";
// import { useQuery } from "react-query";
import ErrorHandler from "../../common/ErrorHandler";
// import { errorToast } from "../../react-toastfiy/toast";
// import "../../css/dashboard.css";

function TableModal(props) {
  let history = useHistory();

  const { tabletitle, tabledata, colnames, mytask } = props;
  const operation_name = tabletitle;
  const stateWiseMilestoneData = tabledata;
  let rowCount = 0;
  let level_2 = mytask.level_2;
  try {
    return (
      <>
        <Modal
          {...props}
          size="lg"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            {/* <Modal.Title id="contained-modal-title-vcenter">
            {operation_name.toString().toUpperCase()}
            </Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className="container table-responsive tableContainer">
              <span
                className="heading heading5 hind-font medium-font-weight"
                style={{
                  fontSize: "25px",
                  color: "black !important",
                  borderBottom: "1px solid grey",
                }}
              >
                {operation_name.toString().toUpperCase()}
              </span>
              {/* <i className="bi bi-zoom-in ms-2" style={{cursor:'pointer'}} operation_name={operation_name} dash_level={"level_1"} onClick={handleZoom}></i> */}
              <table className="table table-bordered table-hover ">
                <thead className="thead-dark">
                  <tr>
                    <th className="col-md-1">No.</th>
                    {Object.entries(stateWiseMilestoneData).map(
                      ([key, index]) => {
                        let mainData = stateWiseMilestoneData[key];
                        let no = 1;
                        if (no == 1) {
                          no = no + 1;
                          return Object.entries(colnames[operation_name]).map(
                            ([key, index]) => {
                              return <th>{index}</th>;
                            }
                          );
                          // return Object.entries(mainData).map(([key, index]) => {

                          //     return <th>{key}</th>

                          // })
                        }
                      }
                    )}
                    {/* {Object.entries(colnames[operation_name]).map(([key, index]) => {
                          return <th>{index}</th>
                        })} */}
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(stateWiseMilestoneData).map(
                    ([key, index]) => {
                      let milestoneData = stateWiseMilestoneData[key];
                      return Object.entries(milestoneData).map(
                        ([key, index]) => {
                          {
                            let mainData = milestoneData[key];
                            let stateKey = key;
                            rowCount = rowCount + 1;
                            return (
                              <tr style={{ cursor: "pointer" }}>
                                <td
                                  className="col-md-1"
                                  onClick={() =>
                                    history.push(
                                      "/superAdminDashboard/shDashboard/",
                                      {
                                        state: stateKey,
                                        state_head:
                                          level_2[stateKey]["State Head"],
                                      }
                                    )
                                  }
                                >
                                  {rowCount}
                                </td>
                                {Object.entries(colnames[operation_name]).map(
                                  ([key, index]) => {
                                    if (mainData[index] == undefined) {
                                      return (
                                        <td
                                          onClick={() =>
                                            history.push(
                                              "/superAdminDashboard/shDashboard/",
                                              {
                                                state: stateKey,
                                                state_head:
                                                  level_2[stateKey][
                                                    "State Head"
                                                  ],
                                              }
                                            )
                                          }
                                        >
                                          -
                                        </td>
                                      );
                                    } else {
                                      return (
                                        <td
                                          onClick={() =>
                                            history.push(
                                              "/superAdminDashboard/shDashboard/",
                                              {
                                                state: stateKey,
                                                state_head:
                                                  level_2[stateKey][
                                                    "State Head"
                                                  ],
                                              }
                                            )
                                          }
                                        >
                                          {mainData[index]}
                                        </td>
                                      );
                                    }
                                  }
                                )}
                              </tr>
                            );
                          }
                        }
                      );
                    }
                  )}
                  {/* {Object.entries(stateWiseMilestoneData).map(([key, index]) => {
                        let stateSpecificData = stateWiseMilestoneData[key];
                        rowCount = rowCount + 1
                        return <tr>
                          <th scope="row">{rowCount}</th>
                          {Object.entries(stateSpecificData[0]).map(([key, index]) => {
                            return <td>{index}</td>
                          })}
                        </tr>
                      })} */}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
}

export default TableModal;
